activ.controller("contact", ["$scope", "$http", "$location", function($scope, $http, $location){


    $scope.showContact = function()
    {
        $location.path('/contact');
    };

    $scope.contactError = "";

    $scope.contactData = {
        name : "",
        email : "",
        telephone : "",
        message : "Please contact me re activDS®"
    };
    $scope.redirToActiv = function()
    {
        window.location = 'http://site.activds.com';
    };

    $scope.redirToLogin = function()
    {
        $location.path('/login');
    };

    $scope.validate = function()
    {

        $scope.contactError = "";

        $scope.contactData.name = $.trim($scope.contactData.name);

        if($scope.contactData.name.length == 0)
        {
            $scope.contactError = "Please Enter Your Name";
            $("#name").focus();
            return false;
        }

        $scope.contactData.email = $.trim($scope.contactData.email);
        if($scope.contactData.email.length == 0)
        {
            $scope.contactError = "Please Enter Your Email Address";
            $("#email").focus();
            return false;
        }

        var re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        var validEmail = re.test($scope.contactData.email);

        if(!validEmail)
        {
            $scope.contactError = "Please Enter a Valid Email Address";
            $("#email").focus();
            return false;
        }


        $scope.contactData.message = $.trim($scope.contactData.message);
        if($scope.contactData.message.length == 0)
        {
            $scope.contactError = "Please Enter a Message";
            $("#message").focus();
            return false;
        }


        return true;
    };

    $scope.contact = function() {
        if($scope.validate()) {

            $scope.statusText = "Submitting Contact Request";
            $scope.showSpinner = true;

            $http({ "method": "POST", "url": $scope.$parent.backend + "/contact", "params": {}, "data": $scope.contactData } ).
                success(function(data, status) {

                    $scope.showSpinner = false;
                    $scope.statusText = "";

                    if(data.success)
                    {
                        $scope.statusText = "Contact Request Sent. We will get back to you shortly.";
                        $("#contactForm")[0].reset();
                    }
                    else
                    {
                        $scope.contactError = "An Error has occurred while submitting request : <br />";
                        $scope.contactError += data.message;
                    }
                }).
                error(function(data, status) {
                    $scope.showSpinner = false;
                    $scope.statusText = "";

                    $scope.contactError = "An Error has occurred while submitting request : <br />";
                    $scope.contactError += data.message;

                });
        }
    };

}]);