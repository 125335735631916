activ.directive("homeNavigation", function(){

    return {

        restrict: "E",
        replace: true,
        templateUrl: "./views/homeNavigation.html?v=" + version,
        controller: function($scope) {
            var  homeNavigation = $(".homeNavigation");
            homeNavigation.find("a").each(function(){
                var el = this;
                if(el.getAttribute("href") === window.location.hash) {
                    el.classList.add("active");
                } else {
                    el.classList.remove("active");
                }
            });

            // Load last state for the toggle arrow
            var homeNavigationTitlesHidden = window.localStorage.getItem("homeNavigationTitlesHidden");
            if(homeNavigationTitlesHidden != null){
                $scope.$root.showTHNTitles = true;
                $scope.$root.toggleTHNitles();
             }else{
                $scope.$root.setContainerWidth();
            }

        }
    }
});
