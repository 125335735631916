activ.controller("displayReport", ["$scope", "$http", "$q", "$interval", "$timeout", function($scope, $http, $q, $interval, $timeout) {

    $scope.reportDataLoaded = true;

    $scope.currentReport = null;

    $scope.showReport = function () {
        if ($scope.reportDataLoaded) {
            $scope.reportDataLoaded = false;
            $http({ "method": "GET", "url": $scope.$parent.backend + "/displaysLatestReport", "params": {}, "data": {} }).
                success(function (data, status) {
                    $scope.reportDataLoaded = true;
                    if (data.success === false) {
                        return $scope.$parent.activAlert(data.error, 2000);
                    }
                    $scope.currentReport = data;
                }).error(function (data, status) {
                $scope.$parent.activAlert(data.error, 2000);
                });
        }

    };

    $scope.showChartsPopup = function () {
        var addEntityTemplateDOM = $("#displayDownTemplate").find(".templateContent");

        // Append a new container to the body and load dialog
        $scope.modalContainer = new ModalClass({title : "Displays Report", hideSaveButton: true, "onBeforeHide" : function(modal){
            var newProjectContainer = modal.find(".displaysReportPopupContainer");
            $("#displayDownTemplate").append(newProjectContainer);
        }, "body" :'<div class="displaysReportPopupContainer"></div>', popupBodyCssClass: 'add-overflow-y'});

        var body = $scope.modalContainer.find(".displaysReportPopupContainer");
        body.append(addEntityTemplateDOM);
        $scope.modalContainer.adjustContentHeight();
    };

    var checkTimer = null;

    $scope.$watch(function () {
        return $scope.$parent.openedTab;
    }, function (openedTab) {
        if (openedTab == "report") {
            $scope.showReport();
            // checkTimer = $interval(function () {
            //     $scope.showReport();
            // }, 5000);
        } else {
            if (checkTimer) {
                $interval.cancel(checkTimer);
                checkTimer = null;
            }
        }
    });

    $scope.$on("$destroy", function () {
        if (checkTimer) {
            $interval.cancel(checkTimer);
            checkTimer = null;
        }
    });

}]);
