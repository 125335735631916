activ.directive('toggleInput', function() {
    return {
        replace: true,
        restrict: 'E',
        template: '<div class="switchContainer">' +
                    '<p class="switchContainer__text">{{label}}</p>' +
                    '<label class="switch">' +
                      '<input type="checkbox" ng-checked="checked" ng-model="checked" ng-change="saveStatus()" />' +
                      '<div class="slider round"></div>' +
                    '</label>' +
                  '</div>',
        scope: {
            checked: "=",
            label: "@",
            key: "@"
        },
        link: function(scope, element, attrs) {

            var STORAGE_KEY = scope.key + 'ToggleStatus';

            scope.checked = retrieveStorageStatus();

            scope.saveStatus = function() {
                localStorage.setItem(STORAGE_KEY, scope.checked);
            };

            function retrieveStorageStatus() {
                var storageStatus = localStorage.getItem(STORAGE_KEY);
                return storageStatus === 'true';
            }
        }
    };
});