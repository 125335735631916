activ.directive('reportScheduleForm', function($http, $compile, $timeout, $q) {
    return {
        restrict: 'A',
        scope: {
            folderId: "=folder",
            afterUpdate: "=callback",
            create: "=create",
            checkState: "=state"
        },
        link: function(scope, element, attrs) {

            scope.displaysGroups = [];
            scope.wrongEmails = [];

            var validateReportEmails = function () {
                var re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                var valid = true;
                scope.wrongEmails = [];
                scope.reportEmailErrors = [];
                scope.reportEmail.emails.forEach(function (row, index) {
                    var emails = row.email.split(',');
                    emails.forEach(function (email) {
                        var trimmedEmail = $.trim(email);
                        if (!re.test(trimmedEmail)) {
                            valid = false;
                            scope.wrongEmails.push(index);
                        }
                    })
                });

                if (!valid) {
                    scope.modalContainer.setErrorText('Wrong emails');
                }
                if (!scope.$$phase) {
                    scope.$apply();
                }
                return valid;
            };

            var checkCurrentFolderScheduleState = function () {
                if (!scope.create) {
                    var deferer = loadReportSchedule();
                    deferer.promise.then(function (data) {
                        if (data.active) {
                            angular.element(element).removeClass("disabled").addClass("enabled");
                        } else {
                            angular.element(element).removeClass("enabled").addClass("disabled");
                        }
                    }, function (data) {
                        angular.element(element).removeClass("enabled").addClass("disabled");
                    });
                }
            };

            var validateDisplayGroupId = function () {
                if (scope.create) {
                    if (!parseInt(scope.reportEmail.displayGroupId)) {
                        scope.modalContainer.setErrorText('Choose display group');
                        return false;
                    } else {
                        return true;
                    }
                } else
                    return true;
            };

            var loadReportSchedule = function () {
                var deferer = new $q.defer();
                var requestData = {};
                if (scope.folderId) {
                    requestData.displayGroupId = scope.folderId;
                }
                $http({ "method": "POST", "url": scope.$parent.backend + "/getReportSchedule", "params": {}, "data": requestData }).
                    success(function (data, status) {
                        deferer.resolve(data);
                    }).error(function (data, status) {
                        deferer.reject(data);
                    });
                return deferer;
            };

            scope.openPopup = function () {

                var templateHTML = $compile(scope.popupTemplate)(scope);

                var buildPopup = function () {

                    // Append a new container to the body and load dialog
                    scope.modalContainer = new ModalClass({title : "Email Report", "onBeforeHide" : function(modal) {
                    }, "body" :templateHTML, popupBodyCssClass: 'add-overflow-y'});

                    scope.modalContainer.adjustContentHeight();
                    scope.modalContainer.primaryBtn.click(function(){
                        scope.saveSettings();
                    });
                };

                var loadDisplaysGroups = function (callback) {
                    if (scope.create) {
                        $http({ "method": "POST", "url": scope.$parent.backend + "/retreiveDisplayGroupsWithoutSchedule", "params": {}, "data": {} }).
                        success(function (data, status) {
                            scope.displaysGroups = data.list;
                            callback();
                        }).error(function (data, status) {
                            scope.displaysGroups = [];
                            callback();
                        });
                    } else {
                        callback();
                    }
                };

                var deferer = loadReportSchedule();

                loadDisplaysGroups(function () {
                    deferer.promise.then(function (data) {
                        if (data.success === false) {
                            scope.$parent.activAlert(data.error, 2000);
                        } else {
                            if (!scope.create)
                                scope.reportEmail = data;
                            else {
                                scope.reportEmail = {
                                    emails: [],
                                    deleted: false,
                                    displayGroupId: !scope.create ? scope.folderId : 0
                                };
                                scope.addEmail();
                            }
                            $timeout(function () {
                                buildPopup();
                            }, 0);
                        }
                    }, function (data) {
                        scope.reportEmail = {
                            emails: [],
                            deleted: false,
                            displayGroupId: !scope.create ? scope.folderId : 0
                        };
                        scope.addEmail();
                        if (data.error) {
                            scope.$parent.activAlert(data.error, 2000);
                        } else {
                            $timeout(function () {
                                buildPopup();
                            }, 0);
                        }
                    });
                })
            };

            scope.saveSettings = function () {
                if (validateReportEmails() && validateDisplayGroupId()) {
                    var scheduleActive = false;
                    scope.reportEmail.emails.forEach(function (emailItem) {
                        if (emailItem.active) {
                            scheduleActive = true;
                        }
                    });
                    var sendData = {
                        ID: scope.reportEmail.ID || 0,
                        emails: JSON.parse(angular.toJson(scope.reportEmail.emails)),
                        active: scheduleActive,
                        displayGroupId: scope.reportEmail.displayGroupId
                    };
                    if (!scope.create) {
                        sendData.displayGroupId = scope.folderId;
                    }
                    $http({ "method": "POST", "url": scope.$parent.backend + "/saveReportEmailSettings", "params": {}, "data": sendData }).
                    success(function (data, status) {

                        if (data.status) {
                            scope.modalContainer.hide();
                            scope.$parent.activAlert("Settings have been saved!", 2000);
                            checkCurrentFolderScheduleState();
                        } else {
                            scope.modalContainer.setStatusText(data.error, "red");
                        }
                        if (typeof scope.afterUpdate === "function") {
                            scope.afterUpdate.call();
                        }
                    }).error(function (data, status) {
                        scope.modalContainer.setStatusText("Error save settings.", "red");
                    });
                }
            };

            scope.addEmail = function () {
                scope.reportEmail.emails.push({
                    email: "",
                    scheduleEmail: true,
                    alerts: true,
                    active: true,
                    scheduleTime: new Date(),
                    days: []
                });
                if (scope.modalContainer)
                    scope.modalContainer.adjustContentHeight(250);

            }

            scope.removeEmail = function (index) {
                scope.reportEmail.emails.splice(index, 1);
                $timeout(function () {
                    if (scope.modalContainer)
                        scope.modalContainer.adjustContentHeight();
                }, 0);
            };

            scope.toggleDay = function (day, index) {
                var idx = scope.reportEmail.emails[index].days.indexOf(day);
                if (idx > -1) {
                    scope.reportEmail.emails[index].days.splice(idx, 1);
                } else {
                    scope.reportEmail.emails[index].days.push(day);
                    scope.reportEmail.emails[index].days.sort();
                }
            };

            scope.checkAllDays = function (index) {
                if (scope.reportEmail.emails[index].days.length === 7) {
                    scope.reportEmail.emails[index].days = [];
                } else {
                    for (var i = 0; i < 7; i++) {
                        if (scope.reportEmail.emails[index].days.indexOf(i) == -1) {
                            scope.reportEmail.emails[index].days.push(i);
                        }
                    }
                    scope.reportEmail.emails[index].days.sort();
                }
            };

            scope.checkStateCheckboxes = function (index, clickedKey, revertKey) {
                if (scope.reportEmail.emails[index] === undefined) {
                    return;
                }
                if (scope.reportEmail.emails[index][clickedKey]) {
                    scope.reportEmail.emails[index][clickedKey] = false;
                } else {
                    scope.reportEmail.emails[index][clickedKey] = true;
                }
                if (!scope.reportEmail.emails[index][clickedKey]) {
                    scope.reportEmail.emails[index][revertKey] = true;
                }
            };

            element.on("click", function (e) {
                scope.$apply(function () {
                    scope.openPopup();
                })
            });

            if (scope.checkState !== false) {
                scope.$watch(function () {
                    return scope.folderId;
                }, function (folderId) {
                    checkCurrentFolderScheduleState();
                });
                checkCurrentFolderScheduleState();
            }

            scope.popupTemplate = '' +
                '<div class="emailReportPopupContainer">' +
                    '<div class="templateContent">' +
                        '<div class="emailReport">' +
                        '<div class="clearfix report-schedule-select-folder" sty ng-if="create">' +
                            '<p class="displayDetailsInputLabel" style="margin-top:2px; vertical-align: middle; width: 135px;">Display group</p>'+
                            '<select ui-select2="{ width: 350}" ng-model="reportEmail.displayGroupId" data-placeholder="Select display group...">' +
                                '<option value=""></option>' +
                                '<option ng-repeat="item in displaysGroups" value="{{item.ID}}">{{item.name}}</option>' +
                            '</select>'+
                        '</div>'+
                            '<div class="form-group">'+
                                '<button ng-click="addEmail()" style="background-color:#d2d2d2; font-size:12px; height:27px; color:black; margin: 5px 0;">'+
                                    '<span class="glyphicon glyphicon-plus"></span>'+
                                    'Add an Email Group'+
                                '</button>'+
                            '</div>'+
                            '<div ng-repeat="email in reportEmail.emails" class="report-email-group">'+
                                '<div class="form-group">'+
                                '<div class="emails-container">'+
                                '<div class="email-report-item" ng-class="{emailError: wrongEmails.indexOf($index) > -1}">'+
                                '<input class="form-control schedule-emails" type="text" id="reportEmail_emails_{{$index}}" ng-model="reportEmail.emails[$index].email" placeholder="Emails (separated by comma)">'+
                                '<label class="checkbox-inline"><input type="checkbox" ng-checked="reportEmail.emails[$index].scheduleEmail" ng-click="checkStateCheckboxes($index, \'scheduleEmail\', \'alerts\')"> Scheduled report</label>'+
                                '<label title="Email when a display goes down" class="checkbox-inline"><input type="checkbox" ng-checked="reportEmail.emails[$index].alerts" ng-click="checkStateCheckboxes($index, \'alerts\', \'scheduleEmail\')"> Alerts <span class="checkbox-info glyphicon glyphicon-info-sign"></span></label>'+
                                '<a ng-if="reportEmail.emails.length > 1" ng-click="removeEmail($index)" title="Remove an Email Group"><span class="glyphicon glyphicon-remove"></span></a>'+
                                '</div>'+
                                '</div>'+
                                '</div>'+
                                '<div class="clearfix" sty>'+
                                    '<p class="displayDetailsInputLabel" style="margin-top:40px; vertical-align: middle; width: 80px;">Time</p>'+
                                    '<timepicker mousewheel="false" id="timePicker_scheduleTime_{{$index}}" style="display:inline-block; float:left;"'+
                                        'ng-model="reportEmail.emails[$index].scheduleTime"'+
                                        'hour-step="1" minute-step="1" show-meridian="false"></timepicker>'+
                                '</div>'+
                                '<div class="clearfix" sty>'+
                                    '<p class="displayDetailsInputLabel" style="margin-top:2px; vertical-align: middle; width: 80px;">Days</p>'+
                                    '<label class="checkbox-inline">'+
                                        '<input type="checkbox" ng-checked="reportEmail.emails[$index].days.length == 7" ng-click="checkAllDays($index)"> All days'+
                                    '</label>'+
                                    '<label class="checkbox-inline">'+
                                        '<input type="checkbox" value="0" ng-checked="reportEmail.emails[$index].days.indexOf(0) > -1" ng-click="toggleDay(0, $index)"> Mon'+
                                    '</label>'+
                                    '<label class="checkbox-inline">'+
                                        '<input type="checkbox" value="1" ng-checked="reportEmail.emails[$index].days.indexOf(1) > -1" ng-click="toggleDay(1, $index)"> Tue'+
                                    '</label>'+
                                    '<label class="checkbox-inline">'+
                                        '<input type="checkbox" value="2" ng-checked="reportEmail.emails[$index].days.indexOf(2) > -1" ng-click="toggleDay(2, $index)"> Wed'+
                                    '</label>'+
                                    '<label class="checkbox-inline">'+
                                        '<input type="checkbox" value="3" ng-checked="reportEmail.emails[$index].days.indexOf(3) > -1" ng-click="toggleDay(3, $index)"> Thu'+
                                    '</label>'+
                                    '<label class="checkbox-inline">'+
                                        '<input type="checkbox" value="4" ng-checked="reportEmail.emails[$index].days.indexOf(4) > -1" ng-click="toggleDay(4, $index)"> Fri'+
                                    '</label>'+
                                    '<label class="checkbox-inline">'+
                                        '<input type="checkbox" value="5" ng-checked="reportEmail.emails[$index].days.indexOf(5) > -1" ng-click="toggleDay(5, $index)"> Sat'+
                                    '</label>'+
                                    '<label class="checkbox-inline">'+
                                        '<input type="checkbox" value="6" ng-checked="reportEmail.emails[$index].days.indexOf(6) > -1" ng-click="toggleDay(6, $index)"> Sun'+
                                    '</label>'+
                                '</div>'+
                                '<div class="form-group label-checkbox-group">'+
                                    '<label for="reportEmail_active_{{$index}}">Schedule Enabled<input id="reportEmail_active_{{$index}}" type="checkbox" ng-model="reportEmail.emails[$index].active"></label>'+
                                '</div>'+
                            '</div>'+
                            '<br/>'+
                        '</div>' +
                    '</div>' +
                '</div>';
        }
    };
});
