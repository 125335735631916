activ.controller("licence", ["$scope", "$http", "$location", "$routeParams", "$sce", "$compile", "$timeout",
                function($scope, $http, $location, $routeParams, $sce, $compile, $timeout){
  $scope.pageTitle = "Licence";
  $scope.dataLoaded = false;

  $scope.licenceType = [
    {"name": "Application", "value": "application"},
    {"name": "Addon", "value": "addon"}
  ];

  $scope.widgets = [];

  var initialLicence = {
    name: "",
    type: "application",
    payMonthly: true,
    payYearly: false,
    monthlyAmount: "0",
    yearlyAmount: "0",
    yearlyFreeMonths: "",
    trialPeriod: 0
  };
  var initialLicencelimits = {
    project: {
      enable: true,
      numberOfProjects: 5
    },
    library: {
      enable: true,
      storage: 10
    },
    teams: {
      enable: false,
      numberOfTeams: 5
    },
    displays: {
      enable: true,
      numberOfDisplays: 3
    },
    user: {
      enable: true,
      numberOfUsers: 1
    },
    templates: {
      enable: true,
      numberOfTemplates: 1
    },
    widgets: {
        enable: true,
        canPurchaseAddons: true,
        widgets: []
    },
    dynamic: {
      user: {
        enable: false,
        items: [{numberOfUsers: 1, amountChange: 0}]
      },
      project: {
        enable: false,
        items: [{numberOfProjects: 1, amountChange: 0}]
      },
      library: {
        enable: false,
        items: [{storage: 10, amountChange: 0}]
      },
      teams: {
        enable: false,
        items: [{numberOfTeams: 10, amountChange: 0}]
      },
      displays: {
        enable: false,
        items: [{id: 0, numberOfDisplays: 10, amountChange: 0}]
      },
      templates: {
        enable: false,
        items: [{id: 0, numberOfTemplates: 10, amountChange: 0}]
      }
    }
  };

  //$scope.licence = JSON.parse(JSON.stringify(initialLicence));
  //$scope.licence.limits = JSON.parse(JSON.stringify(initialLicencelimits));

  $scope.showAddLicence = function(){
      if ($scope.licence && $scope.licence.limits && $scope.licence.limits.dynamic) {
        while ($scope.licence.limits.dynamic['user'].items.length > 0) {
          $scope.licence.limits.dynamic['user'].items.pop();
        }
      }

      $scope.licence = JSON.parse(JSON.stringify(initialLicence));
      $scope.licence.limits = JSON.parse(JSON.stringify(initialLicencelimits));

      $timeout(function() {
        var licenceTemplate = $("#licenceTemplate");

        licenceTemplate.find('.user-dynamic .properties').empty().prepend(getUserPropertiesTemplate());
        licenceTemplate.find('.display-dynamic .properties').empty().prepend(getDisplayPropertiesTemplate());
        licenceTemplate.find('.teams-dynamic .properties').empty().prepend(getTeamPropertiesTemplate());
        licenceTemplate.find('.project-dynamic .properties').empty().prepend(getProjectPropertiesTemplate());

        var templateHTML = $compile($("#licenceTemplate").html())($scope);

        // Append a new container to the body and load dialog
        $scope.modalContainer = new ModalClass({title : "New Licence", "onBeforeHide" : function(modal){
        }, "body" : templateHTML, "popupBodyCssClass": "add-overflow-y"});

        $scope.modalContainer.find('.btn-cancel').click(function() {
          $scope.modalContainer.hide();
        });

        $scope.modalContainer.find('.btn-primary').click(function() {
          updateLicence();
        });
        $scope.modalContainer.hideFooter();
        $scope.modalContainer.adjustContentHeight();
      });
  };

  $scope.licenceLimit = function(key) {
    var itemIndex = [];

    for(var i = 0; i < $scope.licence.limits.dynamic[key].items.length; i++) {
      itemIndex.push(i);
    }

    return itemIndex;
  };

  $scope.showLicenceDetails = function(key, licence){
      $scope.licence = JSON.parse(JSON.stringify(licence));

      if (typeof licence.limits === 'string') {
        $scope.licence.limits = JSON.parse(licence.limits);
      } else {
        $scope.licence.limits = JSON.parse(JSON.stringify(licence.limits));
      }

      $timeout(function() {
        var licenceTemplate = $("#licenceTemplate");

        licenceTemplate.find('.user-dynamic .properties').empty().prepend(getUserPropertiesTemplate());
        licenceTemplate.find('.displays-dynamic .properties').empty().prepend(getDisplayPropertiesTemplate());
        licenceTemplate.find('.teams-dynamic .properties').empty().prepend(getTeamPropertiesTemplate());
        licenceTemplate.find('.project-dynamic .properties').empty().prepend(getProjectPropertiesTemplate());

        var templateHTML = $compile($("#licenceTemplate").html())($scope);

        // Append a new container to the body and load dialog
        $scope.modalContainer = new ModalClass({title : "Update Licence", "onBeforeHide" : function(modal){
        }, "body" :templateHTML, "popupBodyCssClass": "add-overflow-y"});

        $scope.modalContainer.find('.btn-cancel').click(function() {
          $scope.modalContainer.hide();
        })

        $scope.modalContainer.find('.btn-primary').html("Update Licence");

        $scope.modalContainer.find('.btn-primary').click(function() {
          updateLicence(key);
        });

        $scope.modalContainer.hideFooter();
        $scope.modalContainer.adjustContentHeight();
      })
  };

  updateLicence = function(key) {

    if (validateLicence($scope.licence)) {
      $scope.licence.accessRights = getAccessRights($scope.licence.limits);

      var postData = { licence : $scope.licence };

      var licenceCallback = function(data) {
        if (!$scope.licence.ID) {
          $scope.licences.push(data.licence);
        } else if (key !== undefined) {
          $scope.licence = data.licence;
          $scope.licences[key] = data.licence;
        }
        $scope.modalContainer.hide();
      }

      $scope.licencePost(postData, licenceCallback);
    } else {
      $scope.modalContainer.adjustContentHeight();
    }
  };

  validateLicence = function(licence) {
    if (licence.name == "") {
      $scope.modalContainer.setStatusText("Error : Please enter a licence name", "red");
      return false;
    }

    return true;
  };

  getAccessRights = function(limits) {
    var accessRightsArray = [];

    if (limits.project.enable) {
      accessRightsArray.push(AllAccessRightsDict["Projects - Create"]);
      accessRightsArray.push(AllAccessRightsDict["Projects - Edit"]);
      accessRightsArray.push(AllAccessRightsDict["Projects - Delete"]);
      accessRightsArray.push(AllAccessRightsDict["Projects - Publish"]);

      accessRightsArray.push(AllAccessRightsDict["Project Groups - Create"]);
      accessRightsArray.push(AllAccessRightsDict["Project Groups - Edit"]);
      accessRightsArray.push(AllAccessRightsDict["Project Groups - Delete"]);
    }

    if (limits.library.enable) {
      accessRightsArray.push(AllAccessRightsDict["Assets - Create"]);
      accessRightsArray.push(AllAccessRightsDict["Assets - Delete"]);
    }

    if (limits.displays.enable) {
      accessRightsArray.push(AllAccessRightsDict["Displays - Create"]);
      accessRightsArray.push(AllAccessRightsDict["Displays - Edit and Publish"]);
      accessRightsArray.push(AllAccessRightsDict["Displays - Delete"]);

      accessRightsArray.push(AllAccessRightsDict["Displays - Change Default Project"]);
      accessRightsArray.push(AllAccessRightsDict["Displays - Set All Display Override"]);

      accessRightsArray.push(AllAccessRightsDict["Display Groups - Create"]);
      accessRightsArray.push(AllAccessRightsDict["Display Groups - Edit"]);
      accessRightsArray.push(AllAccessRightsDict["Display Groups - Delete"]);
    }

    if (limits.teams.enable) {
      accessRightsArray.push(AllAccessRightsDict["Teams - Create"]);
      accessRightsArray.push(AllAccessRightsDict["Teams - Edit"]);
      accessRightsArray.push(AllAccessRightsDict["Teams - Delete"]);
    }

    if (limits.user.enable) {
      accessRightsArray.push(AllAccessRightsDict["Users - Create"]);
      accessRightsArray.push(AllAccessRightsDict["Users - Edit"]);
      accessRightsArray.push(AllAccessRightsDict["Users - Delete"]);
    }

    return getCombinedAccessRightsValue(accessRightsArray);
  };

  $scope.addNewDynamicProperty = function(fieldKey, item) {
    $scope.licence.limits.dynamic[fieldKey].items.push(item);
  };

  $scope.deleteDynamicProperty = function(fieldKey, index) {
    $scope.licence.limits.dynamic[fieldKey].items.splice(index, 1);
  };

  $scope.licencePost = function(postData, callback){
    var url = $scope.$parent.backend + "/licences";

    if (postData.licence.ID) {
      url += "/" + postData.licence.ID;
    }

    postData.licence.limits = JSON.stringify(postData.licence.limits);

    $http({
      method: "POST", url: url, "params": {},
      "data": postData
    })
    .success(function (data, status){
      callback(data);
    })
    .error(function (data) {
      callback({success:false, error:data.message});
    });
  };

  $scope.retrieveLicences = function() {
      var backend = $scope.backend || $scope.$parent.backend;

      $http({ "method": "POST", "url": backend + "/retrieveLicences", "params": {}}).
          success(function(data, status){
              if(data.success) {
                  $scope.licences = data.licences;
                  $scope.dataLoaded = true;
              }

          }).
          error(function(data, status){
              $scope.dataLoaded = true;
              $scope.licences = [];
              console.error(data);
          });

  };

  $scope.retrieveWidgets = function () {
      var backend = $scope.backend || $scope.$parent.backend;
      $http({ "method": "POST", "url": backend + "/retrieveLibrary", "params": {}, data: {assetType:"Widgets", folderId: 0}}).
      success(function(data, status){
          if (data.success) {
              $scope.widgets = data.assets;
              if ($scope.widgets[0] !== undefined) {
                  //TODO: temporary solution
                  //TODO: we added RSS widget
                  initialLicencelimits.widgets.widgets.push($scope.widgets[0].ID);
              }
          }
      }).
      error(function(data, status){
          console.error(data);
      });
  };

  $scope.deleteLicence = function(licence)
  {
      // Pass data to quick publish
      $http({ "method": "DELETE", "url": $scope.$parent.backend + "/licences/" + licence.ID,
              "params": {}, "data": {} }).
          success(function(data, status){
              if(data.success)
              {
                  // Remove from list
                  $scope.deleteLicenceFromMemory(licence);
                  $scope.$root.hideActivConfirm();
              }else{
                  $scope.$root.confirmModal.setStatus("An error has occurred : " + data.error, "red");
              }
          }).
          error(function(data, status){
              $scope.$root.confirmModal.setStatus("An error has occurred", "red");
              console.log(data);
          });


  };

  $scope.deleteLicenceFromMemory = function(licence)
  {
      for (var i = 0; i < $scope.licences.length; i++) {
          var l = $scope.licences[i];
          if(l.ID == licence.ID)
          {
              $scope.licences.splice(i,1);
              break;
          }
      }
  };

  $scope.changeLicencePaymentTerm = function (licenceField, revertLicenceField) {
      if ($scope.licence[licenceField]) {
          $scope.licence[licenceField] = false;
          $scope.licence[revertLicenceField] = true;
      } else {
          $scope.licence[licenceField] = true;
          $scope.licence[revertLicenceField] = false;
      }
  };

  $scope.showDeleteLicence = function(licence)
  {
      $scope.$root.showActivConfirm("Are you sure you want to delete licence : " + licence.name,
          function(){
          $scope.deleteLicence(licence);
      });
  };

  getUserPropertiesTemplate = function() {
    return '<div class="form-group sidebyside" ng-repeat="item in licence.limits.dynamic.user.items track by $index">' +
      '<label>Max number of users:</label><input class="form-control" type="text" ng-model="item.numberOfUsers" placeholder="Max number of users"/>' +
      '<label>Price change:</label><input class="form-control" type="text" ng-model="item.amountChange" placeholder="Price change"/>' +
      '<button class="dPanel_blueButton" style="float:right;" ng-click="deleteDynamicProperty(&apos;user&apos;, $index)">Delete</button>' +
    '</div>';
  };

  getDisplayPropertiesTemplate = function() {
    return '<div class="form-group sidebyside" ng-repeat="item in licence.limits.dynamic.displays.items track by $index">' +
      '<label>Max number of displays:</label><input class="form-control" type="text" ng-model="item.numberOfDisplays" placeholder="Max number of users"/>' +
      '<label>Price change:</label><input class="form-control" type="text" ng-model="item.amountChange" placeholder="Price change"/>' +
      '<button class="dPanel_blueButton" style="float:right;" ng-click="deleteDynamicProperty(&apos;displays&apos;, $index)">Delete</button>' +
    '</div>';
  };

  getTeamPropertiesTemplate = function() {
    return '<div class="form-group sidebyside" ng-repeat="item in licence.limits.dynamic.teams.items track by $index">' +
      '<label>Max number of teams:</label><input class="form-control" type="text" ng-model="item.numberOfTeams" placeholder="Max number of users"/>' +
      '<label>Price change:</label><input class="form-control" type="text" ng-model="item.amountChange" placeholder="Price change"/>' +
      '<button class="dPanel_blueButton" style="float:right;" ng-click="deleteDynamicProperty(&apos;teams&apos;, $index)">Delete</button>' +
    '</div>';
  };

  getProjectPropertiesTemplate = function() {
    return '<div class="form-group sidebyside" ng-repeat="item in licence.limits.dynamic.project.items track by $index">' +
      '<label>Max number of projects:</label><input class="form-control" type="text" ng-model="item.numberOfProjects" placeholder="Max number of users"/>' +
      '<label>Price change:</label><input class="form-control" type="text" ng-model="item.amountChange" placeholder="Price change"/>' +
      '<button class="dPanel_blueButton" style="float:right;" ng-click="deleteDynamicProperty(&apos;project&apos;, $index)">Delete</button>' +
    '</div>';
  };

  $scope.retrieveLicences();
  $scope.retrieveWidgets();
}]);
