
function ModalClass(options)
{
	var modalOptions = {
		title : "ActivDS",
		body : "",
		buttons : {
					"submit" : {title : "Save"},
					"close" : {title : "Close"},
					"delete" : {title : "Delete"}
				},

		deleteButton : false,
		removeOnHide : true,
		autoShow : true,
		removeOtherModals : true,
		keyboard : true,
		backdrop : true,
        hideSaveButton : false,
		onBeforeHide : null,
		appendTo : document.body,
		onSaveClick : null,
		hideOnSave : false,
		popupBodyCssClass: ""
	};

	$.extend(true, modalOptions, options);

	if(modalOptions.removeOtherModals)
	{
		$(".activdsModal").remove();
	}

	var modalContainer = $('<div class="activdsModal modal unselectable" style="color:black;">'
	  +'<div class="modal-dialog">'
	    +'<div class="activModalContent modal-content">'
	      +'<div class="modal-header">'
	        +'<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
	        +'<h4 class="modal-title">'+modalOptions.title+'</h4>'
	      +'</div>'
	      +'<div class="modal-body ' + modalOptions.popupBodyCssClass + '" style="">'
	      +'</div>'
	      +'<div class="modal-footer">'
	      	+ '<div style="clear:both; text-align: left; display:none;" class="statusText">&nbsp;</div>'
	      	+ '<div style="margin-top:5px;">'
		        + '<button type="button" class="btn btn-default" data-dismiss="modal">'+ modalOptions.buttons.close.title +'</button>'
		        + '<button type="button" class="btn btn-danger">'+modalOptions.buttons.delete.title+'</button>'
		        + '<button type="button" class="btn btn-primary">'+modalOptions.buttons.submit.title+'</button>'
		     +'</div>'   
	      +'</div>'
	    +'</div>' // <!-- /.modal-content -->
	  +'</div>' //<!-- /.modal-dialog -->
	+'</div>') // <!-- /.modal -->'


	$(modalOptions.appendTo).append(modalContainer);

	var thisObj = modalContainer;

	modalContainer.on('hidden.bs.modal', function (e){
		if(thisObj.modalOptions.onBeforeHide) {
			thisObj.modalOptions.onBeforeHide(thisObj);
		}
		if(thisObj.modalOptions.removeOnHide){
			$(this).remove();
		}
	});

	modalContainer.modal({
	  keyboard: false
	});
	
	if(!modalOptions.backdrop)
		modalOptions.backdrop = "static";

	modalContainer.modal({
	  backdrop: modalOptions.backdrop
	});
	

	var modalObj =  new Object();
    
    var thisObj = modalObj;
	modalContainer.on('show.bs.modal', function (e) {
         thisObj.adjustContentHeight();
	});

    modalObj.adjustContentHeight = function(additionalContentHeight)
    {
		var jObj = $(thisObj.modalContainer);

        var winHeight = $(window).height();
		var headerHeight = jObj.find(".modal-header").outerHeight();
		var footerHeight = jObj.find(".modal-footer").outerHeight();
		var contentHeight = jObj.find(".templateContent").outerHeight() + (additionalContentHeight ? additionalContentHeight : 0);

		var gapTopBottom = 2 * 40;

		var occupiedSpace = headerHeight + footerHeight + gapTopBottom;
		var maxHeight = winHeight - occupiedSpace;
		var setModalHeight = Math.min(maxHeight, contentHeight);

		thisObj.setBodyHeight(setModalHeight);
    };

	modalObj.resetContentWidth = function()
	{
		thisObj.setBodyWidth(thisObj.originalWidth);
	};

	modalObj.maximiseContentWidth = function()
	{
		var jObj = $(thisObj.modalContainer);

		var winWidth = $(window).width();
		var contentWidth = jObj.find(".templateContent").outerWidth();

		var sideGap = 2 * 40;

		var widthToSet = winWidth - sideGap;

		var setModalWidth = Math.min(winWidth, widthToSet);

		thisObj.setBodyWidth(setModalWidth);
	};

	modalObj.modalOptions = modalOptions;
	modalObj.modalContainer = modalContainer;

	modalObj.setBodyHeight = function(height){
		var jObj = $(thisObj.modalContainer);
		var modalBody = jObj.find(".modal-body");
		modalBody.height(height);
	};

	modalObj.setBodyWidth = function(width){
		var jObj = $(thisObj.modalContainer);
		var modalBody = jObj.find(".modal-dialog");
		modalBody.width(width);
	};

	modalObj.primaryBtn = modalContainer.find(".btn-primary");

	if(modalOptions.onSaveClick){
		modalObj.primaryBtn.click(function(){

			thisObj.modalOptions.onSaveClick(thisObj);
			if(modalOptions.hideOnSave){
				thisObj.hide();
			}

		});
	}

    if(modalOptions.hideSaveButton)
    {
        modalObj.primaryBtn.hide();
    }

	//Ding added for 122 12/11/2015
	modalObj.closeBtn = modalContainer.find(".btn-default");
	modalObj.xcloseBtn = modalContainer.find(".close");

	modalObj.deleteBtn = modalContainer.find(".btn-danger");

	if(!modalOptions.deleteButton)
	{
		modalObj.deleteBtn.remove();
	}

	modalObj.show = function()
	{
		this.modalContainer.modal('show');
		this.originalWidth = this.modalContainer.find(".modal-dialog").width();
		this.adjustContentHeight();
	};

	modalObj.hide = function()
	{
		this.modalContainer.modal('hide');
	};

	modalObj.remove = function()
	{
		this.modalContainer.remove();
	};

	modalObj.enablePrimaryButton = function()
	{
		this.deleteBtn.removeAttr("disabled");
		this.primaryBtn.removeAttr("disabled");
		this.closeBtn.removeAttr("disabled");//Ding added for 122 12/11/2015
	};

	modalObj.disablePrimaryButton = function()
	{
		this.deleteBtn.attr("disabled", true);
		this.primaryBtn.attr("disabled", true);
		this.closeBtn.attr("disabled", true);	//Ding added for 122 12/11/2015
	};

	modalObj.hideFooter = function(){
		modalContainer.find(".modal-footer").remove();
	};

	modalObj.append = function(bodyContent)
	{
		var modalBody = this.modalContainer.find(".modal-body");
		modalBody.append(bodyContent);
	};

	modalObj.find = function(filter)
	{
		return this.modalContainer.find(filter);
	};


	modalObj.resetStatus = function()
	{
		this.setStatusText("");
	};

	modalObj.setErrorText = function(err) {
		this.setStatusText(err, '#c63f3f');
	};

	modalObj.setStatusText = function(status, color)
	{
		color = color || "white";

		var statusText = this.modalContainer.find(".statusText");
		statusText.html(status);

		if(color == "red")
			color = '#c63f3f';

		statusText.css("color", color);

		if(!statusText){
			statusText.hide();
		}else{
			statusText.show();
		}
	};

	if( modalOptions.body )
	{
		modalObj.append($(modalOptions.body));
	}

	if(modalOptions.autoShow)
	{
		modalObj.show();
	}

	return modalObj;
}