/**
 * Created by Dr Raj Curwen on 11/10/14.
 */

var contentFolder = "assets";

function TimeLineItem(id, name, x, y, z, w, h, alpha, startTime, duration, asset, canvasObject) {
    //  base properties
    this.id = id;
    this.name = name;
    this.x = parseInt(x, 10);
    this.y = parseInt(y, 10);
    this.z = parseInt(z, 10);
    this.w = parseInt(w, 10);
    this.h = parseInt(h, 10);
    this.alpha = parseFloat(alpha);
    this.startTime = parseInt(startTime, 10);
    this.asset = asset;
    this.alwaysOn =null;
    this.lhsTimelineElement = null;
    this.rhsTimelineElement = null;
    this.rhsTimeSpanGroup = null;

    this.containerTitle = null;

    if(asset && asset.templateBlock)
    {
        this.templateBlock = asset.type;
    }

    //  default to 10 seconds !
    this.duration = parseInt(duration) || 10000;

    this.endTime = this.startTime + this.duration;
    this.canvasObject = canvasObject;

    //  a list of Children of type TimeLineItem
    this.children = [];

    //  interface properties
    this.isCollapsed = false;
    this.selected = false;
    this.locked = false;
    this.visible = true;

    //  hierarchy - if I'm a child I have a parent
    this.parent = null;
}

TimeLineItem.prototype.copy = function(tli) {
    this.name = tli.name;
    this.x = tli.x;
    this.y = tli.y;
    this.z = tli.z;
    this.w = tli.w;
    this.h = tli.h;
    this.alpha = tli.alpha;
    this.startTime = tli.startTime;
    this.duration = tli.duration;
    this.asset = tli.asset;
    this.endTime = tli.startTime + tli.duration;
    this.canvasObject = tli.canvasObject;
    this.isCollapsed = tli.isCollapsed;
    this.selected = tli.selected;
    this.locked = tli.locked;
    this.visible = tli.visible;
    this.parent = tli.parent;
    this.alwaysOn = tli.alwaysOn?tli.alwaysOn:null;
};

TimeLineItem.prototype.getDuration = function()
{
    var duration = this.getEndTime() - this.getStartTime();
    this.duration = duration;
    return duration;
};

TimeLineItem.prototype.getEndTime = function() {
    var endTimeVal = this.endTime;

    if ( this.asset.type =="video" && this.hasChildren() )
    {
        var maxEndTimeVal = 0;

        for( var i=0; i<this.children.length; i++ )
        {
            if( maxEndTimeVal <= this.children[i].endTime)
                maxEndTimeVal =  this.children[i].endTime;
        }

        endTimeVal = maxEndTimeVal;
    }

    return endTimeVal;
}

TimeLineItem.prototype.getStartTime = function() {
    var startTimeVal = this.startTime;

    if ( this.asset.type =="video" && this.hasChildren() )
    {
        var minStartTimeVal =  this.children[0].startTime

        for( var i=1; i<this.children.length; i++ )
        {
            if( minStartTimeVal > this.children[i].startTime)
                minStartTimeVal =  this.children[i].startTime;
        }

        startTimeVal = minStartTimeVal;
    }

    return startTimeVal;
};

TimeLineItem.prototype.intersectsWith = function(item){
    var result = true;

    var x1 = this.x
    var y1 = this.y;
    var h1 = this.h;
    var w1 = this.w;
    var b1 = y1 + h1;
    var r1 = x1 + w1;
    var x2 = item.x;
    var y2 = item.y;
    var h2 = item.h;
    var w2 = item.w;
    var b2 = y2 + h2;
    var r2 = x2 + w2;

    if (b1 < y2 || y1 > b2 || r1 < x2 || x1 > r2){
        result = false;
    }

    return result;
}

TimeLineItem.prototype.getContainerTitle = function()
{
    if( this.containerTitle== null || this.containerTitle.length == 0 )
    {
        this.containerTitle = "Container " + parseInt(timeline.getContainersMaxIndex());
    }

    return this.containerTitle;
}

TimeLineItem.prototype.addChildTimelineItem = function(tItem, removeCanvasObj) {

    if(removeCanvasObj == undefined){
        removeCanvasObj = true;
    }

    // Remove canvas elements
    if(removeCanvasObj){
        tItem.canvasObject.holdingElement.parentElement.removeChild(tItem.canvasObject.holdingElement);
        // Remove timeline elements
        var index = timeline.items.indexOf(tItem);
        timeline.items.splice(index, 1);
    }

    if (this.children.length == 0)
    {
        var convertedChild = new TimeLineItem(timeline.uuid.new(), this.name, 0, 0, 0, 0, 0, 0, this.startTime, this.duration, this.asset, this.canvasObject);

        convertedChild.parent = this;
        this.children.push(convertedChild);
    }

    //  calculate start time
    var startTime = this.getLastChild().endTime;

    //  create the child
    //  note my children all use my canvasObject to render, so pass that in
    var child = new TimeLineItem(tItem.id, tItem.name, 0, 0, 0, 0, 0, 0, startTime, tItem.duration, tItem.asset, this.canvasObject);

    //  make sure the child knows - I'm the parent
    child.parent = this;

    //  push the child
    this.children.push(child);

    if(this.autoFill){
        this.autoFillElements();
    }
    else {
        //  update my duration and endTime
        var newEndTime = child.endTime;
        this.endTime = Math.max(this.getEndTime(), newEndTime); // startTime + this.duration;
        this.duration = this.endTime - this.startTime;
    }


    // Update new project length
    timeline.setProjectLength();
    timeline.renderTimeline();

}

TimeLineItem.prototype.updateTemplateChild = function(layerObj, name, asset)
{
    // Use the existing object and replace properties
    this.asset = asset;

    // Update duration if valid
    this.duration = layerObj.duration?layerObj.duration:this.duration;

    this.endTime = this.startTime + this.duration;

    this.templateBlock = null;
    this.asset.templateBlock = null;

    // rebuild the canvas object
    this.canvasObject.resource = this.asset.fileName;
    this.name = name;
    this.canvasObject.name = name;


    timeline.setProjectLength();

    this.canvasObject.rebuild();
    this.canvasObject.removeTemplateHighlighting();
}

TimeLineItem.prototype.addChild = function(id, name, asset) {

    //  calculate start time, for the last child element
    var lastChild = this.getLastChild();
    var lastChildStartTime = lastChild ? lastChild.endTime : 0;

    var newElement = asset.startTime == undefined;

    //  create the child
    //  note my children all use my canvasObject to render, so pass that in
    var child = new TimeLineItem(id, name, 0, 0, 0, 0, 0, 0, asset.startTime||lastChildStartTime, asset.duration, asset, this.canvasObject);

    //  make sure the child knows - I'm the parent
    child.parent = this;

    //  push the child
    this.children.push(child);

    if(newElement && this.autoFill) {

        this.autoFillElements();
    }
    else {
        //  update my duration and endTime
        var newEndTime = child.endTime;
        this.endTime = Math.max(this.getEndTime(), newEndTime); // startTime + this.duration;
        this.duration = this.endTime - this.startTime;

        timeline.setProjectLength();
    }

    // Update timeline elements
    timeline.updateAutoFill();
    timeline.renderTimeline();
};

TimeLineItem.prototype.getFirstChild = function() {

    var child = null;
    if(this.children && this.children.length > 0){
        child = this.children[0];
    }

    return child;
};

TimeLineItem.prototype.getLastChild = function() {
    var child = null;
    if(this.children && this.children.length > 0){
        child = this.children[this.children.length - 1];
    }

    return child;
};


TimeLineItem.prototype.autoFillElements = function() {

    // this is an autofill container, distribute items evenly
    // Auto space the children
    // Dont check if we dont have any children
    if(!this.autoFill || !this.hasChildren())return;

    var childrenLength = this.children.length;

    var duration = this.duration;
    var durationPerChild = Math.abs(duration / childrenLength);

    var prevEndTime = this.startTime;

    for (var i = 0; i < childrenLength - 1; i++) {

        var child = this.children[i];
        child.duration = durationPerChild;
        child.startTime = prevEndTime;
        child.endTime = child.startTime + durationPerChild;

        prevEndTime = child.endTime;
    }

    // update the last element with the remaining time..
    // Avoid fractions!
    var lastChild = this.children[childrenLength - 1];
    lastChild.duration = duration - (durationPerChild * (childrenLength -1));
    lastChild.startTime = prevEndTime;
    lastChild.endTime = lastChild.startTime + durationPerChild;

    this.setTimes();

};



function copyItemForRender(refItem){

    // To avoid circular copy
    refItem.parent = null;
    refItem.lhsTimelineElement = null;
    refItem.rhsTimelineElement = null;
    var cb = refItem.canvasObject;
    refItem.canvasObject = null;

    var newElement = angular.copy(refItem);
    // Update unique id to treat it as a new element
    newElement.id = timeline.uuid.new();

    refItem.parent = this;
    refItem.canvasObject = cb;

    // Update new element properties
    newElement.canvasObject = cb;
    newElement.parent = this;
    newElement.selected = false;

    return newElement;
}

/**
 * Fix the children start/end positions in case the parent is resized
 * Follow Rules for AutoFill/Repeat Items
 */
TimeLineItem.prototype.containerFixStartEnd = function() {
    // Only valid for containers
    if(this.hasChildren()){

        var checkRepeat = true;

        var firstChild = this.getFirstChild();
        var lastChild = this.getLastChild();

        if (!this.snapRepeatIndex) {
            this.snapRepeatIndex = 0;
        }

        // if we're not in auto fill mode, we cant go beyond fist element start, last element end
        if(!this.autoFill){

            // Does this have any repeating elements?
            // If it doesnt, it need to stay in the start/end elements limited range
            // Else we'll handle in the logic below this block
            if (this.startTime > firstChild.startTime) {
                checkRepeat = false;
                this.startTime = firstChild.startTime;
            }

            if(this.asset.type == "video" && this.endTime != lastChild.endTime && this.snapRepeatIndex == 0){
                checkRepeat = false;
                this.endTime = lastChild.endTime;
            }
            else if (this.endTime < lastChild.endTime && this.snapRepeatIndex == 0) {
                checkRepeat = false;
                this.endTime = lastChild.endTime;
            }

            this.duration = this.endTime - this.startTime;
        }
        else{
            this.autoFillElements();
        }

        // Re render to get new elements loaded
        timeline.renderTimeline();
    }
};




TimeLineItem.prototype.updateRHSTimelineElement = function(drillDown){
    // Re render the timeline element
    var thisSpan =  this.hasChildren() ? $(this.rhsTimelineElement) : $(this.rhsTimelineElement);

    var newSpan = timeline.rhsTimeline.createTimelineElement(this);
    thisSpan.replaceWith($(newSpan));

    if(this.hasChildren()){
        this.rhsTimeSpanGroup = newSpan;

        // Do we need to check and update the children as well?
        if(drillDown){
            for (var i=0; i<this.children.length; i++) {
                this.children[i].updateRHSTimelineElement();
            }
        }
    }
    else{
        this.rhsTimelineElement = newSpan;
    }

};


TimeLineItem.prototype.getChildById = function(id) {
    for (var i=0; i<this.children.length; i++) {
        if (this.children[i].id == id) {
            return this.children[i];
        }
    }

    return null;
};


TimeLineItem.prototype.hasChildren = function(){
    return ( this.children && this.children.length > 0);
};

TimeLineItem.prototype.isChild = function() {
    return this.parent != null;
};

TimeLineItem.prototype.sortChildrenByStartTime = function()
{
    if(this.hasChildren())
    {
        this.children.sort(timelineSorterStartTime);
    }
};

function timelineSorterStartTime(a,b) {
    return a.startTime - b.startTime;
}

function updateColorPickers() {
    // Update bg colour
    var colorPickers = $("#properties").find(".color");
    colorPickers.each(function(){
        // forcefully trigger a change event
        $(this).focus();
        $(this).blur();
    });
}

TimeLineItem.prototype.select = function(e)
{
    e.stopPropagation();
    e.preventDefault();

    timeline.$scope.totalSelected++;
    timeline.recalculateMultiSelection();

    if(e.shiftKey)
    {
        this.selected = true;
        this.canvasObject.select();
    } else
    {
        this.selectCurrentItem();
        timeline.$scope.$apply();
    }

    updateColorPickers();
};

TimeLineItem.prototype.selectCurrentItem = function()
{
    timeline.items.forEach(function (_layer)
    {
        _layer.deSelect();
    });

    timeline.$scope.propertyObject = this;
    timeline.$scope.togglePane("properties", null, true);

    // TRIGGER Here

    this.selected = true;
    this.canvasObject.select(false);

    this.lhsTimelineElement.classList.add("selected");
    this.rhsTimelineElement.classList.add("selected");
}

TimeLineItem.prototype.realignChildrenTimings = function()
{
    // Sort first
    this.sortChildrenByStartTime();

    var firstChild = this.children[0];
    var previousChild = firstChild;

    for (var i = 1; i < this.children.length; i++)
    {
        var child = this.children[i];

        // Check if previous end time overlaps current start / end?
        if(child.startTime <= previousChild.endTime)
        {
            // Shift the elements
            child.startTime = previousChild.endTime;
            child.endTime =  child.startTime + child.duration;
        }

        previousChild = child;
    }

    // Update final project length
    timeline.setProjectLength();
    timeline.renderTimeline();
};

TimeLineItem.prototype.deSelect = function()
{
    timeline.$scope.totalSelected--;
    timeline.recalculateMultiSelection();

    if(this.children && this.children.length > 0)
    {
        this.children.forEach(function (_child)
        {
            if(_child.selected)
                _child.deSelect();
        });
    }

    if(this.selected) {

        this.canvasObject.deSelect();
        this.lhsTimelineElement.classList.remove("selected");
        this.rhsTimelineElement.classList.remove("selected");

        this.selected = false;
    }

    // TRIGGER Here
};

// Updates the canvasObject video / thumbnail on stage
TimeLineItem.prototype.refreshCanvasObject = function()
{
    if (this.asset.type == "image" || this.asset.type == "video") {

        var asset = this.asset;
        if (this.hasChildren()) {
            // check type and udpate html element,
            // Only for images / videos
            asset = this.children[0].asset;
        }

        this.canvasObject.resource = asset.fileName;
        this.canvasObject.name = asset.name;
        this.canvasObject.updateContent();
    }
};

TimeLineItem.prototype.updateCanvasObjectZIndex = function() {
    this.canvasObject.z = this.z;
    this.canvasObject.holdingElement.style.zIndex = this.z;
};


TimeLineItem.prototype.disableProperty = function()
{
    return this.parent != null;
};

TimeLineItem.prototype.getX = function()
{
    if(this.parent)
        return this.parent.x;

    return this.x;
};

TimeLineItem.prototype.setTimes = function()
{
    // Refresh the layers
    timeline.setProjectLength();

    // Number format checks
    if( this.startTime.toString().trim().length == 0 || isNaN( this.startTime ))
        this.startTime = 0;
    else
        this.startTime = parseInt(this.startTime);

    if(this.endTime.toString().trim().length == 0 ||  isNaN( this.endTime ))
        this.endTime = 10000;
    else
        this.endTime = parseInt(this.endTime);

    if(this.alwaysOn)
    {
        this.startTime = 0;
        this.endTime = timeline.projectLength;
        this.duration = timeline.projectLength;
        if(!this.locked) {
            this.canvasObject.groupElement.classList.toggle("lock");
        }
    }
    else
    {

        // Remove if locked
        if(this.locked) {
            this.canvasObject.groupElement.classList.toggle("lock");
        }

        this.duration = this.getEndTime() - this.getStartTime();
    }

    // TRIGGER Here

    // Check for overlapping
    if(this.asset.type == "video" || this.asset.type == "image" )
    {
        timelineDragManager.dragMode = "resize";
        this.timeSpanEditEnd( parseInt(this.startTime), parseInt(this.endTime));
    }

    // Does the parent have autofill?
    if(this.parent && this.parent.autoFill) {
        // Update parent duration
        this.parent.recalculateStartEnd();
        this.parent.autoFillElements();
    }

    // Ensure a timeline refresh
    timeline.renderTimeline();
};


TimeLineItem.prototype.toggleLayerVisibility = function(e)
{
    e.stopPropagation();
    e.preventDefault();

    if(this.canvasObject)
    {
        // Toggle
        this.visible = !this.visible;

        if(this.visible) {
            e.target.classList.remove("eye-invisible");
        } else {
            e.target.classList.add("eye-invisible");
        }

        this.updateLayerVisibility();
    }
};

TimeLineItem.prototype.updateLayerVisibility = function()
{
    if(this.visible) {
        this.canvasObject.holdingElement.style.display = "block";
    } else {
        this.canvasObject.holdingElement.style.display = "none";
    }
};

TimeLineItem.prototype.deleteChild = function(child)
{
    child.deSelect();
    // Remove dom stage element,
    // This needs to happen after the apply, lets figure it out later
    this.children.splice(this.children.indexOf(child), 1);
    child.parent = null;

    if(this.children.length == 1){
        // Clear container if there is only one left..
        // A container must have more than 1 element
        if( this.children.length == 1 ){

            this.startTime = this.children[0].startTime;
            this.endTime = this.children[0].endTime;
            this.name = this.children[0].name;

            var newLayerId = this.children[0].id;
            // Update data layer ids
            $("[data-layerid='"+this.id+"']").each(function(){
                $(this).attr("data-layerid", newLayerId);
            });

            this.id = newLayerId;
            this.asset = this.children[0].asset;
            // Update data layerids
            this.canvasObject.layerID = this.id;
            this.duration = this.endTime - this.startTime;
            this.children = [];

            this.refreshCanvasObject();
        }
    }
    else {
        this.recalculateStartEnd();
    }

    this.autoFillElements();
};

TimeLineItem.prototype.recalculateStartEnd = function()
{
    if(this.hasChildren()) {
        this.sortChildrenByStartTime();

        this.startTime = this.getStartTime();
        this.endTime = this.getEndTime();
        this.duration = this.endTime - this.startTime;
    }

};

TimeLineItem.prototype.updateCollapsed = function(){
    if(this.hasChildren()) {
        if (this.isCollapsed) {
            this.children.forEach(function (_child) {
                _child.lhsTimelineElement.style.display = "none";
                _child.rhsTimelineElement.parentElement.style.display = "none";
            });

            this.rhsTimeSpanGroup.style.visibility = "visible";
        }
        else {
            this.children.forEach(function (_child) {
                _child.lhsTimelineElement.style.display = "block";
                _child.rhsTimelineElement.parentElement.style.display = "block";

            });

            this.rhsTimeSpanGroup.style.visibility = "hidden";
        }
    }
};


TimeLineItem.prototype.makeBackground = function() {

    this.h = timeline.$scope.stageProperties.dimensions.h;
    this.w = timeline.$scope.stageProperties.dimensions.w;
    this.x = 0;
    this.y = 0;
    this.z = timeline.getMinZ() - 1;
    this.alpha = 1;
    this.canvasObject.setSizePosition();
    this.locked = true;
    this.canvasObject.groupElement.style.display = "none";

    // TRIGGER Here
};

TimeLineItem.prototype.setSizePosition = function() {
    // Send to canvas object
    this.canvasObject.setSizePosition();
};


TimeLineItem.prototype.setWidget = function() {
    if( !timeline.$scope.propertyObject || !timeline.$scope.propertyObject.canvasObject.widgetElement)
        return;

    var widgetProps = {};

    for(var key in timeline.$scope.propertyObject.canvasObject.widgetProperties) {
        widgetProps[key] = timeline.$scope.propertyObject.canvasObject.widgetProperties[key].val;
    }

    var assetsBaseURL = timeline.$scope.$root.appRoot + "/"  + contentFolder  + "/" + timeline.$scope.groupFolder + "/images/";

    var widgetConfig = {
        appRoot: timeline.$scope.$root.appRoot,
        assetsBaseURL : assetsBaseURL,
        autoStart : null,
        groupFolder : timeline.$scope.groupFolder,
        backendURL : config.backend,
        authToken : timeline.$scope.$root.authToken,
        height: this.h,
        width: this.w,
        useCache : false
    };

    timeline.$scope.propertyObject.canvasObject.widgetElement.widget(widgetProps).render(widgetConfig);
};

TimeLineItem.prototype.toggleLayerLock = function(e) {

    e.stopPropagation();
    e.preventDefault();

    if(this.canvasObject) {
        this.locked = !this.locked;
        this.canvasObject.groupElement.classList.toggle("lock");
    } else {

        timeline.$scope.layers.forEach(function(_layer){

            if(_layer.type === "layer" && _layer.parentGroupID && _layer.parentGroupID === _this.id && _layer.stageElement) {
                _layer.locked = !_layer.locked;
                _layer.stageElement.groupElement.classList.toggle("lock");

                if(_layer.locked) {
                    timeline.$scope.layers[i].stageElement.groupElement.style.display = "none";
                } else {
                    timeline.$scope.layers[i].stageElement.groupElement.style.display = "block";
                }
            }
        });
    }

    e.target.classList.toggle("lock-invisible");

    // Update timeline elements
    $(this.rhsTimelineElement).toggleClass("lock");
    $(this.lhsTimelineElement).find(".layerName").toggleClass("locked");

};

TimeLineItem.prototype.timeSpanEditEnd = function(finalStartTime, finalEndTime)
{
    if (timelineDragManager.dragMode == "resize")
    {
        // We should only move in steps of video duration,
        // Calculate and snap elements into place
        if(this.asset.type == "video")
        {
            var videoDuration = parseInt(this.asset.duration);
            var currentDuration = finalEndTime - finalStartTime;

            var difference = currentDuration - videoDuration;

            // check difference percentage :
            var diffScale = Math.max( Math.round(1 + parseFloat(difference/videoDuration)) , 1);

            // Update the elements start based on loopcount
            finalEndTime = finalStartTime + diffScale*videoDuration;

            // Update layer properties
            this.startTime = finalStartTime;
            this.endTime = finalEndTime;
            this.duration = finalEndTime - finalStartTime;
        }
    }

    var overlap = false;
    var overlappedElement = null;
    var movingRight = false;
    // Get direction of movement L2R or R2L
    if(this.startTime - this.originalStartTime > 0)
        movingRight = true;
    // Helper variable
    var movingLeft = !movingRight;

    // Check if we got a container and also avoid any overlapping
    if (this.parent != null)
    {
        if(movingLeft) {
            // Only check siblings
            for (var i = 0; i < this.parent.children.length; i++) {
                var child = this.parent.children[i];
                if (child.id != this.id) {
                    if ((finalStartTime >= child.startTime && finalStartTime < child.endTime  )
                        || (finalEndTime > child.startTime && finalEndTime < child.endTime  )
                        || ( finalStartTime < child.startTime && finalEndTime > child.endTime )
                        ) {
                        overlap = true;
                        overlappedElement = child;
                        break;
                    }
                }
            }
        }
        else
        {
            // Only check siblings
            for (var i =this.parent.children.length-1; i>=0; i--) {
                var child = this.parent.children[i];
                if (child.id != this.id) {
                    if ((finalStartTime >= child.startTime && finalStartTime < child.endTime  )
                        || (finalEndTime > child.startTime && finalEndTime < child.endTime  )
                        || ( finalStartTime < child.startTime && finalEndTime > child.endTime )
                        ) {
                        overlap = true;
                        overlappedElement = child;
                        break;
                    }
                }
            }
        }

        if(overlap)
        {
            if (timelineDragManager.dragMode == "resize")
            {
                // We got overlapping start / end times align children
                var firstChild = this.parent.children[0];
                var previousChild = firstChild;
                for (var i = 1; i < this.parent.children.length; i++) {
                    var child = this.parent.children[i];

                    // Check if previous end time overlaps current start?
                    if(  previousChild.endTime > child.startTime )
                    {
                        // Shift the start time
                        child.startTime = previousChild.endTime;
                        child.endTime =  child.startTime + child.duration;
                    }

                    previousChild = child;
                }
            }

            else
            {
                // Check for elements' end time , if its about 55% over the next start, swap!

                var overlapPercentage = 0;




                // Lets take first case for moving right to calculate percentage
                if(movingRight)
                {
                    overlapPercentage = (parseFloat((this.endTime - overlappedElement.startTime) / overlappedElement.duration ))*100;

                }
                else
                {
                    overlapPercentage = (parseFloat(( overlappedElement.endTime - this.startTime) / overlappedElement.duration ))*100;
                }
                /*
                 var biggerElem = this.duration > overlappedElement.duration ? _layer : overlappedElement;
                 var smallerElem = this.duration < overlappedElement.duration ? _layer == biggerElem?overlappedElement:_layer : overlappedElement;

                 //overlapPercentage = (parseFloat((this.endTime - overlappedElement.startTime) / this.duration ))*100;
                 overlapPercentage = (parseFloat((biggerElem.endTime - overlappedElement.startTime) / this.duration ))*100;
                 */

                // Over element
                if(overlapPercentage >= 55 )
                {
                    // Swap according to direction of motion

                    // Need to swap cascaded, so the other elements make way for the the swapping/pushing

                    if(movingRight)
                    {
                        // Shift the elements to the right starting the overlapping elements
                        for( var k= this.parent.children.indexOf(this._layer) + 1; k < this.parent.children.indexOf(overlappedElement)+1; k++ )
                        {
                            var child = this.parent.children[k];
                            child.startTime = child.startTime - this.duration;
                            child.endTime = child.endTime - this.duration;
                        }


                        for( var k = this.parent.children.indexOf(overlappedElement); k < this.parent.children; k++ )
                        {
                            var child = this.parent.children[k];
                            child.startTime = child.startTime - this.duration;
                            child.endTime = child.endTime - this.duration;
                        }

                        this.startTime = overlappedElement.endTime;
                        this.endTime = this.startTime + this.duration;

                        //overlappedElement.startTime = this.originalStartTime;
                        //overlappedElement.endTime = overlappedElement.startTime + overlappedElement.duration;
                    }
                    else
                    {
                        this.startTime = overlappedElement.startTime;
                        this.endTime = this.startTime + this.duration;

                        // Shift the elements to the right starting the overlapping elements
                        for( var k= this.parent.children.indexOf(overlappedElement); k < this.parent.children.indexOf(this.layer); k++ )
                        {
                            var child = this.parent.children[k];
                            child.startTime = child.startTime + this.duration;
                            child.endTime = child.endTime + this.duration;
                        }

                        //overlappedElement.startTime = this.endTime;
                        //overlappedElement.endTime = overlappedElement.startTime + overlappedElement.duration;
                    }
                }
                /*
                 else {
                 // Snap the element back to its place
                 // Dont change anything, update the watches and redraw to snap the element back to its original place
                 this.startTime = this.originalStartTime;
                 this.endTime = this.originalEndTime;

                 this.duration = this.endTime - this.startTime;
                 }
                 */
            }

        }

        // Check and move elements about
        this.parent.realignChildrenTimings();
    }
    else{

        timeline.setProjectLength();
        timeline.renderTimeline();

    }
};



var timeline = {
    items : [],
    projectLength : 0,
    lhsTimeline : null,
    rhsTimeline : null,
    zeroTickOffset : 12, // Depends on the current zoom setting

    init : function(){
        this.lhsTimeline = initLHSTimeline();
        this.rhsTimeline = initRHSTimeline();
    },

    add : function(id, name, x, y, z, w, h, alpha, startTime, duration, asset, canvasObject) {
        var tli = new TimeLineItem(id, name, x, y, z, w, h, alpha, startTime, duration, asset, canvasObject);

        this.items.push(tli);
        this.setProjectLength();

        this.renderTimeline();
        return tli;
    },

    getSelectedItem : function(){
        var selectedItem = null;

        // get the first selected item we can find and send back
        for (var i=0; i< this.items.length; i++) {
            var item = this.items[i];
            if(item.selected){
                selectedItem = item;
                break;
            }
            else if(!item.hasChildren()){
                if(item.selected){
                    selectedItem = item;
                    break;
                }
            }
            else{
                // Check children
                var children = item.children;
                for (var j=0; j< children.length; j++){
                    var child = children[j];
                    if(child.selected){
                        selectedItem = child;
                        break;
                    }
                }
            }
        }

        return selectedItem;
    },

    updateTimelineZoom : function(){
        timeline.$scope.oneSecond = parseInt(50 * timeline.$scope.timeLineZoom);

        try {
            var timeSpanHolder = document.getElementById("layerTimeSpans");
            var timeLineTime = document.getElementById("timeLineTime");

            var _seconds = new Array((Math.ceil((parseInt(timeline.$scope.stageProperties.projectLength, 10) + 1000) / 1000)));
            timeLineTime.style.width = timeSpanHolder.style.width = _seconds.length * timeline.$scope.oneSecond + 150 + "px";
            timeSpanHolder.style.width = timeSpanHolder.style.width = _seconds.length * timeline.$scope.oneSecond +150 + "px";


        } catch(e) {  }
    },

    uuid : {
        new: function() {
            function _p8(s) {
                var p = (Math.random().toString(16)+"000000000").substr(2,8);
                return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;
            }
            return _p8() + _p8(true) + _p8(true) + _p8();
        },

        empty: function() {
            return '00000000-0000-0000-0000-000000000000';
        }
    },


    renderTimeline : function(){
        // Rerender timeline elements
        if(this.lhsTimeline == null){
            this.init();
        }

        // reorder the items according to the z value
        this.items.sort(function(a, b) {
            return a.z - b.z;
        });

        this.lhsTimeline.drawLHSTimeline();
        this.rhsTimeline.drawRHSTimeline();
    },
    updateAutoFill : function() {
        for (var i=0; i< this.items.length; i++) {
            var item = this.items[i];
            item.setTimes();
            item.containerFixStartEnd();
        }
    },
    reset: function() {
        // clear any stage elements first
        for(key in this.items) {
            var item = this.items[key];
            item.canvasObject.holdingElement.parentElement.removeChild(item.canvasObject.holdingElement);
        }

        this.items = [];
        this.projectLength = 0;
    },

    getItemById : function(id)
    {
        var foundItem = null;
        for (var i=0; i< this.items.length; i++)
        {
            var _item = this.items[i];
            if( _item.id == id )
            {
                foundItem = _item;
                break;
            }

            // Check for children elements
            if( _item.children && _item.children.length > 0 )
            {
                var cItem = _item.getChildById(id);

                if(cItem) {
                    foundItem = cItem;
                    break;
                }
            }
        }

        return foundItem;
    },

    getMatchingVideoTime : function(referenceTime){

        var matchedTime = referenceTime;
        var match = null;

        // scroll through all video type items and see if it matches any start/end times
        for(var i=0; i< this.items.length; i++) {
            var item = this.items[i];
            if (item.asset.type != "video")
                continue;

            if (item.hasChildren()) {
                // Go through the children
                for (var j = 0; j < item.children.length; j++) {
                    var childItem = item.children[j];

                    match = this.matchRelativeTime(childItem, referenceTime);
                    if(match.matched)
                    {
                        // Matched
                        matchedTime = match.matchedTime;
                        break;
                    }
                }
            }
            else {
                match = this.matchRelativeTime(item, referenceTime);
                if(match.matched)
                {
                    // Matched
                    matchedTime = match.matchedTime;
                }

            }

            if(match && match.matched){
                break;
            }
        }

        return matchedTime;
    },

    matchRelativeTime : function(item, referenceTime){

        var intStart = parseInt(item.startTime/1000);
        var intEnd = parseInt(item.endTime/1000);
        var intReferenceTime = parseInt(referenceTime/1000);

        var matchedTime = referenceTime;
        var matched = false;

        if( intStart == intReferenceTime){
            matched = true;
            matchedTime =  item.startTime;
        }
        else if( intEnd == intReferenceTime){
            matched = true;
            matchedTime =  item.endTime;
        }

        return {matched : matched, matchedTime : matchedTime};
    },

    isTemplateProject : function()
    {
        var result = false;
        for (var i=0; i< this.items.length; i++) {
            var _item = this.items[i];
            if(_item.templateBlock && _item.templateBlock != "widget")
            {
                result = true;
                break;
            }
        }

        return result;
    },

    setProjectLength : function()
    {
        var endTime = 0;
        for(var i=0; i< this.items.length; i++)
        {
            if(this.items[i].alwaysOn)
            {
                continue;
            }

            var itemEndTime =  this.items[i].getEndTime();
            if(endTime <= itemEndTime)
                endTime = itemEndTime;
        }

        this.projectLength = this.$scope.stageProperties.projectLength = Math.ceil(endTime);
        this.rerenderTicks();
    },

    rerenderTicks : function(){

        /*
        <p ng-repeat="i in seconds track by $index" style="width: {{ $index === 0 ? $parent.$parent.oneSecond / 1 : $parent.$parent.oneSecond }}px">

            <span ng-if="$parent.$parent.timeLineZoom <= 0.04 && $index % 60 === 0">{{ getTimeTickStr($index )}}</span>
            <span ng-if="$parent.$parent.timeLineZoom > 0.04 && $parent.$parent.timeLineZoom <= 0.1 && $index % 30 === 0">{{ getTimeTickStr($index )}}</span>
            <span ng-if="$parent.$parent.timeLineZoom > 0.1 && $parent.$parent.timeLineZoom <= 0.2 && $index % 10 === 0">{{ getTimeTickStr($index )}}</span>
            <span ng-if="$parent.$parent.timeLineZoom > 0.2 && $parent.$parent.timeLineZoom <= 0.45 && $index % 5 === 0">{{ $index}}</span>
            <span ng-if="$parent.$parent.timeLineZoom > 0.45">{{$index}}</span>
        </p>
        */

        var timeLineTime = $("#timeLineTime");
        var timeSpanHolder = $("#layerTimeSpans");

        timeLineTime.empty();

        var numSeconds = 0;
        var increment = 1;

        if(this.projectLength === null){
            numSeconds = 0;
        }
        else{
            numSeconds = Math.ceil(this.projectLength/1000);
        }

        var zoom = this.$scope.timeLineZoom;
        var oneSecond =  parseInt( 50 * this.$scope.timeLineZoom);
        this.$scope.oneSecond = oneSecond;

        var width = (numSeconds * oneSecond ) ;

        timeSpanHolder.width(width + 150 );
        timeLineTime.width(width + 150 );


        if(zoom <= 0.02)
            increment = 300;    // 5 minutes
        else if(zoom > 0.02 && zoom <=0.04)
            increment = 60;
        else if(zoom > 0.04 && zoom <=0.1)
            increment = 30;
        else if(zoom > 0.1 && zoom <=0.2)
            increment = 10;
        else if(zoom > 0.2 && zoom <=0.45)
            increment = 5;
        else
            increment = 1;

        this.zeroTickOffset = increment > 1 ? 12 : 1;

        var ticksHTML = "";

        var oneTickWidth = ( width / numSeconds ) * increment;

        var i = 1;

        // Render zero tick to the left

        var tickStr = increment > 1 ? this.getTimeTickStr(0) : 0;
        var tickSeconds = 0;

        var timelineTickMarkClass = "timelineTickMark";

        if( increment == 1 ){
            timelineTickMarkClass += " timelineTickMarkSeconds"
        }

        ticksHTML += '<span class="timelineTick" style="width:'+oneTickWidth+'px;">'+ tickStr
            + '<span class="'+timelineTickMarkClass+'"></span></span>';

        var tickCount = Math.ceil( numSeconds / increment );
        var left = 0;

        while( i <= tickCount){
            tickSeconds = i * increment;
            left = i * oneTickWidth;
            tickStr = increment > 1 ? this.getTimeTickStr(tickSeconds) : tickSeconds;
            ticksHTML += '<span class="timelineTick" style="left:'+left+'px; width:'+oneTickWidth+'px;">'+ tickStr
                + '<span class="'+timelineTickMarkClass+'"></span></span>';
            i++;
        }

        timeLineTime.append(ticksHTML);

        // Initiate re render timespans
        if (!this.$scope.$$phase) this.$scope.$apply();

    },

    getTimeTickStr :function(secVal){

        var retVal = secVal;

        var mins = twoDigits(Math.floor( retVal/60 ));
        var secLeft = twoDigits(retVal % 60);

        var hrs = twoDigits(Math.floor(mins/60));
        var minsLeft = twoDigits(mins % 60);

        if( hrs > 0 ){
            retVal = hrs + ":" + minsLeft + ":" + secLeft;
        }
        else{
            retVal = minsLeft + ":" + secLeft;
        }

        return retVal;
    },


    recalculateMultiSelection : function()
    {
        if(this.$scope.totalSelected > 1)
        {
            this.$scope.multipleSelected = true;
        }
        else
        {
            this.$scope.multipleSelected = false;
        }
    },

    deleteSelected : function()
    {

        var itemsToDelete = [];
        // Delete the selected assets
        for(var i=0; i< this.items.length; i++)
        {
            var item = this.items[i];

            if(item.locked)
                continue;

            if(item.children.length > 0)
            {
                if(item.selected)
                {
                    item.deSelect();
                    item.canvasObject.holdingElement.parentElement.removeChild(item.canvasObject.holdingElement);
                    this.items.splice( this.items.indexOf(item), 1 );
                    i--;
                }
                else
                {
                    for (var j = 0; j < item.children.length; j++) {
                        var child = item.children[j];
                        if (child.selected) {
                            item.deSelect();
                            item.deleteChild(child);
                            j--;
                        }
                    }


                    /*
                    // Did we delete all children?
                    if(item.children.length == 0)
                    {
                        item.deSelect();
                        item.canvasObject.holdingElement.parentElement.removeChild(item.canvasObject.holdingElement);
                        this.items.splice( this.items.indexOf(item), 1 );
                        i--;
                    }
                    else
                    {
                        // Update source
                        item.refreshCanvasObject();
                    }
                    */
                }
            }
            else if( item.selected)
            {
                item.canvasObject.holdingElement.parentElement.removeChild(item.canvasObject.holdingElement);
                this.items.splice( this.items.indexOf(item), 1 );
                i--;
            }
        }

        timeline.$scope.totalSelected = 0;
        this.recalculateMultiSelection();

        //$scope.sortZ();
        this.setProjectLength();

        // Open the library to avoid showing the stray property panel
        timeline.$scope.togglePane("librarySmall", null, true);

        // Rerendert timeline
        timeline.renderTimeline();
    },

    getMinZ : function()
    {
        var minZ = 1000;
        for(var i=0; i< this.items.length; i++) {

            var item = this.items[i];
            if( minZ > parseInt( item.z) )
                minZ = item.z;
        }

        return parseInt(minZ);
    },

    getMaxZ : function()
    {
        var maxZ = 0;
        for(var i=0; i< this.items.length; i++) {

            var item = this.items[i];
            if( maxZ < parseInt(item.z) )
                maxZ = item.z;
        }

        return parseInt(maxZ);
    },

    //  the dom elements are setup with the id's from the timeline
    //  given a correctly setup dom element - find the TimeLineItem
    getItemByElement : function(domElement) {

        if(!domElement)
            return null;

        var targetId = domElement.getAttribute("data-layerid");
        var parentId = domElement.getAttribute("data-parentid");
        var item = null;

        if (parentId) {
            var tli  = this.getItemById(parentId);

            item = tli.getChildById(targetId);
        }
        else {
            item = this.getItemById(targetId);
        }

        return item;
    },


    getContainersMaxIndex : function()
    {
        var cCount = 0
        var cMax = 0;
        for(var i=0; i< this.items.length; i++)
        {

            var item = this.items[i];

            if(item.children.length > 1)
            {
                var containerTitle = item.containerTitle;
                if(item.containerTitle) {
                    var pos = containerTitle.lastIndexOf(" ");
                    var cIndex = parseInt(containerTitle.substr(pos, containerTitle.length));

                    if (cIndex > cMax)
                        cMax = cIndex;

                    cCount++;
                }
            }
        }

        return cMax + 1;
    },

    getContainersCount : function()
    {
        var cCount = 0;
        for(var i=0; i< this.items.length; i++)
        {

            var item = this.items[i];

            if(item.children.length > 1)
            {
                cCount++;
            }
        }

        return cCount;
    },
    getProjectSize : function() {
        var projectSize = 0;

        var assetsCalculated = {}
        this.items.forEach(function(parent) {
            if(parent.children.length > 0) {
                parent.children.forEach(function(child) {
                    var filename = child.asset.fileName;
                    if(!assetsCalculated[filename]) {
                        projectSize += child.asset.fileSize ? child.asset.fileSize : 0;
                        assetsCalculated[filename] = 1;
                    }
                });
            } else {
                var filename = parent.asset.fileName;
                if(!assetsCalculated[filename]) {
                    projectSize += parent.asset.fileSize ? parent.asset.fileSize : 0;
                    assetsCalculated[filename] = 1;
                }
            }
        });

        return projectSize;
    },
    getLayers : function(saveType) {
        var layers = [];
        var counter = 0;
        var contentRoot = timeline.$scope.$parent.appRoot + "/assets"; // TODO: update

        var that = this;
        this.items.forEach(function(_layer, i){

            _layer.groupJSONFlag = false;

            // Group check
            var lChildren = _layer.children;

            if(lChildren.length == 0)
                lChildren = [_layer];


            lChildren.forEach(function(child)
            {
                var layerObj = {};
                var parentGroupID = child.parent ? child.parent.id : null ;

                if(child.parent != null && !child.parent.groupJSONFlag)
                {
                    counter++;
                    var groupLayerObj =  {};
                    groupLayerObj.asset = {};
                    groupLayerObj.id = child.parent.id;
                    groupLayerObj.layerType = "group";
                    groupLayerObj.parentGroupID = null;
                    groupLayerObj.startTime = parseInt(child.parent.startTime, 10);
                    groupLayerObj.endTime = parseInt(child.parent.endTime, 10);
                    groupLayerObj.z = parseInt(child.parent.z, 10);
                    groupLayerObj.alpha = parseFloat(child.parent.alpha);
                    groupLayerObj.asset.id = counter;
                    groupLayerObj.locked = child.parent.locked;
                    groupLayerObj.visible = child.parent.visible;
                    groupLayerObj.asset.name = child.parent.containerTitle;
                    groupLayerObj.isCollapsed = child.parent.isCollapsed;

                    groupLayerObj.alwaysOn = child.parent.alwaysOn;
                    groupLayerObj.autoFill = child.parent.autoFill;
                    groupLayerObj.repeatItems = child.repeatItems;
                    groupLayerObj.repeatCount = child.repeatCount;
                    groupLayerObj.snapRepeatIndex = child.snapRepeatIndex;

                    layers.push(groupLayerObj);
                    child.parent.groupJSONFlag = true;
                }

                counter++;

                layerObj.asset = {};
                layerObj.id = child.id;
                layerObj.layerType = "layer";
                layerObj.parentGroupID = parentGroupID;

                if(child.canvasObject.type == "image"){
                    layerObj.startTime = that.getMatchingVideoTime(child.startTime);
                    layerObj.endTime = that.getMatchingVideoTime(child.endTime);
                }
                else {
                    layerObj.startTime = child.startTime;
                    layerObj.endTime = child.endTime;
                }

                layerObj.x = child.parent? parseInt(child.parent.x, 10) : parseInt(child.x, 10);
                layerObj.y = child.parent? parseInt(child.parent.y, 10) : parseInt(child.y, 10);
                layerObj.w = child.parent? parseInt(child.parent.w, 10) : parseInt(child.w, 10);
                layerObj.h = child.parent? parseInt(child.parent.h, 10) : parseInt(child.h, 10);
                layerObj.z = child.parent? parseInt(child.parent.z, 10) : parseInt(child.z, 10);
                layerObj.locked = child.locked;
                layerObj.visible = child.visible;

                layerObj.autoFill = child.parent? child.parent.autoFill : false;
                layerObj.repeatItems = child.parent? child.parent.repeatItems : false;
                layerObj.repeatCount = child.parent? child.parent.repeatCount : false;
                layerObj.snapRepeatIndex = child.parent? child.parent.snapRepeatIndex : null;

                if(parentGroupID == null){
                    layerObj.alwaysOn = child.alwaysOn;
                }
                else {
                    layerObj.alwaysOn = null;
                }

                //  set collapsed on the object that will represent me
                if (child.parent) {
                    layerObj.isCollapsed = child.parent.isCollapsed;
                }

                layerObj.alpha = child.parent? parseFloat(child.parent.alpha) : parseFloat(child.alpha);
                layerObj.asset.id = counter;
                layerObj.asset.name = child.name;
                layerObj.asset.type = child.canvasObject.type;
                layerObj.asset.url = child.canvasObject.resource;
                layerObj.asset.duration = child.asset.duration;
                layerObj.asset.fileSize = child.asset.fileSize;

                if(child.templateBlock) {
                    layerObj.templateBlock = child.templateBlock;
                    layerObj.asset.templateBlock = true;
                }

                if(saveType == "preview" && (child.canvasObject.type == "video" ||  child.canvasObject.type == "image"))
                {
                    // Update to full url for preview pane to download assets
                    layerObj.asset.fileName = child.asset.fileName;
                    layerObj.asset.url = contentRoot + "/" + timeline.$scope.groupFolder + "/" + child.canvasObject.type + "s/" + child.asset.fileName;
                }

                if(child.canvasObject.type == "image") {
                    layerObj.asset.widgetProperties = {};

                    for(var key in _layer.canvasObject.widgetProperties)
                    {
                        layerObj.asset.widgetProperties[key] = child.canvasObject.widgetProperties[key].val;
                    }
                }

                if(child.canvasObject.type == "widget")
                {
                    layerObj.asset.widgetId = child.asset.widgetId;
                    layerObj.asset.widgetVersion = child.asset.widgetVersion;
                    layerObj.asset.widgetType = child.canvasObject.name;
                    layerObj.asset.widgetProperties = {};

                    if (child.canvasObject.name === "EnhancedHTML") {
                      layerObj.asset.name = "HTML";
                      layerObj.asset.url = "html.html";
                      layerObj.asset.widgetType = "HTML";
                      layerObj.asset.enhanced = true;
                    }

                    for(var key in _layer.canvasObject.widgetProperties)
                    {
                       if (child.canvasObject.name === "EnhancedHTML") {
                         if (key == "url") {
                           layerObj.asset.widgetProperties["rawUrl"] = child.canvasObject.widgetProperties[key].val;
                           layerObj.asset.widgetProperties[key] = timeline.$scope.$parent.backend + "/getHTML?url="
                                + child.canvasObject.widgetProperties[key].val
                                + '&div=' + child.canvasObject.widgetProperties["divSelector"].val
                                + '&height=' + layerObj.h
                                + '&width=' + layerObj.w

                           continue;
                         }
                       }

                      layerObj.asset.widgetProperties[key] = child.canvasObject.widgetProperties[key].val;

                    }

                    // Updates for widgets to support preview
                    if(saveType == "preview")
                    {
                        var urlStr = child.asset.widgetType? (child.asset.widgetType + ".html"):child.asset.fileName;

                        if (child.asset.widgetType == "EnhancedHTML") {
                          urlStr = "HTML.html";
                        }

                        layerObj.asset.url = urlStr;
                    }

                }
                else if(saveType != "preview")
                {
                    layerObj.asset.fileName = child.asset.fileName;
                    layerObj.asset.url = child.asset.fileName;
                }

                layers.push(layerObj);

            });

        });
        return layers;
    }

};

var timelineScrollListenerAttached = false;

function attachTimelineScrollListener(){
    if(!timelineScrollListenerAttached){
        timeline.rhsTimeline.timeSpanHolder.addEventListener("scroll", function(e){

            var scrollTop = e.target.scrollTop;
            timeline.rhsTimeline.layerHolder.scrollTop = scrollTop;

        });
        timelineScrollListenerAttached = true;
    }
}
