activ.controller("profile", ["$scope", "$http", "$location", function($scope, $http, $location){

    $scope.pageTitle = "Profile";
    $scope.userObject = {};
    $scope.pendingUsers = [];
    $scope.editNameMode = false;
    $scope.editEmailMode = false;
    $scope.editPasswordMode = false;
    $scope.groupSection = "all";
    $scope.showSearch = false;
    $scope.loadingProfile = true;
    $scope.loadingGroupUsers = true;
    $scope.loadingPendingUsers = true;
    $scope.userData =  $scope.$root.getUserData();
    $scope.currentTeamName = null;
    $scope.teams = [];

    $scope.retrieveProfile = function(){
        $http({ method: "POST", url: $scope.$root.backend + "/retrieveProfile", "params": {}, "data": {_uID: $scope.$parent.userInfo.id} })
            .success(function(data, status){
                $scope.userObject = data.user;
                $scope.teams = data.teams;
                if($scope.teams.length == 0){
                    $scope.teams.push({name:"Admin"});
                }
                $scope.loadingProfile = false;
            })
            .error(function(data){
                console.error(data);
                //alert("error")
            });
    };

    $scope.retrieveProfile();

    $scope.loadGroupUsers = function()
    {
        $http({ method: "POST", url: $scope.$root.backend + "/retrieveUsersByGroup", "params": {}, "data": {_gID: $scope.$parent.userInfo.groupID, _uID: $scope.$parent.userInfo.id} })
            .success(function(data, status){
                $scope.users = data.users;
                $scope.loadingGroupUsers = false;
            })
            .error(function(data){
                console.error(data);
                //alert("error")
            });

        $http({ method: "POST", url: $scope.$root.backend + "/getPendingUsers", "params": {}, "data": { _gID: $scope.$parent.userInfo.groupID } })
            .success(function(data, status){
                $scope.loadingPendingUsers = false;
                $scope.pendingUsers = data.pendingUsers;

                $scope.getActivityEntryAfterDelay();

            })
            .error(function(data){
                //console.error(data);
                //alert("error")
            });

    };

    $scope.editName = function(){
        $scope.editNameMode = true;
        $scope.oldFirstname = $scope.userObject.firstname;
        $scope.oldSurname = $scope.userObject.surname;
    };

    $scope.cancelEditName = function(){
        $scope.editNameMode = false;
        $scope.userObject.firstname = $scope.oldFirstname;
        $scope.userObject.surname = $scope.oldSurname;
    };

    $scope.editEmail = function(){
        $scope.editEmailMode = true;
        $scope.oldEmail = $scope.userObject.email;
    };

    $scope.cancelEditEmail = function(){
        $scope.editEmailMode = false;
        $scope.userObject.email = $scope.oldEmail;
    };

    $scope.editPassword = function(){
        $scope.editPasswordMode = true;
    };

    $scope.cancelEditPassword = function(){
        $scope.editPasswordMode = false;
        $scope.userObject.password = null;
    };

    $scope.updateProfile = function()
    {
        var updateData = {
            firstname: $scope.userObject.firstname,
            surname: $scope.userObject.surname,
            email: $scope.userObject.email,
            avatar: $scope.userObject.avatar,
            username : $scope.userObject.username
        };

        // Special update case, as emails have to be unique
        updateData.editEmailMode = $scope.editEmailMode;
        updateData.editPasswordMode = $scope.editPasswordMode;

        $scope.editNameMode = false;
        $scope.editEmailMode = false;
        $scope.editPasswordMode = false;

        if ($scope.userObject.password)
            updateData.password = $scope.userObject.password;

        $http({ method: "POST", url: $scope.$root.backend + "/saveProfile", "params": {}, "data":
        {   updateFields: updateData} })
            .success(function(data, status){

                if(!data.success)
                {
                    if(data.email)
                    {
                        // Show error and revert to old email
                        $scope.$parent.activAlert("Error : Email Already Exists.", 2000);
                        $scope.userObject.email = $scope.oldEmail;
                    }
                }

            })
            .error(function(data){
                console.error(data);
                //alert("error")
            });
    };

    $scope.editAvatar = function(){
        document.getElementById("fileAvatar").click();
    };

    $scope.onFileSelect = function(){

        var elems = angular.element(document.querySelector("#fileAvatar"));
        if (elems.length > 0 && elems[0].files.length > 0){
            var file = elems[0].files[0];

            var fileReader = new FileReader();

            fileReader.onload = function(fileLoadedEvent)
            {
                $scope.userObject.avatar = fileLoadedEvent.target.result;
                $scope.updateProfile();
                //console.log(fileLoadedEvent.target.result);
            };

            fileReader.readAsDataURL(file);        }
    };

    $scope.deleteMeClick = function(){
        $scope.$parent.activChoice("Are you sure you would wish to delete your user from ActivDS?", [
            {
                "label": "Yes",
                "fn": function() {
                    $scope.deleteMe();
                }
            },
            {
                "label": "Cancel",
                "fn": function() { }
            }
        ]);

    };

    $scope.deleteMe = function(){
        $http({ method: "POST", url: $scope.$root.backend + "/deleteProfile", "params": {}, "data": {} })
            .success(function(data, status){
                if (data.success){
                    sessionStorage.clear();

                    $scope.$parent.userInfo = {};
                    $http.defaults.headers.common['Auth-Token'] = "";

                    $location.path("/login");

                }
            })
            .error(function(data){
                console.error(data);
                //alert("error")
            });
    };

    $scope.makeAdmin = function(userID){
        $http({ method: "POST", url: $scope.$root.backend + "/saveProfile", "params": {}, "data": {_uID: userID, updateFields: {admin: true}} })
            .success(function(data, status){
                $scope.loadGroupUsers();
            })
            .error(function(data){
                console.error(data);
                //alert("error")
            });

    };

    $scope.revokeAdmin = function(userID){
        $http({ method: "POST", url: $scope.$root.backend + "/saveProfile", "params": {}, "data": {_uID: userID, updateFields: {admin: false}} })
            .success(function(data, status){
                $scope.loadGroupUsers();
            })
            .error(function(data){
                console.error(data);
                //alert("error")
            });

    };

    $scope.switchView = function(target) {

        var targetLink =$("#" + target + "Link");
        var targetDiv = $("#" + target);

        $("#tabLinks").find("li.active").removeClass("active");
        $(".tabView.active").removeClass("active");

        targetLink.addClass("active");
        targetDiv.addClass("active");
    };

    $scope.checkUsers = function() {



    };

    $scope.checkPendingUsers = function() {

        if($scope.users) {

            var returnVal = true;

            if ($scope.users.length < 1) {
                returnVal = true;
            }

            $scope.users.forEach(function (user) {

                if (!user.active) {
                    returnVal = false;
                }

            });

            return returnVal;

        }

    };

    $scope.showResetPassword = function(){

        var entityTemplate = $("#resetPasswordTemplate").html();
        // Append a new container to the body and load dialog
        $scope.modalContainer = new ModalClass({title : "Change Password", "body" : entityTemplate});
        // hook into the save button
        $scope.modalContainer.primaryBtn.click(function(){
            // Get data back from the modal
            $scope.modalContainer.resetStatus();

            var currentPasswordInput = $scope.modalContainer.find(".currentPassword");
            var newPasswordInput = $scope.modalContainer.find(".newPassword");
            var confirmPasswordInput = $scope.modalContainer.find(".confirmPassword");

            if(Validations.checkEmpty(currentPasswordInput, $scope.modalContainer)
                && Validations.checkEmpty(newPasswordInput, $scope.modalContainer)
                && Validations.checkEmpty(confirmPasswordInput, $scope.modalContainer)
            )
            {
                // Validate password
                // Check password match
                var passwordVal = $.trim(newPasswordInput.val());
                var confirmPasswordVal = $.trim(confirmPasswordInput.val());
                if(passwordVal != confirmPasswordVal){
                    $scope.modalContainer.setStatusText("Passwords do not match", "red");
                    newPasswordInput.focus();
                    return;
                }

                // Validate password Strength
                if(!Validations.validatePassword(newPasswordInput, $scope.modalContainer) )
                    return;

                // Make sure the old pass is not the same as the new one
                if( currentPasswordInput.val().toLowerCase() == newPasswordInput.val().toLowerCase() ){
                    $scope.modalContainer.setErrorText("New Password cannot be the same as your old password");
                    newPasswordInput.focus();
                }


                var passwordData = {
                    currentPassword : currentPasswordInput.val(),
                    newPassword : newPasswordInput.val()
                };

                var postData = {
                    action: "resetUserPassword",
                    passwordData : passwordData
                };

                $http({
                    method: "POST", url: $scope.$parent.backend + "/teams", "params": {},
                    "data": postData
                })
                    .success(function (data, status){
                        if(data.success){
                            $scope.modalContainer.hide();
                        }else{
                            $scope.modalContainer.setStatusText("Error : " + data.error, "red");
                        }
                    })
                    .error(function (data) {
                        $scope.modalContainer.setErrorText("Error : " + data.error);
                    });
            }
        });
    };

    $scope.showEditProfile = function(){
            var editUser = $scope.userObject;

            var addEntityTemplate = $("#addUpdateUserTemplate").html();

            // Append a new container to the body and load dialog
            $scope.modalContainer = new ModalClass({title : "Profile", "body" : addEntityTemplate,
                                                    popupBodyCssClass:"auto-overflow-y"});

            var editMode = editUser != null;

            // hook into the save button
            $scope.modalContainer.primaryBtn.click(function(){
                // Get data back from the modal
                $scope.modalContainer.resetStatus();

                var usernameInput = $scope.modalContainer.find(".username");
                var firstnameInput = $scope.modalContainer.find(".firstname");
                var surnameInput = $scope.modalContainer.find(".surname");
                var emailInput = $scope.modalContainer.find(".email");
                var passwordInput = $scope.modalContainer.find(".password");
                var confirmPasswordInput = $scope.modalContainer.find(".confirmPassword");

                if(Validations.checkEmpty(usernameInput, $scope.modalContainer)
                    && Validations.checkEmpty(firstnameInput, $scope.modalContainer)
                    && Validations.checkEmpty(surnameInput, $scope.modalContainer)
                    && Validations.checkEmpty(emailInput, $scope.modalContainer)
                    && Validations.checkEmail(emailInput, $scope.modalContainer)
                )
                {
                    // Validate password in case this is a new user
                    var usernameVal = $.trim(usernameInput.val());
                    var user = {
                        username : $.trim(usernameInput.val()),
                        firstname : $.trim(firstnameInput.val()),
                        surname : $.trim(surnameInput.val()),
                        email: $.trim(emailInput.val())
                    };
                    user.ID = editUser.ID
                    var postData = {
                        action: "addUpdateUser",
                        user : user
                    };

                    var callback = function(err, data){
                        if(!err && data.success){
                            $scope.modalContainer.hide();
                                // Replace user
                                user.avatar = editUser.avatar;
                            $scope.userObject.username = user.username;
                            $scope.userObject.firstname = user.firstname;
                            $scope.userObject.surname = user.surname;
                            $scope.userObject.email = user.email;
                        }else{
                            $scope.modalContainer.setStatusText("Error : " + data.error, "red");
                        }
                    };

                    $scope.teamPost(postData, callback);
                }
            });

            // Prefill data
            $scope.modalContainer.find(".firstname").val(editUser.firstname);
            $scope.modalContainer.find(".surname").val(editUser.surname);
            $scope.modalContainer.find(".username").val(editUser.username);
            $scope.modalContainer.find(".email").val(editUser.email);

        };

    $scope.teamPost = function(postData, callback){
        $http({
            method: "POST", url: $scope.$parent.backend + "/teams", "params": {},
            "data": postData
        })
            .success(function (data, status){
                callback(null, data);
            })
            .error(function (data) {
                callback({error:data.message}, null);
            });
    };

    // Get Activity Code
    $scope.activityTime = new Date().getTime();
    $scope.getActivityEntry = function(){
        var requestTime = (new Date()).getTime();
        $http({ method: "POST", url: $scope.$root.backend + "/getActivityEntry", "params": {},
            "data": {types: "group", time: $scope.activityTime}})
            .success(function(data, status){
                if(data.success && data.records && data.records.length > 0) {
                    // We got a new entry
                    // update timestamp and refresh data
                    $scope.activityTime = requestTime;
                    $scope.loadGroupUsers();
                }
                else{
                    $scope.getActivityEntryAfterDelay();
                }
            })
            .error(function(data){
                console.error(data);
                $scope.getActivityEntryAfterDelay();
            });
    };
    $scope.getActivityTimeout_profile = null;
    $scope.getActivityEntryAfterDelay = function()
    {
        if( $scope.getActivityTimeout_profile )
            clearTimeout($scope.getActivityTimeout_profile);

        $scope.getActivityTimeout_profile = setTimeout(function(){
            if ($location.path() == "/home/profile")
                $scope.getActivityEntry();
        }, 15000);
    };
}]);