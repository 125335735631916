activ.directive("displayScheduleList", function(){

    return {

        restrict: "E",
        replace: true,
        templateUrl: "./views/displayScheduleList.html?v=" + version,
        scope: {
            schedule: "=schedule",
            projects: "=projects",
            count: "=count"
        },
        link: function(scope, element, attr){



        },
        controller: function($scope){

            $scope.isCollapsed = true;

        }

    }

});