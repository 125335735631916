activ.directive("editorLibrary", ["$http",  "ngDialog", function($http, ngDialog){

    return {

        restrict: "E",
        replace: true,
        templateUrl: "./views/editorLibrary.html?v=" + version,
        scope: {
            library: "=library",
            gf: "=gf",
            pLibrary: "=plibrary"
        },

        link: function(scope, element, attr){



        },

        controller: function($scope){

            $scope.removeFromProjectLibraryEndpoint = config.endpoints.removeFromProjectLibrary;

            $scope.toggleLibraryListVisibility = function(e) {

                e.target.classList.toggle("open");
                var toggleElement = e.target.getAttribute("data-for");

                // Check if the data has been loaded for this library
                var dataLoaded = e.target.getAttribute("dataLoaded");
                if(!dataLoaded){
                    // Get data for this asset type and load to library
                    var assetType = e.target.getAttribute("assetType");

                    // post to get data back
                    $http({ "method": "POST", "url": $scope.$parent.backend + "/projectLibrary",
                        "params": {}, "data": {
                            "projectId": $scope.$parent._projectID,
                            "assetType": assetType}
                        }).
                        success(function (data, status) {
                            if(data.success) {
                                var jToggleElem = $("#" + toggleElement);
                                jToggleElem.find(".loadingData").remove();
                                e.target.setAttribute("dataLoaded", 1);
                                // $scope.$parent.$parent.activAlert("Item removed from project library", 1000);
                                $scope.pLibrary[assetType] = data.assets ? data.assets : [];
                                $scope.pLibrary[assetType].sort(sortAlphabetically);
                            }
                        }).
                        error(function (data, status) {
                        });
                }

                document.getElementById(toggleElement).classList.toggle("hidden");

            };

            $scope.openFolderItem = null;
            $scope.showFolderModal = false;

            $scope.openFolder = function(folder)
            {
                $scope.openFolderItem = folder;

                // Show a popup with all folder items
                $scope.openFolderItem = folder;
                ngDialog.open({
                    template: 'folderDialogLibrary',
                    className: 'ngdialog-theme-default ngdialog-theme-custom',
                    scope: $scope
                });
            };

            $scope.addToProjectLibrary = function(libraryType, libItem) {

                var duplicate = false;
                var item = libItem;

                var itemIds = [];
                var assetType = libraryType;

                if(item.children)
                {

                    for (var i = 0; i < item.children.length; i++) {

                        var obj = item.children[i];
                        if(!$scope.existsInProjectLibrary(assetType,obj ))
                        {
                            itemIds.push(obj.ID);
                            $scope.pLibrary[libraryType].push(obj);
                        }
                    }
                }
                else
                {
                    if(!$scope.existsInProjectLibrary(assetType,item ))
                    {
                        itemIds.push(item.ID);
                        $scope.pLibrary[libraryType].push(item);
                    }
                }

                if(itemIds.length == 0)
                    return;

                $http({ "method": "POST", "url": $scope.$parent.backend + "/addToProjectLibrary", "params": {},
                                    "data": { "projectID": $scope.$parent._projectID,
                                        "type": assetType, "assetIDs": itemIds } }).
                    success(function (data, status) {
                        //$scope.$parent.$parent.activAlert("Item added to project library", 1000);
                    }).
                    error(function (data, status) {

                    });


            };

            $scope.removeFromProjectLibrary = function(libraryType, index)
            {
                var item = $scope.pLibrary[libraryType][index];

                $http({ "method": "POST", "url": $scope.$parent.backend + "/" + $scope.removeFromProjectLibraryEndpoint,
                    "params": {}, "data": {
                                    "projectID": $scope.$parent._projectID,
                                    "type": libraryType,
                                    "assetID": item.ID } }).
                    success(function (data, status) {

                        if(data.success) {
                            // $scope.$parent.$parent.activAlert("Item removed from project library", 1000);
                            $scope.pLibrary[libraryType].splice(index, 1);
                        }

                    }).
                    error(function (data, status) {
                    });

            };

            $scope.existsInProjectLibrary = function(type, libItem) {

                var exists = false;

                switch(type) {

                    case "images":

                        if($scope.pLibrary["images"]) {

                            $scope.pLibrary["images"].forEach(function (item) {

                                if (item.ID == libItem.ID) {
                                    exists = true;
                                }

                            });

                        }

                        break;

                    case "videos":

                        if($scope.pLibrary["videos"]) {

                            $scope.pLibrary["videos"].forEach(function (item) {

                                if (item.ID == libItem.ID) {
                                    exists = true;
                                }

                            });

                        }

                        break;

                    case "templates":

                        if($scope.pLibrary["templates"]) {

                            $scope.pLibrary["templates"].forEach(function (item) {

                                if (item.ID == libItem.ID) {
                                    exists = true;
                                }

                            });

                        }

                        break;


                    case "widgets":

                        if($scope.pLibrary["widgets"]) {

                            $scope.pLibrary["widgets"].forEach(function (item) {

                                if (item.ID == libItem.ID) {
                                    exists = true;
                                }

                            });

                        }

                        break;

                }

                return exists;

            };

        }

    }

}]);