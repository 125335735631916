function initRHSTimeline(){

    var rhsTimeline = {};

    rhsTimeline.timeSpanHolder = document.querySelector(".layerTimeSpans");
    rhsTimeline.layerHolder = document.querySelector(".layerHolder");
    rhsTimeline.timeLineRight = document.querySelector(".timeLineRight");

    //  RKC New Code
    rhsTimeline.createTimelineElement = function(tli) {

        var timeSpan = document.createElement("span");

        if(tli.parent != null) {
            timeSpan.setAttribute("data-parentid", tli.parent.id);
        }

        if(tli.alwaysOn)
        {
            tli.startTime = 0;
            tli.endTime = timeline.projectLength;
            tli.duration = timeline.projectLength;
        }

        timeSpan.setAttribute("data-layerid", tli.id);

        tli.rhsTimelineElement = timeSpan;

        timeSpan.classList.add("timeSpan");

        // if parent has repeating items then create and render those
        if(tli.parent && tli.parent.repeatItems && tli.parent.repeatCount > 1) {

            for(var count=0; count<tli.parent.repeatCount; count++) {
                var duration = tli.parent.endTime - tli.parent.startTime;
                var offset = (duration / tli.parent.repeatCount) * count;

                var repeatingSpan = document.createElement("span");
                repeatingSpan.classList.add("timeSpan");
                repeatingSpan.classList.add("repeating");

                var repeatingItem = jQuery.extend({}, tli);
                repeatingItem.startTime = offset + (tli.startTime / tli.parent.repeatCount);
                repeatingItem.endTime = offset + (tli.endTime / tli.parent.repeatCount);
                repeatingItem.duration = tli.duration / tli.parent.repeatCount;
                this.baseRenderTimelineElement(repeatingItem, repeatingSpan);

                timeSpan.appendChild(repeatingSpan);
            }
        } else {

            this.baseRenderTimelineElement(tli, timeSpan);

            //  add resize handles
            var timeSpanHandleLeft = document.createElement("span");
            timeSpanHandleLeft.classList.add("timeSpanHandleLeft");
            timeSpanHandleLeft.setAttribute("resize-handle", "left");
            timeSpanHandleLeft.setAttribute("data-layerid", tli.id);
            timeSpanHandleLeft.addEventListener("mousedown", timelineDragManager.handleMouseDown);
            timeSpan.appendChild(timeSpanHandleLeft);

            var timeSpanHandleRight = document.createElement("span");
            timeSpanHandleRight.classList.add("timeSpanHandleRight");
            timeSpanHandleRight.setAttribute("resize-handle", "right");
            timeSpanHandleRight.setAttribute("data-layerid", tli.id);
            timeSpanHandleRight.addEventListener("mousedown", timelineDragManager.handleMouseDown);
            timeSpan.appendChild(timeSpanHandleRight);

            //  hook up the handlers for the resize sections
            timelineDragManager.initialize(timeline.$scope, tli, timeSpan);
        }

        //  if there children then create a group !
        var timeSpanGroup = null;
        if (tli.children.length > 0) {
            var timeSpanGroup = document.createElement("span");
            timeSpanGroup.setAttribute("data-layerid", tli.id);
            tli.rhsTimeSpanGroup = timeSpanGroup;

            timeSpanGroup.classList.add("timeSpanGroup");

            if (tli.isCollapsed) {
                timeSpanGroup.style.visibility = "visible";
            }

            timeSpanGroup.appendChild(timeSpan);
        }



        //  is the timespan locked?
        if(tli.locked) {
            timeSpan.classList.add("lock");
        }

        //  is the timespan visible ?
        if(!tli.visible) {
            timeSpan.classList.add("layerHidden");
        }


        return timeSpanGroup || timeSpan;
    };

    rhsTimeline.baseRenderTimelineElement = function(tli, timeSpan) {
        var startTimeInSeconds = parseInt(tli.startTime / 1000);
        timeSpan.style.left = ( timeline.zeroTickOffset + ( timeline.$scope.oneSecond * startTimeInSeconds ) ) + "px";

        var durationInSeconds = parseInt( tli.duration/1000 );
        var timeSpanLength = parseInt(durationInSeconds * timeline.$scope.oneSecond);
        timeSpan.style.width = timeSpanLength + "px";
    };

    rhsTimeline.build = function(_layer, topLevel) {
        var layerEl = document.createElement("li");
        layerEl.classList.add("layer");
        layerEl.setAttribute("data-layerid", _layer.id);
        layerEl.setAttribute("title", _layer.name);

        var newSpan = this.createTimelineElement(_layer);


        if(_layer.parent && _layer.parent.isCollapsed)
        {
            layerEl.style.display = "none";
        }


        layerEl.appendChild(newSpan);


        layerEl.addEventListener("click", function(e){

            var target = e.target;
            var dataLayerId = target.getAttribute("data-layerid");
            var layerObj = timeline.getItemById(dataLayerId);
            if(!layerObj.locked) {

                layerObj.select(e);

            }
        });

        topLevel.appendChild(layerEl);
    };

    //  draw the rhs timeline spans
    rhsTimeline.drawRHSTimeline = function () {

        //  reset the timeline
        this.timeSpanHolder.innerHTML = "";
        var topLevel = document.createElement("ul");
        this.timeSpanHolder.appendChild(topLevel);

        //  build
        for (var i = timeline.items.length - 1; i >=0; i--) {
            var parent = timeline.items[i];
            this.build(parent, topLevel);

            if (parent.children.length > 0) {
                for (var x = 0; x < parent.children.length; x++) {
                    this.build(parent.children[x], topLevel);
                }
            }
        }
    };

    // Sends back the start / end time for the dom element
    rhsTimeline.applyStartEndFromTimelineDOM = function(timelineItem, timeSpan, domStyle, mode){

        var result = true;

        var timeSpanHolder = $("#layerTimeSpans");
        var timeLineRight = $("#timeLineRight");

        var oneSecond = timeline.$scope.oneSecond;
        var l = domStyle.left;
        var w = domStyle.width;

        // Check to make sure we're not beyond the lhs zero tick
        if(l < timeline.zeroTickOffset){
            l = timeline.zeroTickOffset;
            w = parseInt( ( timelineItem.endTime / 1000 ) * oneSecond );
            result = false;
        }

        /*
        // Check to make sure we're not beyond the right edge
        var timeSpanRightEdge = l + w;
        var maxRight =  Math.max(timeSpanHolder.width(), timeLineRight.width());

        if( timeSpanRightEdge > maxRight){
            // Pull it back, dont allow further move/resize
            l = parseInt(timeSpan.style.left);
            w = parseInt(timeSpan.style.width);
            result = false;
        }
        */

        // Validations complete, apply the latest styles

        // Apply zero offset for calcs
        l -= timeline.zeroTickOffset;
        timelineItem.startTime = Math.ceil(l / oneSecond) * 1000;

        if(mode && mode == "move"){
            // For move, dont update the endtime, just use the old duration
            timelineItem.endTime = timelineItem.startTime + timelineItem.duration;
        }
        else{
            timelineItem.endTime = Math.ceil((l + w) / oneSecond) * 1000;
            timelineItem.duration = parseInt(timelineItem.endTime) - parseInt(timelineItem.startTime);
        }

        // Update dom element
        this.baseRenderTimelineElement(timelineItem, timeSpan);

        /*
        // Scroll timeline container if needed, Buggy, needs fixing
        var rightEdge = l + w - 50;
        var holderWidth = parseInt(timeSpanHolder.style.width, 10);

        if (rightEdge >= holderWidth) {
            timeLineRight.scrollLeft = timeLineRight.scrollWidth;
        }
        */

        return result;
    };

    rhsTimeline.applyDomStyleFromTimelineItem = function(timelineItem, domElement){

    };

    return rhsTimeline;
}