activ.directive("timeline", function(){

    return {

        restrict: "E",
        replace: true,
        templateUrl: "./views/timeline.html?v=" + version,
        scope: {
            layers: "=layers"
        },

        link: function(scope, element, attr){



        },

        controller: function($scope){

            $scope.timelineToggle = true;
            $scope.seconds = [];

            $scope.toggleTimeline = function() {
                $scope.timelineToggle = !$scope.timelineToggle;
            };

            $scope.twoDigits = function(num) {
                if (num<10) {num = "0" + num};  // add zero in front of numbers < 10
                return num;
            };

            $scope.getTimeTickStr = function(secVal){

                var retVal = secVal;

                var mins = $scope.twoDigits(Math.floor( retVal/60 ));
                var secLeft = $scope.twoDigits(retVal % 60);

                var hrs = $scope.twoDigits(Math.floor(mins/60));
                var minsLeft = $scope.twoDigits(mins % 60);

                if( hrs > 0 ){
                    retVal = hrs + ":" + minsLeft + ":" + secLeft;
                }
                else{
                    retVal = minsLeft + ":" + secLeft;
                }

                return retVal;
            };

            var timeLineZoomStartAmount = $scope.$parent.timeLineZoom;
            var pageYStart = 0;
            var timeSpanHolder = document.getElementById("layerTimeSpans");
            var timeLineTime = document.getElementById("timeLineTime");


            /*
            $scope.$parent.$watch("stageProperties.projectLength", function(){

                if($scope.$parent.stageProperties.projectLength === null){
                    $scope.$parent.stageProperties.projectLength = 0;
                }

                $scope.seconds = new Array((Math.ceil((parseInt($scope.$parent.stageProperties.projectLength, 10) + 1000) / 1000)));
                timeLineTime.style.width = timeSpanHolder.style.width = $scope.seconds.length * $scope.$parent.oneSecond + "px";

            }, false);
            */

            $scope.timeLineZoomFn = function(amount) {

                timeline.$scope.timeLineZoom += amount;

                if(timeline.$scope.timeLineZoom <= 0) {
                    timeline.$scope.timeLineZoom = 0.02;
                }

                // console.log("TLZ : " + $scope.$parent.timeLineZoom );
                timeline.rerenderTicks();
                timeline.renderTimeline();
            };

            var timeLineZoomStart = function(e) {

                timeLineZoomStartAmount = $scope.$parent.timeLineZoom;
                pageYStart = e.pageY;

                window.addEventListener("mousemove", timeLineZoomZoom);
                window.addEventListener("mouseup", timeLineZoomEnd);

            };

            var timeLineZoomZoom = function(e) {

                timeline.$scope.timeLineZoom = Math.max(timeLineZoomStartAmount + ((pageYStart - e.pageY) * 0.005), 0.02);
                //console.log("TLZ : " + $scope.$parent.timeLineZoom);

                timeline.rerenderTicks();
                timeline.renderTimeline();

            };


            var timeLineZoomEnd = function(e) {

                window.removeEventListener("mousemove", timeLineZoomZoom);
                window.removeEventListener("mouseup", timeLineZoomEnd);

            };

            document.getElementById("timeLineTime").addEventListener("mousedown", timeLineZoomStart);

        }

    }

});