activ.filter("timestampToDate", function(){

    return function(item) {
       timestampToDate(item);
    }

});

function timestampToDate(item){
    var dateObj = new Date(parseInt(item, 10));
    var dd = dateObj.getDate();
    var mm = dateObj.getMonth() + 1;
    var yyyy = dateObj.getFullYear();
    var hours = dateObj.getHours();
    var mins = dateObj.getMinutes();
    var ms = dateObj.getMilliseconds();

    var output = dd + "/" + (mm < 10 ? "0" + mm : mm) + "/" + yyyy + " at " + (hours < 10 ? "0" + hours : hours) + ":" + (mins < 10 ? "0" + mins : mins);

    return output;
}

activ.filter("dateTimeToDate", function(){

    return function(item) {

        var dateObj = new Date(item);
        var dd = dateObj.getDate();
        var mm = dateObj.getMonth() + 1;
        var yyyy = dateObj.getFullYear();
        var hours = dateObj.getHours();
        var mins = dateObj.getMinutes();
        var ms = dateObj.getMilliseconds();

        var output = dd + "/" + (mm < 10 ? "0" + mm : mm) + "/" + yyyy + " at " + (hours < 10 ? "0" + hours : hours) + ":" + (mins < 10 ? "0" + mins : mins);

        return output;

    }

});