activ.directive("libraryDirective",
    ["$http", "$upload", "$location", "ngDialog", "$timeout",
    function($http, $upload, $location, ngDialog, $timeout) {

    return {

        restrict: "E",
        replace: true,
        templateUrl: "./views/libraryDirective.html?v=" + version,
        scope: {
            mode:"@",
            pLibrary: "=plibrary",
            library: "=library"
        },

        link: function (scope, element, attr) {

        },

        controller: function ($scope) {

            $scope.cb = (new Date()).getTime();
            $scope.pageTitle = "Library";
            $scope.uploadQueue = [];
            $scope.folderOpenTime = 0;
            $scope.showFileSize = false;
            $scope.showListView = false;
            $scope.libraryLoaded = true;

            $scope.loadingAssets = false;

            $scope.libraryCategories = [
                {
                    "children": [],
                    "name": "Images",
                    "categoryName" : "Images",
                    "_id": null,
                    "parent": null,
                    "type" : "category"
                },
                {
                    "children": [],
                    "name": "Videos",
                    "categoryName" : "Videos",
                    "_id" : null,
                    "parent": null,
                    "type" : "category"
                },
                {
                    "categoryName": "Widgets",
                    "children" : [],
                    "name": "Widgets",
                    "_id": null,
                    "parent": null,
                    "type" : "category"
                },
                {
                    "categoryName": "Templates",
                    "children" : [],
                    "name": "Templates",
                    "_id": null,
                    "parent": null,
                    "type" : "category"
                },
                {
                    "categoryName": "Web Fonts",
                    "children" : [],
                    "name": "Web Fonts",
                    "_id": null,
                    "parent": null,
                    "type" : "category"
                }
            ];

            var groupData = JSON.parse(sessionStorage.getItem("groupData"));
            $scope.groupFolder = groupData.groupFolder;

            $scope.uploadInProgress = false;
            $scope.uploadReady = false;
            $scope.uploadCount = 0;

            $scope.assetGroupEndpoint = config.endpoints.assetGroup;

            $scope.getFolderFromLibrary = function (assetItems, folderId) {
                var folder = null;
                for (var ai = 0; ai < assetItems.length; ai++) {
                    var item = assetItems[ai];

                    if (item.children && item.ID == folderId) {
                        folder = item;
                        break;
                    }
                }

                return folder;
            }

            $scope.retrieveLibrary = function (cb, postdata) {

                if(!postdata){
                    postdata = {}
                }

                $scope.loadingAssets = true;

                $http({
                    "method": "POST",
                    "url": $scope.$parent.$parent.backend + "/retrieveLibrary",
                    "params": {},
                    data: postdata
                }).
                    success(function (data, status) {
                        $scope.loadingAssets = false;
                        if (cb){
                            cb(data);
                        }

                        if($scope.mode != "projectLibrary") {
                            $scope.getActivityEntryAfterDelay();
                        }
                    }).
                    error(function (data, status) {
                        $scope.loadingAssets = false;
                       if(cb){
                           cb(data);
                       }
                    });
            };


            $scope.showLibraryCategory = function (i) {

                [].forEach.call(document.querySelectorAll(".libraryCategory"), function (el) {

                    if (el.getAttribute("id") === "libraryCategory_" + i) {
                        el.classList.toggle("show");
                    } else {
                        el.classList.remove("show");
                    }

                });

            };

            $scope.getFileSizeText = function(bytes) {
                if (!bytes) {
                    return '0 KB';
                }

                return getFileSize(bytes);
            };

            $scope.removeFromLibrary = function (id, category, item) {

                var now = (new Date()).getTime();
                var diff = now - $scope.folderOpenTime;

                // Avoid the double click remove folder action,
                // 600 ms should be sufficient
                if(diff <= 600){
                    return;
                }

                var message = item.type == "folder" ?
                    "This will delete the folder and its contents from the library and the projects which may be using these!<br/>Are you sure?"
                    : "This will delete the item from the library and the project which may be using it!<br/>Are you sure?"

                $scope.$parent.$parent.activChoice(message, [
                    {
                        "label": "Yes",
                        "fn": function () {
                            if (item.type == "folder") {
                                var userInfo = $scope.userInfo || $scope.$parent.$parent.userInfo;
                                // this is a folder
                                // Remove items from folder
                                var postData = {
                                    "groupId": userInfo.groupID,
                                    "assetGroupId": item.ID,
                                    "assetType": $scope.currentCategory.categoryName,
                                    "action": "removeFolder",
                                    "removeChildren": true
                                };

                                postData.sourceFolderId = $scope.currentFolder.ID?$scope.currentFolder.ID:0;

                                $http({
                                    "method": "POST",
                                    "url": $scope.$parent.$parent.backend + "/" + $scope.assetGroupEndpoint,
                                    "params": {},
                                    "data": postData
                                }).
                                    success(function (data, status) {
                                        if (data.success) {
                                            var index = $scope.currentFolder.children.indexOf(item);
                                            $scope.currentFolder.children.splice(index, 1);
                                        }
                                        else{
                                            $scope.$parent.$parent.activAlert("An error has occurred");
                                        }
                                    }).
                                    error(function (data, status) {
                                        $scope.$parent.$parent.activAlert("An error has occurred");
                                    });
                            }
                            else {
                                // Remove from root
                                $http({
                                    "method": "POST",
                                    "url": $scope.$parent.$parent.backend + "/deleteFromLibrary",
                                    "params": {},
                                    "data": {"id": id, "category": category}
                                }).
                                    success(function (data, status) {
                                        if (data.success) {
                                            // We're in a folder
                                            var itemIndex = $scope.currentFolder.children.indexOf(item);
                                            $scope.currentFolder.children.splice(itemIndex, 1);
                                        } else {
                                            $scope.$parent.$parent.activAlert("An error occurred. Please try again", 2000);
                                        }

                                    }).
                                    error(function (data, status) {
                                    });
                            }
                        }
                    },
                    {
                        "label": "No",
                        "fn": function () {
                        }
                    }
                ]);


            };

            var createThumbnail = function (imgData, cb) {

                if (imgData.type.indexOf("video/") === 0) {

                    /*            var videoEl = document.createElement("video");
                     videoEl.addEventListener("loadedmetadata", function(){

                     var w = this.videoWidth;
                     var h = this.videoHeight;
                     videoEl = null;
                     cb(null, w, h);

                     });

                     videoEl.src = imgData.data;*/
                    cb(null, null, null);

                } else if (imgData.type.indexOf("image/") === 0) {

                    var imgObj = new Image();
                    var thumbnail = null;

                    imgObj.onload = function () {

                        var newWidth = 90;
                        var newHeight = Math.round(this.height / (this.width / newWidth));

                        var canvasEl = document.createElement("canvas");
                        canvasEl.style.position = "absolute";
                        canvasEl.style.left = "0px";
                        canvasEl.style.top = "0px";
                        canvasEl.height = newHeight;
                        canvasEl.width = newWidth;

                        var canvas = canvasEl.getContext("2d");

                        canvas.drawImage(this, 0, 0, newWidth, newHeight);

                        thumbnail = canvasEl.toDataURL();

                        cb(thumbnail, this.width, this.height);

                    };

                    imgObj.src = imgData.data;

                }

            };

            var setUpDragAndDrop = function () {

                var dragOver = function (e) {
                    e.stopPropagation();
                    e.preventDefault();
                    return false;
                };

                var dragEnter = function (e) {
                    e.stopPropagation();
                    e.preventDefault();
                    return false;
                };

                var drop = function (e) {

                    e.stopPropagation();
                    e.preventDefault();

                    var extraFilesAdded;
                    var filesProcessed = 0;
                    var fileCount = e.dataTransfer.files.length;
                    $scope.uploadReady = false;
                    $scope.uploadQueue.length <= 0 ? extraFilesAdded = false : extraFilesAdded = true;

                    Array.prototype.forEach.call(e.dataTransfer.files, (function (file, i) {

                        var fileDropWorker = new Worker("js/app/workers/fileDropWorker.js");
                        fileDropWorker.postMessage({file: file, id: i});
                        fileDropWorker.addEventListener("message", function (e) {

                            $scope.uploadQueue.push(e.data);

                            filesProcessed++;
                            if (filesProcessed === fileCount) {
                                $scope.uploadReady = true;
                            }

                            $scope.$apply();

                            if (extraFilesAdded) {

                                var fileUploadQueueContainer = document.getElementById("fileUploadQueueContainer");
                                fileUploadQueueContainer.scrollTop = fileUploadQueueContainer.scrollHeight;

                            }

                        });

                    }));

                    return false;

                };
            };

            (function retrieveFolderSize() {
                var userInfo = $scope.userInfo || $scope.$parent.$parent.userInfo;
                var postData = {
                    "groupId": userInfo.groupID,
                };

                $http({"method": "POST", "url": config.backend + "/retrieveLibraryFolderSize", "params": {}, data: postData}).then(function(response) {
                    if(response.data) {
                        $scope.folderMeta = response.data;
                    } else {
                        $scope.folderMeta = null;
                    }
                });
            })();

            $scope.getFolderSize = function(category) {
                var fileSizeText = '';
                if($scope.folderMeta) {
                    var folderSize = $scope.folderMeta.folders[category] || 0;
                    fileSizeText = '(' + $scope.getFileSizeText(folderSize) + ')';
                }
                return fileSizeText;
            };

            var setupData = function(data){

                $scope.libraryLoaded = true;

                $scope.libraryCategories.forEach(function (category) {
                    if (category.categoryName == "Images") {
                        category.children = data.images;
                    } else if (category.categoryName == "Videos") {
                        category.children = data.videos;
                    } else if (category.categoryName == "Templates") {
                        category.children = data.templates;
                    } else if (category.categoryName == "Widgets") {
                        category.children = data.widgets;
                    }
                });
            }

            if($scope.mode != "projectLibrary") {
                // $scope.retrieveLibrary(setupData);
                // Dont load library here, let it load dynamically
            }else{
                $scope.$watch('library', function(newVal, oldVal){
                    if(newVal) {
                        setupData(newVal);
                        $scope.adjustContentHeight();
                    }
                }, true);
            }

            $scope.hasUploader = function (index) {
                return $scope.$parent.$parent.upload[index] != null;
            };

            $scope.abort = function (index) {
                $scope.$parent.$parent.upload[index].abort();
                $scope.$parent.$parent.upload[index] = null;
            };

            $scope.adjustContentHeight = function(){

                // Apply only for project library mode where we have a shorter height
                if($scope.mode == "projectLibrary") {
                    var sideMenuHeight = $("#sideMenu").height();
                    var librarySmall = $("#librarySmall");

                    if(librarySmall.height() < sideMenuHeight){

                        var fullLibrary = $("#fullLibrary");
                        var libraryContainer = $("#libraryContainer");

                        librarySmall.height(sideMenuHeight);
                        fullLibrary.height(sideMenuHeight);
                        libraryContainer.height(sideMenuHeight);
                    }
                }
            };

            $scope.getLibraryCategory = function (cat) {
                var fCat = null;

                for (var i = 0; i < $scope.libraryCategories.length; i++) {
                    var lc = $scope.libraryCategories[i];
                    if (lc.categoryName.toLowerCase() == cat.toLowerCase()) {
                        fCat = lc;
                        break;
                    }
                }
                return fCat;
            }

            $scope.getItemByIDFromLibrary = function (itemId, libcat) {
                var foundItem = null;

                libcat.forEach(function (item, i) {
                    if (item.ID == itemId) {
                        foundItem = item;
                        return false;
                    }
                });

                return foundItem;
            };

            $scope.getTitleStyle = function(webFont){
                var fontName = getFontNameFromURL(webFont.URL);
                return {"font-family": fontName};
            };

            $scope.showAddWebFont = function(editWebFontObj){
 
                // Show add/edit popup

                var addEntityTemplate = $("#addUpdateWebFont").html();

                var editMode = editWebFontObj ? true : false;

                // Append a new container to the body and load dialog
                $scope.modalContainer = new ModalClass({title : editMode ? "Edit Web Font": "Add Web Font", "body" : addEntityTemplate});

                // Add data to modal container for edit case
                var webFontNameInput = $scope.modalContainer.find("#webFontName");
                var webFontURLInput = $scope.modalContainer.find("#webFontURL");

                var webFont = { 
                    "ID" : 0
                };

                if(editMode){
                    webFontNameInput.val(editWebFontObj.name);
                    webFontURLInput.val(editWebFontObj.URL);
                    webFont.ID = editWebFontObj.ID;
                }

                // hook into the save button
                $scope.modalContainer.primaryBtn.click(function(){
                    
                    var webFontNameVal = $.trim(webFontNameInput.val());
                    var webFontURLVal = $.trim(webFontURLInput.val());
    
                    // update from form
                    webFont.name = webFontNameVal;
                    webFont.URL = webFontURLVal;
                    
                    // Get data back from the modal
                    $scope.modalContainer.resetStatus();

                    // Validate 
                    if(Validations.checkEmpty(webFontNameInput, $scope.modalContainer, true)
                        && Validations.checkEmpty(webFontURLInput, $scope.modalContainer, true)
                    ){
                        var duplicateWebFont = null
                        // Check if we already have a font by the same name before submitting..
                        if($scope.currentCategory.children && $scope.currentCategory.children.length > 0){
                            for(var i = 0 ; i < $scope.currentCategory.children.length; i++){
                                var item = $scope.currentCategory.children[i];
                                if(item.ID != webFont.ID && item.name.toLowerCase() == webFont.name.toLowerCase()){
                                     duplicateWebFont = item;
                                     break;
                                }   
                            }
                        }

                        if(duplicateWebFont){
                            $scope.modalContainer.setStatusText("A Web Font with the same name already exists.", "red");
                            return;
                        }
                        
                        var postData = {
                            action: "addUpdate",
                            webFont : webFont
                        };

                        // Post to backend and save
                        $http({
                            method: "POST", 
                            url: $scope.$parent.backend + "/webFonts", "params": {},
                            "data": postData
                        })
                        .success(function (data, status){
                            // Success case : 
                            // Reload current view 
                            // Hide popup    
                            if(data.success){
                                $scope.modalContainer.hide();
                            }else{
                                $scope.modalContainer.setStatusText("Error while saving web font: " + data.error, "red");
                            }
                        })
                        .error(function (data) {
                            $scope.modalContainer.setStatusText("Error while saving web font: " + data.error, "red");
                        });
                    }
                });

                $scope.modalContainer.adjustContentHeight();                
            };

            $scope.showCreateNewFolder = function(){
                var folderName = prompt("Enter Folder Name", "Folder");
                if (folderName) {
                    folderName = folderName.trim()

                    var categoryRef = $scope.currentCategory;

                    var folderExists = $scope.checkFolderNameExists(null, categoryRef, folderName);
                    if (folderExists) {
                        $scope.$parent.$parent.activAlert("Folder with the same name already exists", 2000);
                        return;
                    }


                    // Put these elements into a folder
                    if (folderName.length > 0) {
                        // For existing groups, send Id :
                        var backend = $scope.backend || $scope.$parent.$parent.backend;
                        var userInfo = $scope.userInfo || $scope.$parent.$parent.userInfo;

                        // Are we inside a folder?
                        {

                            // This is a folder view, lets move the assets into the current folder
                            // and simply call update folder

                            var newFolder = {
                                groupId : userInfo.groupId,
                                children : [],
                                name : folderName,
                                type : "folder"
                            };
                        }

                        var postData = {
                            "groupId": userInfo.groupID,
                            "assetGroupTitle": folderName,
                            "assetType": $scope.currentCategory.name.toLowerCase(),
                            "folder" : $scope.currentFolder,
                            "action" : "addFolder"
                        };

                        postData.assetIds = [];

                        postData.sourceFolderId = ($scope.currentFolder && $scope.currentFolder.ID) ? $scope.currentFolder.ID : 0;

                        // Create a new Folder in the backend and get its id
                        $http({
                            method: "POST", url: backend + "/"+ $scope.assetGroupEndpoint, "params": {},
                            "data": postData
                        })
                            .success(function (data, status) {

                                if (data.success) {

                                    newFolder.ID = data.folder.ID;
                                    newFolder.type = "folder";
                                    newFolder.children = [];
                                    newFolder.dataLoaded = true;
                                    // insert new folder into the array
                                    $scope.currentFolder.children.push(newFolder);
                                    $scope.sortCurrentFolder();
                                }
                                else {
                                    alert("Error while creating folder : " + data.error);
                                }
                            })
                            .error(function (data) {
                                console.error(data);
                                alert("Error while creating folder : " + data.error);
                            });
                    }
                }
            };

            $scope.libraryElemDrop = function ($event, $channel, $draggedAsset, $droppedOnAsset, category, libraryCategoryArray, assetIndex) {
                var categoryType = $channel.toLowerCase();

                // Check access
                if(! $scope.$root.hasAccessRight('Assets - Create')){
                    return;
                }

                if (categoryType == "widgets" || categoryType == "templates"
                    ||  ($scope.breadcrumbsStack.length == 1 &&  $droppedOnAsset.ID == null)
                ) {
                    return;
                }

                // var draggedItem = $draggedAsset; //$scope.getItemByIDFromLibrary($draggedAsset.ID, libraryCategoryArray);
                // var droppedOnItem = $droppedOnAsset; //$scope.getItemByIDFromLibrary($droppedOnAsset.ID, libraryCategoryArray);

                // Check element types
                if($droppedOnAsset.ID == null){

                    // we dropped the item on the top breadcrumb for root category
                    // Move the item out of the folder and into the main library

                    var draggedItem = $scope.getItemByIDFromLibrary($draggedAsset.ID, libraryCategoryArray);

                    if(draggedItem.type == "folder") {
                        // Check for existing folder
                        if($scope.checkFolderNameExists(null, $droppedOnAsset, draggedItem.name)){
                            $scope.$parent.$parent.activAlert("Folder with the same name already exists in folder "
                                    + $droppedOnAsset.name, 2000);
                            return;
                        }
                    }

                    var index = $scope.currentFolder.children.indexOf(draggedItem);

                    var callback = function(data){
                        if(data.success) {
                            $scope.currentFolder.children.splice(index, 1);
                            $droppedOnAsset.children.push(draggedItem);

                            $scope.sortCurrentFolder();
                        }
                    }

                    // Update folder
                    $scope.updateFolder($droppedOnAsset, draggedItem, callback);
                }
                else if ($draggedAsset.ID != $droppedOnAsset.ID) { // Did we drop on the same element? ignore is yes

                    var draggedItem = $draggedAsset; //$scope.getItemByIDFromLibrary($draggedAsset.ID, libraryCategoryArray);
                    var droppedOnItem = $droppedOnAsset; //$scope.getItemByIDFromLibrary($droppedOnAsset.ID, libraryCategoryArray);

                    // Did we drag a folder onto a file? ignore the action
                    if(draggedItem.type == "folder" && droppedOnItem.type!= "folder" ){
                        return;
                    }

                    if ($droppedOnAsset.type == "folder") {
                        // Add Items to the folder
                        var draggedItem = $scope.getItemByIDFromLibrary($draggedAsset.ID, $scope.currentFolder.children);

                        /*
                        if(draggedItem.type == "folder") {
                            // Check for existing folder
                            if($scope.checkFolderNameExists(null, $droppedOnAsset, draggedItem.name)){
                                $scope.$parent.$parent.activAlert("Folder with the same name already exists in folder "
                                    + $droppedOnAsset.name, 2000);
                                return;
                            }
                        }
                        */
                        var index = $scope.currentFolder.children.indexOf(draggedItem);
                        var callback = function(data){
                            if(data.success) {
                                $scope.currentFolder.children.splice(index, 1);
                                if(droppedOnItem.children)
                                    droppedOnItem.children.push(draggedItem);

                                $scope.sortCurrentFolder();
                            }
                        };
                        // Pass the top level folder for mongo updation
                        $scope.updateFolder(droppedOnItem, draggedItem, callback);
                    }
                    else {
                        var folderName = prompt("Enter Folder Name", "Folder");

                        if (folderName) {
                            var droppedOnIndex = -1;

                            folderName = folderName.trim()
                            //var categoryRef = $scope.getLibraryCategory(categoryType);

                            var categoryRef = $scope.currentCategory;

                            var folderExists = $scope.checkFolderNameExists(null, categoryRef, folderName);
                            if (folderExists) {
                                $scope.$parent.$parent.activAlert("Folder with the same name already exists", 2000);
                                return;
                            }


                            // Put these elements into a folder
                            if (folderName.length > 0) {
                                // For existing groups, send Id :
                                var backend = $scope.backend || $scope.$parent.$parent.backend;
                                var userInfo = $scope.userInfo || $scope.$parent.$parent.userInfo;

                                // Are we inside a folder?
                                {

                                    // This is a folder view, lets move the assets into the current folder
                                    // and simply call update folder

                                    var draggedIndex = $scope.currentFolder.children.indexOf(draggedItem);
                                    $scope.currentFolder.children.splice(draggedIndex, 1);

                                    var droppedIndex = $scope.currentFolder.children.indexOf(droppedOnItem);
                                    $scope.currentFolder.children.splice(droppedIndex, 1);

                                    var newFolder = {
                                        groupId : userInfo.groupId,
                                        children : [draggedItem, droppedOnItem],
                                        name : folderName,
                                        type : "folder"
                                    };
                                }

                                var postData = {
                                    "groupId": userInfo.groupID,
                                    "assetGroupTitle": folderName,
                                    "assetType": $channel.toLowerCase(),
                                    "folder" : $scope.currentFolder,
                                    "action" : "addFolder"
                                };

                                postData.assetIds = [];
                                postData.assetIds.push(draggedItem.ID);
                                postData.assetIds.push(droppedOnItem.ID);

                                postData.sourceFolderId = $scope.currentFolder.ID?$scope.currentFolder.ID:0;

                                // Create a new Folder in the backend and get its id
                                $http({
                                    method: "POST", url: backend + "/"+ $scope.assetGroupEndpoint, "params": {},
                                    "data": postData
                                })
                                    .success(function (data, status) {

                                        if (data.success) {

                                            newFolder.ID = data.folder.ID;
                                            newFolder.dataLoaded = true;
                                            
                                            // Remove items
                                            for (var i = 0; i < libraryCategoryArray.length; i++) {
                                                var item = libraryCategoryArray[i];
                                                if (item.ID === draggedItem.ID) {
                                                    libraryCategoryArray.splice(i, 1);
                                                    i--;
                                                }

                                                if (item.ID === droppedOnItem.ID) {
                                                    libraryCategoryArray.splice(i, 1);
                                                    droppedOnIndex = i;
                                                    i--;
                                                }
                                            }

                                            // insert new folder into the array
                                            libraryCategoryArray.push(newFolder);

                                            $scope.sortCurrentFolder();
                                        }
                                        else {
                                            alert("Error while creating folder : " + data.error);
                                        }
                                    })
                                    .error(function (data) {
                                        console.error(data);
                                        alert("Error while creating folder : " + data.error);
                                    });
                            }
                        }
                    }
                }
            };

            $scope.showFolderModal = false;

            $scope.closeFolder = function () {
                $scope.showFolderModal = false;
            };

            $scope.breadcrumbsStack = [];
            $scope.currentFolder = null;
            $scope.currentCategory = null;

            $scope.categoryView = true;
            $scope.currentTopLevelFolder = null;


            $scope.resetRootFolder = function () {
                $scope.breadcrumbsStack = [];
                $scope.currentFolder = null;
                $scope.currentCategory = null;
                $scope.categoryView = true;
                $scope.currentTopLevelFolder = null;

                $scope.adjustContentHeight();
            };

            $scope.sortCurrentFolder = function () {
                if($scope.currentFolder && $scope.currentFolder.children && $scope.currentFolder.children.length > 0) {
                    $scope.currentFolder.children.sort(sortAssets);
                }

                $scope.adjustContentHeight();
            };

            $scope.resetRootFolder();

            $scope.showLibraryRootFolder = function () {
                $scope.resetRootFolder();
            };

            $scope.openLibraryCategoryFolder = function (libraryCategory) {
                // Replace the current view with the folder view
                // Update breadcrumbs
                $scope.folderOpenTime = (new Date()).getTime();
                $scope.breadcrumbsStack = [];
                $scope.breadcrumbsStack.push(libraryCategory);
                $scope.currentCategory = libraryCategory;
                $scope.currentFolder = libraryCategory;

                if(!$scope.currentFolder.dataLoaded){
                    // Does this category have data loaded?
                    // if so show existing data, else load current view data
                    $scope.loadingAssets = false;
                    var callback = function(data){
                        if(data.success){
                            $scope.currentFolder.children = data.assets;
                            $scope.currentFolder.dataLoaded = true;
                            $scope.sortCurrentFolder();
                        }
                        else{
                            // we got an error
                            $scope.$parent.$parent.activAlert("An error occurred whille loading data : "
                                                                    + data.error, 2000);
                        }
                    }

                    var postData = {"assetType" : libraryCategory.name,
                                    "folderId" : 0  // Root level
                                    };

                    $scope.retrieveLibrary( callback, postData );
                }

                $scope.currentTopLevelFolder = null;
                $scope.categoryView = true;

            };

            $scope.openLastFolder = function(){

                if($scope.breadcrumbsStack.length == (index + 1)){
                    // ignore the rightmost click, its the current folder
                    return;
                }

                $scope.breadcrumbsStack.pop();
                var index = $scope.breadcrumbsStack.length - 1;
                var lastBreadCrumb = $scope.breadcrumbsStack[index];

                if( $scope.breadcrumbsStack.length == 0 )
                    $scope.resetRootFolder();
                else
                    $scope.openBreadCrumbFolder(lastBreadCrumb, index + 1);

                // Test workaround for disapearing text on hitting back button
                $timeout(function(){
                    $(window).trigger('resize');
                }, 50);
            };

            $scope.openBreadCrumbFolder = function (breadCrumb, index) {
                // Replace the current view with the folder view
                // Update breadcrumbs
                $scope.folderOpenTime = (new Date()).getTime();

                if($scope.breadcrumbsStack.length == (index + 1)){
                    // ignore the rightmost click, its the current folder
                    return;
                }


                $scope.breadcrumbsStack.splice(index + 1);
                $scope.currentFolder = breadCrumb;
                $scope.currentTopLevelFolder = index > 0 ? breadCrumb : null;

                $scope.categoryView = true;
                $scope.sortCurrentFolder();

            };

            $scope.openLibraryCategoryItemFolder = function (folder) {

                $scope.folderOpenTime = (new Date()).getTime();
                // Replace the current view with the folder view
                // Update breadcrumbs
                if($scope.currentTopLevelFolder == null) {
                    $scope.currentTopLevelFolder = folder;
                }

                $scope.categoryView = false;
                $scope.currentFolder = folder;
                $scope.breadcrumbsStack.push(folder);

                if(!$scope.currentFolder.dataLoaded){
                    // Does this category have data loaded?
                    // if so show existing data, else load current view data
                    $scope.loadingAssets = false;
                    var callback = function(data){
                        if(data.success){
                            $scope.currentFolder.children = data.assets;
                            $scope.currentFolder.dataLoaded = true;
                            $scope.sortCurrentFolder();
                        }
                        else{
                            // we got an error
                            $scope.$parent.$parent.activAlert("An error occurred whille loading data : "
                                + data.error, 2000);
                        }
                    }

                    var postData = {"assetType" : $scope.currentCategory.name,
                        "folderId" : folder.ID  // Root level
                    };

                    $scope.retrieveLibrary( callback, postData );
                }
            };

            $scope.getTemplateByName = function (tName) {
                var template = null;
                var templates = $scope.getLibraryCategory("templates");
                for (var i = 0; i < templates.children.length; i++) {
                    var item = templates.children[i];
                    if (item.name.toLowerCase() == tName.toLowerCase()) {
                        template = item;
                        break;
                    }
                }
                return template;
            };

            $scope.checkFolderNameExists = function (currentFolder, categoryRef, newName) {
                var folderExists = false;
                for (var i = 0; i < categoryRef.children.length; i++) {
                    var item = categoryRef.children[i];
                    if (item.children && item.name == newName) {
                        if (currentFolder && item.ID == currentFolder.ID) {
                            // Do nothing if this is a rename case
                            // and we found the reference folder
                        }
                        else {
                            folderExists = true;
                            break;
                        }
                    }
                }

                return folderExists;
            };

            $scope.openTemplate = function(template){
                $location.path("/editor/template/"+ template.ID);
            };

            $scope.renameTemplate = function(template){

                var newName = prompt("Enter a new name for " + template.name, template.name);

                if (newName) {
                    newName = newName.trim();

                    if (newName.length > 0 && newName != template.name) {
                        // Check for duplicates
                        var existingTemp = $scope.getTemplateByName(newName);
                        if(existingTemp != null){
                            $scope.$parent.$parent.activAlert("Template with the same name already exists", 2000);
                        }
                        else{
                            template.name = newName;
                            $scope.updateTemplate(template);
                        }
                    }
                }
            };

            $scope.renameFolder = function (folder) {
                var newName = prompt("Enter a new name for " + folder.name, folder.name);

                if (newName) {
                    newName = newName.trim();

                    if (newName.length > 0 && newName != folder.name) {
                        var folderExists = $scope.checkFolderNameExists(folder, $scope.currentFolder, newName);
                        if (!folderExists) {

                            folder.name = newName;
                            folder.rename = true;
                            $scope.updateFolder(folder);
                        }
                        else {
                            $scope.$parent.$parent.activAlert("Folder with the same name already exists", 2000);
                        }
                    }
                }
            };

            $scope.toTitleCase = function(str)
            {
                return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
            };

            function isSupportedFile(file) {
                var fileType = file.type;
                var assetType = fileType.split("/")[0].toLowerCase();

                if(assetType != "image" && assetType != "video") {
                    return false;
                } else if(fileType == "video/quicktime") {
                    return false;
                }

                return true;
            }

            $scope.onFileSelect = function ($files) {
                // Do we have access rights?
                if(! $scope.$root.hasAccessRight('Assets - Create')){

                    $scope.$root.activAlert("You are not authorised to upload assets", 2000);
                    return;
                }

                $files.forEach(function (file, i) {
                    var filename = file.name;
                    var fileType = file.type;
                    var assetType = fileType.split("/")[0].toLowerCase();
                    var fileFolder = null;
                    var folderTop = $scope.currentTopLevelFolder;
                    var folderCategory = $scope.getLibraryCategory(assetType + "s");
                    var fileFolderTitle = $scope.toTitleCase(assetType) + "s";

                    if(!isSupportedFile(file)){
                        var errorMsg = "'" + filename + "' is not a supported file format";
                        $scope.$parent.$parent.activAlert(errorMsg, 2000);
                        return;
                    }

                    // are we in a category? match the category type with the asset type
                    if($scope.currentCategory && $scope.currentCategory.name.toLowerCase().indexOf(assetType) == -1 ){
                        $scope.$parent.$parent.activAlert("Only " + $scope.currentCategory.name + " can be uploaded to this folder.", 2000);
                        return;
                    }

                    if($scope.breadcrumbsStack.length > 1){
                        fileFolder = $scope.currentFolder;
                        // We're inside a folder, nest it
                        for(var i=1; i< $scope.breadcrumbsStack.length; i++){
                            var bc = $scope.breadcrumbsStack[i];
                            fileFolderTitle += "/" + bc.name;
                        }
                    }

                    var fileDropWorker = new Worker("js/app/workers/fileDropWorker.js");

                    fileDropWorker.postMessage({file: file, id: i});
                    fileDropWorker.addEventListener("message", function (e) {

                        createThumbnail(e.data, function (thumbnailData, width, height) {

                            var uniqueID = new Date().getTime();
                            var newItem = {
                                uniqueID: uniqueID,
                                groupID: $scope.$parent.$parent.userInfo.groupID,
                                fileName: uniqueID + "_" + file.name,
                                status: "Uploading",
                                progress: 0,
                                hasFinished: false,
                                width: width,
                                height: height,
                                thumbnail: thumbnailData,
                                folder : fileFolder,
                                folderTitle : fileFolderTitle,
                                folderTop : folderTop,
                                folderCategory : folderCategory,
                                libraryItem: {
                                    fileName: uniqueID + "_" + file.name,
                                    name: file.name,
                                    type: file.type
                                }
                            };

                            $scope.$parent.$parent.List.push(newItem);
                            $scope.$parent.$parent.selectedFiles.push(file);

                            $scope.$apply();

                        });

                    });

                });

            };

            var uploadComplete = function (index) {

                $scope.uploadCount--;
                $scope.$parent.$parent.List[index].done = true;

                if ($scope.uploadCount < 1) {
                    //$scope.retrieveLibrary();
                    $scope.$parent.$parent.initUploadFields();
                    $scope.uploadInProgress = false;

                }

            };

            $scope.performUpload = function () {

                $scope.uploadInProgress = true;
                $scope.uploadCount = $scope.$parent.$parent.List.length;

                $scope.$parent.$parent.List.forEach(function (item, i) {

                    item.done = false;
                    $scope.$parent.$parent.progress[i] = 0;

                    $scope.$parent.$parent.upload[i] = $upload.upload({

                        url: $scope.$parent.$parent.backend + '/upload',
                        method: "POST",
                        data: $scope.$parent.$parent.List[i],
                        file: $scope.$parent.$parent.selectedFiles[i]

                    }).then(function (response) {

                        if(response.data.asset) {
                            // Does this item need to go into a folder?
                            if (item.folder != null) {
                                item.folder.children.push(response.data.asset);
                                item.folder.children.sort(sortAssets);
                            }
                            else{
                                item.folderCategory.children.push(response.data.asset);
                                item.folderCategory.children.sort(sortAssets);
                            }
                        }

                        $scope.$parent.$parent.uploadResult.push(response.data);
                        $scope.$parent.$parent.List[i].status = "Processed";
                        $scope.$parent.$parent.Processed.push($scope.$parent.$parent.List[i]);

                        uploadComplete(i);

                    }, function (response) {

                        $scope.$parent.$parent.List[i].status = "Failed";
                        $scope.$parent.$parent.List[i].hasFinished = true;
                        $scope.$parent.$parent.List[i].progress = 0;

                        if (response.data && response.data.error) {
                            $scope.$parent.$parent.List[i].uploadError = response.data.error;
                        }

                    }, function (evt) {

                        $scope.$parent.$parent.List[i].progress = parseInt(100.0 * evt.loaded / evt.total);

                        if ($scope.$parent.$parent.List[i].progress == 100 ) {

                            $scope.$parent.$parent.List[i].hasFinished = true;

                        }

                    });

                });

            };

            // New folder functions
            //$scope.addChildToFolder = function(folder, childItem, callback) {
            $scope.updateFolder = function(folder, childItem, callback) {

                var backend = $scope.backend || $scope.$parent.$parent.backend;
                var userInfo = $scope.userInfo || $scope.$parent.$parent.userInfo;

                var targetFolder = folder;

                var postData = {
                    "groupId" : userInfo.groupID,
                    "folder" : targetFolder,
                    "assetType" : $scope.currentCategory.categoryName,
                    "action" : "updateFolder",
                    "assetIds" : []
                };

                if(folder.rename)
                    postData.action = "renameFolder";

                if(childItem ) {
                    if (childItem.type == "folder") {
                        postData.childFolder = childItem;
                    }
                    postData.assetIds.push(childItem.ID);
                    // We need to remove element from root records,
                    // so pass these asset ids if applicable
                    if ( $scope.categoryView) {
                    }
                }

                postData.sourceFolderId = $scope.currentFolder.ID?$scope.currentFolder.ID:0;

                // Call backend to save the add child
                $http({ method: "POST", url: backend + "/" + $scope.assetGroupEndpoint, "params": {}, "data": postData })
                    .success(function (data, status){
                        if (!data.success) {

                            if(data.error == "duplicate") {
                                $scope.$parent.$parent.activAlert("Folder with the same name already exists in folder "
                                    + targetFolder.name, 2000);

                            }else{
                                $scope.$parent.$parent.activAlert("Error while Updating Folder : " + data.error, 2000);
                                setTimeout(function(){
                                    window.location.reload();
                                }, 2000);
                            }
                        }

                        if (callback) {
                            callback(data);
                        }
                    })
                    .error(function (data) {
                        if(data.error == "duplicate") {
                            $scope.$parent.$parent.activAlert("Folder with the same name already exists in folder "
                                + targetFolder.name, 2000);

                        }else {
                            console.error(data);
                            $scope.$parent.$parent.activAlert("Error while Updating Folder : " + data.error, 2000);
                            setTimeout(function () {
                                window.location.reload();
                            }, 2000);
                        }
                    });
            };

            $scope.updateTemplate = function(template, callback){

                var backend = $scope.backend || $scope.$parent.$parent.backend;
                var userInfo = $scope.userInfo || $scope.$parent.$parent.userInfo;

                var postData = {
                    "groupId" : userInfo.groupID,
                    "templateName" : template.name,
                    "id" : template.ID,
                    "projectData" : template.templateJSON
                };
                
                $http({ method: "POST", url: backend + "/saveAsTemplate", "params": {}, "data": postData })
                    .success(function (data, status) {
                        if (!data.success) {
                            alert("Error while Updating Template : " + data.error);
                        }

                        if(callback)
                            callback(data);

                        $scope.sortCurrentFolder();
                    })
                    .error(function (data) {
                        console.error(data);
                        alert("Error while Updating template : " + data.error);
                        if(callback)
                            callback(data);
                    });
            };
            // Editor library functions
            $scope.existsInProjectLibrary = function(libItem) {

                if($scope.mode != "projectLibrary")
                    return false;

                var exists = false;
                var type = $scope.currentCategory.name.toLowerCase();

                if(libItem.type == "folder"){
                    // Check if all children are in project, if any child in heirarchy is not in the project, show the folder

                    var allChildren = $scope.getAllChildrenFromFolder(libItem);

                    exists = true;
                    if(allChildren.length == 0){
                        exists = false;
                    }
                    else{
                        for(var i=0; i<allChildren.length; i++){

                            if(!$scope.existsInProjectLibrary(allChildren[i]))
                            {
                                exists = false;
                                break;
                            }
                        }
                    }
                }
                else {
                    switch (type) {

                        case "images":

                            if ($scope.pLibrary["images"]) {

                                $scope.pLibrary["images"].forEach(function (item) {

                                    if (item.ID == libItem.ID) {
                                        exists = true;
                                    }
                                });

                            }

                            break;

                        case "videos":

                            if ($scope.pLibrary["videos"]) {

                                $scope.pLibrary["videos"].forEach(function (item) {

                                    if (item.ID == libItem.ID) {
                                        exists = true;
                                    }

                                });

                            }

                            break;
                        case "templates":

                            if ($scope.pLibrary["templates"]) {

                                $scope.pLibrary["templates"].forEach(function (item) {

                                    if (item.ID == libItem.ID) {
                                        exists = true;
                                    }

                                });

                            }

                            break;

                        case "widgets":

                            if ($scope.pLibrary["widgets"]) {

                                $scope.pLibrary["widgets"].forEach(function (item) {

                                    if (item.ID == libItem.ID) {
                                        exists = true;
                                    }

                                });

                            }

                            break;

                    }
                }

                if(!exists){
                    // Special case where the on demand project library didnt load,
                    //  Check the ids if they're in the dLibrary
                    var dbLibrary = $scope.$parent.$parent.dbLibrary;
                    for(var i=0; i< dbLibrary.length; i++){
                        var dbItem = dbLibrary[i];
                        var libItemType = AssetTypes[type];

                        if(libItemType == dbItem.assetType
                            && dbItem.assetId == libItem.ID ){
                            exists = true;
                            break;
                        }
                    }
                }

                return exists;
            };

            $scope.addToProjectLibrary = function(libItem) {

                var item = libItem;

                var libraryType = $scope.currentCategory.name.toLowerCase();
                var itemIds = [];
                var assetType = libraryType;

                if(item.type != "folder")
                {
                    itemIds = [item.ID];
                }

                var postData = { "projectID": $scope.$parent.$parent._projectID,
                    "type": assetType,
                    "assetIDs": itemIds };

                if(item.type == "folder")
                {
                    postData.folderId = item.ID;
                }

                postData.projectLibrary = $scope.pLibrary[libraryType];
                //if (!$scope.existsInProjectLibrary(assetType, item)) {

                $http({ "method": "POST", "url": $scope.$parent.$parent.backend + "/addToProjectLibrary", "params": {},
                    "data": postData }).
                    success(function (data, status) {

                        //$scope.$parent.$parent.activAlert("Item added to project library", 1000);
                        if(data.success) {
                            if (data.assets) {
                                for (var i = 0; i < data.assets.length; i++) {
                                    var item = data.assets[i];
                                    if (!$scope.existsInProjectLibrary(item)) {
                                        $scope.pLibrary[libraryType].push(data.assets[i]);
                                        $scope.pLibrary[libraryType].sort(sortAlphabetically);
                                    }
                                }
                                $scope.$parent.$parent.activAlert("Folder content added to the library", 2000);
                            }
                            else {
                                $scope.pLibrary[libraryType].push(libItem);
                                $scope.pLibrary[libraryType].sort(sortAlphabetically);
                            }
                        }
                    }).
                    error(function (data, status) {
                        $scope.$parent.$parent.activAlert("An error occurred while adding item to the library", 2000);
                        console.log(data);
                    });
            };

            $scope.getAllChildrenFromFolder = function(folder){

                var children = [];

                if(folder.children) {
                    for (var i = 0; i < folder.children.length; i++) {

                        var child = folder.children[i];

                        if (child.type != "folder") {
                            children.push(child);
                        }
                        else {
                            children = children.concat($scope.getAllChildrenFromFolder(child));
                        }
                    }
                }
                return children;
            };

            // Editor library functions END

            // Get Activity Code

            $scope.getItemByIDFromFolder = function(id, type, folder, drillDown){
                var foundItem = null;
                for(var i=0; i< folder.children.length; i++) {
                    var lItem = folder.children[i];

                    if (lItem.ID == id && lItem.type == type) {
                        foundItem = lItem;
                        break;
                    }

                    // recurse for folders
                    if(drillDown && lItem.type == "folder"){
                        foundItem = $scope.getItemByIDFromFolder(id, type, lItem, drillDown);
                    }

                }

                return foundItem;
            };

            $scope.activityTime = (new Date()).getTime();

            $scope.markCategoryForDataLoad = function(catName){
                // Recursively call for children display groups to make sure
                for(var c in $scope.libraryCategories){
                    var libCat = $scope.libraryCategories[c];
                    if(libCat.categoryName.toLowerCase() == catName.toLowerCase()) {
                        if (libCat.children && libCat.children.length > 0) {
                            for (var i = 0; i < libCat.children.length; i++) {
                                if (libCat.children[i].type == "folder") {
                                    $scope.markGroupForDataLoad(libCat.children[i]);
                                }
                            }
                        }
                    }
                }
            };
            $scope.markGroupForDataLoad = function(group){
                group.dataLoaded = false;
                // Recursively call for children display groups to make sure
                // all display groups are reloaded on demand
                if(group.children && group.children.length > 0){
                    for(var i=0; i< group.children.length; i++){
                        if(children[i].type == "folder") {
                            $scope.markGroupForDataLoad(group.children[i]);
                        }
                    }
                }
            };

            $scope.getActivityEntry = function(){
                if($scope.currentCategory == null){
                    // Nothing to update
                    $scope.getActivityEntryAfterDelay();
                    return;
                }

                var requestTime = (new Date()).getTime();
                var postData = {"assetType" : $scope.currentCategory.categoryName,
                                "folderId": $scope.currentFolder.ID ? $scope.currentFolder.ID : 0,
                                "time": $scope.activityTime,
                                "getUpdated" : true
                                };
                var reqFolderId = postData.folderId;
                var reqFolder = $scope.currentFolder;

                $http({ method: "POST", url: $scope.$root.backend + "/retrieveLibrary", "params": {},
                    "data": postData
                    })
                    .success(function(data, status){
                        if(data.success && data.assets && data.assets.length > 0) {
                            var currentFolderId =  ($scope.currentFolder && $scope.currentFolder.ID) ? $scope.currentFolder.ID : 0;
                            $scope.activityTime = requestTime;
                            if(data.refreshView){
                                reqFolder.children = data.assets;
                                reqFolder.children.sort(sortAssets);
                            }
                            else {
                                // We got a new entry
                                // Check and implement differences
                                for (var i = 0; i < data.assets.length; i++) {
                                    var asset = data.assets[i];
                                    var libAsset = $scope.getItemByIDFromFolder(asset.ID, asset.type, reqFolder);

                                    if (libAsset) {
                                        if (asset.deleted) {
                                            // Remove the asset, its  deleted
                                            if (reqFolder.children.indexOf(libAsset) != -1) {
                                                reqFolder.children.splice(reqFolder.children.indexOf(libAsset), 1);
                                            }
                                        }
                                        else if (postData.folderId != (libAsset.folderId ? libAsset.folderId : 0)) {
                                            // Remove the asset, its moved to another folder
                                            // Find and add to another folder
                                            reqFolder.children.splice(reqFolder.indexOf(libAsset), 1);
                                            // move the asset to the other folder
                                            var foundFolder = $scope.getItemByIDFromFolder(libAsset.folderId, "folder", $scope.currentCategory);
                                            if (foundFolder) {
                                                foundFolder.children.push(libAsset);
                                            }
                                        }
                                        else {
                                            // Update asset properties
                                            for (var p in asset) {
                                                libAsset[p] = asset[p];
                                            }
                                        }
                                    }
                                    else if (!asset.deleted) {
                                        // This is an addition
                                        // Make sure the asset is not present
                                        var existingAsset = $scope.getItemByIDFromFolder(asset.ID, asset.type, reqFolder);
                                        if (!existingAsset) {
                                            reqFolder.children.push(asset);
                                            reqFolder.children.sort(sortAssets);
                                        }
                                    }
                                }
                            }
                        }

                        $scope.getActivityEntryAfterDelay();
                    })
                    .error(function(data){
                        console.error(data);
                        $scope.getActivityEntryAfterDelay();
                    });
            };
            $scope.getActivityTimeout_library = null;
            $scope.getActivityEntryAfterDelay = function()
            {
                if( $scope.getActivityTimeout_library )
                    clearTimeout($scope.getActivityTimeout_library);

                $scope.getActivityTimeout_library = setTimeout(function(){
                    if ($location.path() == "/home/library")
                        $scope.getActivityEntry();
                }, 5000);
            };
        }// Controller ends
    }
}]);

function AssetFolder(scope, categoryRef, http)
{
        var af = new Object();

    // Default vals
    af.ID = null;
    af.children = [];
    af.name = "";

    af.scope = scope;
    af.http = http;

    // Category related
    af.categoryRef = categoryRef;
    af.assetType = categoryRef.categoryName.toLocaleLowerCase();


    af.parent = null;
    af.assetParentGroupId = null;

    af.addChild = function(child) {
        var backend = this.scope.backend || this.scope.$parent.$parent.backend;
        var userInfo = this.scope.userInfo || this.scope.$parent.$parent.userInfo;
        var postData = {"groupId" : userInfo.groupID,
            "assetGroupTitle" : this.folderName,
            "assetType" : this.assetType,
            "assetGroupId":  this.ID
        };

        postData.assetIds = [];
        postData.assetIds.push(child.ID);

        var that = this;
        postData.sourceFolderId = $scope.currentFolder.ID?$scope.currentFolder.ID:0;
        // Call backend to save the add child
        this.http({ method: "POST", url: backend + "/" + $scope.assetGroupEndpoint, "params": {}, "data": postData })
            .success(function (data, status) {

                if (data.success) {
                    // Update the arrays

                    // Remove from source
                    for (var i = 0; i < that.categoryRef.children.length; i++)
                    {
                        var item = that.categoryRef.children[i];
                        if (item.ID === child.ID) {
                            that.categoryRef.children.splice(i, 1);
                            break;
                        }
                    }

                    // Add to current folder
                    that.children.push(child);
                }
                else
                {
                    alert("Error while creating folder : " + data.error);
                }

            }).error(function (data) {
                console.error(data);
                alert("Error while creating folder : " + data.error);
            });
    };

    af.rename = function(folderName)
    {

        var backend = this.scope.backend || this.scope.$parent.$parent.backend;
        var userInfo = this.scope.userInfo || this.scope.$parent.$parent.userInfo;
        var postData = {"groupId" : userInfo.groupID,
            "assetGroupTitle" : folderName,
            "assetType" : this.assetType,
            "assetGroupId":  this.ID
        };

        postData.assetIds = [];

        for(var i=0; i< this.children.length; i++)
        {
            postData.assetIds.push(this.children[i].ID);
        }

        var that = this;
        postData.sourceFolderId = $scope.currentFolder.ID?$scope.currentFolder.ID:0;
        // Call backend to save the add child
        this.http({ method: "POST", url: backend + "/" + $scope.assetGroupEndpoint, "params": {}, "data": postData })
            .success(function (data, status) {

                if (data.success) {
                    // All good update our foldername
                    that.folderName = folderName;
                }
                else
                {
                    alert("Error while creating folder : " + data.error);
                }

            }).error(function (data) {
                console.error(data);
                alert("Error while creating folder : " + data.error);
            });
    };


    return af;
}

function newOid(){
    function _p8() {
        var p = (Math.random().toString(16)+"000000000").substr(2,8);
        return p.substr(0,4) + p.substr(4,4);
    }
    return _p8() + _p8() + _p8();
}

function getFontNameFromURL(fontURL, domElement){
    
    var fontNameVar = getURLVar(fontURL, "family");
    var fontName = null;

    if(fontNameVar){
        var head = document.getElementsByTagName('head')[0];
        var link = document.createElement('link');

        // decode url encoding and replace all plus symbols with a space..
        fontName = decodeURIComponent(fontNameVar).replace(/\+/g,' ');
        link.id = fontName;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = fontURL;
        link.media = 'all';
        head.appendChild(link);
    }
    
    return fontName;
}

function getURLVar(url, key){
    var urlVars = getUrlVars(url);
    var urlVar = urlVars[key];
    return urlVar;
}

function getUrlVars(url) {
    var vars = {};
    var parts = url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}