activ.controller("displaySchedule", ["$scope", "$http", function($scope, $http) {

    var days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    $scope.schedules = [];

    $scope.loadDisplaySchedules = function () {
        $http({ "method": "POST", "url": $scope.$parent.backend + "/retrieveReportSchedules", "params": {}, "data": {} }).
        success(function (data, status) {
            if (data.success === false) {
                return $scope.$parent.activAlert(data.error, 2000);
            }
            angular.forEach(data.list, function (item) {
                if (!item.displayGroupName) {
                    item.displayGroupName = "Global settings"
                }
                angular.forEach(item.emails, function (emailItem) {
                    if(emailItem.days) {
                        emailItem.days = emailItem.days.split(',').map(function (d) {
                            return parseInt(d);
                        })
                    }else{
                        emailItem.days = [];
                    }
                })
            });
            $scope.schedules = data.list;
        }).error(function (data, status) {
            $scope.$parent.activAlert(data.error, 2000);
        });
    };

    $scope.getDaysLabel = function (daysNums) {
        if (daysNums.length === 7) {
            return "All days";
        }
        var daysLabels = [];
        angular.forEach(daysNums, function (dayNum) {
           if (days[dayNum] !== undefined) {
               daysLabels.push(days[dayNum]);
           }
        });
        return daysLabels.join(', ');
    };

    $scope.getTime = function (dateTimeString) {
        return moment(dateTimeString).format('HH:mm');
    };

    $scope.getEventsByEmailItem = function (emailItem) {
        var events = [];
        if (emailItem.scheduleEmail) {
            events.push("Scheduled report");
        }
        if (emailItem.alerts) {
            events.push("Alerts");
        }
        return events.join(', ');
    };

    $scope.$watch(function () {
        return $scope.$parent.openedTab;
    }, function (openedTab) {
        if (openedTab == 'schedule') {
            $scope.loadDisplaySchedules();
        }
    });
}]);
