function initLHSTimeline(){

    var lhsTimeline = {};

    lhsTimeline.layerHolder = document.querySelector("#layerHolder");
    lhsTimeline.timeSpanHolder = document.querySelector("#layerTimeSpans");
    lhsTimeline.dragElement = null;
    lhsTimeline.dragOverElement = null;

    lhsTimeline.dropPosition = null;
    lhsTimeline.dragLayerID = null;
    lhsTimeline.hoverElementID = null;
    lhsTimeline.dragLayerStartCoords = {};
    lhsTimeline.dragging = false;
    lhsTimeline.dragTimer = null;

    lhsTimeline.scrollAttached = false;

    lhsTimeline.buildLayers = function(groupID, parentEl, parent) {
        var topLevel = document.createElement("ul");
        parentEl.appendChild(topLevel);

    };

    lhsTimeline.createLHSTimelineElement = function(_layer, topLevel, parent) {
        var layerEl = document.createElement("li");

        $(layerEl).data("layer", _layer);
        _layer.lhsTimelineElement = layerEl;

        layerEl.classList.add("layer");
        layerEl.classList.add("tlLeft");
        layerEl.setAttribute("data-layerid", _layer.id);

        var layerDragIcon = document.createElement("span");
        layerDragIcon.classList.add("dragger");

        var layerVisibilityIcon = document.createElement("span");
        layerVisibilityIcon.classList.add("glyphicon", "glyphicon-eye-open", "eye");

        var layerLockIcon = document.createElement("span");
        layerLockIcon.classList.add("glyphicon", "glyphicon-lock", "lock");

        if((parent && !parent.visible) || !_layer.visible) {
            layerVisibilityIcon.classList.add("eye-invisible");
        }

        if((parent && !parent.locked) || !_layer.locked) {
            layerLockIcon.classList.add("lock-invisible");
        }

        var layerName = document.createElement("p");
        layerName.classList.add("layerName");

        if(_layer.children.length > 1)
        {
            // we have a group top level element here
            var cName = _layer.containerTitle || _layer.getContainerTitle();

            layerName.innerHTML = cName;
            layerEl.setAttribute("title", cName);
        }
        else
        {
            layerName.innerHTML = _layer.name;
            layerEl.setAttribute("title", _layer.name);
        }

        if(_layer.locked) {
            layerName.classList.add("locked");
        }


        if(_layer.parent && _layer.parent.isCollapsed)
        {
            layerEl.style.display = "none";
        }

        layerEl.appendChild(layerDragIcon);
        layerEl.appendChild(layerVisibilityIcon);
        layerEl.appendChild(layerLockIcon);
        layerEl.appendChild(layerName);

        layerEl.addEventListener("click", function(e){

            if(!_layer.locked) {

                _layer.select(e);

            }

        });

        layerVisibilityIcon.addEventListener("click", function(e){
            // broadcast the undo
            timeline.$scope.$emit('modelUpdated', {
                source: 'timelineItem',
                objectId: _layer.id,
                data: JSON.stringify(stripMonitorObject(_layer))
            });
            
            _layer.toggleLayerVisibility(e);

        });

        layerLockIcon.addEventListener("click", function(e){
            // broadcast the undo
            timeline.$scope.$emit('modelUpdated', {
                source: 'timelineItem',
                objectId: _layer.id,
                data: JSON.stringify(stripMonitorObject(_layer))
            });

            _layer.toggleLayerLock(e);

        });

        var thisObj = this;

        var startLayerMove = function(e) {

            e.preventDefault();
            e.stopPropagation();

            thisObj.dragTimer = setTimeout(function(){

                thisObj.dragging = true;

                var clickedElement = e.target;

                if(clickedElement.classList.contains("eye") || clickedElement.classList.contains("lock")
                    || clickedElement.classList.contains("glyphicon-chevron-right")) { return; }

                while (!clickedElement.classList.contains("tlLeft")) {

                    clickedElement = clickedElement.parentNode;

                }

                thisObj.dragLayerID = clickedElement.getAttribute("data-layerid");
                thisObj.dragElement = clickedElement.cloneNode(true);
                thisObj.dragElement.classList.add("layerDrag");
                thisObj.dragElement.classList.remove("tlLeft");

                document.body.appendChild(thisObj.dragElement);

                thisObj.dragElement.style.position = "absolute";
                thisObj.dragElement.style.left = e.pageX - e.offsetX + "px";
                thisObj.dragElement.style.top = e.pageY - e.offsetY + "px";

                thisObj.dragLayerStartCoords.x = e.pageX;
                thisObj.dragLayerStartCoords.y = e.pageY;

                window.addEventListener("mousemove", doLayerMove);

            }, 250);

            window.addEventListener("mouseup", endLayerMove);
        };

        var doLayerMove = function(e) {

            e.stopPropagation();
            e.preventDefault();

            if(thisObj.dragging) {

                var hoverElement = {};

                thisObj.dragElement.style.left = parseInt(thisObj.dragElement.style.left, 10) - (thisObj.dragLayerStartCoords.x - e.pageX) + "px";
                thisObj.dragElement.style.top = parseInt(thisObj.dragElement.style.top, 10) - (thisObj.dragLayerStartCoords.y - e.pageY) + "px";

                thisObj.dragLayerStartCoords.x = e.pageX;
                thisObj.dragLayerStartCoords.y = e.pageY;

                if (e.target.classList.contains("tlLeft")) {
                    hoverElement.el = e.target;
                } else if (e.target.parentNode.classList.contains("tlLeft")) {
                    hoverElement.el = $(e.target).closest(".tlLeft")[0];
                }

                if (hoverElement.el) {

                    // remove other hover elements
                    $(".tlLeft").removeClass("hoverTop").removeClass("hoverBottom");

                    thisObj.hoverElementID = hoverElement.el.getAttribute("data-layerid");

                    hoverElement.boundingBox = hoverElement.el.getBoundingClientRect();

                    if (thisObj.dragLayerStartCoords.y > (hoverElement.boundingBox.bottom -
                                ((hoverElement.boundingBox.bottom - hoverElement.boundingBox.top) / 2))) {

                        $(hoverElement.el).addClass("hoverBottom");
                        thisObj.dropPosition = "below";

                    } else {

                        $(hoverElement.el).addClass("hoverTop");
                        thisObj.dropPosition = "above";
                    }

                    thisObj.dragOverElement = hoverElement.el
                }
            }
        };

        function isValidMove(draggedLayer, draggedOverLayer) {
            if(draggedOverLayer.parent != null){
                // check the dragged item type against the container
                if(draggedLayer.asset.type != draggedOverLayer.parent.asset.type) {
                    return false;
                }
            }
            return true;
        }

        var endLayerMove = function(e) {

            e.stopPropagation();
            e.preventDefault();

            clearTimeout(thisObj.dragTimer);

            // Remove highlighting
            $(".tlLeft").removeClass("hoverTop").removeClass("hoverBottom");
            window.removeEventListener("mousemove", doLayerMove);
            window.removeEventListener("mouseup", endLayerMove);

            if(!thisObj.dragging)
            {
                return;
            }

            thisObj.dragging = false;

            if(thisObj.dragElement && thisObj.dragElement.parentNode) {
                thisObj.dragElement.parentNode.removeChild(thisObj.dragElement);
            }

            // dragIndex = layer being dragged (0 = top layer), hoverIndex = layer dropped onto (0 = top layer)
            var dragIndex = null;
            var hoverIndex = null;

            var draggedLayer = timeline.getItemByElement(thisObj.dragElement);
            var draggedOverLayer = timeline.getItemByElement(thisObj.dragOverElement);

            var isValid = isValidMove(draggedLayer, draggedOverLayer);
            if(!isValid) {
                return false;
            }

            var draggedOverLayerParentId = draggedOverLayer.parent? draggedOverLayer.parent.id : null;

            var processMove = true;
            var addElementToCanvas = false;

            // Did we move an element out of the parent?
            if(draggedLayer.parent != null) {

                var draggedParent = draggedLayer.parent;
                // Remove from the parent
                // Did we drop this into a container?
                if(draggedLayer.parent.id != draggedOverLayerParentId){

                    // This is a different element
                    // copy the element and render
                    // Remove from parent
                    draggedParent.deleteChild(draggedLayer);
                    //timeline.items.push(draggedLayer);

                    // Get parent dom styles
                    draggedLayer.x = draggedParent.x;
                    draggedLayer.y = draggedParent.y;
                    draggedLayer.z = draggedParent.z;
                    draggedLayer.alpha = draggedParent.alpha;
                    draggedLayer.h = draggedParent.h;
                    draggedLayer.w = draggedParent.w;
                    draggedLayer.id = timeline.uuid.new();

                    addElementToCanvas = true;
                }
                else{
                    processMove = false;
                }
            }

            if(!processMove)
                return;

            // Did we drag over a container?
            if(draggedOverLayer.parent != null){

                // We've dragged over a container. make the dragged element a part of this container
                var parentContainer = draggedOverLayer.parent;
                parentContainer.addChildTimelineItem(draggedLayer, !addElementToCanvas);
            }
            else if(addElementToCanvas){
                // We need to move this item out of the container
                // Add to the canvas as a new element
                timeline.$scope.dropped(null, null, null, draggedLayer);
            }

            timeline.items.forEach(function(_layer, i){

                if(_layer.id === thisObj.dragLayerID) {
                    dragIndex = i;
                } else if(_layer.id === thisObj.hoverElementID) {
                    hoverIndex = i;
                }

            });

            if(!(dragIndex === hoverIndex
                    || hoverIndex === null
                || dragIndex === null)
                ) {

                // Reposition elements
                // get indices
                var arrElToMove = timeline.items.splice(dragIndex, 1)[0];

                var sliceTop = timeline.items.slice(0, hoverIndex);
                var sliceBottom = timeline.items.slice(hoverIndex, timeline.items.length);

                sliceTop.push(arrElToMove);
                timeline.items = sliceTop.concat(sliceBottom);


                // broadcast the undo
                timeline.$scope.$emit('modelUpdated', {
                    source: 'timelineModified',
                    data: JSON.stringify(timeline.getLayers(null))
                });


                for (var i = 0; i < timeline.items.length; ++i) {
                    if (typeof timeline.items[i] === "undefined") {
                        timeline.items.splice(i--, 1);
                    } else {
                        timeline.items[i].z = 10000 + i;
                    }
                }

                // Update canvas z index
                timeline.items.forEach(function (_layer, i) {
                    _layer.updateCanvasObjectZIndex();
                });
            }

            window.removeEventListener("mousemove", doLayerMove);
            window.removeEventListener("mouseup", endLayerMove);

            timeline.$scope.$parent.changesMade = true;

            // TRIGGER Here
            // Re render the lhs timeline
            timeline.renderTimeline();
            timeline.rerenderTicks();
        };

        layerDragIcon.addEventListener("mousedown", startLayerMove);

        return layerEl;
    };

    lhsTimeline.decorateGroup = function(_layer, element) {
        var layerCollapseIcon = document.createElement("span");
        layerCollapseIcon.classList.add("glyphicon", "glyphicon-chevron-right");

        layerCollapseIcon.addEventListener("click", function(e){

            e.stopPropagation();
            e.preventDefault();


            layerCollapseIcon.parentNode.classList.toggle("collapsed");
            _layer.isCollapsed = !_layer.isCollapsed;

            _layer.updateCollapsed();

            timeline.$scope.$apply();

        });

        if(_layer.isCollapsed) {
            element.classList.add("collapsed");
        }

        element.appendChild(layerCollapseIcon);
        element.classList.add("layerGroup");
    };

    lhsTimeline.build = function(_layer, topLevel, parent) {

        var element = this.createLHSTimelineElement(_layer, topLevel, parent);

        topLevel.appendChild(element);

        if (_layer.children.length > 0) {
            this.decorateGroup(_layer, element);

            //  build
            for (var i = 0; i < _layer.children.length; i++) {
                this.build(_layer.children[i], element, _layer);
            }
        }
    };

    lhsTimeline.drawLHSTimeline = function () {

        if(!this.scrollAttached){
            this.attachScroll();
        }

        this.layerHolder.innerHTML = "";

        var topLevel = document.createElement("ul");
        this.layerHolder.appendChild(topLevel);

        //  build
        for (var i = timeline.items.length - 1; i >=0; i--) {
            this.build(timeline.items[i], topLevel, null);
        }

    };


    lhsTimeline.attachScroll = function(){
        this.layerHolder.addEventListener("scroll", function(e){
            var scrollTop = e.target.scrollTop;
            lhsTimeline.timeSpanHolder.scrollTop = scrollTop;
        });

        this.scrollAttached = true;
    }


    // TRIGGER Here LHS


    return lhsTimeline;
}



