activ.directive("cmsNavigation", function(){

    return {

        restrict: "E",
        templateUrl: "./views/cmsNavigation.html?v=" + version,
        controller: function($scope) {
        }
    }
});
