activ.controller("cms", ["$scope", "$http", "$location", "$routeParams", "$sce",
                function($scope, $http, $location, $routeParams, $sce){
  $scope.pageTitle = "CMS";

  $scope.websiteUrls = {
    "home": "",
    "blog": "/blog.html",
    "buynow": "/buy-now.html",
    "addons": "/addons.html",
    "video-guides": "/video-guides.html",
    "faqs": "/faqs.html",
    "resources": "/resources.html"
  };

  $scope.trustSrc = function(src) {
    return $sce.trustAsResourceUrl(src);
  };

  $scope.pageUrl = ($routeParams.pageID) ? config.websitePath + $scope.websiteUrls[$routeParams.pageID]
                                         : config.websitePath;

  $scope.page = {src: $scope.pageUrl
                  + "?editor=true&token=" + $scope.userInfo.token};
}]);
