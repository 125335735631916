
activ.controller("displays", ["$scope", "$http", "$location", "$compile", "ngDialog", "$upload", "$timeout",
                    function($scope, $http, $location, $compile, ngDialog, $upload, $timeout){

    // helps connect non angular code
    currentScope = $scope;
    currentScope.http = $http;
    $scope.pageTitle = "Displays";
    $scope.projects = [];
    $scope.displays = [];
    $scope.displayGroups = [];
    $scope.showListView = false;
    $scope.boxTitleInput = "Display 1";
    $scope.publishedProjects = [];
    $scope.hideInaccessibleContent = true;

    $scope.breadcrumbsStack = [];
    $scope.userData = $scope.$root.getUserData();

    $scope.getDefaultOverride = function(displayGroupId){
        return {
                ID:0,
                active : false,
                schedules:[],
                projectId : "0",
                displayGroupId : displayGroupId,
                useScheduledProject: false,
                schedulesLoaded : false
        }
    }

    $scope.rootDisplayGroup = {ID:0, name : "Displays",
        displays : [], displayGroups: [],
        override : $scope.getDefaultOverride(0)
        };

    $scope.currentOverrideActive = false;

    $scope.currentDisplayGroup = $scope.rootDisplayGroup;
    $scope.breadcrumbsStack.push($scope.currentDisplayGroup);

    $scope.chartNames = [
        {
            "name": "Views per month",
            "val": "testChartHolder1"
        },
        {
            "name": "Display uptime/downtime",
            "val": "testChartHolder2"
        },
        {
            "name": "Views per project",
            "val": "testChartHolder3"
        }
    ];

    $scope.selectedChart = $scope.chartNames[0].name;

    var addRecentDisplay = function(id) {

        var backend = $scope.backend || $scope.$parent.backend;
        $http({ "method": "POST", "url": backend + "/addRecentDisplay", "params": {},
            "data": { "displayID": id } }).success(function(data, status){ }).error(function(data, status){ });

    };

    $scope.currentOpenDisplaySectionId = null;

    $scope.currentOpenDisplayId = null;

    $scope.selectedDisplays = [];
    $scope.displaySelected = function(display, e)
    {
        e.cancelBubble = true;
        if(e.stopPropagation) e.stopPropagation();

        $scope.selectedDisplays = [];
        // get selected displays
        for(var i=0; i<$scope.displays.length; i++)
        {
            var d = $scope.displays[i];

            if(d.selected)
            {
                $scope.selectedDisplays.push(d);
            }
        }
    };

    $scope.createNewDisplayGroup = function()
    {
        var grpTitle = $scope.groupTitleInput.trim();

        if(grpTitle && grpTitle.trim().length > 0)
        {
            grpTitle = grpTitle.trim();

            // All good, create the group

            //Check if group already exists
            var existingGroup = $scope.getDisplayGroupByName(grpTitle);
            if(existingGroup)
            {
                $scope.$parent.activAlert("Error: Display Group with the same name already exists!", 2000);
                return;
            }

            var postData = {"groupName" : grpTitle, "action" : "create"};

            if ($scope.currentDisplayGroup && $scope.currentDisplayGroup.ID != 0) {
                postData.parentId = $scope.currentDisplayGroup.ID;
            }

            if($scope.modalContainer.selectedDisplays){
                postData.childDisplays = "";
                var separator = "";
                for(var d in $scope.modalContainer.selectedDisplays){
                    postData.childDisplays += separator + $scope.modalContainer.selectedDisplays[d].ID;
                    separator = ",";
                }
            }

            $http({ method: "POST", url: $scope.$parent.backend + "/displayGroup", "params": {}, "data": postData })
                .success(function (data, status)
                {
                    $scope.boxCodeAutoFillRunning = false;
                    if (data.success)
                    {
                        //$scope.$parent.activAlert("Display Group Created", 2000);
                        // Update selected displays
                        var newGroup = data.displayGroup;

                        newGroup.ownerFullName = $scope.userData.firstname + " " + $scope.userData.surname;
                        newGroup.displays = [];

                        if($scope.modalContainer.selectedDisplays){
                            for(var d in $scope.modalContainer.selectedDisplays ){
                                newGroup.displays.push($scope.modalContainer.selectedDisplays[d]);
                                $scope.removeDisplayFromGroup($scope.currentDisplayGroup,
                                    $scope.modalContainer.selectedDisplays[d]);
                            }
                        }
                        newGroup.startTime = new Date();
                        newGroup.startTime.setHours(0);
                        newGroup.startTime.setMinutes(0);

                        newGroup.shutdownTime = new Date();
                        newGroup.shutdownTime.setHours(0);
                        newGroup.shutdownTime.setMinutes(0);

                        newGroup.id = newGroup.ID;
                        // Push to top
                        $scope.displayGroups.splice(0,0,newGroup);

                        $scope.toggleNewDisplayGroupPanel();
                    }
                })
                .error(function (data, success) {

                });

        }
    };

    $scope.closeOpenDisplayInfoPanels = function()
    {
        var displayInformationPanels = $(".displayInformation");
        displayInformationPanels.hide();
        $scope.currentOpenDisplayId = null;
        $scope.currentOpenDisplayGroupId = null;

    };

    $scope.setDisplayInfoTimeout = function(timeoutVal)
    {
        $scope.getActivityTimeout_displays_interval = timeoutVal; // ms
    };

    $scope.closeOpenDisplayPanelsOnOpen = function(dPanel)
    {
        if(!dPanel.is(":visible"))
        {
            $scope.closeOpenDisplayInfoPanels();
            $scope.creatingDisplayGroup = false;
        }

        dPanel.toggle();
    };


    $scope.toggleNewDisplayPanel = function()
    {
        $scope.modalContainer.hide();
    };


    $scope.toggleNewDisplayGroupPanel = function()
    {
        $scope.modalContainer.hide();
    };


    $scope.toggleDisplayGroupPanel = function(groupIndex)
    {
        var dId = "displayInformation_displayGroup_" + groupIndex;

        var ddElem = $("#" + dId);

        $scope.closeOpenDisplayPanelsOnOpen(ddElem);

        if( ddElem.is(":visible"))
        {
            // If open
        }
        else {

        }
    };

    $scope.showEditDisplayGroup = function(displayGroupId)
    {
        var dId = "displayInformation_displayGroup_edit_" + displayGroupId;

        var ddElem = $("#" + dId);

        $scope.closeOpenDisplayPanelsOnOpen(ddElem);

        if( ddElem.is(":visible"))
        {
            // If open
            $scope.currentOpenDisplayGroupId = displayGroupId;
        }
        else
        {

        }

    };

    $scope.currentOpenDisplayId = null;
    $scope.currentOpenDisplayGroupId = null;


    $scope.switchDisplayTab = function(displayID, target, displayGroup) {
        var section = target.getAttribute("data-section-for").toLowerCase();
        var parent = target.parentNode;
        var parentParent = parent.parentNode;

        // Deselect last active element
        var activHeading = parent.querySelectorAll(".active")[0];
        activHeading.classList.remove("active");

        var activDetails = document.getElementById(activHeading.getAttribute("id") + "_details");
        $(activDetails).hide();

        // Select current selected element
        var currentHeading = target;

        target.classList.add("active");
        var currentDetails = document.getElementById(target.getAttribute("id") + "_details");
        $(currentDetails).show();

        var isDisplayGroup = displayGroup != null;
        var isDisplayGroupOverride = displayID == "override";
        if(isDisplayGroupOverride){
            isDisplayGroup = false;
        }

        var currentDisplay = null;
        if(isDisplayGroupOverride){
            currentDisplay = $scope.currentDisplayGroup.override;
            currentDisplay.isDisplayGroupOverride = true;
        }else{
            currentDisplay = isDisplayGroup ? displayGroup : $scope.getCurrentOpenDisplayObject();
            currentDisplay.isDisplayGroup = isDisplayGroup;
            currentDisplay.isDisplay = !isDisplayGroup;
        }

        // allow global access via scope
        currentScope.currentDisplay = currentDisplay;

        // if we opened the Calendar tab, load the current calendar info
        if(target.innerText == "Calendar"){

            /*
            if(currentDisplay.calendarLoaded){
                return;
            }
            */

            // load previous calendar events
            var postData = {
                action: "getAll"
            };

            var calContainerId= isDisplayGroup ? ("dg_calendarSchdeuleContainer_display_"+ displayID)
                                                : ( isDisplayGroupOverride? "override_calendarSchdeuleContainer"
                                                    : ("calendarSchdeuleContainer_display_" + displayID) );


            var calendarSchdeuleContainer = $("#"+ calContainerId );
            renderCurrentMonthCalendar(calendarSchdeuleContainer);

            if(isDisplayGroupOverride){
                postData.displayGroupOverrideId = currentDisplay.ID;
            }
            else if(isDisplayGroup){
                postData.displayGroupId = currentDisplay.ID;
            }else{
                postData.displayId = currentDisplay.ID;
            }

            var backend = $scope.backend || $scope.$parent.backend;
            $http({ method: "POST", url: backend + "/calendarSchedules", "params": {}, "data": postData  })
                .success(function (data, status) {
                    // Update current display Properties :
                    if(data.success) {
                        if (currentDisplay) {
                            currentDisplay.calendarLoaded = true;

                            var calendarEvents = data.calendarEvents;
                            currentDisplay.calendarEvents = updateCalendarEvents(calendarEvents );
                        }
                    }
                    else{
                        $scope.$parent.activAlert("An Error has occurred. Please try again", 2000);
                    }
                })
                .error(function (data) {
                    // set next
                    console.error(data);
                    $scope.$parent.activAlert("An Error has occurred. Please try again", 2000);
                });
        }

        // if we opened the schedules tab, load display schedules data if any
        if(target.innerText == "Schedules"){

            if(isDisplayGroupOverride && $scope.currentDisplayGroup.override.ID == 0){
                // Display Group Override isnt saved..
                $scope.currentDisplayGroup.override.schedulesLoaded = true;
                return;
            }

            if(currentDisplay && !currentDisplay.schedulesLoaded){
                // Load schedules on demand!

                var isDisplayGroupOverride = displayID == "override";

                var currentDisplayId = currentDisplay.ID;

                var postdata = {displayId: currentDisplayId,
                                action : "getDisplaySchedules",
                                isDisplayGroupOverride : isDisplayGroupOverride,
                                isDisplayGroup : isDisplayGroup};

                var backend = $scope.backend || $scope.$parent.backend;
                $http({ method: "POST", url: backend + "/retrieveDisplay", "params": {}, "data": postdata })
                    .success(function (data, status) {
                        // Update current display Properties :
                        if(data.success) {
                            if (currentDisplay) {
                                currentDisplay.schedulesLoaded = true;
                                currentDisplay.schedules = data.schedules;

                                $scope.$broadcast("schedulesUpdated", { newValue : data.schedules });
                            }
                        }
                        else{
                            $scope.$parent.activAlert("An Error has occurred. Please try again", 2000);
                        }
                    })
                    .error(function (data) {
                        // set next
                        console.error(data);
                        //alert("error")
                        $scope.$parent.activAlert("An Error has occurred. Please try again", 2000);
                    });
            }
        }
        else if(target.innerText == "Security") {

            // if we opened the security tab, load security data if its not there
            if(displayGroup.security != null && displayGroup.securityMembers == null){
                // load from backend
                displayGroup.loadingSecurity = true;

                var postdata = {"displayGroupId" : displayGroup.ID,
                                "action" : "loadSecurityMembers",
                                "token" : displayGroup.security};
                $http({ method: "POST", url: config.backend + "/displayGroup", "params": {}, "data": postdata })
                    .success(function (data, status) {
                        // Update current display Properties :
                        if(data.success) {
                            displayGroup.loadingSecurity = false;
                            displayGroup.securityMembers = data.securityMembers;
                            $scope.updateModalHeight();
                        }
                        else{
                            $scope.$parent.activAlert("An Error has occurred. Please try again", 2000);
                        }
                    })
                    .error(function (data) {
                        // set next
                        console.error(data);
                        //alert("error")
                        $scope.$parent.activAlert("An Error has occurred. Please try again", 2000);
                    });
            }
        }

        // Update modal height
        $scope.modalContainer.adjustContentHeight();


        /*
         // Auto expand/collapse
        if(target.innerText == "Schedules"){
             $scope.modalContainer.maximiseContentWidth();
        }else{
            // $scope.modalContainer.resetContentWidth();
        }
        */
    };

    $scope.maximiseSchedulesView = function(){
        $scope.modalContainer.maximiseContentWidth();
        $scope.modalContainer.maximisedView = true;
    };

    $scope.resetSchedulesView = function(){
        $scope.modalContainer.maximisedView = false;
        $scope.modalContainer.resetContentWidth();
    };


    $scope.selectChart = function(c){

        var charts = document.querySelectorAll(".chartHolder > div");

        [].forEach.call(charts, function(chart){

            if(chart.getAttribute("ID") === c) {
                chart.style.display = "block";
            } else {
                chart.style.display = "none";
            }

        });

    };

    $scope.boxCodeInput = "";
    $scope.boxCodePrefilled = false;

    $scope.boxCodeAutoFillRunning = false;

    $scope.checkBoxCode = function() {

        var boxCodeEntered = $scope.boxCodeInput.trim();

        var boxCodeLen = boxCodeEntered.length;

        // eg : ed9f3078-b232-61b5-9b82-71d261f22c46
        if( !$scope.boxCodeAutoFillRunning && boxCodeLen >= 6 && boxCodeLen < 36)
        {
            $scope.boxCodeAutoFillRunning = true;

            // Try to autofill
            $http({ method: "POST", url: $scope.$parent.backend + "/checkBoxCode", "params": {}, "data": { "boxCodeEntered": boxCodeEntered } })
                .success(function (data, status) {

                    $scope.boxCodeAutoFillRunning = false;
                    if (data.success)
                    {
                        if (data.exists)
                        {
                            $scope.boxCodePrefilled = true;
                            // If we find a matching boxcode, autofill it in and move cursor..
                            $scope.boxCodeInput = data.unregisteredBoxCode;
                        }

                    }
                })
                .error(function (data, success) {
                    $scope.boxCodeAutoFillRunning = false;
                });
        }
    };

    $scope.registerDisplay = function()
    {
        //$scope.boxCodeInput = $.trim($scope.boxCodeInput);

        if($scope.boxCodeInput.length > 0 && $scope.boxTitleInput.length > 0 )
        {

            var displayJSON = {action:"registerByBoxCode", "parentId": 0, "boxCode" : $scope.boxCodeInput, "boxTitle" :  $scope.boxTitleInput};

            if ($scope.currentDisplayGroup && $scope.currentDisplayGroup.ID != 0) {
                displayJSON.parentId = $scope.currentDisplayGroup.ID;
            }

            // pass to backend to process registering the display and get back the group info for the box
            var newDisplay = JSON.stringify(displayJSON);

            $http({ "method": "POST", "url": $scope.$parent.backend + "/registerDisplay", "params": {}, "data": newDisplay }).
                success(function (data, status) {
                    if (data.success) {
                        // Display Registered // Add to list
                        $scope.$parent.activAlert("Display Registered", 2000);
                        $scope.displays.push(data.display);

                        $scope.boxCodeInput = "";
                        $scope.boxTitleInput = "Display " + ( parseInt($scope.displays.length) + 1 );
                        $scope.toggleNewDisplayPanel();
                        addRecentDisplay(data.displayID);

                    } else {
                        $scope.$parent.activAlert("Error Registering Display. " + data.error, 2000);
                    }

                }).error(function (data, status) {
                    $scope.$parent.activAlert("Error Registering Display.", 4000);
                });
        }
    };

    $scope.retrievePublishedProjects = function(loadDisplays)
    {
        var backend = $scope.backend || $scope.$parent.backend;
        $http({ "method": "POST", "url": backend + "/retrievePublishedProjects", "params": {}, "data": {} }).
            success(function(data, status){
                if(data.success)
                {
                    $scope.publishedProjects = data.publishedProjects;

                    if(loadDisplays) {
                        $scope.retrieveDisplays(false);
                    }
                }
            }).
            error(function(data, status){ });
    };

    // Retrieve existing Displays
    $scope.retrieveDisplays = function(projectsLoad)
    {
        var backend = $scope.backend || $scope.$parent.backend;
        $http({ "method": "POST", "url": backend + "/retrieveDisplays", "params": {},
                    "data": {"parentId" : $scope.currentDisplayGroup.ID} }).
            success(function(data, status){

                if(data.success)
                {
				   // Update the current override
					
					// Save previous schedules
                    var overrideSchedules = [];
                    var overrideSchedulesLoaded = false;

                    if( $scope.currentDisplayGroup.override) {
                        overrideSchedules = $scope.currentDisplayGroup.override.schedules || [];
                        overrideSchedulesLoaded = $scope.currentDisplayGroup.override.schedulesLoaded || false;
                    }
					
                    $scope.currentDisplayGroup.override =
                        data.displayGroupOverride != null ?  data.displayGroupOverride :
                                $scope.getDefaultOverride($scope.currentDisplayGroup.ID);

					// Restore
                    $scope.currentDisplayGroup.override.schedules = overrideSchedules;
                    $scope.currentDisplayGroup.override.schedulesLoaded = overrideSchedulesLoaded;
					
                    $scope.displayCount = data.displayCount;

                    $scope.currentOverrideActive = $scope.currentDisplayGroup.override?$scope.currentDisplayGroup.override.active : false;

                    $scope.currentDisplayGroup.override.schedules = [];

                    $scope.displays = $scope.currentDisplayGroup.displays;
                    $scope.displayGroups = $scope.currentDisplayGroup.displayGroups;

                    $scope.currentDisplayGroup.dataLoaded = true;

                    // Only call if we get a list of displays back
                    $scope.getActivityEntryAfterDelay();

                    // Process the information and sanitise
                    for(var i=0; i< data.displays.length; i++)
                    {
                        var cDisplay = data.displays[i];
                        cDisplay.ID = cDisplay.ID;
                        cDisplay.newTitle = cDisplay.boxTitle;
                        $scope.postProcessDisplay(cDisplay);

                        var foundDisplay = null;

                        // Find and update current display
                        for (var j = 0; j < $scope.displays.length; j++) {
                            var sD = $scope.displays[j];
                            sD.ID = sD.ID;

                            if(cDisplay.ID == sD.ID) {
                                foundDisplay = sD;

                                if (cDisplay.ID != $scope.currentOpenDisplayId) {
                                    // This isnt the current open display, update it
                                    $scope.displays[j] = cDisplay;
                                }
                            }
                        }

                        // This is a new display
                        if(foundDisplay == null)
                        {
                            $scope.displays.push(cDisplay);
                        }

                    }

                    // Check for display groups
                    for (var i = 0; i < data.displayGroups.length; i++)
                    {
                        var dg = data.displayGroups[i];
                        // Helper
                        dg.id = dg.ID;

                        $scope.setDisplayGroupStatusClass(dg);

                        $scope.setDefaultSettings(dg);
                        //dg.syncChildDisplays = false;
                        // Check and update scope groups
                        var existingGroup = $scope.getDisplayGroupById(dg.id);

                        if(!dg.displays){
                            dg.displays = [];
                            dg.dataLoaded = false;
                        }

                        if (!existingGroup) {

                            $scope.displayGroups.push(dg);

                            // Check child displays
                            for (var j = 0; j < dg.displays.length; j++) {
                                var d = dg.displays[j];
                                d.newTitle = d.boxTitle;
                                d.hasGroup = true;
                                d.displayGroup = dg;
                                $scope.postProcessDisplay(d);

                            }
                        }
                        else {
                            // Update children
                            for (var ii = 0; ii < dg.displays.length; ii++) {
                                var cDisplay = dg.displays[ii];

                                $scope.postProcessDisplay(cDisplay);

                                var foundDisplay = null;

                                // Find and update current display
                                for (var j = 0; j < dg.displays.length; j++) {
                                    var sD = dg.displays[j];

                                    sD.newTitle = sD.boxTitle;

                                    if (cDisplay.ID == sD.ID) {
                                        foundDisplay = sD;

                                        if (cDisplay.ID != $scope.currentOpenDisplayId) {
                                            // This isnt the current open display, update it
                                            dg.displays[j] = cDisplay;
                                        }
                                    }
                                }

                                // This is a new display
                                if (foundDisplay == null) {
                                    dg.displays.push(cDisplay);
                                }

                            }
                        }



                    }

                    // Reset the box title in case the last selected tab wasnt the new dispaly
                    if($scope.currentOpenDisplayId != null)
                    {
                        $scope.boxTitleInput = "Display " + ( parseInt($scope.displays.length) + 1 );
                    }

                    if(projectsLoad) {
                        $scope.retrievePublishedProjects(false);
                    }
                }

            }).
            error(function(data, status){
                console.log(data);
            });
    };


    $scope.dragHandleMouseDown = function(displayId)
    {
        var dd = $("#displayInformation_" + displayId).parent().find(".displayInformation");
        //  dd.hide();
    };



   $scope.enableDisplayDragging = function()
   {
       if(!$scope.$root.hasAccessRight("Display Groups - Edit")){
           $(".displayDragHandle").hide();
           return;
       }

       $(".displayDragHandle").show();
       $(".nonGroupDisplays").draggable({revert:true, scroll: true,
                            handle:".displayDragHandle", disabled:false,
                                start : function(){
                                    // Hide the display info
                                    var displayId = $(this).attr("displayId");
                                    var dd = $("#displayInformation_" + displayId).parent().find(".displayInformation");
                                    dd.hide();
                                },
                               drag: function(event, ui ) {

                                   $scope.wasDraggingDisplay = true;
                               }
                           });

       $(".displayGroupHeading").droppable({
           accept: ".activDisplay",
           activeClass: "ui-state-hover",
           hoverClass: "ui-state-active",
           drop: function( event, ui ) {
               var jThis = $( this );

               var displayGroupId = jThis.attr("displayGroupId");
               var displayGroup = $scope.getDisplayGroupById(displayGroupId);

               var draggable = ui.draggable;
               var displayId = draggable.attr("displayId");


               // call server to update display

               // Update DOM
               var display = $scope.getDisplayObjById(displayId);
               displayGroup.displays.push(display);
               $scope.deleteDisplayFromMemory(displayId);


               var postData = {"displayGroupId" : displayGroupId, "displayId" : displayId, "action" : "addDisplayToGroup"};
               $http({ method: "POST", url: $scope.$parent.backend + "/displayGroup", "params": {}, "data": postData })
                   .success(function (data, status)
                   {
                       $scope.boxCodeAutoFillRunning = false;
                       if (data.success)
                       {
                           // Do nothing
                          // $scope.$parent.activAlert("Display Added to Group", 2000);
                       }
                       else{
                           $scope.$parent.activAlert("An Error has occurred. Please try again", 2000);
                           console.log(data.error);
                       }
                   })
                   .error(function (data, success) {
                       $scope.$parent.activAlert("An Error has occurred. Please try again", 2000);
                       console.log(data);
                   });
           }
       });
   };

/*
    $scope.removeDisplayFromGroup = function(displayId) {
        var postData = {"displayId": displayId, "action": "removeDisplayFromGroup"};
        $http({method: "POST", url: $scope.$parent.backend + "/displayGroup", "params": {}, "data": postData})
            .success(function (data, status) {
                $scope.boxCodeAutoFillRunning = false;
                if (data.success) {

                    var display = $scope.getDisplayObjById(displayId);

                    $scope.deleteDisplayFromMemory(displayId);
                    $scope.displays.splice(0, 0, display);
                }
            })
            .error(function (data, success) {
                $scope.$parent.activAlert("An Error has occurred. Please try again", 2000);
            });
    };

*/

    $scope.disableDisplayDragging = function()
    {
        $(".displayDragHandle").hide();
        $(".nonGroupDisplays").draggable({ disabled: true });
    };

    $scope.postProcessDisplay = function(cDisplay)
    {
        $scope.setDefaultSettings(cDisplay);
        $scope.setDisplayStatusClass(cDisplay);
    };

    $scope.setDefaultSettings = function(dObj)
    {
        // validation check, make sure schedules is an array
        if(!dObj.schedules)
            dObj.schedules = [];

        if(!dObj.startTime)
        {
            dObj.startTime = new Date();
            dObj.startTime.setHours(0);
            dObj.startTime.setMinutes(0);
        }
        else
        {
            dObj.startTime = new Date(dObj.startTime);
        }

        if(!dObj.shutdownTime)
        {
            dObj.shutdownTime = new Date();
            dObj.shutdownTime.setHours(0);
            dObj.shutdownTime.setMinutes(0);
        }
        else
        {
            dObj.shutdownTime = new Date(dObj.shutdownTime);
        }
    };

    $scope.getDisplayById = function(displayId)
    {
        var d = null;

        for (var i = 0; i < $scope.displays.length; i++) {
            var sd = $scope.displays[i];
            if(sd.ID == displayId){
                d = sd;
                break;
            }
        }
        return d;
    }

    // Display Group Helpers
    $scope.getDisplayGroupForDisplay = function(d)
    {
        var dg = null;

        for (var i = 0; i < $scope.displayGroups.length; i++) {
            var sdg = $scope.displayGroups[i];

            // Check this display in children if any
            for (var j = 0; j < sdg.displays.length; j++) {
                var cd = sdg.displays[j];

                if(cd.ID == d.ID)
                {
                    dg = sdg;
                    break;
                }

                if(dg)
                    break;
            }
        }
        return dg;
    }

    $scope.getDisplayGroupByName = function(gName)
    {
        var dg = null;

        for (var i = 0; i < $scope.displayGroups.length; i++) {
            var sdg = $scope.displayGroups[i];

            if(sdg.name.toLowerCase() == gName.toLowerCase())
            {
                dg = sdg;
                break;
            }
        }
        return dg;
    }

    $scope.getDisplayGroupById = function(gId)
    {
        var dg = null;

        for (var i = 0; i < $scope.displayGroups.length; i++) {
            var sdg = $scope.displayGroups[i];

            if(sdg.id == gId)
            {
                dg = sdg;
                break;
            }
        }
        return dg;
    }

    $scope.setDisplayGroupStatusClass = function(cDisplay)
    {
        // Check command
        cDisplay.titleText = "";

       if (cDisplay.minHeartbeat) {
            var heartbeatTime = new Date(cDisplay.minHeartbeat).getTime();
            var now = (new Date()).getTime();

            // Get difference in minutes
            var diff = parseFloat(parseFloat(now - heartbeatTime) / 1000) / 60;

            cDisplay.statusClass = "display_red";

            if (diff < 10) {
                cDisplay.statusClass = "display_green";
                cDisplay.titleText = "Displays Running";
            }
            else if (diff < 30) {
                cDisplay.statusClass = "display_amber";
                cDisplay.titleText = "No communications in last 30 minutes";
            }
            else {
                cDisplay.titleText = "A Display has problems connecting to the internet";
            }
        }

    };

    $scope.getDisplayImage = function(cDisplay){
        var dImage = (cDisplay.urlAddress) ? "img/display_www.png" : "img/display.png?v=1";
        if(!cDisplay.displayState){
            dImage = "img/display_unregisterd.png?v=1";
        }

        return dImage;
    };

    $scope.setDisplayStatusClass = function(cDisplay)
    {
        // Check command
        cDisplay.showResetDownloadButton = false;

        cDisplay.errorStatus = null;

        if(!cDisplay.displayState){
            cDisplay.statusClass = "display_gray";
            cDisplay.titleText = cDisplay.boxTitle + " - Unregistered Display";
        }
        else {

            if (cDisplay.assetDownloadAttempt && cDisplay.assetDownloadAttempt >= 10
                && cDisplay.command != "ResetAssetDownload"
            ) {
                cDisplay.showResetDownloadButton = true;
                cDisplay.errorStatus = "Assets Download Failed";
            }

            cDisplay.titleText = "";
            if (cDisplay.errorStatus) {
                cDisplay.statusClass = "display_red";
                cDisplay.titleText = cDisplay.errorStatus;
            }
            else if (cDisplay.heartbeat) {
                var heartbeatTime = new Date(cDisplay.heartbeat).getTime();
                var now = (new Date()).getTime();

                // Get difference in minutes
                var diff = parseFloat(parseFloat(now - heartbeatTime) / 1000) / 60;

                cDisplay.statusClass = "display_red";

                if (diff < 10) {
                    cDisplay.statusClass = "display_green";
                    cDisplay.titleText = "Display Running";
                }
                else if (diff < 30) {
                    cDisplay.statusClass = "display_amber";
                    cDisplay.titleText = "No communications in last 30 minutes";
                }
                else if (cDisplay.displayState == DisplayStateCodes["SLEEPING"]) {
                    cDisplay.statusClass = "display_purple";
                    cDisplay.titleText = "Display Sleeping";
                } else {
                    // Red case
                    cDisplay.titleText = "Display has problems connecting to the internet";
                }
            }

            // Check logs for cases on scheduled shutdown
            if (cDisplay.logs && cDisplay.logs.length > 0) {
                var lastLog = cDisplay.logs[0];

                if (lastLog.message == "Scheduled Shutdown") {
                    cDisplay.statusClass = "display_blue";
                    cDisplay.titleText = "Display Sleeping";
                }

            }
        }

    };

   //$scope.retrieveDisplays(true);
    $scope.retrievePublishedProjects(true);

    $scope.twoDigits = function(digit)
    {
        var twoDigit = parseInt(digit);
        if(digit >=0 && digit<=9)
            twoDigit = "0" + digit;

        return twoDigit;
    };


    $scope.getLogDate = function(logTime)
    {
        if(!logTime)
            return " - ";
        var d = new Date(logTime);
        var curr_date =  d.getDate();
        var curr_month = d.getMonth() + 1;
        var curr_year = d.getFullYear();
        var curr_hour = d.getHours();
        var curr_minute = d.getMinutes();
        var curr_second = d.getSeconds();

        var  dateTimeStr = ( $scope.twoDigits(curr_date)  + "/" + $scope.twoDigits(curr_month) + "/"+ curr_year + ", "
                                +$scope.twoDigits(curr_hour) + ":"
            + $scope.twoDigits(curr_minute) + ":" + $scope.twoDigits(curr_second));

        return dateTimeStr;
    };

    $scope.getLastActivityTimeStr = function(cDisplay)
    {
        if(!cDisplay)
            return "";

        if(!cDisplay.displayState){
           return cDisplay.boxTitle + " - Unregistered Display";
        }

        var heartbeatTime = cDisplay.heartbeat;
        if(!heartbeatTime)
            return " - ";

        var m_names = new Array("Jan", "Feb", "Mar",
            "Apr", "May", "Jun", "Jul", "Aug", "Sep",
            "Oct", "Nov", "Dec");

        var d = new Date(heartbeatTime);
        var curr_date =  d.getDate();
        var curr_month = d.getMonth();
        var curr_year = d.getFullYear();
        var curr_hour = d.getHours();
        var curr_minute = d.getMinutes();

        var  dateTimeStr = "Last Activity : " + ( $scope.twoDigits(curr_hour) + ":" + $scope.twoDigits(curr_minute) + ", " + $scope.twoDigits(curr_date) + " " + m_names[curr_month]
            + " " + curr_year);

        return dateTimeStr;
    };

    $scope.getDisplayProjectTitle = function(cDisplay){
        var retStr = "No Project";
        if(cDisplay.projectTitle){
            retStr = cDisplay.projectTitle;
        }
        return retStr;
    }

    $scope.showDeleteDisplay = function(display)
    {
        $scope.$root.showActivConfirm("Are you sure you want to delete display : " + display.boxTitle,
            function(){
            $scope.deleteDisplay(display);
        });
    };

    $scope.showDeleteDisplayGroup = function(displayGroup)
    {
        $scope.$root.showActivConfirm("Are you sure you want to delete display group : " + displayGroup.name
            + "<br/>*This will also remove all child Display/Display Groups within this group",
            function(modal){
            displayGroup.removeChildren = true;
            $scope.deleteDisplayGroup(displayGroup);
        });
    };

    $scope.deleteDisplayGroup = function(displayGroup)
    {
        var gId = displayGroup.ID;
        var postData = {"displayId" : gId, isDisplayGroup :true, "removeChildren" : displayGroup.removeChildren };

        // Pass data to quick publish
        $http({ "method": "POST", "url": $scope.$parent.backend + "/deleteDisplay", "params": {}, "data": postData }).
            success(function(data, status){
                if(data.success)
                {
                    // Remove from list
                    $scope.deleteDisplayGroupFromMemory(displayGroup);
                    $scope.$root.confirmModal.hide();
                }else{
                    $scope.$root.confirmModal.setStatusText("An error has occurred : " + data.error, "red");
                }
            }).
            error(function(data, status){
                $scope.$root.confirmModal.setStatusText("An error has occurred", "red");
                console.log(data);
            });
    };

    $scope.deleteDisplay = function(display)
    {
        var postData = {"displayId" : display.ID, isDisplayGroup : false};

        // Pass data to quick publish
        $http({ "method": "POST", "url": $scope.$parent.backend + "/deleteDisplay", "params": {}, "data": postData }).
            success(function(data, status){
                if(data.success)
                {
                    // Remove from list
                    $scope.deleteDisplayFromMemory(display);
                    $scope.$root.hideActivConfirm();
                }else{
                    $scope.$root.confirmModal.setStatus("An error has occurred : " + data.error, "red");
                }
            }).
            error(function(data, status){
                $scope.$root.confirmModal.setStatus("An error has occurred", "red");
                console.log(data);
            });


    };

    $scope.deleteDisplayFromMemory = function(display)
    {
        var deletedDisplay = false;
        for (var i = 0; i < $scope.displays.length; i++) {
            var d = $scope.displays[i];
            if(d.ID == display.ID)
            {
                $scope.displays.splice(i,1);
                deletedDisplay = true;
                break;
            }
        }

        if( !deletedDisplay ) {
            // check display groups as well
            for (var i = 0; i < $scope.displayGroups.length; i++) {
                var dg = $scope.displayGroups[i];

                for (var j = 0; j < dg.displays.length; j++) {
                    var d = dg.displays[j];

                    if (d.ID == display.ID) {
                        dg.displays.splice(j, 1);
                        deletedDisplay = true;
                        break;
                    }
                }

                if (deletedDisplay)
                    break;
            }
        }
    };

    $scope.deleteDisplayGroupFromMemory = function(displayGroup)
    {
        for (var i = 0; i < $scope.displayGroups.length; i++) {
            var dg = $scope.displayGroups[i];

            if (dg.id == displayGroup.ID) {
                $scope.displayGroups.splice(i,1);
                break;
            }
        }
    };


    $scope.hideDeleteDisplay = function(index)
    {
        var deleteDD =  document.getElementById("displayInformation_delete_"+ index);
        deleteDD.style.display = "none";
    };

    $scope.markResetRequestSuccessful = function(display){
        if(display.resetRequested){
            var dHTime = (new Date(display.heartbeat)).getTime();
            var nowTime = (new Date()).getTime();

            if(dHTime > display.resetRequestTime) {
                // Heartbeat came after the reset request
                // reset request has to be taken
                display.resetRequestStatus = "Reset Successful";
                var gap = (nowTime - display.resetRequestTime) / 1000;
                if (gap > 180) {
                    display.resetRequested = false;
                }
            }
        }
    };

    $scope.getDisplayStatus = function(display)
    {
        var status = "";

        if(display.heartbeat && display.lastModifiedOn)
        {
            var dHTime = (new Date(display.heartbeat)).getTime();
            var dLMTime = (new Date(display.lastModifiedOn)).getTime();

            var diff =  (dLMTime - dHTime) / 1000;

            if( diff > 0 && diff <= 120 )
            {
                // The heartbeat is smaller than the modified time,
                // The box doesnt have the update yet
                status = "Update Requested";
            }
            else if( diff < 0 && Math.abs(diff) <= 180 )
            {
                // The heartbeat is after the modified time,
                // The box must have the latest update
                status = "Update Received By Box";
            }
        }

        return status;
    }

    $scope.enablePublishButton = function(displayId, isGroup)
    {

        var publishButtonIdPrefix = "quickProjectPublish_";

        if(isGroup) {
            publishButtonIdPrefix  = "dg_quickProjectPublish_";
        }

        var publishButton = document.getElementById(publishButtonIdPrefix + displayId);
        publishButton.removeAttribute("disabled", "disabled");
        publishButton.classList.remove("publishDisabled");
    };

    $scope.enablePublishButton_groupedDisplay = function(displayId)
    {
        var display = $scope.getDisplayObjById(displayId);

        var enablePub = true;

        if(display.syncWithDisplayGroup)
        {
            if(confirm("Are you sure? This will replace display settings with the group settings")) {
                // If we enabled the sync button, copy settings from display group and apply
                // $scope.copyDisplayGroupSettings(displayId);
            }
            else {
                enablePub = false;
                display.syncWithDisplayGroup = false;
            }
        }


        if(enablePub) {
            var publishButton = document.getElementById("quickProjectPublish_" + displayId);
            publishButton.removeAttribute("disabled", "disabled");
            publishButton.classList.remove("publishDisabled");
        }
    };

    $scope.copyDisplayGroupSettings = function(displayId)
    {
        var display = $scope.getDisplayObjById(displayId);
        var displayGroup = $scope.getDisplayGroupById(display.displayGroupId);

        display.projectId = angular.copy(displayGroup.projectId);
        display.projectTransition = angular.copy(displayGroup.projectTransition);
        display.startTime = angular.copy(displayGroup.startTime);
        display.shutdownTime = angular.copy(displayGroup.shutdownTime);
        display.useScheduledProject = angular.copy(displayGroup.useScheduledProject);
        display.schedules = angular.copy(displayGroup.schedules);
    };

    $scope.disablePublishButton = function(displayId)
    {
        var publishButton = document.getElementById("quickProjectPublish_" + displayId);
        publishButton.setAttribute("disabled", "disabled");
        publishButton.classList.remove("publishDisabled");
        publishButton.classList.add("publishDisabled");
    };

    $scope.disableGroupPublishButton = function(displayId)
    {
        var publishButton = document.getElementById("dg_quickProjectPublish_" + displayId);
        publishButton.setAttribute("disabled", "disabled");
        publishButton.classList.remove("publishDisabled");
        publishButton.classList.add("publishDisabled");
    };


    $scope.weekDaysArray = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    $scope.newScheduleDialog = null;

    $scope.sch_hstep = 1;
    $scope.sch_mstep = 1;

    $scope.sch_changed = function() {

        if ($scope.newSchedule.endTime <= $scope.newSchedule.startTime) {
            $scope.newSchedule.endTime = new Date($scope.newSchedule.startTime.getTime() + $scope.sch_mstep * 60000)
        }
    };

    $scope.checkAllWeekDays = function(){
        if($scope.newSchedule.allWeek) {
            for (var w in $scope.newSchedule.weekDays) {
                $scope.newSchedule.weekDays[w] = true;
            }
        }
    };

    $scope.checkAndTickAllWeek = function(){
        var allWeekSelected = true;
        for (var w in $scope.newSchedule.weekDays) {
            if(!$scope.newSchedule.weekDays[w]){
                allWeekSelected = false;
                break;
            }
        }
        $scope.newSchedule.allWeek = allWeekSelected;
    };

    $scope.showAddSchedule = function(scheduleObj, scheduleObjScope, type)
    {
        // Clear Data source
        $scope.newSchedule = {
            editMode : false,
            projectId: 0,
            name: "",
            startTime: new Date(),
            type : type,
            endTime: new Date(),
            weekDays: {
                "Mon": false,
                "Tue": false,
                "Wed": false,
                "Thu": false,
                "Fri": false,
                "Sat": false,
                "Sun": false
            }
        };

        if(scheduleObj) {
           $scope.newSchedule.scheduleObjScope = scheduleObjScope;
           $scope.newSchedule.editMode = true;
           // Make a compatible copy for editing
           $scope.newSchedule.projectId = scheduleObj.timeSpans[0].projectID;
           $scope.newSchedule.name = scheduleObj.name;
           $scope.newSchedule.scheduleID = scheduleObj.scheduleID;

           $scope.newSchedule.startTime = new Date(scheduleObj.timeSpans[0].startTime);
           $scope.newSchedule.endTime = new Date(scheduleObj.timeSpans[0].endTime);
           $scope.newSchedule.timeSpans = angular.copy(scheduleObj.timeSpans);

           for(var d in scheduleObj.timeSpans[0].days)
           {
               var day =  scheduleObj.timeSpans[0].days[d];
               var dayName = $scope.weekDaysArray[day];
               $scope.newSchedule.weekDays[dayName] = true;
           }
        }

        $scope.newScheduleDialog = ngDialog.open({
            template: 'newScheduleDialog',
            className: 'ngdialog-theme-default ngdialogNewSchedule',
            scope: $scope
        });

        $scope.sch_changed();
    };


    $scope.getHourMinsStr = function(dt)
    {
        var hmStr = "";
        var hh = $scope.twoDigits(dt.getHours());
        var mm = $scope.twoDigits(dt.getMinutes());

        hmStr = hh + "" + mm;

        return hmStr;
    };

    $scope.createSchedule = function()
    {
        // Validate new schedule
        if($scope.newSchedule.name.trim().length == 0)
        {
            $scope.$parent.activAlert("Please Enter the Schedule Title", 1000);
            document.getElementById("scheduleTitleInput").focus();
        }
        else if($scope.newSchedule.projectId == 0)
        {
            $scope.$parent.activAlert("Please Select a Project", 1000);
            document.getElementById("scheduleProjectSelect").focus();
        }
        else
        {
            var newScheduleObj = {};

            // Update current display schedules
            newScheduleObj.name = $scope.newSchedule.name;
            newScheduleObj.startDate = null;
            newScheduleObj.endDate = null;

            var newTimespan = {};
            newTimespan.days = [];
            newTimespan.projectID = $scope.newSchedule.projectId;
            newTimespan.colour = 1;

            newScheduleObj.scheduleID = 0;

            for(var d in $scope.newSchedule.weekDays)
            {
                if( $scope.newSchedule.weekDays[d]) {
                    var index = $scope.weekDaysArray.indexOf(d);
                    newTimespan.days.push(index);
                }
            }

            if(newTimespan.days.length == 0)
            {
                $scope.$parent.activAlert("Please Select at least one weekday for the Schedule", 2000);
                return;
            }

            newTimespan.days.sort();
            newTimespan.timeSpanID =  1;

            newScheduleObj.timeSpans = [];

            newScheduleObj.scheduleID = 0;
            newScheduleObj.name = $scope.newSchedule.name;
            newTimespan.startTime = $scope.newSchedule.startTime.getTime(); // $scope.getHourMinsStr($scope.newSchedule.startTime);
            newTimespan.endTime = $scope.newSchedule.endTime.getTime(); //$scope.getHourMinsStr($scope.newSchedule.endTime);

            newScheduleObj.timeSpans.push(newTimespan);
            newScheduleObj.ID = 0;

            var currentOpenDisplayObj = $scope.getCurrentOpenDisplayObject();


            var isDisplayGroup = false;
            var isDisplayGroupOverride = false;
            newScheduleObj.type = ScheduleTypes.Display;

            if(!currentOpenDisplayObj)
            {
                // this might be a displayGroup
                currentOpenDisplayObj = $scope.getCurrentOpenDisplayGroupObject();
                if(currentOpenDisplayObj) {
                    isDisplayGroup = true;
                    newScheduleObj.type = ScheduleTypes.DisplayGroup;
                }
                else {
                    isDisplayGroupOverride = true;
                    currentOpenDisplayObj = $scope.currentDisplayGroup.override;
                    newScheduleObj.type = ScheduleTypes.DisplayGroupOverride;
                }
            }

            // detect clash
            var clashed = SchedulesManager.detectClashForSchedule(newScheduleObj, currentOpenDisplayObj.schedules);

            if(clashed)
            {
                $scope.$parent.activAlert("Error : The schedule you entered clashes with an existing schedule!<br/>Please change timings/weekdays and try again", 4000);
                return false;
            }

            currentOpenDisplayObj.schedules.push(newScheduleObj);

            var postData =
            {   displayId : currentOpenDisplayObj.ID,
                schedules : [newScheduleObj],
                isDisplayGroup : isDisplayGroup
            };

            if(isDisplayGroupOverride){
                postData.isDisplayGroupOverride = isDisplayGroupOverride;
                if( $scope.currentDisplayGroup.override.ID == 0 ) {
                    postData.displayGroupOverride = $scope.currentDisplayGroup.override;
                }
            }

            var backend = $scope.backend || $scope.$parent.backend;


            $http({ method: "POST", url: backend + "/createUpdateSchedules", "params": {}, "data": postData })
                .success(function(data, status){

                    if(data.success){
                        newScheduleObj.ID = newScheduleObj.scheduleID = data.ID;
                        // All good, create schedule
                        $scope.newScheduleDialog.close();
                        $scope.$parent.activAlert("Schedule Created", 1000);
                        if( $scope.currentDisplayGroup.override.ID == 0 )
                        {
                            // We should have an override object returned.. update the id
                            $scope.currentDisplayGroup.override.ID = data.overrideID;
                        }

                        $scope.$broadcast("schedulesUpdated", { newValue : currentOpenDisplayObj.schedules });
                    }
                    else{
                        currentOpenDisplayObj.schedules.pop();
                        if(data.error && data.error.indexOf("clash") != -1){
                            $scope.$parent.activAlert("Error : The schedule you entered clashes with an existing schedule!<br/>Please change timings/weekdays and try again", 4000);
                        }else{
                            $scope.$parent.activAlert("Error while creating schedule", 2000);
                        }
                        
                        console.error(data);
                    }

                })
                .error(function(data){
                    currentOpenDisplayObj.schedules.pop();
                    $scope.$parent.activAlert("Error while creating schedule", 2000);
                    // set next
                    console.error(data);
                    //alert("error")
                });
        }

    };

    $scope.updateSchedule = function(deleteFlag)
    {
        // Delete Case :
        if(deleteFlag)
        {

            var currentOpenDisplayObj = $scope.getCurrentOpenDisplayObject();
            var isDisplayGroup = false;
            var isDisplayGroupOverride = false;
            if(!currentOpenDisplayObj)
            {
                // this might be a displayGroup
                currentOpenDisplayObj = $scope.getCurrentOpenDisplayGroupObject();

                if(!currentOpenDisplayObj) {
                    currentOpenDisplayObj = $scope.currentDisplayGroup.override;
                    isDisplayGroupOverride = true;
                }
                else {
                    isDisplayGroup = true;
                }
            }


            // Verify
            if(confirm("Are you sure you want to delete this schedule?")) {

                 var scheduleIdToDelete = $scope.newSchedule.scheduleID;

                var scheduleToDelete = null;
                var delIndex = -1;

                for (var i = 0; i < currentOpenDisplayObj.schedules.length; i++) {
                    var s = currentOpenDisplayObj.schedules[i];

                    if(s.scheduleID == scheduleIdToDelete)
                    {
                        delIndex = i;
                        scheduleToDelete = s;

                        currentOpenDisplayObj.schedules.splice(i,1);
                        i--;
                        break;
                    }
                }

                var postData = {
                    schedules : [scheduleToDelete],
                    delete : true};

                var backend = $scope.backend || $scope.$parent.backend;

                $http({method: "POST", url: backend + "/createUpdateSchedules", "params": {}, "data": postData})
                    .success(function (data, status) {

                        if(data.success) {
                            // Redraw the schedules
                            $scope.newSchedule.scheduleObjScope.update();
                            // All good, create schedule
                            $scope.newScheduleDialog.close();
                            $scope.$parent.activAlert("Schedule Deleted", 2000);
                        }
                        else{
                            currentOpenDisplayObj.schedules.splice(i,0, scheduleToDelete);
                            $scope.$parent.activAlert("Error while Deleting schedule", 2000);
                            console.error(data);
                        }
                    })
                    .error(function (data) {
                        currentOpenDisplayObj.schedules.splice(i,0, scheduleToDelete);
                        $scope.$parent.activAlert("Error while Deleting schedule", 2000);
                        // set next
                        console.error(data);
                        //alert("error")
                    });
            }

            return;
        }

        // Validate new schedule
        if($scope.newSchedule.name.trim().length == 0)
        {
            $scope.$parent.activAlert("Please Enter the Schedule Title", 1000);
            document.getElementById("scheduleTitleInput").focus();
        }
        else if($scope.newSchedule.projectId == 0)
        {
            $scope.$parent.activAlert("Please Select a Project", 1000);
            document.getElementById("scheduleProjectSelect").focus();
        }
        else
        {
            // Create the new schedule object and prepare
            var newScheduleObj = {};

            // Update current display schedules
            newScheduleObj.name = $scope.newSchedule.name;
            newScheduleObj.startDate = null;
            newScheduleObj.endDate = null;
            newScheduleObj.type =  $scope.newSchedule.type;
            var newTimespan = {};
            newTimespan.days = [];
            newTimespan.projectID = $scope.newSchedule.projectId;
            newTimespan.colour = 1;

            for(var d in $scope.newSchedule.weekDays)
            {
                if( $scope.newSchedule.weekDays[d]) {
                    var index = $scope.weekDaysArray.indexOf(d);
                    newTimespan.days.push(index);
                }
            }
            newTimespan.days.sort();
            newTimespan.timeSpanID =  1;

            newScheduleObj.timeSpans = [];

            newScheduleObj.scheduleID = $scope.newSchedule.scheduleID;
            newScheduleObj.name = $scope.newSchedule.name;
            newTimespan.startTime = $scope.newSchedule.startTime.getTime();
            newTimespan.endTime = $scope.newSchedule.endTime.getTime();

            newScheduleObj.timeSpans.push(newTimespan);

            // Replace the current schedule from the display!

            var currentOpenDisplayObj = $scope.getCurrentOpenDisplayObject();

            if(!currentOpenDisplayObj)
                currentOpenDisplayObj = $scope.getCurrentOpenDisplayGroupObject();

            if(!currentOpenDisplayObj)
                currentOpenDisplayObj = $scope.currentDisplayGroup.override;

            // detect clash
            var clashed = SchedulesManager.detectClashForSchedule(newScheduleObj, currentOpenDisplayObj.schedules)

            if(clashed)
            {
                $scope.$parent.activAlert("Error : The schedule you entered clashes with an existing schedule!<br/>Please change timings/weekdays and try again", 4000);
                return false;
            }


            for (var i = 0; i < currentOpenDisplayObj.schedules.length; i++) {
                var obj = currentOpenDisplayObj.schedules[i];
                if(obj.scheduleID == $scope.newSchedule.scheduleID )
                {
                    // Replace with our new schedule
                    currentOpenDisplayObj.schedules[i] = newScheduleObj;
                    break;
                }
            }

            $scope.newScheduleDialog.close();
            $scope.newSchedule.scheduleObjScope.update();
            currentOpenDisplayObj.schedulesEdited = true;
        }
    };

    $scope.publishToDisplayGroup = function(displayGroup, event)
    {
        // Temp data load, needs improvements
        var index = displayGroup.ID;
        var publishedProjectSelector = document.getElementById('publishedProjectSelector_dg_' + index);
        var projectTransitionSelector = document.getElementById('projectTransitionSelector_dg_' + index);

        displayGroup.projectId = displayGroup.projectId || 0;

        var publishedProjectSelectorSelectedIndex =  publishedProjectSelector.selectedIndex;
        if(publishedProjectSelectorSelectedIndex == -1){
            $scope.$parent.activAlert("Please select a valid project", 4000);
            return;
        }

        var projectTransition = projectTransitionSelector.options[projectTransitionSelector.selectedIndex].value;

        var displayGroupId = displayGroup.ID;

        if(!confirm("Publish changes to displayGroup : " + displayGroup.name + "\nAre you sure?"))
        {
            return;
        }

        var now = (new Date()).getTime();

        var postData = {
            displayGroupId : displayGroupId,
            displayGroupName : displayGroup.name,
            projectId : displayGroup.projectId,
            projectTransition : projectTransition,
            invertDisplay : displayGroup.invertDisplay,
            startTime :  displayGroup.startTime ? displayGroup.startTime.getTime() : now,
            shutdownTime : displayGroup.shutdownTime ? displayGroup.shutdownTime.getTime() : now,
            useScheduledProject : displayGroup.useScheduledProject,
            syncChildDisplays : displayGroup.syncChildDisplays,
            syncStartChildDisplays : displayGroup.syncStartChildDisplays,
            syncWithDisplayGroup: displayGroup.syncWithDisplayGroup,
            schedules : displayGroup.schedules,
            action : "publish"
        };

        var backend = $scope.backend || $scope.$parent.backend;
        $scope.publishing = true;

        var publishButton =  event.target;
        publishButton.setAttribute("disabled", "disabled");
        publishButton.classList.remove("publishDisabled");
        publishButton.classList.add("publishDisabled");

        // Pass data to quick publish
        $http({ "method": "POST", "url": backend + "/displayGroup", "params": {}, "data": postData }).
            success(function(data, status){

                $scope.$parent.activAlert("Published", 2000);

                $scope.publishing = false;

                if(data.success)
                {
                    if(displayGroup.dataLoaded && displayGroup.syncChildDisplays)
                    {
                        // Mark all child displays
                        for (var i = 0; i < displayGroup.displays.length; i++) {
                            var d = displayGroup.displays[i];
                            d.syncWithDisplayGroup = true;
                        }
                    }

                    $scope.disableGroupPublishButton(index);
                }
                else
                {
                    $scope.$parent.activAlert("Error while publishing : " + data.error, 2000);
                }
            }).
            error(function(data, status){
                $scope.publishing = false;
                $scope.$parent.activAlert("Error while Publishing..", 2000);
                publishButton.removeAttribute("disabled");
                publishButton.classList.remove("publishDisabled");

                console.log(data);
            });
    };

    $scope.publishToDisplay = function(display, event)
    {

        var index = display.ID;
        // Temp data load, needs improvements
        var publishedProjectSelector = document.getElementById('publishedProjectSelector_' + index);
        var projectTransitionSelector = document.getElementById('projectTransitionSelector_' + index);

        var projectTransition = projectTransitionSelector.options[projectTransitionSelector.selectedIndex].value;

        var publishedProjectSelectorSelectedIndex =  publishedProjectSelector.selectedIndex;
        if(publishedProjectSelectorSelectedIndex == -1){
            $scope.$parent.activAlert("Please select a valid project", 4000);
            return;
        }

        var projectTitle = publishedProjectSelector.options[publishedProjectSelector.selectedIndex].innerHTML;

        var display =  $scope.getDisplayObjById(index);

        var displayId = display.ID;
		display.projectId = display.projectId || 0;
 
        if(!confirm("Publish changes to display : " + display.boxTitle + "\nAre you sure?"))
        {
            return;
        }

        var now = (new Date()).getTime();

        var postData = {
            displayId : displayId,
            projectId : display.projectId,
            projectTitle : projectTitle,
            projectTransition : projectTransition,
            startTime :  display.startTime ? display.startTime.getTime() : now,
            shutdownTime : display.shutdownTime ? display.shutdownTime.getTime() : now,
            useScheduledProject : display.useScheduledProject,
            invertDisplay : display.invertDisplay ? display.invertDisplay : false,
            syncWithDisplayGroup : display.syncWithDisplayGroup
        };

        var backend = $scope.backend || $scope.$parent.backend;
        $scope.publishing = true;

        var publishButton =  event.target;
        publishButton.setAttribute("disabled", "disabled");
        publishButton.classList.remove("publishDisabled");
        publishButton.classList.add("publishDisabled");

        // Pass data to quick publish
        $http({ "method": "POST", "url": backend + "/publishProjectToDisplay", "params": {}, "data": postData }).
            success(function(data, status){
                $scope.publishing = false;

                if(data.success)
                {
                    var reqStatusSpan = document.getElementById("displayReqStatus_"+ index);
                    reqStatusSpan.innerHTML = "Update Requested";

                    $scope.$parent.activAlert("Published", 2000);
                    $scope.disablePublishButton(index);

                    addRecentDisplay(displayId);
                }
                else
                {
                    $scope.enablePublishButton(index);
                    $scope.$parent.activAlert(data.error, 4000);
                }
            }).
            error(function(data, status){
                $scope.publishing = false;
                $scope.$parent.activAlert("Error while Publishing..", 2000);
                publishButton.removeAttribute("disabled");
                publishButton.classList.remove("publishDisabled");

                console.log(data);
            });

    };

    setTimeout(function(){
        var testChart = document.getElementById("testChart1");
        if(!testChart)
            return;

        var ctx1 = document.getElementById("testChart1").getContext("2d");

        var chartData1 = {
            labels : ["November", "December", "January","February","March","April"],
            datasets : [
                {
                    fillColor : "rgba(141,198,63,1)",
                    strokeColor : "rgba(141,198,63,1)",
                    data : [800,850,900,950,1200,1500]
                }
            ]
        };

        var chartOptions1 = {

            //Boolean - If we show the scale above the chart data
            scaleOverlay : false,

            //Boolean - If we want to override with a hard coded scale
            scaleOverride : false,

            //** Required if scaleOverride is true **
            //Number - The number of steps in a hard coded scale
            scaleSteps : null,
            //Number - The value jump in the hard coded scale
            scaleStepWidth : null,
            //Number - The scale starting value
            scaleStartValue : null,

            //String - Colour of the scale line
            scaleLineColor : "rgba(0,0,0,.1)",

            //Number - Pixel width of the scale line
            scaleLineWidth : 1,

            //Boolean - Whether to show labels on the scale
            scaleShowLabels : true,

            //Interpolated JS string - can access value
            scaleLabel : "<%=value%>",

            //String - Scale label font declaration for the scale label
            scaleFontFamily : "'Arial'",

            //Number - Scale label font size in pixels
            scaleFontSize : 12,

            //String - Scale label font weight style
            scaleFontStyle : "normal",

            //String - Scale label font colour
            scaleFontColor : "#d2d2d2",

            ///Boolean - Whether grid lines are shown across the chart
            scaleShowGridLines : false,

            //String - Colour of the grid lines
            scaleGridLineColor : "rgba(255,255,255,.05)",

            //Number - Width of the grid lines
            scaleGridLineWidth : 1,

            //Boolean - If there is a stroke on each bar
            barShowStroke : true,

            //Number - Pixel width of the bar stroke
            barStrokeWidth : 2,

            //Number - Spacing between each of the X value sets
            barValueSpacing : 5,

            //Number - Spacing between data sets within X values
            barDatasetSpacing : 1,

            //Boolean - Whether to animate the chart
            animation : false,

            //Number - Number of animation steps
            animationSteps : 60,

            //String - Animation easing effect
            animationEasing : "easeOutQuart",

            //Function - Fires when the animation is complete
            onAnimationComplete : null

        };

        var testChart1 = new Chart(ctx1).Bar(chartData1, chartOptions1);


        var ctx2 = document.getElementById("testChart2").getContext("2d");

        var chartData2 = [
            {
                value: 97,
                color:"#8dc63f"
            },
            {
                value : 3,
                color : "#c63f3f"
            }
        ];

        var chartOptions2 = {
            //Boolean - Whether we should show a stroke on each segment
            segmentShowStroke : true,

            //String - The colour of each segment stroke
            segmentStrokeColor : "#fff",

            //Number - The width of each segment stroke
            segmentStrokeWidth : 2,

            //Boolean - Whether we should animate the chart
            animation : true,

            //Number - Amount of animation steps
            animationSteps : 100,

            //String - Animation easing effect
            animationEasing : "easeOutBounce",

            //Boolean - Whether we animate the rotation of the Pie
            animateRotate : true,

            //Boolean - Whether we animate scaling the Pie from the centre
            animateScale : false,

            //Function - Will fire on animation completion.
            onAnimationComplete : null
        };

        var testChart2 = new Chart(ctx2).Pie(chartData2, chartOptions2);


        var ctx3 = document.getElementById("testChart3").getContext("2d");

        var chartData3 = {
            labels : ["Project 1", "Project 2", "Project 3"],
            datasets : [
                {
                    fillColor : "rgba(141,198,63,1)",
                    strokeColor : "rgba(141,198,63,1)",
                    data : [8000,8500,9000]
                }
            ]
        };

        var chartOptions3 = {

            //Boolean - If we show the scale above the chart data
            scaleOverlay : false,

            //Boolean - If we want to override with a hard coded scale
            scaleOverride : false,

            //** Required if scaleOverride is true **
            //Number - The number of steps in a hard coded scale
            scaleSteps : null,
            //Number - The value jump in the hard coded scale
            scaleStepWidth : null,
            //Number - The scale starting value
            scaleStartValue : null,

            //String - Colour of the scale line
            scaleLineColor : "rgba(0,0,0,.1)",

            //Number - Pixel width of the scale line
            scaleLineWidth : 1,

            //Boolean - Whether to show labels on the scale
            scaleShowLabels : true,

            //Interpolated JS string - can access value
            scaleLabel : "<%=value%>",

            //String - Scale label font declaration for the scale label
            scaleFontFamily : "'Arial'",

            //Number - Scale label font size in pixels
            scaleFontSize : 12,

            //String - Scale label font weight style
            scaleFontStyle : "normal",

            //String - Scale label font colour
            scaleFontColor : "#d2d2d2",

            ///Boolean - Whether grid lines are shown across the chart
            scaleShowGridLines : false,

            //String - Colour of the grid lines
            scaleGridLineColor : "rgba(255,255,255,.05)",

            //Number - Width of the grid lines
            scaleGridLineWidth : 1,

            //Boolean - If there is a stroke on each bar
            barShowStroke : true,

            //Number - Pixel width of the bar stroke
            barStrokeWidth : 2,

            //Number - Spacing between each of the X value sets
            barValueSpacing : 5,

            //Number - Spacing between data sets within X values
            barDatasetSpacing : 1,

            //Boolean - Whether to animate the chart
            animation : false,

            //Number - Number of animation steps
            animationSteps : 60,

            //String - Animation easing effect
            animationEasing : "easeOutQuart",

            //Function - Fires when the animation is complete
            onAnimationComplete : null

        };

        var testChart3 = new Chart(ctx3).Bar(chartData3, chartOptions3);


    }, 1000);

    $scope.activityTime = new Date().getTime();
    $scope.activityTimePublished = new Date().getTime();

    $scope.maxHour = 24;
    $scope.maxMin = 12; // 5 min steps
    $scope.getNumber = function(num) {
        var ret = [];
        for (var i=0; i<num; i++)
            ret.push(i);
        return ret;
    };

    $scope.markGroupForDataLoad = function(group){
        group.dataLoaded = false;
        // Recursively call for children display groups to make sure
        // all display groups are reloaded on demand
        if(group.displayGroups && group.displayGroups.length > 0){
            for(var i=0; i< group.displayGroups.length; i++){
                $scope.markGroupForDataLoad(group.displayGroups[i]);
            }
        }
    };

    $scope.getActivityEntry = function(){
        var types = ["displays", "published"];
        var backend = $scope.backend || $scope.$parent.backend;
        var userInfo = $scope.userInfo || $scope.$parent.userInfo;
        var reqTime = (new Date()).getTime();

        $http({ method: "POST", url: backend + "/getActivityEntry", "params": {},
            "data": {types: types, time:$scope.activityTime} })
            .success(function(data, status){
                if(data.success && data.records && data.records.length > 0) {
                  $scope.activityTime = reqTime;
                  var retrieveDisplaysCalled = false;
                  var retreivePublishedProjects = false;

                  for(var i=0; i<data.records.length; i++){
                      var record = data.records[i];
                      if(record.type == "displays" && !retrieveDisplaysCalled){
                          //$scope.retrieveDisplays(false);
                          $scope.markGroupForDataLoad($scope.rootDisplayGroup);
                          // Reload current group
                          $scope.retrieveDisplays(false);
                          retrieveDisplaysCalled = true;
                      }
                      else if(record.type == "published" && !retreivePublishedProjects){
                          $scope.retrievePublishedProjects(false);
                          retreivePublishedProjects = true;
                      }
                  }
                }
                $scope.getActivityEntryAfterDelay();
            })
            .error(function(data){
                $scope.getActivityEntryAfterDelay();
                // set next
                console.error(data);
                //alert("error")
            });
    };

    $scope.getActivityEntryAfterDelay = function()
    {
		var delay =  (3 * 60 * 1000); // 3 mintues
        if( $scope.getActivityTimeout_displays )
            clearTimeout($scope.getActivityTimeout_displays);

        $scope.getActivityTimeout_displays = setTimeout(function(){
            if ($location.path() == "/home/displays")
                $scope.getActivityEntry();
        }, delay);
    };


    $scope.getActivityTimeout_display = null;

    $scope.getOpenDisplayInfoAfterDelay = function()
    {
        if($scope.getActivityTimeout_display ) {
            clearTimeout($scope.getActivityTimeout_display);
        }

        $scope.getActivityTimeout_display = setTimeout(function(){
            if ($location.path() == "/home/displays")
                $scope.getOpenDisplayInfo();
        }, 5000);

    };

    $scope.updateCurrentSchedule = function()
    {
        var currentDisplay = $scope.getCurrentOpenDisplayObject();

        if(!currentDisplay)
            currentDisplay = $scope.getCurrentOpenDisplayGroupObject();

        if(!currentDisplay)
            currentDisplay = $scope.currentDisplayGroup.override;

        currentDisplay.updatingSchedule = true;

        // Update button class
        var successCallback = function(result)
        {
            if(result.success)
            {
                currentDisplay.schedulesEdited = false;
                $scope.$parent.activAlert("Schedules Updated", 2000);
            }

            currentDisplay.updatingSchedule = false;
        };

        $scope.saveCurrentOpenDisplaySchedules(successCallback);
    };


    $scope.markCurrentDisplaySchedulesEdit = function() {

        var currentDisplay = $scope.getCurrentOpenDisplayObject();

        if(!currentDisplay)
            currentDisplay = $scope.getCurrentOpenDisplayGroupObject();

        if(!currentDisplay)
            currentDisplay = $scope.currentDisplayGroup.override;

        currentDisplay.schedulesEdited = true;

        if ($scope.$root.$$phase != '$apply' && $scope.$root.$$phase != '$digest') {
            $scope.$apply();
        }

    };


    $scope.saveCurrentOpenDisplaySchedules = function(callback)
    {
        var currentOpenDisplayObj = $scope.getCurrentOpenDisplayObject();

        var isDisplayGroup = false;
        var isDisplayGroupOverride = false;

        if(!currentOpenDisplayObj) {
            currentOpenDisplayObj = $scope.getCurrentOpenDisplayGroupObject();

            if(!currentOpenDisplayObj) {
                currentOpenDisplayObj = $scope.currentDisplayGroup.override;
                isDisplayGroupOverride = true;
            }
            else{
                isDisplayGroup = true;
            }
        }

        // save to db
        var postData = {displayId : currentOpenDisplayObj.ID,
            schedules : currentOpenDisplayObj.schedules,
            isDisplayGroup:isDisplayGroup,
            isDisplayGroupOverride : isDisplayGroupOverride
        };

        var backend = $scope.backend || $scope.$parent.backend;

        $http({ method: "POST", url: backend + "/createUpdateSchedules", "params": {}, "data": postData })
            .success(function(data, status){
                // Saved..
                if(callback)
                    callback(data);

                if(!data.success){
                    $scope.$parent.activAlert("Error : The schedule you entered clashes with an existing schedule!<br/>Please change timings/weekdays and try again", 4000);
                }
            })
            .error(function(data){
                if(callback)
                    callback(data);

                if(data.error && data.error.indexOf("clash") != -1){
                    $scope.$parent.activAlert("Error : The schedule you entered clashes with an existing schedule!<br/>Please change timings/weekdays and try again", 4000);
                }else{
                    $scope.$parent.activAlert("Error while creating schedule", 2000);
                }
            });
    };


    $scope.getCurrentOpenDisplayGroupObject = function()
    {
        return window.currentModal.displayGroupObj;
    };

    $scope.getCurrentOpenDisplayObject = function()
    {
        return window.currentModal.displayObj;
    };

    $scope.getDisplayObjById = function(dId)
    {
        var displayObj = null;

        for (var i = 0; i < $scope.displays.length; i++) {
            var d = $scope.displays[i];
            if(d.ID == dId)
            {
                displayObj = d;
                break;
            }
        }

        if(displayObj == null ) {
            // check display groups as well
            for (var i = 0; i < $scope.displayGroups.length; i++) {
                var dg = $scope.displayGroups[i];

                for (var j = 0; j < dg.displays.length; j++) {
                    var d = dg.displays[j];

                    if (d.ID == dId) {
                        displayObj = d;
                        displayObj.isDisplayGroup = true;
                        break;
                    }
                }

                if (displayObj != null)
                    break;
            }
        }


        return displayObj;
    }

    $scope.openedTab = "displayList";

    $scope.showDPanelTab = function(tabId)
    {
        // get the tab and display, if already open dont do anything
        var currentOpenDPanel = $(".dPanelActive");
        var tabContainerId = tabId + "Container";

        var currentOpenDPanelId = currentOpenDPanel.attr("id");

        if(currentOpenDPanel.attr("id") != tabContainerId)
        {
            $scope.openedTab = tabId;
            currentOpenDPanel.hide();
            currentOpenDPanel.removeClass("dPanelActive");

            var currentOpenDPanelHeading = $("#" + currentOpenDPanelId.replace("Container", "Heading"));
            currentOpenDPanelHeading.removeClass("dLink_active");

            var dPanel = $("#"+  tabContainerId);
            var dPanelHeading = $("#" + tabId + "Heading");
            dPanelHeading.addClass("dLink_active");

            dPanel.show();
            dPanel.addClass("dPanelActive");
        }
    };

    $scope.checkEnterAndUpdateDisplayTitle = function($event, display)
    {
        if($event.keyCode == 13) {  // Enter key
            $scope.updateDisplayTitle(display);
        }
    };

    $scope.checkEnterAndUpdateURLAddress = function($event, display)
    {
        if($event.keyCode == 13) {  // Enter key
            $scope.updateURLAddress(display);
        }
    };

    $scope.updateDisplayTitle = function(display)
    {
        var newTitle = $.trim(display.newTitle);

        if( newTitle.length == 0 )
        {
            $scope.$parent.activAlert("Please enter a valid Display Title", 1000);
            return;
        }

        var postData = {displayId : display.ID, newTitle : newTitle };

        $http({ method: "POST", url: $scope.$parent.backend + "/updateDisplayTitle", "params": {}, "data": postData})
            .success(function (data, status) {

                if (data.success)
                {
                    display.boxTitle = display.newTitle;
                }
                else
                {
                    $scope.$parent.activAlert("Error while Updating Display Title Please Try again : " + data.error, 2000);
                }
            })
            .error(function (data, status) {
                $scope.$parent.activAlert("Unexpected Error : " + data, 2000);
            });
    };

    $scope.updateURLAddress = function(display)
    {
        var urlAddress = $.trim(display.urlAddress);

        if( urlAddress.length == 0 )
        {
            $scope.$parent.activAlert("Please enter a valid URL Address", 1000);
            return;
        }

        var postData = {displayId : display.ID, urlAddress : urlAddress };

        $http({ method: "POST", url: $scope.$parent.backend + "/updateURLAddress", "params": {}, "data": postData})
            .success(function (data, status) {

                if (data.success)
                {
                    display.boxTitle = display.newTitle;
                }
                else
                {
                    $scope.$parent.activAlert("Error while Updating URL Address Please Try again : " + data.error, 2000);
                }
            })
            .error(function (data, status) {
                $scope.$parent.activAlert("Unexpected Error : " + data, 2000);
            });
    };

    $scope.toggleMin = function() {
        $scope.minDate = $scope.minDate ? null : new Date();
    };
    $scope.toggleMin();

    $scope.resetDisplay = function(display)
    {
        var displayGroup = $scope.currentDisplayGroup.ID == 0 ? null : $scope.currentDisplayGroup;
        var postData = {displayId: display.ID, command : "ResetDisplay",
            displayGroupId : $scope.currentDisplayGroup.ID };

        var backend = $scope.backend || $scope.$parent.backend;
        if(!confirm("Are you sure? This will reset the current display settings and clear out the current running project!")) {
            return;
        }
        $http({ method: "POST", url: backend + "/displayCommands", "params": {}, "data": postData })
            .success(function (data, status) {

                if(!data.success) {
                    $scope.$parent.activAlert("There was an error while setting display command, Please try again", 2000);
                }else{

                    if(displayGroup) {
                        display.syncWithDisplayGroup = false;
                    }

                    display.projectId = 0;
                    display.useScheduledProject = false;

                    // $scope.$parent.activAlert("Display Reset Request sent.", 3000);
                    display.resetRequestStatus = "Reset Requested";
                    display.resetRequested = true;

                    display.resetRequestTime = (new Date()).getTime();
                }

            }).error(function (data) {
                $scope.$parent.activAlert("There was an error while setting display command, Please try again", 2000);
                console.error(data);
            });
    };

    $scope.resetAssetsDownload = function()
    {
        var postData = {displayId: $scope.currentOpenDisplayId, command : "ResetAssetDownload"};
        var backend = $scope.backend || $scope.$parent.backend;

        $http({ method: "POST", url: backend + "/displayCommands", "params": {}, "data": postData })
            .success(function (data, status) {

                    if(data.success) {
                        var currentOpenDisplay = $scope.getDisplayObjById(postData.displayId);
                        currentOpenDisplay.showResetDownloadButton = false;
                    }
                else {
                        $scope.$parent.activAlert("There was an error while setting display command, Please try again", 2000);
                    }
        }).error(function (data) {
                $scope.$parent.activAlert("There was an error while setting display command, Please try again", 2000);
                console.error(data);
            });
    }

    $scope.getOpenDisplayInfo = function()
    {
        // do not double call this
        // Clear timeout as a precaution
        if($scope.getActivityTimeout_display ) {
            clearTimeout($scope.getActivityTimeout_display);
        }

        if( $scope.currentOpenDisplayId ) {
            var postdata = {displayId: $scope.currentOpenDisplayId};
            var backend = $scope.backend || $scope.$parent.backend;
            $http({ method: "POST", url: backend + "/retrieveDisplay", "params": {}, "data": postdata })
                .success(function (data, status) {
                    // Update current open display Properties :
                    var currentOpenDisplay = $scope.getCurrentOpenDisplayObject();

                    if(currentOpenDisplay) {

                        if(currentOpenDisplay.resetRequested) {
                            $scope.markResetRequestSuccessful(currentOpenDisplay);
                        }

                        currentOpenDisplay.statusMessage = data.display.statusMessage;
                        currentOpenDisplay.logs = data.display.logs;
                        currentOpenDisplay.heartbeat = data.display.heartbeat;
                        currentOpenDisplay.lastModifiedOn = data.display.lastModifiedOn;
                        currentOpenDisplay.appVersion = data.display.appVersion;
                        currentOpenDisplay.projectTitle = data.display.projectTitle;
                        currentOpenDisplay.logsLoaded = true;
                        currentOpenDisplay.displayState = data.display.displayState;
                        $scope.setDisplayStatusClass(currentOpenDisplay);
                    }

                    $scope.getOpenDisplayInfoAfterDelay();
                })
                .error(function (data) {
                    $scope.getOpenDisplayInfoAfterDelay();
                    // set next
                    console.error(data);
                    //alert("error")
                });
        }
    };

    $scope.resetGroupOverrideDialog = function(){
        var displayInformation_override_edit = $("#displayInformation_override_edit");

        var headerSelected = displayInformation_override_edit.find(".active");
        var sectionShown = displayInformation_override_edit.find(".showSection");

        headerSelected.removeClass("active");
        sectionShown.hide();

        var quickProjectHeading = displayInformation_override_edit.find("#quickProject_override");
        var quickProjectSection = displayInformation_override_edit.find("#quickProject_override_details");
        var schedulesSection = displayInformation_override_edit.find("#schedules_override_details");

        quickProjectHeading.addClass("active");
        quickProjectSection.show();
        schedulesSection.hide();
    };

    $scope.openDisplayGroup = function(displayGroup){
        $scope.currentDisplayGroup = displayGroup;
        if(!$scope.currentDisplayGroup.dataLoaded ){
            $scope.currentDisplayGroup.displays = [];
            $scope.currentDisplayGroup.displayGroups = [];
            $scope.retrieveDisplays();
        }else{
            $scope.displays = $scope.currentDisplayGroup.displays;
            $scope.displayGroups = $scope.currentDisplayGroup.displayGroups;
            $scope.currentOverrideActive = $scope.currentDisplayGroup.override?$scope.currentDisplayGroup.override.active : false;
        }
        $scope.resetGroupOverrideDialog();
        $scope.breadcrumbsStack.push(displayGroup);
    };

    $scope.openBreadCrumb = function(dg, index){
       // No action for clicking the last breadcrumb, as we're on the same folder
        var stackLen = $scope.breadcrumbsStack.length;
        if(index < stackLen - 1) {
            // Remove breadcrumbs till this clicked one
            $scope.breadcrumbsStack.splice(index);
            $scope.openDisplayGroup(dg);
        }
    };

    $scope.openLastFolder = function(){
        $scope.breadcrumbsStack.pop();
        if($scope.breadcrumbsStack.length == 0){
            $scope.openRoot();
        }
        else {
            $scope.currentDisplayGroup = $scope.breadcrumbsStack[$scope.breadcrumbsStack.length - 1];
            $scope.displays = $scope.currentDisplayGroup.displays;
            $scope.displayGroups = $scope.currentDisplayGroup.displayGroups;
        }
    };

    $scope.openRoot = function(){
        $scope.openDisplayGroup($scope.rootDisplayGroup);
    }

    $scope.showOverrideAll = function(){
        var addEntityTemplateDOM = $("#displayInformation_override_edit").find(".templateContent");
        // Append a new container to the body and load dialog
        $scope.modalContainer = new ModalClass({title : "Override all Displays", "appendTo" : "#displaysContainer",
            "onBeforeHide" : function(modal){
                var displayDetailsContainer = modal.find(".displayDetailsContainer");
                $("#displayInformation_override_edit").append(displayDetailsContainer);

                // Reset the popup
                var headingLink = document.querySelector("#quickProject_override");
                $scope.currentDisplay.schedulesLoaded = false;
                $scope.switchDisplayTab($scope.currentOpenDisplayId, headingLink, true);
                $scope.currentOpenDisplayId = null;
            }, "body" :'<div class="displayDetailsContainer"></div>', popupBodyCssClass: 'add-overflow-y'});

        var body = $scope.modalContainer.find(".displayDetailsContainer");
        body.append(addEntityTemplateDOM);
        $scope.modalContainer.hideFooter();
        $scope.modalContainer.find(".modal-dialog").css("width", "700px");
        $scope.modalContainer.adjustContentHeight();
		window.currentModal = $scope.modalContainer;

        $("#publishedProjectSelector_override").val($scope.currentDisplayGroup.override.projectId);
    };

    $scope.publishDisplayGroupOverride = function(event)
    {

        // Temp data load, needs improvements
        var publishedProjectSelector = document.getElementById('publishedProjectSelector_override');
        var projectTransitionSelector = document.getElementById('projectTransitionSelector_override');

        var projectId = publishedProjectSelector.options[publishedProjectSelector.selectedIndex].value;

        var projectTransition = projectTransitionSelector.options[projectTransitionSelector.selectedIndex].value;

        var active = $("#overrideToggle").is(":checked");

        if(active) {
            if (!confirm("You are about to override all displays\nAre you sure?")) {
                return;
            }
        }

        var postData = {
            displayGroupOverride : angular.copy($scope.currentDisplayGroup.override),
            action : "publish"
        };
        postData.displayGroupOverride.active = active;

        // Remove stray data
        delete postData.displayGroupOverride.schedules;
        var backend = $scope.backend || $scope.$parent.backend;
        $scope.publishing = true;
        $scope.disablePublishButton('override');

        // Pass data to quick publish
        $http({ "method": "POST", "url": backend + "/displayGroupOverride", "params": {}, "data": postData }).
            success(function(data, status){
                $scope.publishing = false;

                if(data.success)
                {
                    $scope.currentDisplayGroup.override.active = active;
                    $scope.currentDisplayGroup.override.ID = data.displayGroupOverride.ID;
                    // Success, close dialog
                    $scope.modalContainer.hide();
                }
                else
                {
                    $scope.enablePublishButton('override');
                    $scope.modalContainer.setErrorText("Error while publishing : " + data.error);
                }
            }).
            error(function(data, status){
                $scope.publishing = false;
                $scope.modalContainer.setErrorText("Error while Publishing.." + json.stringify(data));
                $scope.enablePublishButton('override');
                console.log(data);
            });
    };

    // Updated Display groups methods
    $scope.showDisplayGroupDetails = function(displayGroup){
        var addEntityTemplateDOM = $("#displayInformation_displayGroup_edit_" + displayGroup.ID).find(".templateContent");
        // Append a new container to the body and load dialog
        $scope.modalContainer = new ModalClass({title : displayGroup.name, "appendTo" : "#displaysContainer",
            "onBeforeHide" : function(modal){
            var displayDetailsContainer = modal.find(".displayDetailsContainer");
            $("#displayInformation_displayGroup_edit_" + displayGroup.ID).append(displayDetailsContainer);

            // Reset the popup
            var headingLink = document.querySelector("#dg_quickProject_" + displayGroup.ID);
            $scope.currentDisplay.schedulesLoaded = false;
            $scope.switchDisplayTab($scope.currentOpenDisplayId, headingLink, displayGroup);
            $scope.currentOpenDisplayId = null;
        }, "body" :'<div class="displayDetailsContainer"></div>', popupBodyCssClass: 'add-overflow-y'});

        var body = $scope.modalContainer.find(".displayDetailsContainer");
        body.append(addEntityTemplateDOM);
        $scope.modalContainer.hideFooter();

        window.currentModal =  $scope.modalContainer;
        window.currentModal.displayGroupObj = displayGroup;

        $scope.modalContainer.find(".modal-dialog").css("width", "700px");
        $scope.modalContainer.adjustContentHeight();

        $("#publishedProjectSelector_dg_"+displayGroup.ID).val(displayGroup.projectId);
    };

    // Updated Displays methods
    $scope.showDisplayDetails = function(display){

        $scope.currentOpenDisplayId = display.ID;
        $scope.getOpenDisplayInfo();

        var addEntityTemplateDOM = $("#displayInformation_" + display.ID).find(".templateContent");
        // Append a new container to the body and load dialog
        $scope.modalContainer = new ModalClass({title : display.boxTitle, "appendTo" : "#displaysContainer",
            "onBeforeHide" : function(modal){
            var displayDetailsContainer = modal.find(".displayDetailsContainer");
            $("#displayInformation_" + display.ID).append(displayDetailsContainer);

            // Reset the popup
            var headingLink = document.querySelector("#quickProject_" + $scope.currentOpenDisplayId);
            $scope.currentDisplay.schedulesLoaded = false;
            $scope.switchDisplayTab($scope.currentOpenDisplayId, headingLink);
            $scope.currentOpenDisplayId = null;

        }, "body" :'<div class="displayDetailsContainer"></div>', popupBodyCssClass: 'add-overflow-y'});

        window.currentModal = $scope.modalContainer;
        $scope.modalContainer.displayObj = display;

        var body = $scope.modalContainer.find(".displayDetailsContainer");
        body.append(addEntityTemplateDOM);
        $scope.modalContainer.hideFooter();
        $scope.modalContainer.find(".modal-dialog").css("width", "700px");
        $scope.modalContainer.adjustContentHeight();

        if(!$scope.$root.hasAccessRight('Displays - Edit And Publish')){
            $scope.modalContainer.find("input").attr("disabled", true);
            $scope.modalContainer.find("select").attr("disabled", true);
            $scope.modalContainer.find("a.btn-link").attr("disabled", true);
        }

        $("#publishedProjectSelector_" + display.ID).val(display.projectId);
    };

    $scope.showAddDisplayGroup = function(selectedDisplays){
        // Reset data
        $scope.groupTitleInput = "";

        var addEntityTemplateDOM = $("#addDisplayGroupTemplate").find(".templateContent");
        // Append a new container to the body and load dialog
        $scope.modalContainer = new ModalClass({title : "New Display Group", "appendTo" : "#displaysContainer",
            "onBeforeHide" : function(modal){
                var displayDetailsContainer = modal.find(".displayDetailsContainer");
                $("#addDisplayGroupTemplate").append(displayDetailsContainer);
            }, "body" :'<div class="displayDetailsContainer"></div>', popupBodyCssClass: 'add-overflow-y'});

        var body = $scope.modalContainer.find(".displayDetailsContainer");
        body.append(addEntityTemplateDOM);
        $scope.modalContainer.hideFooter();
        $scope.modalContainer.adjustContentHeight();
        // Add data
        $scope.modalContainer.selectedDisplays = selectedDisplays;
    };

    $scope.csvFile = null;
    $scope.csvFileProgress = 0;
    $scope.csvFileFinished = false;
    $scope.csvFileError = null;

    $scope.csvFileSelected = function(files){
        $scope.csvFile = null;
        if(files) {
            $scope.csvFile = files[0];
        }
    };

    $scope.registerDisplayMac = function(uploadFile){
        if(uploadFile) {
            if (!$scope.csvFile) {
                $scope.modalContainer.setErrorText("Please select a  csv file");
            }
            else {
                $upload.upload({
                    url: $scope.$root.backend + '/uploadDisplayCSV',
                    method: "POST",
                    data: {"action": "uploadCSV", displayGroupId : $scope.currentDisplayGroup.ID},
                    file: $scope.csvFile

                }).then(function (response) {

                    $scope.csvFileFinished = true;
                    var responseData = response.data;

                    if(responseData.success) {

                        $scope.modalContainer.find(".uploadForm").find("input").val("");

                        for (var i = 0; i < responseData.newDisplays.length; i++) {
                            var display = response.data.newDisplays[i];
                            $scope.postProcessDisplay(display);
                            $scope.currentDisplayGroup.displays.push(display);
                        }

                        for (var i = 0; i < responseData.updatedDisplays.length; i++) {
                            var display = responseData.updatedDisplays[i];
                            var existingDisplay = $scope.getDisplayById(display.ID);
                            existingDisplay.boxTitle = display.boxTitle;
                        }

                        // Show summary :
                        var infoHtml = "Upload Finished <br/>";
                        infoHtml += "Displays Added  : " + responseData.newDisplays.length + "<br/>";
                        infoHtml += "Displays Updated  : " + responseData.updatedDisplays.length + "<br/>";

                        if (responseData.errors && responseData.errors.length > 0) {
                            infoHtml += "Errors : " + responseData.errors.length + "<br/>";
                            +"<br/>";
                            infoHtml += "<span style='color:#c63f3f;'>"+ responseData.errors.join("") + "</span>";
                        }
                        $scope.modalContainer.setStatusText(infoHtml);
                    }
                    else{
                        $scope.modalContainer.setErrorText(responseData.error);
                    }

                }, function (response) {

                    $scope.csvFileError = response;
                    $scope.csvFileFinished = true;
                    $scope.csvFileProgress = 0;

                }, function (evt) {
                    $scope.csvFileProgress = parseInt(100.0 * evt.loaded / evt.total);

                    if($scope.csvFileProgress == 100) {
                        $scope.csvFileFinished = true;
                    }

                });
            }
        }
        else {
            var inputsContainer = $scope.modalContainer.find(".displayDetailsContainer");
            var inputRows = inputsContainer.find(".inputRow");
            // Get valid entries
            var displayName = "";
            var macAddress = "";

            var list = [];
            var allValid = true;
            for (var i = 0; i < inputRows.length; i++) {
                var inputRow = $(inputRows[i]);

                var displayNameInput = inputRow.find(".displayNameInput");
                var macAddressInput = inputRow.find(".macAddressInput");

               if(!Validations.checkEmpty(displayNameInput, $scope.modalContainer)){
                   allValid = false;
                   break;
               }else if(!Validations.checkEmpty(macAddressInput, $scope.modalContainer)){
                    allValid = false;
                    break;
                }else  if(!Validations.checkMacAddress(macAddressInput, $scope.modalContainer)){
                   allValid = false;
                   break;
               }

                displayName = displayNameInput.val().trim();
                macAddress = macAddressInput.val().trim();

                list.push({
                    "boxTitle": displayName,
                    "macAddress": macAddress,
                    "displayGroupId": $scope.currentDisplayGroup.ID
                });
            }

            // Post to server
            if (allValid && list.length > 0) {
                var postData = {action: "registerByMacAddress", "newDisplays": list};
                $http({
                    method: "POST",
                    url: $scope.$parent.backend + "/registerDisplay",
                    "params": {},
                    "data": postData
                })
                    .success(function (data, status) {

                        if (data.success) {

                            for (var i = 0; i < data.newDisplays.length; i++){
                                var display = data.newDisplays[i];
                                $scope.postProcessDisplay(display);
                                $scope.currentDisplayGroup.displays.push(display);
                            }

                            for (var i = 0; i < data.updatedDisplays.length; i++){
                                var display = data.updatedDisplays[i];
                                var existingDisplay = $scope.getDisplayById(display.ID);
                                existingDisplay.boxTitle = display.boxTitle;
                            }
                            $scope.modalContainer.hide();
                        }
                        else {
                            $scope.$parent.activAlert("An Error has occurred. Please try again", 2000);
                            console.log(data.error);
                        }
                    })
                    .error(function (data, success) {
                        $scope.$parent.activAlert("An Error has occurred. Please try again", 2000);
                        console.log(data);
                    });
            }
        }
    };

    $scope.registerDisplayUrl = function(){
        var inputsContainer = $scope.modalContainer.find(".displayDetailsContainer");
        var inputRows = inputsContainer.find(".inputRow");
        // Get valid entries
        var displayName = "";
        var urlAddress = "";

        var list = [];
        var allValid = true;
        for (var i = 0; i < inputRows.length; i++) {
            var inputRow = $(inputRows[i]);

            var displayNameInput = inputRow.find(".displayNameInput");
            var urlAddressInput = inputRow.find(".urlAddressInput");

           if(!Validations.checkEmpty(displayNameInput, $scope.modalContainer)){
               allValid = false;
               break;
           } else if (!Validations.checkEmpty(urlAddressInput, $scope.modalContainer)){
               allValid = false;
               break;
           } else if (!Validations.checkUrl(urlAddressInput, $scope.modalContainer)){
               allValid = false;
               break;
           }

            displayName = displayNameInput.val().trim();
            urlAddress = urlAddressInput.val().trim();

            list.push({
                "boxTitle": displayName,
                "urlAddress": urlAddress,
                "displayGroupId": $scope.currentDisplayGroup.ID
            });
        }

        // Post to server
        if (allValid && list.length > 0) {
            var postData = {action: "registerByUrlAddress", "newDisplays": list};
            $http({
                method: "POST",
                url: $scope.$parent.backend + "/registerDisplay",
                "params": {},
                "data": postData
            })
            .success(function (data, status) {

                if (data.success) {

                    for (var i = 0; i < data.newDisplays.length; i++){
                        var display = data.newDisplays[i];
                        $scope.postProcessDisplay(display);
                        $scope.currentDisplayGroup.displays.push(display);
                    }

                    for (var i = 0; i < data.updatedDisplays.length; i++){
                        var display = data.updatedDisplays[i];
                        var existingDisplay = $scope.getDisplayById(display.ID);
                        existingDisplay.boxTitle = display.boxTitle;
                    }


                    $scope.modalContainer.hide();
                }
                else {
                    $scope.$parent.activAlert("An Error has occurred. Please try again", 2000);
                    console.log(data.error);
                }
            })
            .error(function (data, success) {
                $scope.$parent.activAlert("An Error has occurred. Please try again", 2000);
                console.log(data);
            });
        }
    };


    $scope.showAddDisplayMacAddress = function(uploadCSV){
        if (!$scope.$root.checkPermission('displays', 'numberOfDisplays', $scope.displayCount)) {
          return $scope.$parent.activAlert("You have reached your licence limit.", 1000);
        }
        // Load a fresh grid for fast data entry
        var addEntityTemplateDOM = $("#addDisplayByMacAddressTemplate").find(".templateContent");
        // Append a new container to the body and load dialog
        $scope.modalContainer = new ModalClass({title : "New Display",
            "onBeforeHide" : function(modal){
                var displayDetailsContainer = modal.find(".displayDetailsContainer");
                $("#addDisplayByMacAddressTemplate").append(displayDetailsContainer);
            }, "body" :'<div class="displayDetailsContainer"></div>', popupBodyCssClass: 'add-overflow-y'});

        var body = $scope.modalContainer.find(".displayDetailsContainer");
        body.append(addEntityTemplateDOM);

        $scope.modalContainer.hideFooter();
        $scope.modalContainer.adjustContentHeight();

        if(uploadCSV){
            addEntityTemplateDOM.find(".textEntryForm").hide();
            addEntityTemplateDOM.find(".uploadForm").show();
        }
        else{
            addEntityTemplateDOM.find(".textEntryForm").show();
            addEntityTemplateDOM.find(".uploadForm").hide();
        }

        //clear
        addEntityTemplateDOM.find(".textEntryForm").find("input").val("");
        addEntityTemplateDOM.find(".uploadForm").find("input").val("");
        addEntityTemplateDOM.find(".statusText").html("");
    };

    $scope.showAddDisplayUrl = function(){
        if (!$scope.$root.checkPermission('displays', 'numberOfDisplays', $scope.displayCount)) {
          return $scope.$parent.activAlert("You have reached your licence limit.", 1000);
        }
        // Load a fresh grid for fast data entry
        var addEntityTemplateDOM = $("#addDisplayByUrlTemplate").find(".templateContent");
        // Append a new container to the body and load dialog
        $scope.modalContainer = new ModalClass({title : "New Display",
            "onBeforeHide" : function(modal){
                var displayDetailsContainer = modal.find(".displayDetailsContainer");
                $("#addDisplayByUrlTemplate").append(displayDetailsContainer);
            }, "body" :'<div class="displayDetailsContainer"></div>', popupBodyCssClass: 'add-overflow-y'});

        var body = $scope.modalContainer.find(".displayDetailsContainer");
        body.append(addEntityTemplateDOM);

        $scope.modalContainer.hideFooter();
        $scope.modalContainer.adjustContentHeight();
        //clear
        addEntityTemplateDOM.find(".textEntryForm").find("input").val("");
        addEntityTemplateDOM.find(".statusText").html("");
    };

    $scope.showAddDisplay = function(){
        // Reset data

        if (!$scope.$root.checkPermission('displays', 'numberOfDisplays', $scope.displayCount)) {
          return $scope.$parent.activAlert("You have reached your licence limit.", 1000);
        }
        $scope.boxCodePrefilled = false;
        $scope.boxTitleInput = "Display " + ( parseInt($scope.displays.length) + 1 );
        $scope.boxCodeInput = "";

        var addEntityTemplateDOM = $("#addDisplayTemplate").find(".templateContent");
        // Append a new container to the body and load dialog
        $scope.modalContainer = new ModalClass({title : "New Display", "appendTo" : "#displaysContainer",
            "onBeforeHide" : function(modal){
                var displayDetailsContainer = modal.find(".displayDetailsContainer");
                $("#addDisplayTemplate").append(displayDetailsContainer);
        }, "body" :'<div class="displayDetailsContainer"></div>', popupBodyCssClass: 'add-overflow-y'});

        var body = $scope.modalContainer.find(".displayDetailsContainer");
        body.append(addEntityTemplateDOM);
        $scope.modalContainer.hideFooter();
        $scope.modalContainer.adjustContentHeight();
    };


    $scope.getDisplayGroupByNameFromList = function(dgName, list, excludeDisplayGroup){
        var filtered = list.filter(function(t){
            var result = t.name.toLowerCase() == dgName.toLowerCase();
            if( excludeDisplayGroup && excludeDisplayGroup.ID == t.ID)
                result = false;
            return result
        });
        return filtered.length > 0 ? filtered[0] : null;
    };

    $scope.removeDisplayFromGroup = function(displayGroup, display){
        for(var i=0; i< displayGroup.displays.length; i++){
            if(displayGroup.displays[i].ID == display.ID){
                displayGroup.displays.splice(i,1);
                break;
            }
        }
    };


    $scope.removeDisplayGroupFromGroup= function(displayGroup, childDisplayGroup){
        for(var i=0; i< displayGroup.displayGroups.length; i++){
            if(displayGroup.displayGroups[i].ID == childDisplayGroup.ID){
                displayGroup.displayGroups.splice(i,1);
                break;
            }
        }
    };


    // Drag drop related functions
    $scope.dgItemDrop = function(event, channel, draggedData, index, droppedOnChannel, droppedOn) {
        // Disable drop on same item
        if (droppedOnChannel == channel) {
            // Ignore drop on same item
            if (draggedData.ID == droppedOn.ID) {
                return;
            }
        } else if ($scope.breadcrumbsStack.length == 1 && droppedOnChannel == "breadcrumb") {
            // We're at the root, dont allow any breadcrumb action
            return;
        }

        // All good, process the drop action
        // Identify action

        // Action 1 : Display on Display Group
        if (( droppedOnChannel == "breadcrumb"
            || droppedOnChannel == "displayGroup")
            && channel == "display"
        ) {
            $http({
                method: "POST", url: $scope.$parent.backend + "/displayGroup", "params": {},
                "data": {action: "addDisplayToGroup", "displayId": draggedData.ID, "displayGroupId": droppedOn.ID}
            })
                .success(function (data, status) {
                    if (data.success) {
                        droppedOn.displays = droppedOn.displays ? droppedOn.displays : [];
                        // Move the display to the target displayGroup
                        if(droppedOn.dataLoaded) {
                            droppedOn.displays.push(draggedData);
                        }
                        $scope.removeDisplayFromGroup($scope.currentDisplayGroup, draggedData);
                    } else {
                        $scope.$parent.activAlert("An Error Has occurred : " + data.error, 4000);
                    }
                })
                .error(function (data) {
                    console.error(data);
                    $scope.$parent.activAlert("An Error Has occurred : " + JSON.stringify(data), 4000);
                });
        }
        else if ((droppedOnChannel == "breadcrumb"
            || droppedOnChannel == "displayGroup")
            && channel == "displayGroup"
        ) {
            // Action 2 : Display Group on Display Group

            // Check if the displayGroup is duplicate
            if (droppedOn.dataLoaded && $scope.getDisplayGroupByNameFromList(draggedData.name, droppedOn.displayGroups)) {
                $scope.$parent.activAlert("A Display Group with the same name already exists in the target", 4000);
                return;
            }

            $http({
                method: "POST", url: $scope.$parent.backend + "/displayGroup", "params": {},
                "data": {
                    action: "addDisplayGroupToGroup",
                    displayGroupName: draggedData.name,
                    "childDisplayGroupId": draggedData.ID,
                    "displayGroupId": droppedOn.ID
                }
            })
                .success(function (data, status) {
                    if (data.success) {
                        droppedOn.displayGroups = droppedOn.displayGroups ? droppedOn.displayGroups : [];
                        // Move the display to the target displayGroup
                        if(droppedOn.dataLoaded) {
                            droppedOn.displayGroups.push(draggedData);
                        }
                        $scope.removeDisplayGroupFromGroup($scope.currentDisplayGroup, draggedData);
                    } else {
                        $scope.$parent.activAlert(data.error, 4000);
                    }
                })
                .error(function (data) {
                    console.error(data);
                    $scope.$parent.activAlert("An Error Has occurred : " + JSON.stringify(data), 4000);
                });
        } else if (channel == "display" && droppedOnChannel == channel) {
            // Action 3 : display on display
            // => Add a new displayGroup
            var selectedDisplays = [draggedData, droppedOn];
            $scope.showAddDisplayGroup(selectedDisplays);
        }
    };


    // Type ahead functions
    $scope.searchMembers = "";
    $scope.memberSelected = function(item, model, label, event, displayGroup){

        // Update the data to the db
        // Generate/update token
        var secObj = null;
        if(!displayGroup.security){
            secObj = {"users" : {}, "teams" : {}}
        }
        else{
            secObj = JSON.parse(displayGroup.security);
         }
        secObj.users[item.ID] = true;
        var secObjStr = JSON.stringify(secObj);

        $http({ "method": "POST", "url": config.backend + "/displayGroup", "params": {},
            "data": { "displayGroupId": displayGroup.ID ,
                        "action" : "updateSecurityToken",
                        security : secObjStr} }).success(function(data, status)
            {

                // is this the first member?
                if(!displayGroup.securityMembers){
                    displayGroup.securityMembers = [];
                }
                // Add new member
                displayGroup.securityMembers.push(item);
                $scope.modalContainer.find(".searchMemberInput").val("");
                $scope.updateModalHeight();
                displayGroup.security = secObjStr;

            }).error(function(data, status){
            $scope.$parent.activAlert("An unexpected error has occurred :" + data.message || data.error , 2000);
            });
    };

    $scope.removeSecurityMember = function(item, displayGroup){
        // Update the data to the db
        // Generate/update token
        var secObj = JSON.parse(displayGroup.security);

        delete secObj.users[item.ID];
        var secObjStr = JSON.stringify(secObj);

        $http({ "method": "POST", "url": config.backend + "/displayGroup", "params": {},
            "data": { "displayGroupId": displayGroup.ID ,
                "action" : "updateSecurityToken",
                security : secObjStr} }).success(function(data, status)
        {
            // is this the first member?
            if(!displayGroup.securityMembers){
                displayGroup.securityMembers = [];
            }
            // Remove member member
            displayGroup.securityMembers.splice(displayGroup.securityMembers.indexOf(item), 1);
            displayGroup.security = secObjStr;
            $scope.updateModalHeight();
        }).error(function(data, status){
            $scope.$parent.activAlert("An unexpected error has occurred :" + data.message || data.error , 2000);
        });
    };

    $scope.updateModalHeight = function(callback){
        $scope.$root.updateModalHeight($scope.modalContainer, callback);
    };

    $scope.getMatchingMembers = function(val, displayGroup){
        var postData = {action : "getMatchingEntities", queryString : val};

        return  $http({ "method": "POST", "url": config.backend + "/teams", "params": {},
            "data": postData }).then(function(response, status){
            var data = response.data;
            var list = [];
            if(data.success) {
                // process
                // Load users
                for (var i = 0; i < data.users.length; i++) {
                    var user = data.users[i];
                    user.displayString = user.firstname + " " + user.surname + " (" + user.username + ")";
                    // dont add the owner of the group or existing members
                    if(displayGroup.createdBy != user.ID) {
                        if(displayGroup.security){
                            var secObj = JSON.parse(displayGroup.security);
                            if(!secObj.users.hasOwnProperty(user.ID)){
                                list.push(user);
                            }
                        }
                        else {
                            list.push(user);
                        }
                    }

                }
            }
            return list;
        });
/*

        return $http.post(config.backend + "/teams", postData).then(function(response){
            return [{username : "test"}, {username: "test2"}];
        }, function(err){
            return [{username : "err test"}, {username: "err test2"}];
        });
*/

    };

}]);
