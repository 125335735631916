activ.directive('projectListView', function() {
    return {
        replace: true,
        restrict: 'E',
        templateUrl: "./views/projectListView.html?v=" + version,
        scope: true,
        link: function(scope, element, attrs) {

        }
    };
});