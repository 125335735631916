activ.directive("editorProjectPanel", function(){

    return {

        restrict: "E",
        replace: true,
        templateUrl: "./views/editorProjectPanel.html?v=" + version,
        scope: {},

        link: function(scope, element, attr){


        },

        controller: function($scope){

            $scope.save = function() {

                $scope.$parent.exportProjectJSON();

            };

            $scope.publish = function() {
                if(timeline.isTemplateProject()) {
                    $scope.$parent.$parent.activAlert("Please update or remove all Template Elements before publishing.", 3000);
                }
                else {
                    $scope.$parent.exportProjectJSON("publish");
                }

            };

            $scope.preview = function() {

                if(timeline.isTemplateProject()){
                    $scope.$parent.$parent.activAlert("Please update or remove all Template Elements before previewing.", 3000);
                }
                else
                {
                    $scope.$parent.previewProject("preview");
                }

            };

            $scope.saveAsTemplate = function() {
                if(timeline.isTemplateProject()){
                    $scope.$parent.$parent.activAlert("Please update or remove all Template Elements before saving as Template.", 3000);
                }
                else {
                    $scope.$parent.exportProjectJSON("template");
                }

            };

            $scope.saveAs = function()
            {
                // Dont allow save as until project data loads
                if($scope.$parent.checkProjectDataLoading()){
                    return;
                }

                // Get title
                var currentProjectName = $scope.$parent.loadedProject.name;
                var newTitle = prompt("Please Enter a Project Name", currentProjectName);

                if( newTitle != null && newTitle != undefined )
                {
                    newTitle = newTitle.trim();

                    if(newTitle.length > 0)
                    {
                        $scope.$parent.saveAsProjectTitle = newTitle;
                        // valid entry, lets try and copy the current project and save
                        $scope.$parent.exportProjectJSON("saveAs", function(retData)
                        {
                            alert("Project Saved As : " + newTitle);
                            // Open the new project
                        });
                    }
                    else
                    {
                        // Do nothing
                    }

                }

            }

        }

    }

});