activ.directive("homeHeader", function(){

    return {

        restrict: "E",
        replace: true,
        templateUrl: "./views/homeHeader.html?v=" + version

    }

});