
var AllAccessRightsList = [
	"Full Access",
	"Full Access to Children",
	"",    // Reserved
	"",    // Reserved

	"Teams - Create",
	"Teams - Edit",
	"Teams - Delete",

	"Users - Create",
	"Users - Edit",
	"Users - Delete",

	"Projects - Create",
	"Projects - Edit",
	"Projects - Delete",

	"Project Groups - Create",
	"Project Groups - Edit",
	"Project Groups - Delete",

	"Displays - Create",
	"Displays - Edit and Publish",
	"Displays - Delete",

	"Displays - Change Default Project",
	"Displays - Set All Display Override",

	"Display Groups - Create",
	"Display Groups - Edit",
	"Display Groups - Delete",

	"Assets - Create",
	"Assets - Delete",

	"Projects - Publish"
];

var AllAccessRights = {};

for(var i=0; i<AllAccessRightsList.length; i++){
	var powVal = Math.pow(2, i);
	AllAccessRights[powVal] = AllAccessRightsList[i];
}

var AllAccessRightsDict = {};
for(var ar in AllAccessRights ){
	var str  = AllAccessRights[ar];
	AllAccessRightsDict[str] = parseInt(ar);
}

var arModal = null;

function renderAccessRights(container, prefillAccessRights, _arModal, $scope)
{
	container.empty();

	var accessRights = AllAccessRights;

	if( typeof accessRights == "number"){
		accessRights = getAccessRightsFromValue(accessrights);
	}

	// Add select All button
	var topContainer = $("<div></div>");
	container.append(topContainer);

	var fullAccessContainer = $('<div id="AccessRightsContainer_1">'
								+'<input class="accessRight" type="checkbox" id="accessRight_1" value="1">'
								+'<label style="margin-left:10px;" for="accessRight_1">Full Access</label>'
							+'</div>');

	if ($scope) {
		if ($scope.$root.hasAccessRight("Full Access")) {
			container.append(fullAccessContainer);
		}
	} else {
		container.append(fullAccessContainer);
	}


	var accessRightsContainer = $('<div class="accessRights" style="margin-left:10px;"></div>');
	container.append(accessRightsContainer);

	var accessRightGroups = {};

	// Load all Access Right groups
	accessRightGroups["Teams"] = (getAccessRightGroup("Teams"));
	accessRightGroups["Users"] = (getAccessRightGroup("Users"));
	accessRightGroups["Displays"] = (getAccessRightGroup("Displays"));
	accessRightGroups["Display Groups"] = (getAccessRightGroup("Display Groups"));
	accessRightGroups["Projects"] = (getAccessRightGroup("Projects"));
	accessRightGroups["Project Groups"] = (getAccessRightGroup("Project Groups"));
	accessRightGroups["Assets"] = (getAccessRightGroup("Assets"));

	for(var arGrp in accessRightGroups) {
		var accessRights = accessRightGroups[arGrp];
		var showAccessRights = false;

		for (var accessRight in accessRights) {
			if ($scope) {
				if ($scope.$root.hasAccessRight(accessRights[accessRight])) {
					showAccessRights =true;
				}
			} else {
				showAccessRights = true;
			}
		}

		if (!showAccessRights) {
			continue;
		}

		var accessRightGroupContainer = $('<div style="margin-top:10px;"></div>');
		accessRightsContainer.append(accessRightGroupContainer);

		var accessRightGroupHeading = $('<div style=margin-bottom:5px;>'
											+'<span class="glyphicon glyphicon-plus" style="font-size:0.9em"></span>'
											+'<span style="margin-left:4px; cursor:pointer;">'+ arGrp +'</span>'
											+'<input class="selectAllCheckbox"  type="checkbox" style="margin-left:5px; vertical-align: sub;" onClick="event.stopPropagation()" />'
										+'</div>');
		accessRightGroupContainer.append(accessRightGroupHeading)
		var selectAllCheckbox = accessRightGroupContainer.find(".selectAllCheckbox");

		selectAllCheckbox.change(function(){
			// Tick / untick all children
			var thisObj = $(this);
			var checked = thisObj.is(":checked");
			thisObj.parent().parent().find(".accessRight").prop("checked", checked);
		});

		accessRightGroupHeading.click(function(){
			var thisParent = $(this).parent();
			var accessRightsDiv = thisParent.find(".accessRightEntry");
			accessRightsDiv.toggle();

			var glyphicon = thisParent.find(".glyphicon");
			glyphicon.toggleClass("glyphicon-minus");

			if(arModal)
				arModal.adjustContentHeight();
		});

		var hasAllGroupAccessRights = true;
		for (var accessRight in accessRights) {

			if (accessRight <= 8) {
				continue;
			}

			var accessRightText = accessRights[accessRight];
			if (!accessRightText)
				continue;

			var accessRightContainer = $('<div class="accessRightEntry" id="AccessRightsContainer_' + accessRight + '"></div>');

			accessRightGroupContainer.append(accessRightContainer);

			accessRightContainer.hide();
			accessRightContainer.css("padding-left", "10px");

			var accessRightInt = parseInt(accessRight);

			var aID = 'accessRight_' + accessRight;
			var checked = "";

			if ( hasAccessRight( prefillAccessRights, accessRightInt)) {
				checked = 'checked = "checked"';
			}else{
				hasAllGroupAccessRights = false;
			}

			var accessRightCheckbox = $('<input class="accessRight" type="checkbox" ' + checked + ' id="' + aID + '" value="' + accessRight + '"/>');
			var accessRightLabel = $('<label style="margin-left:10px; cursor:pointer;" for="' + aID + '">' + accessRightText + '</label>');

			accessRightContainer.append(accessRightCheckbox);
			accessRightContainer.append(accessRightLabel);

			accessRightCheckbox.change(function(){
				var thisObj = $(this);
				var parentObj = thisObj.parent().parent();
				var allCheckBoxesChecked = parentObj.find(".accessRight:not(:checked)").length == 0;

				if(allCheckBoxesChecked){
					// Update select all checkbox
					parentObj.find(".selectAllCheckbox").prop("checked", true);
				}
				else{
					parentObj.find(".selectAllCheckbox").prop("checked", false);
				}
			});
		}

		if(hasAllGroupAccessRights) {
			// Tick the all checkbox
			selectAllCheckbox.prop("checked", true);
		}
	}

	var faCheck = fullAccessContainer.find("input");

	arModal = _arModal? _arModal : null;
	faCheck.change(function(){
		var faChecked = $(this).is(":checked");

		if(faChecked){
			accessRightsContainer.find(".accessRight").prop("checked", false);
			accessRightsContainer.hide();
		}else{
			accessRightsContainer.find(".accessRight").prop("checked", true);
			accessRightsContainer.show();
		}

		if(arModal)
			arModal.adjustContentHeight();
	});

	if( hasAccessRight(prefillAccessRights, AllAccessRightsDict["Full Access"] ) ){
		faCheck.prop("checked", true);
		faCheck.trigger("change");
	}

	var fullAccessToChildrenContainer = $('<div id="AccessRightsContainer_2">'
		+'<input class="accessRight" type="checkbox" id="accessRight_2" value="2">'
		+'<label style="margin-left:10px;" for="accessRight_2">Full Access To Children</label>'
		+'</div>');

	var facCheck = fullAccessToChildrenContainer.find("input");

	if( hasAccessRight(prefillAccessRights, AllAccessRightsDict["Full Access to Children"]) ){
		facCheck.prop("checked", true);
	}

	if ($scope) {
		if ($scope.$root.hasAccessRight("Full Access to Children")) {
			container.append(fullAccessToChildrenContainer);
		}
	} else {
		container.append(fullAccessToChildrenContainer);
	}
}

function getAccessRightGroup(accessRightStr){

	var accessRightGrp = {};
	for (var ar in AllAccessRights) {
		if (ar <= 8) {
			continue;
		}

		var accessRightText = AllAccessRights[ar];
		if(accessRightText.indexOf(accessRightStr) == 0){
			accessRightGrp[ar] = accessRightText;
		}
	}

	return accessRightGrp;
}

function getSelectedAccessRights(modalContainer)
{
	var selectedFields = modalContainer.find(".accessRight:checked");

	var formFieldVal = 0;
	for (var i = 0; i < selectedFields.length; i++) {
		var sf = selectedFields[i]
		formFieldVal += parseInt($(sf).val());
	};

	 return formFieldVal;
}

function getAccessRightsFromValue(accessRightsValue){
	var accessRights = {};

	for (var accessRight in AllAccessRights) {

		if(accessRight == 0){
			continue;
		}

		var accessRightText = AllAccessRights[accessRight];
		var accessRightInt = parseInt(accessRight);

		if( accessRightsValue && ( (accessRightInt & accessRightsValue) == accessRightInt) ){
			accessRights[accessRight] = accessRightText;
		}
	}
	return accessRights;
}

function getAllAccessRights(){
	var allAccessRights = AllAccessRightsDict["Full Access"] + AllAccessRightsDict["Full Access to Children"];
	return allAccessRights;
}

function _hasAccessRight(accessRights, checkAccessRightInt){
	var result = false;

	if( checkAccessRightInt && ( (checkAccessRightInt & accessRights) == checkAccessRightInt) ){
		result = true;
	}

	return result;
}

function hasAccessRight(accessRights, checkAccessRightInt){
	var result = false;
	
	if(typeof accessRights == "object"){
		// this must be a team object
		for(var t in accessRights){
			var accessRightsVal = accessRights[t];
			if( accessRightsVal & AllAccessRightsDict["Full Access"]   == AllAccessRightsDict["Full Access"] ){
				result = true;
				break;
			}
			else if (checkAccessRightInt && ( (checkAccessRightInt & accessRightsVal) == checkAccessRightInt)) {
				result = true;
				break;
			}
		}
	}
	else
	{
		if( accessRights & AllAccessRightsDict["Full Access"] == AllAccessRightsDict["Full Access"] ){
			result = true;
		}
		else if (checkAccessRightInt && ( (checkAccessRightInt & accessRights) == checkAccessRightInt)) {
			result = true;
		}
	}

	return result;
}

function getValueFromAccessRights(accessRights){
	var val = 0;
	for(var ar in accessRights) {
		val += parseInt(ar);
	}
	return val;
}

function getAdjustedAccessRights(parentAccessRights, childAccessRights){

	var childARObj = getAccessRightsFromValue(childAccessRights);
	// var parentARObj = getAccessRightsFromValue(parentAccessRights);

	var didAdjust = false;
	for(var ar in childARObj){
		var arInt = parseInt(ar);

		if( !hasAccessRight(parentAccessRights, arInt)) {
			delete childARObj[ar];
			didAdjust = true;
		}
	}

	// Calculate adjusted access rights
	return didAdjust ? getValueFromAccessRights(childARObj) : null;
}


function getCombinedAccessRightsValue(accessRightsArray){

    var combinedAccessRightsObj = {};
    var combinedAccessRightsVal = 0;
    for(var i=0; i< accessRightsArray.length; i++){
        var accessRightVal = accessRightsArray[i]
        var accessRightObj = getAccessRightsFromValue(accessRightVal);

        // Combine
        for (var j in accessRightObj) {
            if (!combinedAccessRightsObj.hasOwnProperty(j)) {
                combinedAccessRightsObj[j] = accessRightObj[j];
            }
        }
    }

    combinedAccessRightsVal = getValueFromAccessRights(combinedAccessRightsObj);
    return combinedAccessRightsVal;
}
