// To help connect non angular code with angular code
var currentScope = null;

var AssetTypes = {  "none" : 0,
    "image":1, "images" : 1,
    "video" : 2, "videos" : 2,
    "widget" : 3, "widgets" : 3,
    "template" : 4, "templates" : 4};

var DisplayStateCodes = {"NONE" : 0, "RUNNING" : 1, "SLEEPING" : 2, "STOPPED" : 3};

var ScheduleTypes = {"None" : 0, "Display" : 1, "DisplayGroup" : 2, "DisplayGroupOverride" : 3};

activ.controller("appController", ["$scope", "$location", "$http", "ngDialog", "$timeout",
    function($scope, $location, $http, ngDialog, $timeout){

    $scope.theYear = new Date().getFullYear();

    $scope.appRoot = config.appRoot;
    $scope.backend = config.backend;
    $scope.$root.backend = config.backend;
    $scope.$root.appRoot = config.appRoot;

    var userData = sessionStorage.getItem("userData");

    if(userData !== null && userData != undefined && userData != "undefined") {
        $scope.userInfo = JSON.parse(userData);
        $http.defaults.headers.common['Auth-Token'] = $scope.userInfo.token;
    } else {
        $scope.userInfo = {};
        $http.defaults.headers.common['Auth-Token'] = "";
    }

    $scope.home = function(){
        $location.path("/home");
    };

    $scope.logout = function() {
        var referrer = sessionStorage.getItem('referrer');
        var userData = sessionStorage.getItem("userData");
        sessionStorage.clear();
        config.authToken = null;
        if (referrer && referrer !== "false" && userData ) {
          window.location = referrer + '?action=logout';
        } else {
          $location.path("#/login");
        }
    };

    $scope.$root.getUserData = function(){
        var userData = null;
        if(sessionStorage.getItem("userData") != null) {
            userData = JSON.parse(sessionStorage.getItem("userData"));
        }
        return userData;
    };

    $scope.$root.getGroupData = function(){
        var groupData = null;
        if(sessionStorage.getItem("groupData") != null) {
            groupData = JSON.parse(sessionStorage.getItem("groupData"));
        }
        return groupData;
    };

    $scope.$root.getGroupFolder = function(){
        var groupFolder = null;
        var groupData = $scope.$root.getGroupData();
        if(groupData != null){
            groupFolder = groupData.groupFolder;
        }
        return groupFolder;
    };

    $scope.$root.getGroupName = function(){
        var groupName = null;
        var groupData = $scope.$root.getGroupData();
        if(groupData != null){
            groupName = groupData.name;
        }
        return groupName;
    };

    $scope.$root.loadGroupInfo = function() {
        $scope.$root.groupFolder = $scope.$root.getGroupFolder();
        $scope.$root.groupName = $scope.$root.getGroupName();
    };

    $scope.$root.loadUserInfo = function() {
        var userData =  $scope.$root.getUserData();
        $scope.$root.adminUser = userData.admin;
        $scope.$root.accessRights = userData.accessRights;
        $scope.$root.licenceLimits = (userData.licenceLimits) ? JSON.parse(userData.licenceLimits)
                                                              : {};
        $scope.$root.authToken = userData.token;
    };

    $scope.$root.hasAccessRight = function(accessRight){
          return hasAccessRight($scope.$root.accessRights, AllAccessRightsDict[accessRight]);
    };

    $scope.$root.checkPermission = function(limitKey, limitField, currentUsage) {
      if (this.$root.licenceLimits[limitKey]) {
        var access = this.$root.licenceLimits[limitKey];
        return (access[limitField] > 0) ? (currentUsage < access[limitField]) : true;
      }
    };

    $scope.checkLogin = function(){
		var href = window.location.href.toLowerCase()
		if( href.indexOf("login") != -1
			||  href.indexOf("contact") != -1
			||  href.indexOf("register") != -1
            ||  href.indexOf("forgotpassword") != -1){
				return;
		}
        var loggedIn = sessionStorage.getItem("loggedIn");
        if( !loggedIn) {
            $scope.logout();
        }else{
            $scope.$root.loadGroupInfo();
            $scope.$root.loadUserInfo();
        }
    };

    $scope.$on("$routeChangeSuccess", function(){
        $scope.checkLogin();
    });

    $scope.checkLogin();

    $scope.activAlert2 = function(message, time) {

        var messageBox = document.getElementById("activAlert");
        var messageEl = document.querySelector("#activAlert > p");
        messageEl.innerHTML = message;

        messageBox.classList.add("visible");

        setTimeout(function(){

            messageBox.classList.remove("visible");

        }, time);

    };

    $scope.alertDialog = null;
    $scope.alertDialogTimeout = null;

    $scope.activAlert = function(message, time)
    {
        if($scope.alertDialogTimeout){
            clearTimeout($scope.alertDialogTimeout);
        }

        if($scope.alertDialog) {
            $scope.alertDialog.close();
        }

        var messageTemplate = "<div class='ng-dialog-alertContent'>"+message+"</div>";

        $scope.alertDialog = ngDialog.open({
            template: messageTemplate,
            className: 'ngdialog-theme-default ngdialog-alertOverride',
            plain: true,
            showClose: false,
            scope: $scope
        });

        if(!time)
            time = 4000;

        setTimeout(function(){
            // Update position
            var dialogContainer = document.querySelector(".ngdialog-alertOverride");
            var dialogContent = document.querySelector(".ng-dialog-alertContent");

            // calculate padding
            var dialogHeight = dialogContent.offsetHeight;
            var windowHeight = window.innerHeight;

            var middle = windowHeight/2;
            var dialogHeightMiddle = middle - dialogHeight/2

            dialogContainer.style.paddingTop = dialogHeightMiddle - 20 + "px";
            dialogContainer.style.paddingBottom = "0px";
        }, 50);

        var alertDialog = $scope.alertDialog;
        window.setTimeout(function(){
            alertDialog.close();
        }, time);
    };

    $scope.$root.activAlert = $scope.activAlert;

    $scope.activPrompt = function(message, cb) {

        var messageBox = document.getElementById("activPrompt");
        var messageEl = document.querySelector("#activPrompt > p");
        var inputEl = document.querySelector("#activPrompt > input");
        var buttonEl = document.querySelector("#activPrompt > button");
        messageEl.innerHTML = message;
        inputEl.value = "";
        inputEl.focus();

        messageBox.classList.add("visible");

        var buttonClick = function() {

            buttonEl.removeEventListener("click", buttonClick);
            cb(inputEl.value.trim());
            messageBox.classList.remove("visible");

        };

        buttonEl.addEventListener("click", buttonClick);

    };

    $scope.activChoice  = function(message, buttons) {

        var messageBox = document.getElementById("activChoice");
        var messageEl = document.querySelector("#activChoice > p");
        var buttonContainer = document.querySelector("#activChoice .choices");

        buttonContainer.innerHTML = "";

        buttons.forEach(function(button){

            var buttonEl = document.createElement("button");
            buttonEl.innerHTML = button.label;

            buttonEl.addEventListener("click", function(){
                button.fn();
                messageBox.classList.remove("visible");
            });

            buttonContainer.appendChild(buttonEl);

        });

        messageEl.innerHTML = message;

        messageBox.classList.add("visible");

    };

    $scope.$root.$on( "$routeChangeSuccess", function(event, next, current) {

        var path = $location.path();
        if(path.indexOf("editor")!= -1){
            $scope.$root.isEditor = true;
        }
        else{
            $scope.$root.isEditor = false;
        }
    });



    $scope.initUploadFields = function(){
        $scope.List = [];
        $scope.Processed = [];
        $scope.Failed = [];
        $scope.selectedFiles = [];
        $scope.progress = [];
        $scope.upload = [];
        $scope.uploadResult = [];

    };

    $scope.initUploadFields();

    $scope.$root.showTHNTitles = true;

    $scope.$root.showBottomMenuToggle = function(){
        $(".homeNavigationTitleToggle").removeClass("hidden");
        var result = $(".homeNavigationContainer").is(":visible");
        return result;
    };

    $scope.$root.toggleTHNitles = function(){
        var homeNavigation = $(".homeNavigation");
        homeNavigation.find("span").toggle();
        $scope.$root.showTHNTitles = !$scope.$root.showTHNTitles;

        if($scope.$root.showTHNTitles)
            window.localStorage.removeItem("homeNavigationTitlesHidden");
        else
            window.localStorage.setItem("homeNavigationTitlesHidden", 1);

        $scope.$root.setContainerWidth();
    };

    $scope.$root.setContainerWidth = function(){
        var homeNavigation = $(".homeNavigation");
        if($scope.$root.showTHNTitles ){
            homeNavigation.removeClass("collapsed");
            $(".viewContainer").css("width", "calc(100% - 300px)");
        }
        else{
            homeNavigation.addClass("collapsed");
            $(".viewContainer").css("width", "calc(100% - 150px)");
        }
    }

    $scope.$root.updateModalHeight = function(modal, callback, delay){
        delay = delay || 10;
        $timeout(function(){
            if(modal) {
                modal.adjustContentHeight();
            }
            if (callback) {
                callback();
            }

        }, delay);
    };


    $scope.$root.confirmModal = null;
    // Utility methods
    $scope.$root.showActivConfirm = function(msg, callbackYes, title){

        if(!msg){
            msg = "Are you sure?";
        }
        if(!title){
            title = "Confirm";
        }

        var templateHtml = $("#confirmModalTemplate").html();
        $scope.$root.confirmModal = new ModalClass({title : title, removeOnHide : true, "onBeforeHide" : function(modal){
            $scope.$root.confirmModal.visible = false;
        }, "body" : templateHtml, buttons : {
                        "submit" : {title : "Yes"},
                        "close" : {title : "No"} },
            onSaveClick : function(){
                if(callbackYes){
                    callbackYes($scope.$root.confirmModal);
                }
            }
        });

        // Fill info
        $scope.$root.confirmModal.find(".confirmMessage").html(msg);
        $scope.$root.confirmModal.visible = true;

        $scope.$root.confirmModal.adjustContentHeight();
    };

    $scope.$root.hideActivConfirm = function(){
        if($scope.$root.confirmModal && $scope.$root.confirmModal.visible){
            $scope.$root.confirmModal.hide();
        }
    };

    $scope.$root.setActivConfirmStatus = function(status, color){
        scope.$root.confirmModal.setStatusText(status, color);
    }
}]);
