activ.controller("login", ["$scope", "$http", "$document", "$location", function($scope, $http, $document, $location){

    $scope.initErrors = function(){
        $scope.loggingIn = false;
        $scope.errors = [];
    }

    $scope.version = version;

    $scope.username = "";
    $scope.password = "";

    var authToken;
    var queryStrings = window.location.search.substr(1).split("&");

    queryStrings.forEach(function(queryString) {
      if (queryString.indexOf("token=") === 0) {
        authToken = queryString.substr(6);
      }
    });
    // Test for session
    var userData = sessionStorage.getItem("userData");

    if (authToken && !userData) {
      sessionStorage.setItem("referrer", document.referrer);
      $http.defaults.headers.common['Auth-Token'] = authToken;

      $http({ "method": "POST", "url": $scope.$root.backend + "/validateUserToken", "params": {}, "data": {} }).
          success(function(data, status){

              if(data.success) {
                  window.location.search ="";
                  doLogin(data.userData, data.groupData)
              } else {
                  $scope.errors = data.errors ? data.errors : [data.error];
              }
          }).
          error(function (data, status) {
              $scope.errors = ["Server Error"];
              $scope.loggingIn = false;
          });
    }

    $scope.showContact = function(){
        $location.path('/contact');
    };


    if(userData !== null){
        $http({ "method": "POST", "url": $scope.$parent.backend + "/checkUserToken", "params": {}, "data": {} }).
            success(function(data, status){

                if(data == "VALID_TOKEN") {
                    $location.path("/home");
                }

            }).
            error(function(data, status){
                // Do nothing, User has to login to get a valid session
            });
    }

    $scope.login = function() {

        // Add extra checks to avoid AJAX calls
        if($scope.loggingIn){
            // avoid double post
            return;
        }

        $scope.initErrors();

        var basicErrors = false;
        $scope.username = $scope.username.trim();

        if($scope.username.length == 0) {
            $scope.errors.push("Invalid Username");
            basicErrors = true;
        }

        if(!$scope.password || $scope.password.trim() === "") {
            $scope.errors.push("Invalid Password");
            basicErrors = true;
        }

        // If no errors at this point, AJAX call to login backend
        if (basicErrors) {
        } else {
            var loginData = {"username": ($scope.username || ""), "password": ($scope.password || "")};
            $scope.loggingIn = true;

            $http({
                "method": "POST",
                "url": $scope.$parent.backend + "/" + config.endpoints.login,
                "params": {},
                "data": loginData
            }).
                success(function (data, status) {
                    // Test for success / failure conditions
                    $scope.loggingIn = false;
                    if (data.success) {
                        doLogin(data.userData, data.groupData)
                    }
                    else {
                        $scope.errors = data.errors ? data.errors : [data.error];
                    }
                }).
                error(function (data, status) {
                    $scope.errors = ["Server Error"];
                    $scope.loggingIn = false;
                });
        }
    };

    var doLogin = function(userData, groupData) {
        // DO we have valid access rights?
        if(userData.accessRights == 0){
            $scope.errors = ["You do not have valid access rights. Please contact your Company Administrator."];
        }else {
            sessionStorage.setItem("userData", JSON.stringify(userData));
            sessionStorage.setItem("groupData", JSON.stringify(groupData));
            sessionStorage.setItem("loggedIn", true);
            sessionStorage.setItem("Auth-Token", true);

            $scope.$parent.userInfo = userData;

            $scope.$root.groupFolder = $scope.$root.getGroupFolder();
            $scope.$root.adminUser = userData.admin;
            $scope.$root.authToken = $scope.$parent.userInfo.token;

            // Set for global access
            config.authToken = $scope.$root.authToken;

            $http.defaults.headers.common['Auth-Token'] = $scope.$parent.userInfo.token;
            $location.path("/home");
        }
    };


    $scope.forgotPassword = function(){

        var entityTemplate = $("#forgotPasswordTemplate").html();
        // Append a new container to the body and load dialog
        $scope.modalContainer = new ModalClass({title : "Forgot Password", "body" : entityTemplate});
        // hook into the save button

        $scope.modalContainer.primaryBtn.html("Submit");
        $scope.modalContainer.primaryBtn.click(function(){

        // Get data back from the modal
        $scope.modalContainer.resetStatus();

        var usernameInput = $scope.modalContainer.find(".username");
        var emailInput = $scope.modalContainer.find(".email");

        var usernameVal = $.trim(usernameInput.val());
        var emailVal = $.trim(emailInput.val());

        if(!Validations.checkEmpty(emailInput, $scope.modalContainer)){0
            return;
        }

        if(!Validations.checkEmail(emailInput, $scope.modalContainer, false)){
            $scope.modalContainer.setErrorText("Invalid Email.");
            emailInput.focus();
            return;
        }

        // All Good, post
        var postData = {
            action: "forgotPassword",
            email : emailVal
        };

        $scope.modalContainer.setStatusText("Submitting Request. Please wait.");
        $scope.modalContainer.primaryBtn.prop("disabled", true);

        $http({
                method: "POST", url: $scope.$parent.backend + "/forgotPassword", "params": {},
                "data": postData
            })
            .success(function (data, status){
                $scope.modalContainer.resetStatus();
                if(data.success){
                    $scope.modalContainer.find("#forgotPassForm").hide();
                    $scope.modalContainer.find("#forgotPassResult").show();
                    $scope.modalContainer.primaryBtn.hide();
                }else{
                    $scope.modalContainer.setStatusText("Error : " + data.error, "red");
                    $scope.modalContainer.primaryBtn.prop("disabled", false);
                }
            })
            .error(function (data) {
                $scope.modalContainer.primaryBtn.prop("disabled", false);
                $scope.modalContainer.setErrorText("Error : " + data.message);
            });

        });
    };

}]);
