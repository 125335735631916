/**
 *  Created by Dr Raj Curwen on 10/10/14.
 */


/*
    Drag Functions for the Timeline.

    I've copied these functions here - they will need refactoring and sorting out
    this is just stage one of the clear up!
*/

function resizeGroup ($scope, parentID) {

    var startTime = null;
    var endTime = null;

    timeline.$scope.layers.forEach(function (_layer) {

        if (_layer.parentGroupID === parentID) {

            if (startTime === null) {
                startTime = _layer.startTime;
            }

            if (endTime === null) {
                endTime = _layer.endTime;
            }

            if (startTime >= _layer.startTime) {
                startTime = _layer.startTime;
            }

            if (endTime <= _layer.endTime) {
                endTime = _layer.endTime;
            }

        }

    });

    var timeSpan = document.querySelector("li[data-layerid='" + parentID + "'] .timeSpan");
    var timeSpanLength = ((endTime - startTime) / 1000) * timeline.$scope.oneSecond;
    timeSpan.style.width = (timeSpanLength * timeline.$scope.timeLineZoom) + "px";
    timeSpan.style.left = timeline.$scope.oneSecond * (startTime / 1000) + (timeline.$scope.oneSecond / 2) + "px";

}

var timelineDragManager = {

    timeSpanDragStartX: 0,
    timeSpanDragStartY: 0,
    timeSpanStartLayerID: null,
    timeSpanEndLayerID: null,
    timeSpanResizeEl: null,
    dragTimer: null,
    mouseEventRHSTimeline : null,

    _layer: null,
    timeSpan: null,
    $scope : null,
    dragMode : null,
    timeSpan : null,

    initialize: function($_scope, layer, timespan) {
        this.$scope = $_scope;

        timespan.addEventListener("mousedown", timelineDragManager.onMouseDown);
        window.addEventListener("mouseup", timelineDragManager.onMouseUp);

        /*
        var jObj = $(timespan);
        jObj.draggable({
            containment: "#timeLineRight",
            appendTo: "#timeLineRight",
            axis:"x",
            scroll:true,
            drag:function(event, ui){

                var currentPosition = ui.position;
                var originalPosition = ui.originalPosition;

                var movingLeft = originalPosition.left - currentPosition.left >= 0;
                var movingRight = !movingLeft;

                var timeLineRight = $("#timeLineRight");

                var timeLineRightWidth = timeLineRight.width();
                var timeLineRightScrollWidth = timeLineRight[0].scrollWidth;

                var timeLineRightScrollLeft = timeLineRight.scrollLeft();
                var timeLineRightMaxScrollLeft = timeLineRightScrollWidth - timeLineRightWidth;

                // check scroll positions, if we're close to left/right edge with space to space, scroll dynamically

                var currentTimespan = ui.helper;
                var currentTimespanWidth = currentTimespan.width();

                var currentTimespanLeft = currentTimespan.position().left;

                // do we need to manually scroll ?
                var timespanRightEdge = currentTimespanWidth + currentTimespanLeft; // + timeLineRightScrollLeft;

                var newScrollPos = -1;

                if( movingRight && timespanRightEdge > (timeLineRightWidth +  timeLineRightScrollLeft)){
                    // we gotta scroll right
                    newScrollPos = Math.min(timeLineRightScrollLeft + 100, timeLineRightMaxScrollLeft);
                }
                else if( movingLeft && timeLineRightScrollLeft > 0 && currentTimespanLeft <=  timeLineRightScrollLeft){
                    // we gotta scroll left
                    newScrollPos = Math.max(timeLineRightScrollLeft - 100, 0);
                }

                if( newScrollPos != -1) {
                    timeLineRight.stop(true).animate({scrollLeft:newScrollPos}, 100);
                }

            }

        });
        */
    },

    onMouseDown: function(e){

        e.preventDefault();
        e.stopPropagation();

        // broadcast the undo
        timeline.$scope.$emit('modelUpdated', {
            source: 'timelineModified',
            data: JSON.stringify(timeline.getLayers(null))
        });

        timelineDragManager.timeSpan = e.target;
        timelineDragManager._layer = timeline.getItemByElement(timelineDragManager.timeSpan);

        if(timelineDragManager._layer.locked)
            return;

        // AY TEST FOR HIGHLIGHTING
        // Deselect any other timeline spans
        var previousSelected =  document.querySelectorAll(".timeSpan.selected");
        for(var i=0; i< previousSelected.length; i++)
        {
            var tSpan = previousSelected[i];
            tSpan.classList.remove("selected");
        }
        timelineDragManager.timeSpan.classList.add("selected");

        timelineDragManager._layer.originalStartTime = timelineDragManager._layer.startTime;
        timelineDragManager._layer.originalEndTime = timelineDragManager._layer.endTime;

        timelineDragManager.dragMode = "move";
        timelineDragManager.dragTimer = null;
        // Dont attach any mousemove events if the layer is locked
        if(!timelineDragManager._layer.locked && !timelineDragManager._layer.alwaysOn)
        {
            timelineDragManager.dragTimer = setTimeout(function () {

                timelineDragManager.timeSpanDragStartX = parseInt(e.pageX, 10);
                timelineDragManager.timeSpanDragStartY = parseInt(e.pageY, 10);
                timelineDragManager.timeSpanStartLayerID = e.target.parentNode.getAttribute("data-layerid");

                window.addEventListener("mousemove", timelineDragManager.timeSpanMove);

            }, 250);
        }
    },

    onMouseUp : function(e){
        if (timelineDragManager.dragTimer) {
            clearTimeout(timelineDragManager.dragTimer);
        }

        window.removeEventListener("mousemove", timelineDragManager.timeSpanMove);
        window.removeEventListener("mousemove", timelineDragManager.timeSpanResize);
    },

    handleMouseDown : function(e) {

        e.preventDefault();
        e.stopPropagation();

        timelineDragManager.dragMode = "resize";
        timelineDragManager.timeSpan = e.target.parentNode;
        timelineDragManager._layer = timeline.getItemByElement(timelineDragManager.timeSpan);

        //  RKC we should select the layer - but it stops the resize!
        //  _layer.select(e);

        // AY TEST FOR HIGHLIGHTING
        // Deselect any other timeline spans
        var previousSelected =  document.querySelectorAll(".timeSpan.selected");
        for(var i=0; i< previousSelected.length; i++)
        {
            var tSpan = previousSelected[i];
            tSpan.classList.remove("selected");
        }
        timelineDragManager.timeSpan.classList.add("selected");

        timelineDragManager.timeSpanDragStartX = parseInt(e.pageX, 10);
        timelineDragManager.timeSpanResizeEl = e.target;

        timelineDragManager._layer.originalStartTime = timelineDragManager._layer.startTime;
        timelineDragManager._layer.originalEndTime = timelineDragManager._layer.endTime;

        timelineDragManager.dragTimer = null;

        // Dont listen to any mousemove if the layer is locked
        if(!timelineDragManager._layer.locked && !timelineDragManager._layer.alwaysOn) {
            // broadcast the undo
            timeline.$scope.$emit('modelUpdated', {
                source: 'timelineModified',
                data: JSON.stringify(timeline.getLayers(null))
            });

            window.addEventListener("mousemove", timelineDragManager.timeSpanResize);
        }
    },

    /*
     Timespan drag events
     */

    timeSpanMove: function (e) {

        if (!timelineDragManager._layer.locked) {

            var w = parseInt(timelineDragManager.timeSpan.style.width, 10);

            var l = parseInt(timelineDragManager.timeSpan.style.left, 10);

            var moveDistance = (e.pageX - timelineDragManager.timeSpanDragStartX);

            var absDistance = Math.abs( moveDistance );

            // Snap one second ticks or the rounded off calcs will fail
            if(absDistance < timeline.$scope.oneSecond){
                //console.log("AD : " + absDistance + " OS : " +  timeline.$scope.oneSecond);
                return;
            }

            l = l + moveDistance;

            var oldStartTime = timelineDragManager._layer.startTime;
            var oldEndTime = timelineDragManager._layer.endTime;
            var oldDuration = timelineDragManager._layer.duration;


            // Validate and apply new styles/time to element
            var result = timeline.rhsTimeline.applyStartEndFromTimelineDOM(timelineDragManager._layer,
                timelineDragManager.timeSpan, {left : l, width: w}, "move");

            if(!result) {
                return;
            }

            var newStartTime = timelineDragManager._layer.startTime;
            var newEndTime = timelineDragManager._layer.endTime;

            // Check here if we're a container or a child layer
            if( timelineDragManager._layer.hasChildren()){
                // This is a container
                // Shift the elements with the offset
                var timeGap = oldStartTime - newStartTime;
                for(var i=0; i< timelineDragManager._layer.children.length; i++){
                    var child = timelineDragManager._layer.children[i];
                    child.startTime = child.startTime - timeGap;

                    if (child.startTime < 0) {
                        child.startTime = 0;
                    }

                    child.endTime = child.endTime - timeGap;
                    child.duration = child.endTime - child.startTime;
                }
            }

            timelineDragManager._layer.startTime = newStartTime
            timelineDragManager._layer.endTime = newEndTime;

            timelineDragManager._layer.duration = timelineDragManager._layer.endTime - timelineDragManager._layer.startTime;

            timeline.$scope.$apply();

            timelineDragManager.timeSpanDragStartX = e.pageX;

            if (timelineDragManager._layer.parentGroupID) {
                resizeGroup(timeline.$scope, timelineDragManager._layer.parentGroupID);
            }

            // Only attach for valid cases
            window.addEventListener("mouseup", timelineDragManager.timeSpanEditEnd);
        }

    },

    timeSpanResize: function (e) {

        if (!timelineDragManager._layer.locked) {

            var w = parseInt(timelineDragManager.timeSpan.style.width, 10);
            var l = parseInt(timelineDragManager.timeSpan.style.left, 10);

            var resizeHandle =  timelineDragManager.timeSpanResizeEl.getAttribute("resize-handle");
            var moveDistance = (e.pageX - timelineDragManager.timeSpanDragStartX);

            var absDistance = Math.abs( moveDistance );

            // Snap one second ticks or the rounded off calcs will fail
            if(absDistance < timeline.$scope.oneSecond){
                return;
            }

            if(resizeHandle == "left"){
                l = l + moveDistance;
                w = w - moveDistance;   // Add to width, as move distance will be negative
            }else{
                w = w + moveDistance;   // Just update width
            }

            //console.log("l : " + l + ", w : " + w);

            if(w <= 0){
                // We need to swap
                // flip handle
                if(resizeHandle == "left") {
                    l = parseInt(timelineDragManager._layer.endTime / 1000 ) * timeline.$scope.oneSecond;
                    timelineDragManager.timeSpanResizeEl = $(timelineDragManager.timeSpan).find(".timeSpanHandleRight")[0];
                }
                else{
                    l = (parseInt(timelineDragManager._layer.startTime / 1000 ) * timeline.$scope.oneSecond ) - timeline.$scope.oneSecond;
                    timelineDragManager.timeSpanResizeEl = $(timelineDragManager.timeSpan).find(".timeSpanHandleLeft")[0];
                }

                w = timeline.$scope.oneSecond;

                //console.log("UPDATED : l : " + l + ", w : " + w + " OS : " + timeline.$scope.oneSecond);
            }

            // Validate and apply new styles/time to element
            var result = timeline.rhsTimeline.applyStartEndFromTimelineDOM(timelineDragManager._layer,
                timelineDragManager.timeSpan, {left : l, width: w});

            // Update position for a valid resize
            if(result) {
                //console.log("VALID : Start : " + timelineDragManager._layer.startTime + ", End : " + timelineDragManager._layer.endTime);
                timelineDragManager.timeSpanDragStartX = e.pageX;
                timeline.$scope.$apply();
                window.addEventListener("mouseup", timelineDragManager.timeSpanEditEnd);
            }
        }
    },

    timeSpanEditEnd: function (e) {
        // Detach all events
        window.removeEventListener("mousemove", timelineDragManager.timeSpanResize);
        window.removeEventListener("mousemove", timelineDragManager.timeSpanMove);
        window.removeEventListener("mouseup", timelineDragManager.timeSpanEditEnd);

        var finalStartTime = timelineDragManager._layer.startTime;
        var finalEndTime = timelineDragManager._layer.endTime;
        timelineDragManager._layer.timeSpanEditEnd(finalStartTime, finalEndTime);

        // Re update in case of flips
        finalStartTime = timelineDragManager._layer.startTime;
        finalEndTime = timelineDragManager._layer.endTime;

        if(timelineDragManager._layer.parent == null)
        {
            // THis is the single element or a container element ( Only top level elements have no parent )
            timelineDragManager._layer.startTime = finalStartTime;
            timelineDragManager._layer.endTime = finalEndTime;

            timelineDragManager._layer.duration = timelineDragManager._layer.endTime - timelineDragManager._layer.startTime;
            timelineDragManager.timeSpanDragStartX = e.pageX;

            if(timelineDragManager._layer.hasChildren()) {
                    // Recalculate the parent start / end times if applicable
                    timelineDragManager._layer.containerFixStartEnd();
            }
        }
        else
        {
            // This is a child later
            // Recalculate the parent start / end times if applicable

            timelineDragManager._layer.parent.containerFixStartEnd();

            timelineDragManager._layer.parent.refreshCanvasObject();
        }

        timeline.$scope.changesMade = true;
        timeline.setProjectLength();
        timelineDragManager._layer.updateCollapsed();
    }
};