activ.controller("register", ["$scope", "$http", "$location", function($scope, $http, $location){

    $scope.pageTitle = "Register";
    $scope.userObject = {};
    $scope.companyExists = null;
    $scope.companyChosen = "";
    $scope.pendingGroup = true;
    $scope.groupID = null;
    $scope.view = "all";
    $scope.foundCompanyName = null;

    var _doLogin = function(userData) {

        sessionStorage.setItem("userData", JSON.stringify(userData));

        $scope.$parent.userInfo = userData;
        $http.defaults.headers.common['Auth-Token'] = $scope.$parent.userInfo.token;

        $location.path("/home");

    };

    var doLogin = function(userData, groupData) {
        sessionStorage.setItem("userData", JSON.stringify(userData));
        sessionStorage.setItem("groupData", JSON.stringify(groupData));
        sessionStorage.setItem("loggedIn", true);
        $scope.$parent.userInfo = userData;
        $scope.$root.groupFolder = $scope.$root.getGroupFolder();

        $http.defaults.headers.common['Auth-Token'] = $scope.$parent.userInfo.token;
        $location.path("/home");
    };

    $scope.checkCompanyTimeout = null;
    $scope.checkingCompany = false;

    $scope.checkCompanyAfterDelay = function(){
        if($scope.checkCompanyTimeout){
            clearTimeout($scope.checkCompanyTimeout);
            $scope.checkCompanyTimeout = null;
        }

        $scope.checkCompanyTimeout = setTimeout($scope.checkCompany, 600);
    };

    $scope.checkCompany = function() {

        if($scope.checkingCompany){
            $scope.checkCompanyAfterDelay();
            return;
        }

        if( $scope.foundCompanyName && $scope.foundCompanyName.toLowerCase() == $scope.userObject.groupName.toLowerCase() ){
            $scope.userObject.groupName = $scope.foundCompanyName;
            $scope.$apply();
            return;
        }

        // Reset
        $scope.userObject.groupID = 0;
        $scope.companyExists = null;
        $scope.foundCompanyName = null;

        if($scope.userObject.groupName) {
            $scope.userObject.groupName = $.trim($scope.userObject.groupName);

            $http({ method: "POST", url: $scope.$parent.backend + "/checkCompany",
                "params": {}, "data": { "groupName":  $scope.userObject.groupName } })
                .success(function (data, status) {
                    $scope.checkingCompany = false;
                    if (data.success) {
                        if (data.exists) {
                            // Update existing data
                            $scope.companyExists = true;
                            $scope.userObject.groupID = data.groupID;
                            $scope.foundCompanyName = data.groupName;
                            $scope.userObject.groupName = data.groupName;
                        }
                        else{
                            $scope.foundCompanyName = null;
                            // do nothing, this will be a new company, and the user made admin
                            $scope.companyExists = false;
                        }
                    }
                })
                .error(function (data, success) {
                    $scope.checkingCompany = false;
                    $scope.foundCompanyName = null;
                    console.error(data);
                });
        }
    };

    $scope.createUser = function(){

        var updateData = {
            username: $scope.userObject.username,
            firstname: $scope.userObject.firstname,
            surname: $scope.userObject.surname,
            email: $scope.userObject.email,
            avatar: $scope.userObject.avatar || "",
            password: $scope.userObject.password,
            groupId : $scope.userObject.groupID,
            groupName : $scope.userObject.groupName
        };

        $http({ method: "POST", url: $scope.$parent.backend + "/createProfile", "params": {},
            "data": { "userData":updateData  } })
            .success(function (data, status) {
                if (data.success) {
                    var loginData = { "username": $scope.userObject.username, "password": $scope.userObject.password };
                    $http({ "method": "POST", "url": $scope.$parent.backend + "/login", "params": {}, "data": loginData } ).
                        success(function(data, status) {
                            if(data.success){
                                doLogin(data.userData, data.groupData);
                            }
                            else{
                                $scope.errors = [data.error];
                            }
                        }).
                        error(function(data, status) {
                            $location.path("/login")
                        });
                }
                else{
                    $scope.message = data.error;
                }
            })
            .error(function (data) {
                $scope.message = data;
                console.error(data);
                //alert("error")
            });
    };

    $scope.validate = function(){

        if (!$scope.userObject.groupName){
            $scope.message = "Company required";
            return false;
        }

        if (!$scope.userObject.username){
            $scope.message = "Username required";
            return false;
        }

        if (!$scope.userObject.firstname){
            $scope.message = "First name required";
            return false;
        }

        if (!$scope.userObject.surname){
            $scope.message = "Surname required";
            return false;
        }

        if (!$scope.userObject.email){
            $scope.message = "Email required";
            return false;
        }

        if (!$scope.userObject.password){
            $scope.message = "Password required";
            return false;
        }

        if (!$scope.userObject.passwordConfirm){
            $scope.message = "Confirm password required";
            return false;
        }

        if ($scope.userObject.password !== $scope.userObject.passwordConfirm){
            $scope.message = "Passwords must match";
            return false;
        }

        return true;
    };

    $scope.editAvatar = function(){
        document.getElementById("fileAvatar").click();
    };

    $scope.onFileSelect = function(){
        var elems = angular.element(document.querySelector("#fileAvatar"));
        if (elems.length > 0 && elems[0].files.length > 0){
            var file = elems[0].files[0];

            var fileReader = new FileReader();

            fileReader.onload = function(fileLoadedEvent)
            {
                $scope.userObject.avatar = fileLoadedEvent.target.result;
                $scope.$apply();
            };

            fileReader.readAsDataURL(file);        }
    };

}]);