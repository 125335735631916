activ.directive('displayDownCharts', function($http) {
    return {
        replace: true,
        restrict: 'E',
        templateUrl: "./views/displayDownCharts.html?v=" + version,
        scope: true,
        link: function(scope, element, attrs) {

            scope.displaysData = [];
            scope.chartData = [];
            scope.currentDisplay = {
                id: null
            };

            var shortEnglishHumanizer = humanizeDuration.humanizer({
                language: 'shortEn',
                languages: {
                    shortEn: {
                        y: function() { return 'y' },
                        mo: function() { return 'mo' },
                        w: function() { return 'w' },
                        d: function() { return 'd' },
                        h: function() { return 'h' },
                        m: function() { return 'm' },
                        s: function() { return 's' },
                        ms: function() { return 'ms' },
                    }
                }
            })

            var units = null;

            scope.options = {
                chart: {
                    type: 'discreteBarChart',
                    height: 500,
                    width: 500,
                    x: function(d){return d.key;},
                    y: function(d){return d.value;},
                    showLabels: false,
                    duration: 100,
                    labelThreshold: 0.01,
                    labelSunbeamLayout: true,
                    legend: {
                        margin: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0
                        },
                    },
                    showValues: false,
                    showYAxis: true,
                    showXAxis: true,
                    showLegend: false,
                    color: function(d,i){
                        return d.color;
                    },
                    xAxis: {
                        tickPadding: 10,
                        // tickPadding: 100
                    },
                    yAxis: {
                        tickFormat: function (n) {
                            if (n > 0 && units == null) {
                                if (n < 60) {
                                    units = ['m'];
                                } else if (n < 24*60) {
                                    units = ['h'];
                                } else if (n < 7*24*60) {
                                    units = ['d'];
                                } else if (n < 30*24*60) {
                                    units = ['w'];
                                } else if (n < 12*30*24*60) {
                                    units = ['mo'];
                                } else {
                                    units = ['y'];
                                }
                            }
                            if (n <= 0) {
                                return "";
                            }
                           return shortEnglishHumanizer(n*60*1000, {largest: 1, round: true, units: units})
                        },
                        showMaxMin: false,
                        ticks: 3
                    },
                    discretebar: {
                        margin: {
                            left: 0,
                            right: 150
                        }
                    },
                    tooltip: {
                        contentGenerator: function (e) {
                            var series = e.series[0];
                            return series.key + " " + humanizeDuration(series.value*60*1000, {language: 'en'});
                        }
                    }
                }
            };

            $http({ "method": "GET", "url": scope.$parent.backend + "/displaysDown", "params": {}, "data": {} }).
                success(function (data) {
                    var resultData = [{
                        display: {
                            ID: -1,
                            boxTitle: "All displays",
                        },
                        allWorkTime: 0,
                        downTime: 0,
                    }].concat(data);

                    var totalTime = 0, downTime = 0;

                    for (var i=0; i < data.length; i++) {
                        totalTime += parseInt(data[i].allWorkTime);
                        downTime += parseInt(data[i].downTime);
                    }

                    resultData[0].allWorkTime = totalTime;
                    resultData[0].downTime = downTime;

                    scope.displaysData = resultData;

                    if (resultData[0] !== undefined) {
                        scope.currentDisplay.id = parseInt(resultData[0].display.ID);
                        scope.currentDisplay.boxTitle = resultData[0].display.boxTitle;
                    }
                    scope.buildChartsData();
                });

            scope.$watch('currentDisplay', function () {
                scope.buildChartsData();
            }, true);

            scope.buildChartsData = function () {
                scope.chartData = [];
                angular.forEach(scope.displaysData, function (row) {
                    if (row.display.ID == scope.currentDisplay.id) {
                        scope.currentDisplay.boxTitle = row.display.boxTitle;
                        units = null;
                        scope.chartData = [{
                            key: "Test",
                            values: [
                                {key: "Up Time", value: row.allWorkTime - row.downTime, color: "#86c63f"},
                                {key: "Down Time", value: row.downTime, color: "#c63f3f"}
                            ]
                        }];
                    }
                });
            };
        }
    };
});
