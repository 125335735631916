activ.directive("displaySchedule", function(){

    return {
        restrict: "E",
        replace: true,
        templateUrl: "./views/displaySchedule.html?v=" + version,
        scope: {
            schedules: "=schedules",
            projects: "=projects",
            display : "=display",
            displayGroup : "=displayGroup"
        },

        link: function($scope, element, attr){

            // One hour = 80px width @ 100% zoom
            $scope.oneHour = 80 * $scope.zoom;

            //$scope.scheduleTimeSpan = new ScheduleTimeSpan()

            $scope.topZ = 2000;                                                            // Upper Z-Index value for selected timespans
            $scope.bottomZ = 1000;                                                         // Default Z-Index for timespans

            $scope.containerElement = element;

            $scope.loadDOM = function() {
                this.schedulesContainer = this.containerElement; // $("#schedule_" + this.display._id);
                this.dayRows = $(".day",  this.schedulesContainer);  // document.querySelectorAll(".scheduleWeek .day");              // Nodelist containing the row for each day
                this.rightcontainer = this.schedulesContainer.find(".scheduleRight"); //document.querySelector(".scheduleRight");              // Right container
                this.containerDivs = this.schedulesContainer.find(".scContainer"); //document.querySelectorAll(".scheduleRight > div");      // Nodelist containing day rows + time
                this.timeMarkers =  this.schedulesContainer.find(".timesP"); //$("#scTimes_" + this.display._id + " p"); // document.querySelectorAll(".scheduleRight div.times p");  // Nodelist containing times (top row), used for scaling when zooming
            };

             $scope.update = function() {

                 var parentCon = this.containerElement;
                 this.loadDOM();

                 this.dayRows.html("");
                 this.dayRows.css("background-size", this.oneHour + "px 60px");
                 this.containerDivs.css("width", ((this.oneHour * 25) - 1 + "px"));
                 this.timeMarkers.css("width",(80 * this.zoom + "px"));
                 this.timeMarkers.css("font-size", (1 * this.zoom > 1 ? 1 : 1 * this.zoom) + "em");
                 this.timeMarkers.css("padding", "0px " + this.zoom * 20 + "px");


                 // Update all schedule items as well..
                 if(!$scope.schedules)
                     $scope.schedules = [];

                 $scope.scheduleObj = new DisplayScheduleClass($scope.schedules, $scope);

                 $scope.scheduleObj.render();
            };

             $scope.checkClashes = function(t) {

                var clash = false;
                var clashDays = [];
                var clashStartTime = false;
                var clashEndTime = false;
                var start = this.parseTime(t.startTime);
                var end = this.parseTime(t.endTime);

                for (var i = 0; i < this.schedules.length; i++)
                {
                    var _schedule = this.schedules[i];

                    for (var j = 0; j < _schedule.timeSpans.length; j++) {
                        var _timespan = _schedule.timeSpans[j];

                        var dayMatch = false;

                        if (t.timeSpanID !== _timespan.timeSpanID) {

                            t.days.forEach(function (day) {

                                if (_timespan.days.indexOf(day) !== -1) {

                                    dayMatch = true;

                                }

                            });

                            if (dayMatch) {

                                var tsStart = this.parseTime(_timespan.startTime);
                                var tsEnd = this.parseTime(_timespan.endTime);

                                if (start.timestamp >= tsStart.timestamp && end.timestamp <= tsEnd.timestamp) {

                                    clash = true;
                                    clashEndTime = true;
                                    clashStartTime = true;

                                    t.days.forEach(function (day) {

                                        if (_timespan.days.indexOf(day) !== -1 && clashDays.indexOf(day) === -1) {

                                            clashDays.push(day);

                                        }

                                    });


                                } else if ((start.timestamp >= tsStart.timestamp && start.timestamp <= tsEnd.timestamp)
                                    || (start.timestamp < 0 || start.timestamp > 86400000)) {

                                    clash = true;
                                    clashStartTime = true;
                                    clashDays = _timespan.days;

                                    t.days.forEach(function (day) {

                                        if (_timespan.days.indexOf(day) !== -1 && clashDays.indexOf(day) === -1) {

                                            clashDays.push(day);

                                        }

                                    });

                                } else if ((end.timestamp >= tsStart.timestamp && end.timestamp <= tsEnd.timestamp)
                                    || (end.timestamp < 0 || end.timestamp > 86400000)) {

                                    clash = true;
                                    clashEndTime = true;
                                    clashDays = _timespan.days;

                                    t.days.forEach(function (day) {

                                        if (_timespan.days.indexOf(day) !== -1 && clashDays.indexOf(day) === -1) {

                                            clashDays.push(day);

                                        }

                                    });

                                } else if (start.timestamp <= tsStart.timestamp && end.timestamp >= tsEnd.timestamp) {

                                    clash = true;
                                    clashEndTime = true;
                                    clashStartTime = true;
                                    clashDays = _timespan.days;

                                    t.days.forEach(function (day) {

                                        if (_timespan.days.indexOf(day) !== -1 && clashDays.indexOf(day) === -1) {

                                            clashDays.push(day);

                                        }

                                    });

                                }

                            }

                        }
                    }

                }
                return {
                    isClash: clash,
                    clashDays: clashDays,
                    clashStartTime: clashStartTime,
                    clashEndTime: clashEndTime
                }

            };

            $scope.updateTimeSpanModel = function() {

                for (var i = 0; i < this.schedules.length; i++) {
                    var _schedule = this.schedules[i];


                    for (var j = 0; j < _schedule.timeSpans.length; j++) {
                        var _timespan = _schedule.timeSpans[j];

                        if(_timespan.timeSpanID === this.$root.currentTimeSpan.timeSpanID) {
                            _timespan.startTime = this.$root.currentTimeSpan.startTime;
                            _timespan.endTime = this.$root.currentTimeSpan.endTime;
                            _timespan.projectID = this.$root.currentTimeSpan.projectID;
                            _timespan.days = this.$root.currentTimeSpan.days;
                            _timespan.colour = this.$root.currentTimeSpan.colour;
                        }
                    }
                }
            };

            $scope.update();

        },
        controller: ['$scope', function($scope)
        {
            if($scope.displayGroup)
            {
                $scope.display = $scope.displayGroup;
                $scope.hasDisplayGroup = true;
            }

            $scope.zoom = 1.0;
            $scope.linkedTimeSpans = [];
            $scope.selectedDays = [0,0,0,0,0,0,0];
            $scope.$root.editMode = false;

            $scope.$on('schedulesUpdated', function(evt, args) {
                $scope.schedules = args.newValue;
                $scope.update();
            });

            $scope.timeSpanColours = [
                {
                    "background": "#ffffff",
                    "text": "#000000"
                },
                {
                    "background": "#8dc63f",
                    "text": "#ffffff"
                },
                {
                    "background": "#00aef0",
                    "text": "#ffffff"
                },
                {
                    "background": "#c63f3f",
                    "text": "#ffffff"
                },
                {
                    "background": "#000000",
                    "text": "#ffffff"
                },
                {
                    "background": "#f2ba57",
                    "text": "#ffffff"
                },
                {
                    "background": "#dc81df",
                    "text": "#ffffff"
                },
                {
                    "background": "#7c77e2",
                    "text": "#ffffff"
                },
                {
                    "background": "#c5c5c5",
                    "text": "#000000"
                },
                {
                    "background": "#438aa7",
                    "text": "#ffffff"
                }
            ];

            $scope.getItemByID = function(arr, id) {

                //  Default Item
                var item = null;

                [].forEach.call(arr, function(el){

                    if(el.ID === id || ( el.ID && el.ID == id ))
                    {
                        item = el;
                    }

                });

                return item;

            };

            $scope.zoomIn = function() {
                if(this.zoom < 1.75) {
                    this.zoom += 0.25;
                    this.oneHour = 80 * this.zoom;
                    this.update();
                }
            };

            $scope.zoomOut = function() {
                if(this.zoom > 0.50) {
                    this.zoom -= 0.25;

                    this.oneHour = 80 * this.zoom;
                    this.update();
                }
            };

            $scope.parseTime = function(timeStr) {

                var h = parseInt(timeStr.substr(0,2), 10);
                var m = parseInt(timeStr.substr(2,2), 10);

                return {
                    "hours": h,
                    "minutes": m,
                    "timestamp": (m * 60000) + ( (h*60) * 60000 )
                }

            };



            $scope.applyErrorStyling = function(clashes) {

                if (clashes.clashStartTime) {

                    document.getElementById("startTimeLabel").classList.add("clash");

                } else {
                    document.getElementById("startTimeLabel").classList.remove("clash");
                }

                if (clashes.clashEndTime) {

                    document.getElementById("endTimeLabel").classList.add("clash");

                } else {

                    document.getElementById("endTimeLabel").classList.remove("clash");

                }

            };



            $scope.currentTimeSpanUpdate = {
                scope  : this,
                projectID: function(val) {
                    this.scope.$root.currentTimeSpan.projectID = val;
                    this.scope.updateTimeSpanModel();
                    this.scope.update();
                },

                startTime: function(val) {

                    if(val.length === 4) {

                        var clashes = this.scope.checkClashes({ startTime: val, endTime: this.scope.$root.currentTimeSpan.endTime,
                            days: this.scope.$root.currentTimeSpan.days, timeSpanID: this.scope.$root.currentTimeSpan.timeSpanID });

                        if (!clashes.isClash) {
                            this.scope.$root.currentTimeSpan.startTime = val;
                            this.scope.updateTimeSpanModel();
                            this.scope.update();
                        }

                        this.scope.applyErrorStyling(clashes);
                    }

                },

                endTime: function(val) {

                    if(val.length === 4) {

                        var clashes = this.scope.checkClashes({ startTime: this.scope.$root.currentTimeSpan.startTime, endTime: val,
                            days: this.scope.$root.currentTimeSpan.days, timeSpanID: this.scope.$root.currentTimeSpan.timeSpanID });

                        if (!clashes.isClash) {

                            this.scope.$root.currentTimeSpan.endTime = val;

                            this.scope.updateTimeSpanModel();

                            this.scope.update();

                        }

                        this.scope.applyErrorStyling(clashes);

                    }

                },

                day: function(val) {

                    var daysCopy = this.scope.$root.currentTimeSpan.days;
                    var placeInArray = daysCopy.indexOf(val);

                    if(placeInArray === -1) {

                        daysCopy.push(val);

                    } else {

                        daysCopy.splice(placeInArray, 1);

                    }

                    var clashes = this.scope.checkClashes( { startTime: this.scope.$root.currentTimeSpan.startTime,
                        endTime: this.scope.$root.currentTimeSpan.endTime, days: daysCopy, timeSpanID: this.scope.$root.currentTimeSpan.timeSpanID });

                    if(!clashes.isClash){

                        this.scope.$root.currentTimeSpan.days = daysCopy;
                        this.scope.updateTimeSpanModel();
                        this.scope.update();
                    }
                    this.scope.applyErrorStyling(clashes);
                }

            };

            $scope.hideEdit = function() {

                [].forEach.call(document.querySelectorAll(".editPanel .clash"), function(el){
                    el.classList.remove("clash");
                });

                //document.querySelector(".editPanel").style.display = "none";
                $("#editPanel_" + this.display.ID).hide();

                this.$root.editMode = false;
            };

            $scope.showColourPicker = function() {
                document.getElementById("colourPicker_" + this.display.ID).style.display = "block";
            };

            $scope.hideColourPicker = function() {
                document.getElementById("colourPicker_" + this.display.ID).style.display = "none";
            };

            $scope.selectColour = function(i, ts) {
                ts.colour = i;
                this.updateTimeSpanModel();
                this.update();
                this.hideColourPicker();
            };
         }]
    }

});