"use strict"

var minutesInDay = 24 * 60;

var allCalendarEvents = [];

var calendarRenderDate = null;
var calendarContainer = null;
var calendarGuid = null;

$(document).ready(function(){
    moment.locale('en', {
        week: { dow: 1 }
    });
});

function renderCurrentMonthCalendar(container, events){
    allCalendarEvents = events || [];
    currentScope.currentDisplay.calendarEvents = allCalendarEvents;
    var thisMonth = moment();
    renderCalendar(container, thisMonth);
}

function updateCalendarEvents(cEvents){
    allCalendarEvents = [];
    for(var ce in cEvents){
        var cEvent = cEvents[ce];
        allCalendarEvents.push(new CalendarEvent(cEvent));
    }
    calendarRefresh();
    return allCalendarEvents;
}

function calendarRefresh(){
    renderCalendarEvents(allCalendarEvents, calendarRenderDate);
}

function calendarMonth_next(){
    calendarRenderDate.add(1, "month");
    renderCalendar(calendarContainer, calendarRenderDate);
}

function calendarMonth_prev(){
    calendarRenderDate.add(-1, "month");
    renderCalendar(calendarContainer, calendarRenderDate);
}

var fullDayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
var shortDayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

function renderCalendar(container, momentDate){

    if(!container || container.length == 0){
        // invalid container, abort render
        return;
    }

    calendarGuid = newGuid();
    calendarContainer = container;

    // clear container and fill in the calendar
    container.html("");
    calendarRenderDate = momentDate.clone();

    var todayDateSign = moment().format("YYYY-MM-DD");

    // render main container :
    var calendarMainBody = $("<div class='calendarMainBody'></div>");
    container.append(calendarMainBody);

    // reset to first date of the month
    momentDate.date(1);
    $(".calendarMonthHeading").html(momentDate.format("MMMM YYYY") );
    var monthStartDay = momentDate.day();
    var daysInMonth = momentDate.daysInMonth();

    // push sunday as the last day of week
    if(monthStartDay == 0){
        monthStartDay = 7;
    }

    var blankItems = monthStartDay - 1;

    // render heading..
    var headerRow = $("<div class='calendarList calendarHeaderList'></div>");
    calendarMainBody.append(headerRow);

    for(var i=0; i<7; i++){
        var dLI = $("<div class='calendarListItem calendarHeaderListItem'>" + fullDayNames[i] + "</div>");
        headerRow.append(dLI);
    }

    var calendarWeekListContainer = $("<div class='calendarWeekListContainer'></div>");
    calendarMainBody.append(calendarWeekListContainer);

    // Render dates
    var row = $("<div class='calendarList calendarWeekList'></div>");
    calendarWeekListContainer.append(row);

    calendarRenderDate.prevMonthOffset = momentDate.clone();

    if(blankItems > 0) {
        momentDate.add((-1*blankItems), "day");
        calendarRenderDate.prevMonthOffset = momentDate.clone();
        for (var i = 0; i < blankItems; i++) {
            var bDateSign = momentDate.format("YYYY-MM-DD");
            var blankDiv = $("<div id='"+ bDateSign + calendarGuid + "' data-date-sign='"+bDateSign+"' data-date-index='"+i+"' class='calendarListItem calendarWeekListItem calendarWeekListItem_prevMonth'>"+momentDate.date()+"</div>");
            row.append(blankDiv);
            attachCalendarEntryClick(blankDiv);
            momentDate.add(1, "day");
        }
    }

    var itemCount = blankItems;
    var currentDateSign = null;

    for(var i=0; i<daysInMonth; i++){
        if( i!= 0 && (i + blankItems) % 7 == 0) {
            // next row
            row = $("<div class='calendarList calendarWeekList'></div>");
            calendarWeekListContainer.append(row);
        }

        currentDateSign = momentDate.format("YYYY-MM-DD");

        var wDiv = $("<div id='"+currentDateSign+ calendarGuid+ "' data-date-sign='"+currentDateSign+"' data-date-index='"+(blankItems + i)+"' class='calendarListItem calendarWeekListItem'>" + (i+1) + "</div>");
        row.append(wDiv);
        attachCalendarEntryClick(wDiv);

        if(currentDateSign == todayDateSign){
            wDiv.addClass("calendarListItem_today")
        }

        momentDate.add(1, "day");
        itemCount++;
    }

    // add remaining empty items
    // 6/7 rows possible..
    var maxItems = itemCount>35?42:35;

    var remainingBlanks = maxItems - itemCount;
    if(remainingBlanks<7){
        for(var i=0; i<remainingBlanks; i++){
            var nDateSign = momentDate.format("YYYY-MM-DD");
            var blankDiv = $("<div id='"+nDateSign+ calendarGuid + "' data-date-sign='"+nDateSign+"' data-date-index='"+(itemCount + i)+"' class='calendarListItem calendarWeekListItem calendarWeekListItem_nextMonth'>"+(i+1)+"</div>");
            row.append(blankDiv);
            attachCalendarEntryClick(blankDiv);
            momentDate.add(1, "day");
        }
    }

    momentDate.add(-1, "day");
    calendarRenderDate.nextMonthOffset = momentDate.clone();

    // normalise time
    calendarRenderDate.prevMonthOffset.hour(0);
    calendarRenderDate.prevMonthOffset.minute(0);
    calendarRenderDate.prevMonthOffset.second(59);
    calendarRenderDate.prevMonthOffset.millisecond(0);

    calendarRenderDate.nextMonthOffset.hour(23);
    calendarRenderDate.nextMonthOffset.minute(59);
    calendarRenderDate.nextMonthOffset.second(59);
    calendarRenderDate.nextMonthOffset.millisecond(999);
    calendarRenderDate.calendarFirstContainer = $("#" + calendarRenderDate.prevMonthOffset.format("YYYY-MM-DD") + calendarGuid);
    calendarRenderDate.calendarLastContainer = $("#" + calendarRenderDate.nextMonthOffset.format("YYYY-MM-DD") + calendarGuid);

    renderCalendarEvents(allCalendarEvents, calendarRenderDate);
}


function attachCalendarEntryClick(elem){
    var thisElem = elem;
    elem.click(function(ev){
        
        var dateSign = thisElem.attr("data-date-sign");
        var mDate = moment(dateSign, "YYYY-MM-DD");
        var nextDayDate = mDate.clone().add(1, "day");

        var newEvent = new CalendarEvent({
            title: "New Calendar Schedule",
            startTime : mDate.format("YYYY-MM-DD") + " 00:00", 
            endTime: nextDayDate.format("YYYY-MM-DD") + " 00:00"
        });

        newEvent.showEditPopup();
    });
}

function renderCalendarEvents(calendarEvents, momentDate){

    // process events to add meta info
    for(var ce in calendarEvents){
        var cEvent = calendarEvents[ce];
        cEvent.refreshData();
    }

    // remove previous items
    $(".calendarEvent").remove();

    var currentViewRange = moment.range([momentDate.prevMonthOffset, momentDate.nextMonthOffset]);

    // get all events for this month
    var currentMonthEvents = [];
    
    for(var ce in calendarEvents){
        var cEvent = calendarEvents[ce];
        var cEventRange = cEvent.dateRange;
        if(cEventRange.intersect(currentViewRange)){
            currentMonthEvents.push(cEvent);
        }
    }

    for(var cme in currentMonthEvents){
        var cmEvent = currentMonthEvents[cme];
        cmEvent.draw();
    }
}

// Util methods
function getColorSet(){
    var randomColor = "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);});
    return {bgColor: randomColor, fontColor: invertColor(randomColor, true)};
}

function invertColor(hex, bw) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

function newGuid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}

// Calendar Event Class methods for adding/editing events
function CalendarEvent(cEventData){
    var cObj = {};

    cObj.guid = newGuid();

    /*
    eg:
    PROJECT
     {
        title: "Test Project Feb-Mar",
        startTime : '2018-05-17 12:00:17.013',
        endTime : '2018-08-09 20:00:17.013'
    },
    */

    // add obj props to our data
    $.extend(cObj, cEventData);

    cObj.refreshData = function(){
        // Date eg: 2016-11-07 01:00:17.013
        this.mStart = moment(this.startTime, "YYYY-MM-DD HH:mm:ss.sssZ");
        this.mEnd = moment(this.endTime, "YYYY-MM-DD HH:mm:ss.sssZ");
        
        this.startDateSign = this.mStart.format("YYYY-MM-DD");

        this.endDateSign = this.mEnd.format("YYYY-MM-DD");

        if(this.mEnd.hour() == 0 && this.mEnd.minute() == 0 )
        {
            // this ends on the previous day's end
            this.endDateSign = this.mEnd.clone().add(-1, "day").format("YYYY-MM-DD");
        }

        this.titleStr = this.title 
                            + "\n" + "Start : " + this.mStart.format("DD MMM YYYY, HH:mm")
                            + "\n" + "End : " + this.mEnd.format("DD MMM YYYY, HH:mm");

        if(!this.colorSet){
            // get random color set;
            this.colorSet = getColorSet();
        }

        this.dateRange = moment.range([this.mStart, this.mEnd]);
    };

    cObj.showEditPopup = function(){

        $("#calendarModalStatus").html("&nbsp;");
        
        var thisEvent = this;

        $("#calendarModal").modal('show');
        
        $("#caledarModalTitle").html("Calendar Schedule" );

        var calendarModalContent = $("#calendarModalContent");
        // clear before fill
        calendarModalContent.html("");

        // Fill in title edit
        var titleContainer = $("<div></div>");
        calendarModalContent.append(titleContainer);

        var titleLabel = $("<label style='display:inline-block; width: 100px;' for='calendarEventTitle'>Title</label>");
        var titleInput = $("<input style='margin-left:10px; display:inline-block; width:calc(100% - 120px);' "
                                +"id='calendarEventTitle' type='text' value='' />");

        titleContainer.append(titleLabel);
        titleContainer.append(titleInput);

        titleInput.val(this.title);

        var psContainer = $("<div id='calendarPSSelectContainer' style='margin-top:20px;'></div>");
        calendarModalContent.append(psContainer);

        var psLabel = $("<label style='display:inline-block; width: 100px;' for='calendarPSSelector'>Project</label>");
        var psSelect = $("<select id='calendarPSSelector' style='color:black; margin-left:10px; display:inline-block; width:calc(100% - 120px);'>"
                            +"<option id='calendarPSSelectDefaultOption' selected='selected' value='0'>Select Project</option>"
                    +"</select>");

        psContainer.append(psLabel);
        psContainer.append(psSelect);

        // load projects into the selector
        var publishedProjects = currentScope.publishedProjects;
        var psSelector = psContainer.find("#calendarPSSelector");
        for(var p in publishedProjects){
            var project = publishedProjects[p];
            var option = $("<option value='"+project.ID+"'></option>");
            psSelector.append(option);
            option.html(project.name);
        }

        var dateSelectorContainer = $("<div style='margin-top:20px;'></div>");
        calendarModalContent.append(dateSelectorContainer);

        var startDateSelectorHTML = $("<div style='width:50%; padding-right:10px; display:inline-block;'><label for='calendarEventStartDatePicker'>Start Date/Time:</label>"
            +"<div class='form-group'>"
                +"<div class='input-group calendarEventStartDatePicker' id='calendarEventStartDatePicker'>"
                    +"<input autocomplete='off' onkeydown='return false' style='cursor:pointer;' "
                        +"class='form-control' type='text' value='' "
                    +"name='calendarEventStartDate' id='calendarEventStartDate' />"
                    +"<span class='input-group-addon'><span class='glyphicon glyphicon-calendar'></span>"
                +"</div>"
            +"</div></div>");

        dateSelectorContainer.append(startDateSelectorHTML);
        
        var endDateSelectorHTML = $("<div style='padding-left:10px; width:50%; display:inline-block;'><label for='calendarEventEndDatePicker'>End Date/Time:</label>"
            +"<div class='form-group'>"
                +"<div class='input-group calendarEventEndDatePicker' id='calendarEventEndDatePicker'>"
                    +"<input autocomplete='off' onkeydown='return false' style='cursor:pointer;' "
                        +"class='form-control' type='text' value='' "
                    +"name='calendarEventEndDate' id='calendarEventEndDate' />"
                    +"<span class='input-group-addon'><span class='glyphicon glyphicon-calendar'></span>"
                +"</div>"
            +"</div></div>");
        
        dateSelectorContainer.append(endDateSelectorHTML);

        // load type selector divs

        // detach last click event so they dont get called twice from last attach
        $("#caledarModalDelete").off("click");
        $("#caledarModalSave").off("click");

        // attach current event click
        $("#caledarModalSave").click(function(){
            thisEvent.save();
        });
        
        // attach current event click
        $("#caledarModalDelete").click(function(){
            if(confirm("Are you sure you want to delete : "+ thisEvent.title +" ?")){
                thisEvent.delete();
                $("#calendarModal").modal('hide');
            }
        });

        // Update dates
        if(thisEvent.startTime){
            var startDateVal = moment(thisEvent.startTime, "YYYY-MM-DD HH:mm:ss.sssZ").local().format("DD-MM-YYYY HH:mm");
            $("#calendarEventStartDate").val(startDateVal);
        }

        if(thisEvent.endTime){
            var endDateVal = moment(thisEvent.endTime, "YYYY-MM-DD HH:mm:sss.sZ").local().format("DD-MM-YYYY HH:mm");
            $("#calendarEventEndDate").val(endDateVal);
        }

        // Init Date Pickers
        $("#calendarEventStartDate").datetimepicker({format:'DD-MM-YYYY HH:mm'});
        $("#calendarEventEndDate").datetimepicker({format:'DD-MM-YYYY HH:mm'});

        $("#caledarModalDelete").show();
        $("#caledarModalSave").html("Save Changes");

        // Hide the delete button if this is a new event and udpate save title
        if(!thisEvent.id) {
            $("#caledarModalDelete").hide();
            $("#caledarModalSave").html("Save");
        }

        $("#calendarPSSelector").val(thisEvent.projectId || "0");
    };

    cObj.getPostData = function(){
        this.refreshData();
        return {
            id : this.id,
            title: this.title,
            displayId: this.displayId,
            displayGroupId : this.displayGroupId,
            displayGroupOverrideId: this.displayGroupOverrideId,
            projectId : this.projectId,
            startTime : this.mStart.toDate(),
            endTime : this.mEnd.toDate()
        }
    };

    cObj.save = function(){
        // make sure its not clashing
        $("#calendarModalStatus").html("&nbsp;");

        var titleVal = $("#calendarEventTitle").val().trim();
        var startDateVal = $("#calendarEventStartDate").val().trim();
        var endDateVal = $("#calendarEventEndDate").val().trim();

        var mStartDate = moment(startDateVal, "DD-MM-YYYY HH:mm");
        var mEndDate = moment(endDateVal, "DD-MM-YYYY HH:mm");

        var mDateRange = moment.range([mStartDate, mEndDate]);

        // first test on the data available
        var clashingEvent = isDateRangeClashingWithCurrentEvents(mDateRange, this.id) ;
        if(clashingEvent){
            $("#calendarModalStatus").html("Please change dates. Current values clashing with : " + clashingEvent.title);
            return;
        }

        var thisEvent = this;
        // Second we'll test on the backend and return an error if an event is clashing..

        // TODO : validate blanks etc

        // Load from the Edit Popup
        this.title = titleVal;

        this.startTime = mStartDate.format("YYYY-MM-DD HH:mm");
        this.endTime = mEndDate.format("YYYY-MM-DD HH:mm");

        // reset both ids
        this.projectId = 0;

        this.projectId = parseInt($("#calendarPSSelector").val());

        if(!this.projectId){
            $("#calendarModalStatus").html("Please select a project.");
            return;
        }

        var nowDate = moment();
        var diff = 0;

        diff = mEndDate.diff(mStartDate, "minutes");
        if(diff <= 0){
            $("#calendarModalStatus").html("End Date/Time cannot come before the Start Date/Time. Please update End Date/Time and try again.");
            return;
        }

        if(!this.id){
            // This is a new event
            if(currentScope.currentDisplay.isDisplayGroupOverride){
                this.displayGroupOverrideId = currentScope.currentDisplay.ID;
            }else if(currentScope.currentDisplay.isDisplayGroup){
                this.displayGroupId = currentScope.currentDisplay.ID;
            }else{
                this.displayId = currentScope.currentDisplay.ID;
            }

            diff = nowDate.diff(mStartDate, "minutes");
            if(diff > 30){
                $("#calendarModalStatus").html("Start Date/Time cannot be in the past. Please update Start Date/Time and try again.");
                return;
            }
        }

        diff = nowDate.diff(mEndDate, "minutes");
        if(diff > 10){
            $("#calendarModalStatus").html("End Date/Time cannot be in the past. Please update End Date/Time and try again.");
            return;
        }

        // ajax call to update
        // use standard angular calls
        currentScope.http({ "method": "POST", "url": currentScope.backend + "/calendarSchedules", "params": {},
            "data": { calendarSchedule : this.getPostData(), action: "addUpdate"} })
            .success(function(data, status){
                if(data.success) {
                    // update the on screen representation
                    if (!thisEvent.id) {
                        // add new events to current list
                        thisEvent.id = data.scheduleId;
                        allCalendarEvents.push(thisEvent);
                    }

                    // Hide popup and update current view
                    $("#calendarModal").modal('hide');
                    calendarRefresh();
                }
                else{
                    $("#calendarModalStatus").html("An error has occurred : " + data.message);
                }
            })
            .error(function(data, status){
                $("#calendarModalStatus").html("Unexpected error. Please try again later.");
            });
    };

    cObj.delete = function(){
        // ajax call to delete
        currentScope.http({ "method": "POST", "url": currentScope.backend + "/calendarSchedules", "params": {},
            "data": { calendarSchedule : this.getPostData(), action: "delete"} })
            .success(function(data, status){
                if(data.success) {
                    // update the on screen representation
                    // remove from array
                    allCalendarEvents.splice(allCalendarEvents.indexOf(cObj), 1);
                    calendarRefresh();
                }
                else{
                    $("#calendarModalStatus").html("An error has occurred : " + data.message);
                }
            })
            .error(function(data, status){
                $("#calendarModalStatus").html("Unexpected error. Please try again later.");
            });
    };

    cObj.draw = function(){
        var cmEvent = this;
        var cmStartDateSign = cmEvent.startDateSign;
        var cmEndDateSign = cmEvent.endDateSign;

        var calendarStartContainer = $("#" + cmStartDateSign + calendarGuid);
        var calendarEndContainer = $("#" + cmEndDateSign + calendarGuid);

        if(calendarStartContainer.length == 0){
            calendarStartContainer = calendarRenderDate.calendarFirstContainer;
        }

        if(calendarEndContainer.length == 0){
            calendarEndContainer = calendarRenderDate.calendarLastContainer;
        }

        // draw an event div from startContainer till end container
        drawCalendarEvent(cmEvent, calendarStartContainer, calendarEndContainer);
    };

    return cObj;
}

function drawCalendarEvent(cEvent, startContainer, endContainer){

    var startContainerIndex = parseInt(startContainer.attr("data-date-index"));
    var endContainerIndex = parseInt(endContainer.attr("data-date-index"));

    var eventStartLine = Math.floor(startContainerIndex / 7);
    var eventEndLine = Math.floor(endContainerIndex / 7);

    var lineGap = eventEndLine - eventStartLine;

    if(lineGap > 0){

        // recurse over different lines to draw continuous event
        var currentLineEndContainer = startContainer.parent().children().last();
        drawCalendarEvent(cEvent, startContainer, currentLineEndContainer);

        var currentLineNo = eventStartLine;

        // was this the last line?
        // if not, update to next line and keep going..
        if(currentLineNo != eventEndLine){
            var newStartContainer = startContainer.parent().next().children().first();
            var cmEndDateSign = cEvent.endDateSign;
            var originalEndContainer = $("#" + cmEndDateSign + calendarGuid);
            if(originalEndContainer.length == 0){
                originalEndContainer = calendarRenderDate.calendarLastContainer;
            }
            drawCalendarEvent(cEvent, newStartContainer, originalEndContainer);
        }
    }
    else {
        // this is a single line event, directly draw
        var blocksGap = endContainerIndex - startContainerIndex;
        drawSingleLineEvent(cEvent, startContainer, blocksGap);
    }
}

function drawSingleLineEvent(cEvent, startContainer, blocksGap){
    var calendarMainBody = startContainer.closest(".calendarMainBody");
    var eventDiv = $("<div class='calendarEvent ellipsisContent "+cEvent.guid+"'><span class='calendarEventTitle'></span></div>");

    eventDiv.html(cEvent.title);
    calendarMainBody.append(eventDiv);

    eventDiv.attr("title", cEvent.titleStr);

    var startContainerWidth = startContainer.outerWidth();
    var width = startContainerWidth + (blocksGap * startContainerWidth);
    var top = startContainer.offset().top - calendarContainer.offset().top;
    var left = startContainer.offset().left - calendarContainer.offset().left;

    var leftOffset = 0;
    var rightOffset = 0;

    // is this the start date container?
    if(startContainer.data("dateSign") == cEvent.startDateSign){
        // update position based on the time
        var startHour = cEvent.mStart.hour();
        var startMinute = cEvent.mStart.minute();
        var startTotalMinutes = (startHour * 60) + startMinute;
        leftOffset = ( (startContainerWidth/minutesInDay) * startTotalMinutes );
    }

    var startContainerRow = startContainer.parent();
    var startContainerRowChildren = startContainerRow.children()
    var startContainerIndex = startContainerRowChildren.index(startContainer);

    var endContainer = $(startContainerRowChildren[startContainerIndex + blocksGap]);

    // is this the end date container?
    if(endContainer.data("dateSign") == cEvent.endDateSign){
        var endHour = cEvent.mEnd.hour();
        var endMinute = cEvent.mEnd.minute();
        var endTotalMinutes = (endHour * 60) + endMinute;
        rightOffset = endTotalMinutes>0 ? ( startContainerWidth - ( (startContainerWidth/minutesInDay) * endTotalMinutes ) ) : 0;
    }

    eventDiv.css("top", (top + 25 ) + "px");
    eventDiv.css("left", (left + leftOffset) + "px");
    eventDiv.width(width - leftOffset - rightOffset - 6);

    eventDiv.css("background-color", cEvent.colorSet.bgColor);
    eventDiv.css("color", cEvent.colorSet.fontColor);

    // Attach events
    eventDiv.click(function(){
        cEvent.showEditPopup();
    });
}

function isDateRangeClashingWithCurrentEvents(dateRange, ignoreId){
    var result = null;

    for(var ce in allCalendarEvents){
        var cEvent = allCalendarEvents[ce];
        
        if(cEvent.id != ignoreId){
            if( cEvent.dateRange.intersect(dateRange)){
                result = cEvent;
                break;
            }
        }
    }

    return result;
}

function isEventClashingWithCurrentEvents(currentEvent){
    return isDateRangeClashingWithCurrentEvents(currentEvent.dateRange, currentEvent.id);
}
