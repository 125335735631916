activ.controller("projectsDataFeeds", ["$scope", "$http", function($scope, $http) {

    $scope.dataFeeds = [];
    $scope.newFeedTitle = "";
    $scope.dateOptions = {
        formatYear: 'yy',
        startingDay: 1
    };
    $scope.datePickerFormat = 'dd-MMMM-yyyy';


    $scope.toggleDPanelRowDetails = function(pId, isDataFeed)
    {
        var pContainer = $("#" + pId + "Container");
        pContainer.toggle();

        if(isDataFeed) {
            var dfId = parseInt(pId.replace("dataFeed", ""));
            var dataFeed = $scope.getDataFeedById(dfId);
            // Does this feed have data loaded?
            if (!dataFeed.storiesLoaded) {
                // Load now

                $http({ method: "POST", url: $scope.$parent.backend + "/dataFeeds", "params": {},
                        "data": {feedId : dfId, action:"loadStories"}})
                    .success(function (data, status) {
                        dataFeed.stories = data.stories;
                        dataFeed.storiesLoaded = true;
                    })
                    .error(function(data,status){
                        console.log(status);
                        console.log(data);
                        $scope.$parent.activAlert("There was an error while loading data feed stories, Please try again", 2000);
                    });
            }

        }
    };

    $scope.getDataFeedById = function(dfId) {
        var dfFound = null;
        for (var i = 0; i < $scope.dataFeeds.length; i++) {
            var df = $scope.dataFeeds[i];
            if (df.ID == dfId) {
                dfFound = df;
                break;
            }
        }
        return dfFound;
    };

    $scope.createNewFeed = function()
    {
        var newFeedTitle = $.trim($scope.newFeedTitle);

        if(newFeedTitle.length ==0)
        {
            $scope.$parent.activAlert("Please Enter a Feed Title", 1000);
            $("#newFeedTitle").focus();
            return;
        }

        var duplicateFeed = false;
        for (var i = 0; i < $scope.dataFeeds.length; i++) {
            var df = $scope.dataFeeds[i];
            if(df.title.toLowerCase() == newFeedTitle.toLowerCase())
            {
                duplicateFeed = true;
                break;
            }
        }

        if(duplicateFeed)
        {
            $scope.$parent.activAlert("A Data Feed with the same title already exists", 2000);
            return;
        }

        var postData = {"action" : "createFeed", newFeedTitle : newFeedTitle};

        $http({ method: "POST", url: $scope.$parent.backend + "/dataFeeds", "params": {}, "data": postData})
            .success(function (data, status) {

                $scope.newFeedTitle = "";

                if (data.success)
                {
                    data.newDataFeed.newStoryDescription = "";
                    data.newDataFeed.newStoryType = "story";
                    data.newDataFeed.newStoryDescription = "";
                    data.newDataFeed.newStoryDate = new Date();


                    $scope.dataFeeds.push(data.newDataFeed);
                    $scope.toggleDPanelRowDetails('newDataFeed');
                }
                else
                {
                    $scope.$parent.activAlert("Error while creating Data Feed : " + data.error, 2000);
                }
            })
            .error(function (data, status) {
                $scope.$parent.activAlert("Unexpected Error : " + data, 2000);
            });
    };

    // Get existing data feeds
    $scope.loadDataFeeds = function()
    {
        var postData = {action : "getAll"};

        $http({ method: "POST", url: $scope.$parent.backend + "/dataFeeds", "params": {}, "data": postData})
            .success(function (data, status) {

                $scope.newFeedTitle = "";

                if (data.success)
                {
                    // Set defaults for new feeds models
                    for (var i = 0; i < data.dataFeeds.length; i++) {
                        var df = data.dataFeeds[i];
                        df.newStoryType = "story";
                        df.newStoryDescription = "";
                        df.newStoryDate = new Date();
                        df.newTitle = df.title;

                        if(df.stories) {
                            // Update stories
                            for (var j = 0; j < df.stories.length; j++) {
                                var dfs = df.stories[j];

                                if (dfs.type == 'diary')
                                    dfs.date = new Date(dfs.date);
                                else
                                    dfs.date = new Date();

                                dfs.opened = false;
                            }
                        }

                    }
                    $scope.dataFeeds = data.dataFeeds;

                }
                else
                {
                    $scope.$parent.activAlert("Error while getting Data Feeds : " + data.error, 2000);
                }
            })
            .error(function (data, status) {
                $scope.$parent.activAlert("Unexpected Error : " + data, 2000);
            });
    };
    $scope.loadDataFeeds();


    $scope.updateFeedTitle = function(dataFeed)
    {
        var newFeedTitle = $.trim(dataFeed.newTitle);

        if( newFeedTitle.length == 0 )
        {
            $scope.$parent.activAlert("Please enter a valid Feed Title", 1000);

            return;
        }

        var postData = {action : "updateFeedTitle", dataFeedId : dataFeed.ID, newFeedTitle : newFeedTitle };


        $http({ method: "POST", url: $scope.$parent.backend + "/dataFeeds", "params": {}, "data": postData})
            .success(function (data, status) {

                if (data.success)
                {
                    $scope.$parent.activAlert("Feed Title Updated", 800);
                    dataFeed.title = dataFeed.newTitle;
                }
                else
                {
                    $scope.$parent.activAlert("Error while Updating Feed Title Please Try again : " + data.error, 2000);
                }
            })
            .error(function (data, status) {
                $scope.$parent.activAlert("Unexpected Error : " + data, 2000);
            });
    };

    $scope.createNewDataFeedStory = function(dataFeed)
    {
        if(!dataFeed.newStoryDescription || !dataFeed.newStoryType)
            return;

        if(dataFeed.newStoryType == 'diary')
        {

            if(!dataFeed.newStoryDate || !dataFeed.newStoryDate.getTime ) {

                $scope.$parent.activAlert("Please Select a valid date", 2000);
                return;
            }
        }

        // validate Add the new feed to the dataFeed
        var newStory = {description : $.trim(dataFeed.newStoryDescription), type : dataFeed.newStoryType};

        if( newStory.description.length == 0 )
        {
            return;
        }


        if(dataFeed.newStoryDate)
        {
            newStory.date = dataFeed.newStoryDate.getTime();
        }


        var postData = {action : "addFeedStory", dataFeedId : dataFeed.ID, newStory : newStory };


        $http({ method: "POST", url: $scope.$parent.backend + "/dataFeeds", "params": {}, "data": postData})
            .success(function (data, status) {

                $scope.newFeedTitle = "";

                if (data.success)
                {
                    dataFeed.newStoryDescription = "";
                    dataFeed.stories.push(data.newStory);
                }
                else
                {
                    $scope.$parent.activAlert("Error while creating Story : " + data.error, 2000);
                }
            })
            .error(function (data, status) {
                $scope.$parent.activAlert("Unexpected Error : " + data, 2000);
            });
    };

    $scope.showDeleteDataFeed = function(dataFeed)
    {
        if(confirm("Are you sure you want to delete this Data Feed"))
        {
            var postData = {"action" : "deleteFeed", dataFeedId : dataFeed.ID};

            $http({ method: "POST", url: $scope.$parent.backend + "/dataFeeds", "params": {}, "data": postData})
                .success(function (data, status) {

                    $scope.newFeedTitle = "";

                    if (data.success)
                    {
                        var index = $scope.dataFeeds.indexOf(dataFeed);
                        $scope.dataFeeds.splice(index,1);
                    }
                    else
                    {
                        $scope.$parent.activAlert("Error while Deleting Data Feed : " + data.error, 2000);
                    }
                })
                .error(function (data, status) {
                    $scope.$parent.activAlert("Unexpected Error : " + data, 2000);
                });
        }
    };
    $scope.initEditStory = function(story, dataFeed)
    {
        story.originalData = angular.copy(story)
        story.editMode = true;
    };


    $scope.checkEnterAndEditNewStory = function($event, story, dataFeed)
    {
        if($event.keyCode == 13) {  // Enter key
            $scope.updateStory(story, dataFeed);
        }
        else if($event.keyCode == 27) { // Escape key
            $scope.cancelEditStory(story, dataFeed);
        }
    }


    $scope.updateStory = function(story, dataFeed)
    {

        var updatedStory = angular.copy(story);

        if(updatedStory.type == 'diary')
        {

            if(!updatedStory.date || !updatedStory.date.getTime ) {

                $scope.$parent.activAlert("Please Select a valid date", 2000);
                return;
            }

        }


        // Update date obj
        updatedStory.date = new Date(updatedStory.date).getTime();

        delete updatedStory.originalData;
        delete updatedStory.editMode;
        delete updatedStory.opened;

        var postData = {action : "updateFeedStory", dataFeedId : dataFeed.ID, story : updatedStory };

        $http({ method: "POST", url: $scope.$parent.backend + "/dataFeeds", "params": {}, "data": postData})
            .success(function (data, status) {

                $scope.newFeedTitle = "";

                if (data.success)
                {
                    story.editMode = false;
                }
                else
                {
                    $scope.$parent.activAlert("Error while Updating Story : " + data.error, 2000);
                }
            })
            .error(function (data, status) {
                $scope.$parent.activAlert("Unexpected Error : " + data, 2000);
            });

    };

    $scope.deleteStory = function(story, dataFeed)
    {
        if(confirm("Are you sure you want to delete this story?")) {
            var index = dataFeed.stories.indexOf(story);

            var postData = {action : "deleteFeedStory", dataFeedId : dataFeed.ID, story : story };

            $http({ method: "POST", url: $scope.$parent.backend + "/dataFeeds", "params": {}, "data": postData})
                .success(function (data, status) {

                    $scope.newFeedTitle = "";

                    if (data.success)
                    {
                        dataFeed.stories.splice(index, 1);
                    }
                    else
                    {
                        $scope.$parent.activAlert("Error while Deleting Story : " + data.error, 2000);
                    }
                })
                .error(function (data, status) {
                    $scope.$parent.activAlert("Unexpected Error : " + data, 2000);
                });
        }
    };

    $scope.getFormattedStoryDate = function(sDate)
    {
        if(!sDate) {
            return " - ";
        }

        var m_names = new Array("Jan", "Feb", "Mar",
            "Apr", "May", "Jun", "Jul", "Aug", "Sep",
            "Oct", "Nov", "Dec");

        var d = new Date(sDate);
        var curr_date =  d.getDate();
        var curr_month = d.getMonth();
        var curr_year = d.getFullYear();
        // var curr_hour = d.getHours();
        // var curr_minute = d.getMinutes();

        var  dateTimeStr = ($scope.twoDigits(curr_date) + " " + m_names[curr_month] + " " + curr_year);

        return dateTimeStr;
    };

	$scope.twoDigits = function(num) {
		if (num<10) {num = "0" + num};  // add zero in front of numbers < 10
		return num;
	};

			
    $scope.cancelEditStory = function(story, dataFeed)
    {
        var originalData = story.originalData;
        story.type = originalData.type;
        story.description = originalData.description;

        delete story.originalData;

        story.editMode = false;
    };

    $scope.checkEnterAndUpdateFeedTitle = function($event, dataFeed)
    {
        if($event.keyCode == 13) {  // Enter key
            $scope.updateFeedTitle(dataFeed);
        }
    };

    $scope.checkEnterAndCreateNewStory = function($event, dataFeed)
    {
        if($event.keyCode == 13) {  // Enter key
            $scope.createNewDataFeedStory(dataFeed);
        }
    };

    $scope.open = function($event, obj) {
        $event.preventDefault();
        $event.stopPropagation();
        obj.opened = true;
    };
}]);
