activ.controller("home", ["$scope", "$http", "$location", function($scope, $http, $location){

    $scope.gotData = false;
    $scope.recentProjects = [];
    $scope.recentDisplays = [];

    $scope.openProject = function(id) {
        $location.path("/editor/" + id);
    };

    $scope.cacheBuster = new Date().getTime();

    $scope.getPreviewImageForProject = function(projectFileName){
        var pThumb = "";
        if(projectFileName && projectFileName.length > 0){
            pThumb = $scope.$parent.appRoot+'/projects/' + $scope.$root.groupFolder
                + '/thumbnails/' + projectFileName + '.jpg?cb='+$scope.cacheBuster + "&auth="+$scope.$root.authToken;
        }
        else{
                pThumb = $scope.$parent.appRoot+'/assets/__noPreviewAvailable.jpg?auth='+$scope.$root.authToken;
        }

        return pThumb;
    };



    $http({ "method": "POST", "url": $scope.$parent.backend + "/getRecentProjects", "params": {}, "data": { } })
        .success(function(recentProjectsData, status){

            $scope.recentProjects = [];

            if(recentProjectsData && recentProjectsData.recentProjects)
            {
                recentProjectsData.recentProjects.filter(function (el) {
                    return el !== null && !el.deleted;
                });

                $scope.recentProjects = recentProjectsData.recentProjects;
            }

            $http({ "method": "POST", "url": $scope.$parent.backend + "/getRecentDisplays", "params": {}, "data": { } })
                .success(function(recentDisplayData, status){

                    if(recentDisplayData.recentDisplays) {
                        $scope.recentDisplays = recentDisplayData.recentDisplays.filter(function (el) {
                            return el !== null;
                        });
                    }
                    $scope.gotData = true;

                }).error(function(){});


        }).error(function(){});

}]);