
var Validations = {};

Validations.checkEmpty = function(formField, modalContainer, showError)
{
	var valid = true;
	var formFieldVal = $.trim(formField.val());

	showError = showError == undefined ? true : showError;

	if(formFieldVal.length ==0)
	{
		if(showError) {
			formField.focus();
			var fieldNameDisplay = formField.attr("name");
			if (!fieldNameDisplay)
				fieldNameDisplay = formField.attr("placeholder");
			if (!fieldNameDisplay)
				fieldNameDisplay = "Field";

			modalContainer.setStatusText(fieldNameDisplay + " is required", "red");
		}

		valid = false;
	}

	return valid;
}

Validations.checkUrl = function(formField, modalContainer, showError)
{
	var valid = true;
	var formFieldVal = $.trim(formField.val());

	showError = showError == undefined ? true : showError;

	var re = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

	var valid = re.test(formFieldVal);

	if(!valid && showError)
	{
		formField.focus();
		modalContainer.setStatusText("Invalid URL Address", "red");
	}

	return valid;
}

Validations.checkEmail = function(formField, modalContainer, showError)
{

	showError = showError == undefined ? true : showError;

	var formFieldVal = $.trim(formField.val());

	//	var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
	// Unicode regex
	var re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

	var valid = re.test(formFieldVal);

	if(!valid && showError)
	{
		formField.focus();
		modalContainer.setStatusText("Invalid Email Address", "red");
	}

	return valid;
}

Validations.validatePassword = function(formField, modalContainer)
{
	var valid = false;

	var formFieldVal = $.trim(formField.val());

	var	txtpass = formFieldVal;
	var passwordErr = "";
	var validate = this.checkPasswordStrength(txtpass);

	if(validate.passwordErr != null)
	{
		formField.focus();
		modalContainer.setStatusText(validate.passwordErr, "red");
	}
	else{
		valid = true;
	}

     return valid;
}

Validations.checkPasswordStrength = function(txtpass){

	var retObj = {valid : true, passwordErr : null};

	//	var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
	// Unicode regex
	//if txtpass bigger than 8 give 1 point
	if (txtpass.length < 8) {
		retObj.passwordErr = "Password must be at least eight characters in length";
	}
	else{
		//if txtpass has both lower and uppercase characters give 1 point
		if ( !( txtpass.match(/[a-z]/) ) )
			retObj.passwordErr = "Password must contain at least one smallcase character";
		else
		{

			if ( !( txtpass.match(/[A-Z]/) ) )
				retObj.passwordErr = "Password must contain at least one capital case character";
			else
			{
				//if txtpass has at least one number give 1 point
				if (!txtpass.match(/\d+/))
					retObj.passwordErr = "Password must contain at least one digit";
				else
				//if txtpass has at least one special characther give 1 point
				//if ( !txtpass.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/) )
				//	retObj.passwordErr = "Password must contain at least one special character";
				debugger;
				if(!txtpass.match(/[^a-zA-Z0-9 ]/)) {
					retObj.passwordErr = "Password must contain at least one special character";
				}

			}
		}
	}

	if(retObj.passwordErr!= null)
		retObj.valid = false;

	return retObj;
}

Validations.checkAccessRights = function(entity, fieldName, filedFilter, fieldNameDisplay)
{
	var valid = false;
	var formField = entity.modalContainer.find(filedFilter);

	var selectedField = formField.find(":checked");

	if(selectedField.length == 0)
	{
		fieldNameDisplay = fieldNameDisplay || fieldName;
		entity.setStatusText(fieldNameDisplay + " is required", "red");
	}
	else{
		var formFieldVal = 0;
		for (var i = 0; i < selectedField.length; i++) {
			var sf = selectedField[i]
			formFieldVal += parseInt($(sf).val());

		};
		entity[fieldName] = formFieldVal;
		valid = true;
	}

	return valid;
}


Validations.loadFieldVal = function(entity, fieldName, filedFilter)
{
	var formField = entity.modalContainer.find(filedFilter);
	var formFieldType = formField.attr("type");
	var valid = false;

	if(formFieldType == "checkbox")
	{
		valid = true;
		var formFieldVal = formField.is(":checked");
		entity[fieldName] = formFieldVal;
	}
	else
	//if( formFieldType == "text" || formFieldType == "select")
	{
		var formFieldVal = $.trim(formField.val());
		if(formFieldVal.length >=0)
		{
			entity[fieldName] = formFieldVal;
			valid = true;
		}
	}

	return valid;
}

Validations.checkMacAddress = function(formField, modalContainer)
{
	var formFieldVal = $.trim(formField.val());
	var regex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
	var valid = regex.test(formFieldVal);

	if(!valid)
	{
		formField.focus();
		modalContainer.setErrorText("Invalid Mac Address");
	}
	else{
		valid = true;
	}

	return valid;
}

function convertDateFromPicker(strDate){
	// strDate format should be dd/mm/yyyy. Separator can be anything e.g. / or -. It wont effect
	var dt1   = parseInt(strDate.substring(0,2));
	var mon1  = parseInt(strDate.substring(3,5));
	var yr1   = parseInt(strDate.substring(6,10));
	var date = new Date(yr1, mon1-1, dt1);
	return date;
}

function getFormattedTimestamp(timestamp)
{
    var retStr = "";

    if(timestamp)
    {
        var timestampDate = new Date(timestamp);
        var dd = timestampDate.getDate();
        var mm = timestampDate.getMonth()+1;

        //var hours = timestampDate.getHours();
        //var mins = timestampDate.getMinutes();

        var yyyy = timestampDate.getFullYear();

        //retStr = twoDigits(hours) + ":" + twoDigits(mins) + " " + twoDigits(dd) +'/'+twoDigits(mm)+'/'+yyyy;
        retStr = twoDigits(dd) +'/'+twoDigits(mm)+'/'+yyyy;
    }

    return retStr;
}

function ellipsisContainer(container, charLimit)
{
	var htmlContent = container.html();
	if( htmlContent.length > charLimit )
	{
		var stripped = htmlContent.substring(0, charLimit + 3);
		stripped += "...";
		container.html(stripped);

		container.attr("title", htmlContent);
	}
}

function twoDigits(num)
{
    var ret = num;

    if( parseInt(num)  < 9 )
    {
        ret = "0" + num;
    }

    return ret;
}

function getLineBreakHTMLFromText(txt)
{
	var lbHTML = "";

	if( txt && txt.length > 0)
	{
		lbHTML = txt.replace(/(?:\r\n|\r|\n)/g, '<br />');
	}

	return lbHTML;
}

function getTimeElapsed(timestamp)
{
    var utcDateTime = moment.utc(timestamp);
    var datetime = moment(utcDateTime.toDate());

    var nowDate = moment();

    var diff = moment.duration(datetime.diff(nowDate));

    var retStr = moment.duration(diff).humanize(true);
    /*
    var time = moment.duration(dateTime)
    var retStr = "";
    if(time.years   > 0){   retStr = time.years   + ' years and '     + time.months   + ' months';}
    if(time.months  > 0){   retStr = time.months  + ' months and '    + time.days     + ' days';}
    if(time.days    > 0){   retStr = time.days    + ' days and '      + time.hours    + ' hours';}
    if(time.hours   > 0){   retStr = time.hours   + ' hours and '     + time.minutes  + ' minutes ' + time.seconds + ' seconds';}
    if(time.minutes > 0){   retStr = time.minutes + ' minutes and '   + time.seconds  + ' seconds';}
    if(time.seconds > 0){   retStr = time.seconds + ' seconds';}
    */

    return retStr;
}
