/**
 * Created by Dr Raj Curwen on 14/10/14.
 */

//  factored out from editor.js - Dan's favourite repository !

/*

 STAGE FUNCTIONS

 */

var contentFolder = "assets";

var dragElementType = null;

function checkLocked(id)
{
    var lock = false;
    timeline.items.forEach(function(_layer){
        if(_layer.id === id) {
            if(_layer.locked) {
                lock = true;
            }
        }
    });

    return lock;
}

var createLayerGroup = function(layers)
{
    if(Object.prototype.toString.call(layers) === "[object Array]" && layers.length === 2) {

        var groupID = timeline.uuid.new();
        var createNewGroup = true;

        // baselayer = layer being dropped onto
        // droplayer = layer being dropped

        var baseLayer = timeline.getItemById(layers[0]);
        var dropLayer = timeline.getItemById(layers[1]);

        baseLayer.addChildTimelineItem(dropLayer);

        // Remove the grouping style from any elements that still have it
        var groupJoinEls = document.querySelectorAll(".stage .stageElement .groupElement.lvl-over");
        Array.prototype.forEach.call(groupJoinEls, function(el){
            el.classList.remove("lvl-over");
        });

        groupJoinEls = document.querySelectorAll(".stage .stageElement .groupElement.lvl-over-red");
        Array.prototype.forEach.call(groupJoinEls, function(el){
            el.classList.remove("lvl-over-red");
        });

        // TRIGGER Here
    }
};

var stageElement = function(elementObj) {

    var _this = this;

    this.x = elementObj.x;
    this.y = elementObj.y;
    this.z = elementObj.z;
    this.w = elementObj.width;
    this.h = elementObj.height;
    this.alpha = elementObj.alpha || 1;
    this.ratio = this.w / this.h;
    this.resource = elementObj.resource;
    this.type = elementObj.type;
    this.itemID = elementObj.itemID;
    this.templateBlock = elementObj.templateBlock?true:false;

    this.dragStartCoords = { x: 0, y: 0 };
    this.resizeStartCoords = { x: 0, y: 0 };
    this.resizeStartSize = { w: 0, h: 0 };
    this.content = null;
    this.holdingElement = null;
    this.selectionHighlight = null;
    this.resizeElement = null;
    this.groupElement = null;
    this.mouseDownTime = 0;
    this.name = elementObj.name;
    this.paused = true;
    this.dragged = false;

    if(this.type == "widget") {
        this.widgetVersion =  elementObj.widgetVersion || null
        this.widgetProperties = elementObj.widgetProperties || null;
    }
    else if(this.type == "image") {

        var imgPropDefaults = {
            "transitionIn": {
                val: "None",
                valueList: ["", "None", "Fade In"],
                propertyName: "Transition In",
            },
            "transitionOut": {
                val: "None",
                valueList: ["", "None", "Fade Out"],
                propertyName: "Transition Out",
            }
        };

        this.widgetProperties = imgPropDefaults;

        if( elementObj.widgetProperties )
        {
            for (var key in elementObj.widgetProperties )
            {
                if( this.widgetProperties[key] != undefined ) {
                    var theVal = elementObj.widgetProperties[key];
                    this.widgetProperties[key].val = theVal;
                }
            }
        }

    }


    this.rebuild = function()
    {
        this.updateContent();
    };

    this.updateContent = function()
    {
        switch(this.type) {

            case "video":
                _this.content.pause();
                _this.content.src = timeline.$scope.$root.appRoot + "/" + contentFolder  + "/" + timeline.$scope.groupFolder + "/videos/"
                    + this.resource + "?cb=" + timeline.uuid.new()+"&auth="+timeline.$scope.$root.authToken;
                if (this.paused)
                    _this.content.pause();

                break;
            case "image":
                _this.content.src = timeline.$scope.$root.appRoot + "/" + contentFolder  + "/" + timeline.$scope.groupFolder
                    + "/images/"+ this.resource + "?cb=" + timeline.uuid.new() + "&auth="+timeline.$scope.$root.authToken;
                break;
        }
    };

    this.addToStage = function() {

        this.holdingElement = document.createElement("div");
        this.holdingElement.classList.add("stageElement");
        this.holdingElement.style.width = this.w + "px";
        this.holdingElement.style.height = this.h + "px";
        this.holdingElement.style.left = this.x + "px";
        this.holdingElement.style.top = this.y + "px";
        this.holdingElement.style.opacity = this.alpha;
        this.holdingElement.style["zIndex"] = "" + this.z;
        this.holdingElement.setAttribute("data-layerid", this.layerID);

        this.selectionHighlight = document.createElement("div");
        this.resizeElement = document.createElement("div");
        this.groupElement = document.createElement("div");

        switch(this.type) {

            case "video":
                _this.content = document.createElement("video");
                if(!elementObj.templateBlock) {
                    _this.content.src = timeline.$scope.$root.appRoot + "/" + contentFolder + "/" + timeline.$scope.groupFolder
                        + "/videos/" + this.resource + "?cb=" + timeline.uuid.new() + "&auth="+timeline.$scope.$root.authToken;;
                }

                _this.content.style.width = this.w + "px";
                _this.content.style.height = this.h + "px";
                _this.content.autoplay = "true";
                _this.content.muted = "true";
                _this.content.style.height = "100%";
                _this.content.style["-webkit-transform-origin"] = "0% 0%";
                _this.content.style["transform-origin"] = "0% 0%";

                if (this.paused) {
                    _this.content.pause();
                }

                break;

            case "image":

                _this.content = document.createElement("img");
                if(!elementObj.templateBlock)
                {
                    _this.content.src = timeline.$scope.$root.appRoot + "/" + contentFolder + "/" + timeline.$scope.groupFolder
                        + "/images/" + this.resource + "?cacheBuster=" + timeline.uuid.new()+ "&auth="+timeline.$scope.$root.authToken;
                }

                _this.content.style.width = this.w + "px";
                _this.content.style.height = this.h + "px";
                break;

            case "widget":
                _this.content = document.createElement("iframe");

                if(this.widgetVersion) {
                    _this.content.src = config.baseUrlWidget + "/" + contentFolder + "/widgets/versioned/" + this.widgetVersion + "/"
                                        + this.name.toLowerCase() + ".html?cb=" + timeline.uuid.new()+ "&auth="+timeline.$scope.$root.authToken;
                } else {
                    _this.content.src = config.baseUrlWidget + "/" + contentFolder + "/widgets/" + this.name.toLowerCase()
                                        + ".html?cb=" + timeline.uuid.new()+ "&auth="+timeline.$scope.$root.authToken;
                }

                _this.content.style.width = "100%";
                _this.content.style.height = "100%";
                _this.content.classList.add("widgetIframe");
                break;

        }

        _this.content.classList.add("contentElement");

        this.holdingElement.appendChild(_this.content);
        this.holdingElement.appendChild(this.selectionHighlight);
        this.holdingElement.appendChild(this.resizeElement);
        this.holdingElement.appendChild(this.groupElement);

        this.groupElement.setAttribute("data-layerid", this.layerID);

        document.getElementById("stage").appendChild(this.holdingElement);

        if(elementObj.templateBlock &&
            ( this.type == "video" || this.type == "image") ) {
            this.holdingElement.style.backgroundColor = "rgba(239, 239, 239, 0.5)";
            this.holdingElement.style.outline = "black dotted thick";


            _this.content.style.visibility = "hidden";

            // Template Drop only supported for video / images
            this.groupElement.classList.add("templateBlock");
            this.groupElement.classList.add("templateBlock_" + this.type);
        }

        if(this.type == "video") {

            this.videoControlsHolder = document.createElement("div");
            this.videoControlsHolder.classList.add("videoControls");
            this.videoControls = document.createElement("span");
            this.videoControls.classList.add("glyphicon");
            this.videoControls.classList.add("glyphicon-play");

            this.videoControlsHolder.appendChild(this.videoControls);
            this.holdingElement.appendChild(this.videoControlsHolder);

            if(elementObj.templateBlock)
            {
                this.videoControlsHolder.style.visibility = "hidden";
            }

        } else if(this.type == "widget") {

            var widgetID = new Date().getTime();
            var widgetIframe = _this.content.contentWindow;
            var widgetScript = widgetIframe.document.createElement("script");
            widgetScript.innerHTML = "var __widgetCommunication;";
            widgetIframe.document.body.appendChild(widgetScript);

            var widgetCommunication = function(ctx) {

                return {

                    getProperties: function() {
                        try {
                            return ctx.widget().properties;
                        } catch(err) {
                            console.log(err);
                        }
                    },

                    getFunctions: function() {
                        try {
                            return ctx.widget().functions;
                        } catch(err) {
                            console.log(err);
                        }
                    },

                    loadEvent: function() {
                        ctx.addEventListener("load", function(){
                            ctx.top.postMessage(widgetID, "*");
                        });
                    }

                };

            };

            widgetIframe.__widgetCommunication = widgetCommunication(widgetIframe);

            var widgetLoadEvent = function(e) {

                if(e.data == widgetID) {

                    //window.removeEventListener("message", widgetLoadEvent);

                    if(!_this.widgetProperties) {

                        _this.widgetProperties = widgetIframe.__widgetCommunication.getProperties();

                    } else {

                        var props = widgetIframe.__widgetCommunication.getProperties(); // Defaults

                        var originalProps = JSON.parse(JSON.stringify(_this.widgetProperties)); // saved
                        var out = JSON.parse(JSON.stringify(props));

                        for (var key in out)
                        {
                            if( originalProps[key] != undefined ) {
                                var theVal = originalProps[key];
                                out[key].val = theVal;
                            }
                        }

                        _this.widgetProperties = out;

                    }

                    if(!_this.widgetFunctions) {

                        _this.widgetFunctions = widgetIframe.__widgetCommunication.getFunctions();

                    }

                    // Load the assets Images path
                    var assetsBaseURL = timeline.$scope.$root.appRoot + "/"  + contentFolder  + "/" + timeline.$scope.groupFolder + "/images/";

                    var widgetConfig = {
                        appRoot : timeline.$scope.$root.appRoot,
                        assetsBaseURL : assetsBaseURL,
                        autoStart : null,
                        groupFolder : timeline.$scope.groupFolder,
                        backendURL : config.backend,
                        authToken : timeline.$scope.$root.authToken,
                        height: _this.h,
                        width: _this.w,
                        useCache : false
                    };

                    widgetIframe.widget(_this.widgetProperties).render(widgetConfig);
                    //widgetIframe.widget(_this.widgetProperties).render(assetsBaseURL, null, timeline.$scope.groupFolder, config.backend);

                    _this.widgetElement = widgetIframe;
                }
            };

            window.addEventListener("message", widgetLoadEvent);
            widgetIframe.__widgetCommunication.loadEvent();
        }

        this.holdingElement.addEventListener("click", _this.clicked);

        this.holdingElement.addEventListener("mousedown", _this.dragStart);

        this.resizeElement.addEventListener("mousedown", _this.resizeStart);

        this.groupElement.classList.add("groupElement");

        if(elementObj.locked || elementObj.alwaysOn) {
            this.groupElement.classList.add("lock");
        }

        this.groupElement.setAttribute("x-lvl-drop-target", "true");

        //timeline.$scope.$apply();

        this.setSizePosition();
        setTimeout(timeline.$scope.setProjectLength, 0);

    };


    this.removeTemplateHighlighting = function()
    {
        this.groupElement.classList.remove("templateBlock");
        this.groupElement.classList.remove("templateBlock_" + this.type);

        this.holdingElement.style.backgroundColor = "transparent";
        this.holdingElement.style.outline = "none";

        _this.content.style.visibility = "visible";
        if(this.type == "video") {
            this.videoControlsHolder.style.visibility = "visible";
        }
    };

    this.toggleVideoPlayback = function() {

        this.paused = !this.paused;

        if(this.paused) {

            this.content.pause();
            this.videoControls.classList.add("glyphicon-play");
            this.videoControls.classList.remove("glyphicon-pause");

        } else {

            this.content.play();
            this.videoControls.classList.remove("glyphicon-play");
            this.videoControls.classList.add("glyphicon-pause");

        }

    };

    this.deSelect = function() {
        if (_this.selectionHighlight) {
            _this.selectionHighlight.classList.remove("selectionHighlight");
            _this.resizeElement.classList.remove("resizeElement");
        }
    };

    this.select = function(checkGroup) {
        if(typeof checkGroup === "undefined") {
            checkGroup = true;
        } else {
            checkGroup = false;
        }

        if (_this.selectionHighlight)
        {
            _this.selectionHighlight.classList.add("selectionHighlight");
            _this.resizeElement.classList.add("resizeElement");
        }

       // var _layer = timeline.getItemById(_this.layerID);
       // _layer.selected = true;

        /*
        if(checkGroup) {

            timeline.items.forEach(function(_layer){

                if (_layer.id === _this.layerID && _layer.parentGroupID !== null) {
                    _this.selectGroup(_layer.parentGroupID);
                }
            });

        }

        timeline.$scope.togglePane("properties", null, true);

        timeline.$scope.$apply();
        */

        //  RKC WHAT DOES THIS FUNCTION DO !!!
        //timeline.$scope.layers[0].setTimes();

    };


    this.selectGroup = function(groupID) {

        timeline.items.forEach(function(_layer){

            if(_layer.parentGroupID && _layer.parentGroupID === groupID) {

                _layer.stageElement.select(false);

            }

        });

    };

    this.dragStart = function(e) {

        e.preventDefault();
        e.stopPropagation();

        if(e.target.parentNode.classList.contains("videoControls")) {

            _this.toggleVideoPlayback();
            return false;

        }

        var lock = checkLocked(_this.layerID);

        if(lock) { return; }

        _this.holdingElement.style.pointerEvents = "none";
        _this.mouseDownTime = new Date().getTime();

        window.addEventListener("mouseup", _this.dragEnd);

        //  RKC
        //  this is the last key pressed I think so need to workout how to address
        //  it in this function ! but this is confusing code - why do we check the
        //  key is not a space? then go onto check for the shiftKey on the event and
        //  never use key!!!!!! Commented out for now

        // !SPACE
        //if(key !== 32) {

            var _layer = timeline.getItemById(_this.layerID);
            dragElementType = _layer.asset.type;

            if(e.shiftKey) {
                _layer.select(e);

            } else {

                var deselect = false;

                timeline.items.forEach(function(_layer){

                    if(_layer.id === e.target.parentNode.getAttribute("data-layerid") && _layer.selected) {
                        deselect = true;
                    }

                });

                timeline.items.forEach(function (_layer) {

                    if (_layer.canvasObject && !deselect) {
                        _layer.deSelect();
                    }

                });

                _this.holdingElement.style['zIndex'] = "999999999999";

                _layer.select(e);

            }

            // Save the original dragstart as well
            _this.dragStartCoords = {
                x: parseInt(e.pageX, 10),
                y: parseInt(e.pageY, 10)
            };

            _this.dragStartOriginal = {
                x: _this.x,
                y: _this.y
            };

            window.addEventListener("mousemove", _this.dragMove);

            e.target.style.cursor = "move";

        //}

    };

    this.dragMove = function(e) {
        e.preventDefault();
        e.stopPropagation();

        var layer = timeline.getItemById(_this.layerID);
        var lock = checkLocked(_this.layerID);

        if(lock ) { return; }

        var tempX = parseInt(e.pageX, 10);
        var tempY = parseInt(e.pageY, 10);
        var zoomMultiplier = 1 / timeline.$scope.zoom;

        timeline.items.forEach(function(_layer){

            if(_layer.selected) {
                var x = Math.floor( parseInt(_layer.canvasObject.x) + (tempX - _this.dragStartCoords.x) * zoomMultiplier);
                var y = Math.floor(parseInt(_layer.canvasObject.y) + (tempY - _this.dragStartCoords.y) * zoomMultiplier);

                _layer.x = _layer.canvasObject.x = x;
                _layer.y = _layer.canvasObject.y = y;

                _layer.canvasObject.holdingElement.style.left = x + "px";
                _layer.canvasObject.holdingElement.style.top = y + "px";

            }

        });

        if(e.target.classList.contains("groupElement"))
        {

            var targetLayerId =e.target.getAttribute("data-layerid");
            var _layer = timeline.getItemById(targetLayerId);
            if(!layer.alwaysOn && !_layer.alwaysOn && !_layer.locked && !( _layer.parent && _layer.parent.locked )) {
                if (_layer.asset.type === dragElementType)
                    e.target.classList.add("lvl-over");
                else
                    e.target.classList.add("lvl-over-red");
            }
        }
        else
        {

            Array.prototype.forEach.call(document.querySelectorAll(".groupElement"), function(_ge){

                _ge.classList.remove("lvl-over");
                _ge.classList.remove("lvl-over-red");

            });

        }

        _this.dragStartCoords = { x: tempX, y: tempY };
        this.dragged = true;

        timeline.$scope.$apply();

    };

    this.dragEnd = function(e) {

        e.preventDefault();
        e.stopPropagation();

        if(this.dragged) {
            // broadcast the undo
            timeline.$scope.$emit('modelUpdated', {
                source: 'timelineItem',
                objectId: _this.id,
                data: JSON.stringify({x: _this.dragStartOriginal.x, y: _this.dragStartOriginal.y})
            });
        }


        _this.dragged = false;
        _this.mouseDownTime = new Date().getTime() - _this.mouseDownTime;

        e.target.style.cursor = "default";

        _this.holdingElement.style['zIndex'] = _this.z;

        if(e.target.classList.contains("groupElement"))
        {
            var _layer1 = timeline.getItemById(_this.layerID);

            var targetLayerId = e.target.getAttribute("data-layerid");
            var _layer2 = timeline.getItemById(targetLayerId);

            // Dont allow Grouping to/from for always on elements
            if(!_layer1.alwaysOn && !_layer2.alwaysOn)
            {
                // Only group compatible types!
                if( _layer1.asset.type == _layer2.asset.type
                    && ( _layer1.asset.type == "image" || _layer1.asset.type == "video" )
                    )
                {
                    createLayerGroup([e.target.parentNode.getAttribute("data-layerid"), _this.layerID]);
                }
                else
                {
                    // Snap the element back to where it started
                    _layer1.x = _this.dragStartOriginal.x;
                    _layer1.y = _this.dragStartOriginal.y;
                    _layer1.setSizePosition();

                    Array.prototype.forEach.call(document.querySelectorAll(".groupElement"), function(_ge){

                        _ge.classList.remove("lvl-over");
                        _ge.classList.remove("lvl-over-red");

                    });

                }
            }

        }

        _this.holdingElement.style.pointerEvents = "auto";

        window.removeEventListener("mousemove", _this.dragMove);
        window.removeEventListener("mouseup", _this.dragEnd);

        timeline.$scope.changesMade = true;
        this.dragged = false;

        // for validation
        _this.setSizePosition();
    };

    this.resizeStart = function(e) {
        e.preventDefault();
        e.stopPropagation();

        var lock = checkLocked(_this.layerID);
        timeline.$scope.resizing = true;

        if(lock) { return; }

        _this.holdingElement.style["zIndex"] = parseInt(_this.holdingElement.style["zIndex"]) + 10000;

        _this.resizeStartCoords = {
            x: parseInt(e.screenX, 10),
            y: parseInt(e.screenY, 10)
        };

        _this.resizeStartOriginal = {
            w: _this.w,
            h: _this.h
        };

        timeline.items.forEach(function(_layer){

            if(_layer.selected) {

                _layer.canvasObject.resizeStartSize = {
                    w: parseInt(_layer.canvasObject.holdingElement.style.width, 10),
                    h: parseInt(_layer.canvasObject.holdingElement.style.height, 10)
                };

            }

        });

        window.addEventListener("mousemove", _this.resizeMove);
        window.addEventListener("mouseup", _this.resizeEnd);

    };

    this.resizeMove = function(e) {

        e.preventDefault();
        e.stopPropagation();

        _this.dragged = true;

        var lock = checkLocked(_this.layerID);

        if(lock) { return; }

        var mouseMoveX = parseInt(e.screenX, 10) - _this.resizeStartCoords.x;
        var mouseMoveY = parseInt(e.screenY, 10) - _this.resizeStartCoords.y;

        timeline.items.forEach(function(_layer){

            if(_layer.selected) {

                if(e.shiftKey) {

                    _layer.h = _layer.canvasObject.h = Math.round(_layer.canvasObject.resizeStartSize.h + ( mouseMoveY / timeline.$scope.zoom ));
                    _layer.w = _layer.canvasObject.w = Math.round(_layer.canvasObject.h * _layer.canvasObject.ratio);


                    _layer.canvasObject.holdingElement.style.width = _layer.canvasObject.w + "px";
                    _layer.canvasObject.holdingElement.style.height = _layer.canvasObject.h + "px";

                    _layer.canvasObject.content.style.width = "100%";
                    _layer.canvasObject.content.style.height = "100%";

                } else {

                    _layer.w  = _layer.canvasObject.w = Math.round(_layer.canvasObject.resizeStartSize.w + ( mouseMoveX / timeline.$scope.zoom ));
                    _layer.h = _layer.canvasObject.h = Math.round(_layer.canvasObject.resizeStartSize.h + ( mouseMoveY / timeline.$scope.zoom ));


                    _layer.canvasObject.holdingElement.style.width = _layer.canvasObject.w + "px";
                    _layer.canvasObject.holdingElement.style.height = _layer.canvasObject.h + "px";

                    if (_layer.canvasObject.type === "video") {

                        var videoRatio = parseInt(window.getComputedStyle(_layer.canvasObject.content).getPropertyValue("width"), 10) / parseInt(window.getComputedStyle(_layer.canvasObject.content).getPropertyValue("height"), 10);

                        var targetRatio = _layer.canvasObject.w / _layer.canvasObject.h;

                        var adjustmentRatio = targetRatio / videoRatio;

                        _layer.canvasObject.content.style["-webkit-transform"] = "scaleX(" + adjustmentRatio + ")";
                        _layer.canvasObject.content.style["transform"] = "scaleX(" + adjustmentRatio + ")";

                    } else if (_layer.canvasObject.type === "image") {

                        _layer.canvasObject.content.style.width = "100%";
                        _layer.canvasObject.content.style.height = "100%";

                    }

                }

            }

        });

        timeline.$scope.$apply();

    };

    this.resizeEnd = function(e) {
        // Load the assets Images path
        if(timeline.$scope.resizing) {

            e.preventDefault();
            e.stopPropagation();

            _this.holdingElement.style["zIndex"] = parseInt(_this.holdingElement.style["zIndex"]) - 10000;

            timeline.$scope.resizing = false;
            timeline.$scope.changesMade = true;

            // broadcast the undo
            timeline.$scope.$emit('modelUpdated', {
                source: 'timelineItem',
                objectId: _this.id,
                data: JSON.stringify({w: _this.resizeStartOriginal.w, h: _this.resizeStartOriginal.h})
            });
        }

        // Remove mouse listeners to avoid mouseevent issues..
        window.removeEventListener("mousemove", _this.resizeMove);
        // window.removeEventListener("mouseup", _this.resizeEnd);

        // for validation
        if(_this.dragged)
            _this.setSizePosition();

        _this.dragged = false;

    };

    this.deleteFromStage = function() {

        var lock = checkLocked(_this.layerID);

        if(lock) { return; }

        _this.holdingElement.parentNode.removeChild(_this.holdingElement);
        timeline.$scope.propertyObject = null;
        setTimeout(timeline.$scope.setProjectLength, 0);

        timeline.$scope.changesMade = true;

    };

    this.toggleLayerVisibility = function(e) {

        e.preventDefault();
        e.stopPropagation();

        _this.layerVisibility = !_this.layerVisibility;

        if(_this.layerVisibility) {
            _this.holdingElement.style.display = "block";
        } else {
            _this.holdingElement.style.display = "none";
        }

        e.target.classList.toggle("eye-invisible");

        timeline.$scope.changesMade = true;

    };

    this.setSizePosition = function() {

        var theLayer = timeline.getItemById(this.layerID);

        this.w = parseInt(theLayer.w);
        this.h = parseInt(theLayer.h);
        this.x = parseInt(theLayer.x);
        this.y = parseInt(theLayer.y);
        this.alpha = theLayer.alpha;
        this.z = theLayer.z;

        var stageProps = timeline.$scope.stageProperties;
        // Check for stage no clipping and set accordingly :
        if(stageProps.noClipping)
        {
            var stageHeight = parseInt(stageProps.dimensions.h);
            var stageWidth = parseInt(stageProps.dimensions.w);
            var scopeApply = false;

            if( this.x < 0 ) {
                this.x = 0;
                scopeApply = true;
            }

            if(this.y < 0) {
                this.y = 0;
                scopeApply = true;
            }

            if( (this.x + this.w) > stageWidth )
            {
                this.x = stageWidth - this.w;

                if( this.x <0)
                {
                    this.x = 0;
                    this.w = stageWidth;
                }

                scopeApply = true;
            }

            if( (this.y + this.h) > stageHeight )
            {
                this.y = stageHeight - this.h;

                if( this.y <0)
                {
                    this.y = 0;
                    this.h = stageHeight;
                }

                scopeApply = true;
            }

            // Send info back to layer
            theLayer.x = this.x;
            theLayer.y = this.y;
            theLayer.h = this.h;
            theLayer.w = this.w;

            if(scopeApply){
                timeline.$scope.$apply();
            }
        }

        this.holdingElement.style.width = this.w + "px";
        this.holdingElement.style.height = this.h + "px";
        this.holdingElement.style.left = this.x + "px";
        this.holdingElement.style.top = this.y + "px";
        this.holdingElement.style.opacity = this.alpha ;
        this.holdingElement.style.zIndex = this.z;

        Array.prototype.forEach.call(_this.holdingElement.childNodes, function(el){
            if(el.classList.contains("contentElement")) {
                el.style.width = "100%";
                el.style.height = "100%";
            }
        });

        timeline.$scope.changesMade = true;
    }

};
