activ.controller("projects", ["$scope", "$http", "$location","$timeout", "$compile",
    function($scope, $http, $location, $timeout, $compile){

    $scope.pageTitle = "Projects";
    $scope.newProject = false;
    $scope.projects = [];
    $scope.templates = [];
    $scope.selectedTemplate;
    $scope.selectedTemplateName = "No Template";
    $scope.showFolderSize = false;
    $scope.showListView = false;
    $scope.cacheBuster = (new Date()).getTime();
    $scope.hideInaccessibleContent = true;

    $scope.templateFolderPath = "";
    $scope.defaultProject = null;

    var groupData = JSON.parse(sessionStorage.getItem("groupData"));
    $scope.groupFolder = groupData.groupFolder;
    $scope.templateThumbnailsFolder = $scope.$parent.appRoot + "/assets/" + $scope.groupFolder + "/templates/thumbnails";

    $scope.openProject = function(id) {
        $location.path("/editor/" + id);
    };

    $scope.showProjectDetails = function(project){

        var templateDOM = $("#projectDetailsTemplate").find(".templateContent");

        // Append a new container to the body and load dialog
        $scope.modalContainer = new ModalClass({title : "Project", "onBeforeHide" : function(modal){
                var copyProjectContainer = modal.find(".copyProjectContainer");
                $("#projectDetailsTemplate").append(copyProjectContainer);
            }, "body" :'<div class="copyProjectContainer"></div>'});

        var body = $scope.modalContainer.find(".copyProjectContainer");
        body.append(templateDOM);


        // Fill info
        var cDate = new Date(project.createdOn);
        var mDate = new Date(project.lastModifiedOn);
        $scope.modalContainer.find(".createdBy").html(project.createdBy + " on " + timestampToDate(cDate.getTime()));
        $scope.modalContainer.find(".modifiedBy").html(project.lastModifiedBy + " on " + timestampToDate(mDate.getTime()));

        $scope.currentProject = project;

        $scope.newCopyProjectName = $scope.currentProject.name;
        $scope.modalContainer.find("#newCopyProjectName").val($scope.newCopyProjectName);

        $scope.modalContainer.adjustContentHeight();

        if(!$scope.$root.hasAccessRight('Projects - Create')){
            $scope.modalContainer.primaryBtn.remove();
        }
        else {
            $scope.modalContainer.primaryBtn.html("Copy Project");

            $scope.modalContainer.primaryBtn.click(function () {
                $scope.copyProject($scope.currentProject);
            });
        }
    };

    $scope.showAddProject = function() {
       // Show the project dialog
        // Reset model

        if (!$scope.$root.checkPermission('project', 'numberOfProjects', $scope.totalProjects)) {
          return $scope.$parent.activAlert("You have reached your licence limit.", 1000);
        }

        var addEntityTemplateDOM = $("#addProjectTemplate").find(".templateContent");

        // Append a new container to the body and load dialog
        $scope.modalContainer = new ModalClass({title : "Project", "onBeforeHide" : function(modal){
            var newProjectContainer = modal.find(".newProjectContainer");
            $("#addProjectTemplate").append(newProjectContainer);
        }, "body" :'<div class="newProjectContainer"></div>'});

        var body = $scope.modalContainer.find(".newProjectContainer");
        body.append(addEntityTemplateDOM);
        $scope.selectedTemplate = "";
        $scope.selectedTemplateName = "No Template";
        $("#newProjectName").val("");
        $scope.modalContainer.adjustContentHeight();
        $scope.modalContainer.primaryBtn.click(function(){
            $scope.createNewProject();
        });
    };

    $('#templatesDropDownContainer').on('show.bs.dropdown', function () {

        $timeout(function () {
            var currentBodyHeight = $scope.modalContainer.find(".modal-body").height();
            var dropDownHeight = $("#templatesDropdown").height();
            $scope.modalContainer.setBodyHeight(currentBodyHeight + dropDownHeight);
        },10);
    });

    $('#templatesDropDownContainer').on('hide.bs.dropdown', function () {
        $scope.$root.updateModalHeight($scope.modalContainer);
    });

    $scope.closeCreateNewProject = function()
    {
        $scope.newProject = false;

    };

    $scope.selectTemplate = function(template){
        if(template) {
            $scope.selectedTemplate = template.ID;
            $scope.selectedTemplateName = template.name;
        }else{
            $scope.selectedTemplate = "";
            $scope.selectedTemplateName = "No Template";
        }
    };

    $scope.showTemplatesDropdown = false;
    $scope.toggleTemplatesDropdown = function(){
        alert("here");
        $scope.showTemplatesDropdown = !$scope.showTemplatesDropdown;
    };

    $scope.newProjectName = "";
    $scope.newCopyProjectName = "";

    $scope.createNewProject = function() {

        var projectName = document.getElementById("newProjectName").value.trim();
        var template = $scope.selectedTemplate; //document.getElementById("templateSelect").value;

        if(projectName !== "") {

            var output = {};
            output.schedule = {};
            output.projectGroupId = $scope.currentProjectGroup.ID;
            output.projectData = {
                "width": 1920,
                "height": 1080,
                "background": "#FFFFFF",
                "id": 1,
                "name": projectName,
                "checkForUpdates": 600000,
                "duration": 10000,
                "layers": [],
                "templateID": template ? template : null
            };

            var sendData = JSON.stringify(output);

            var backend = $scope.backend  || $scope.$parent.backend;

            $http({ "method": "POST", "url": backend + "/createProject", "params": {}, "data": sendData }).
                success(function (data, status) {

                    if (data.success) {
                        var backend = $scope.backend  || $scope.$parent.backend;
                        $http({ "method": "POST", "url": backend + "/" + config.endpoints.createProjectThumbnail, "params": {}, "data":
                                    { fileName: data.projectFile,
                                        groupFolder: data.groupFolder.substr(data.groupFolder.lastIndexOf('/') + 1),
                                        width: 1920, height: 1080,
                                        projectId : data.projectID
                                    }
                                })
                            .success(function(createThumbData, status){ })
                            .error(function(createThumbData, status){ });

                        $scope.modalContainer.hide();
                        $location.path("/editor/" + data.projectID);

                    } else {
                        $scope.modalContainer.setStatusText(data.error, "red");
                    }
                }).error(function (data, status) {
                    $scope.modalContainer.setStatusText("Error creating project." + data.message, "red");
                });

        } else {

            $scope.$parent.activAlert("Please enter a project name", 3000);

        }

    };


    $scope.templatesLoaded = false;

    $scope.retrieveProjects = function(cb){
        var backend = $scope.backend || $scope.$parent.backend;
        var postData = {parentId : $scope.currentProjectGroup.ID};

        $http({ "method": "POST", "url": backend + "/retrieveProjects", "params": {},
                "data": postData}).
            success(function(data, status){
                if(data.success) {
                    $scope.currentProjectGroup.projects = data.projects;
                    $scope.currentProjectGroup.projectGroups = data.projectGroups;
                    $scope.totalProjects = data.projectCount;

                    if(data.defaultProject) {
                        $scope.defaultProject = data.defaultProject;
                    }

                    $scope.currentProjectGroup.dataLoaded = true;

                    if(!$scope.templatesLoaded) {
                        $http({"method": "POST", "url": backend + "/retrieveTemplates", "params": {}, "data": {}}).
                            success(function (data, status) {
                                if (data.success) {
                                    $scope.templates = data.assets;
                                    $scope.templatesLoaded = true;
                                }

                                if (cb) {
                                    cb();
                                }

                            }).
                            error(function (data, status) {
                                console.error(data);
                            });
                    }
                }

            }).
            error(function(data, status){
                console.error(data);
            });

    };

    $scope.getProjectSizeText = function(bytes) {
        if (!bytes) {
            return '0 KB';
        }

        return getFileSize(bytes);
    };

    $scope.copyProject = function(projectSelected) {

        //var projectSelected = $scope.projects[index];

        // Get new project title :
        /*
        var currentProjectFile = $scope.$parent.projectFile;
        var lastIndexOfDot = currentProjectFile.lastIndexOf(".json");
        var currentProjectName = currentProjectFile.substr(0, lastIndexOfDot);
        */
        var newTitle = $scope.newCopyProjectName; //prompt("Please Enter a Project Name", projectSelected.name);

        if( newTitle != null && newTitle != undefined )
        {
            newTitle = newTitle.trim();
            if(newTitle.length > 0)
            {

                if(newTitle == projectSelected.name){
                    $scope.$parent.activAlert("Project with the same name already exists!", 2000);
                    return;
                }

                var backend = $scope.backend  || $scope.$parent.backend;

                // valid entry, lets try and copy the current project and save
                var postData = {"copyProjectId" : projectSelected.ID,
                                "newProjectName" :  newTitle,
                                "projectName" :  projectSelected.name,
                                "projectFile" : projectSelected.projectFile
                                };
                // Call for copy project backend
                $http({ "method": "POST", "url": backend + "/copyProject", "params": {}, "data": postData }).
                    success(function (data, status) {

                        if (data.success) {

                            $scope.modalContainer.hide();
                            $scope.$parent.activAlert("Project Copied Successfully.", 2000);
                            // Add project and use the original project file to show immediate screenShot
                            data.newProject.projectFile =  projectSelected.projectFile;
                            $scope.currentProjectGroup.projects.push(data.newProject);

                        }
                        else
                        {
                            $scope.$parent.activAlert("Error creating project : " + data.error, 2000);
                        }

                    }).error(function (data, status) {});
            }
            else
            {
                // Do nothing
            }

        }

    };

    $scope.showDeleteProject = function(project)
    {
        var projectSelected = project; //$scope.projects[index];
//         var projectDD =  document.getElementById("projectInformation_delete_"+ index);
        if(confirm("Are you sure you want to delete project : " + project.name + "?") )
        {
            $scope.deleteProject(project);
        }
    };

    $scope.deleteProject = function(projectSelected)
    {
        var postData = {"projectId" : projectSelected.ID};

        var backend = $scope.backend || $scope.$parent.backend;

        $http({ "method": "POST", "url": backend + "/deleteProject", "params": {}, "data": postData }).
            success(function(data, status){
                if(data.success)
                {
                   $scope.removeProjectFromProjectGroup($scope.currentProjectGroup, projectSelected);
                }
            }).
            error(function(data, status){
                console.log(data);
            });
    };

    $scope.hideDeleteProject = function(index){
        var projectDD =  document.getElementById("projectInformation_delete_"+ index);
        projectDD.style.display = "none";
    };

    $scope.markGroupForDataLoad = function(group){
        group.dataLoaded = false;
        // Recursively call for children display groups to make sure
        // all display groups are reloaded on demand
        if(group.projectGroups && group.projectGroups.length > 0){
            for(var i=0; i< group.projectGroups.length; i++){
                $scope.markGroupForDataLoad(group.projectGroups[i]);
            }
        }
    };

    $scope.activityTime = new Date().getTime();
    $scope.getActivityEntry = function(){
        var requestTime = (new Date()).getTime();
        var backend = $scope.backend  || $scope.$parent.backend;
        $http({ method: "POST", url: backend + "/getActivityEntry", "params": {},
            "data": {types: "projects", time: $scope.activityTime}})
            .success(function(data, status){
                if(data.success && data.records && data.records.length > 0) {
                    // We got a new entry
                    // update timestamp and refresh data
                    $scope.activityTime = requestTime;
                    $scope.markGroupForDataLoad($scope.rootProjectGroup);
                    // Reload current group
                    $scope.retrieveProjects();
                }
                else{
                    $scope.getActivityEntryAfterDelay();
                }
            })
            .error(function(data){
                console.error(data);
                $scope.getActivityEntryAfterDelay();
            });
    };

    $scope.getActivityTimeout_projects = null;

    $scope.getActivityEntryAfterDelay = function()
    {
        if( $scope.getActivityTimeout_projects )
            clearTimeout($scope.getActivityTimeout_projects);

        $scope.getActivityTimeout_projects = setTimeout(function(){
            if ($location.path() == "/home/projects")
                $scope.getActivityEntry();
        }, 10000);
    };


    // Folder structure code
    $scope.breadcrumbsStack = [];
    $scope.rootProjectGroup = {ID:0, name : "Projects", dataLoaded : false,
                                projectGroups : [], projects: []};

    $scope.currentProjectGroup = $scope.rootProjectGroup;

    $scope.removeProjectFromProjectGroup = function(projectGroup, project){
        for(var i=0; i< projectGroup.projects.length; i++){
            if(projectGroup.projects[i].ID == project.ID){
                projectGroup.projects.splice(i,1);
                break;
            }
        }
    };

    $scope.removeProjectGroupFromProjectGroup = function(projectGroup, childProjectGroup){
        for(var i=0; i< projectGroup.projectGroups.length; i++){
            if(projectGroup.projectGroups[i].ID == childProjectGroup.ID){
                projectGroup.projectGroups.splice(i,1);
                break;
            }
        }
    };

    $scope.getProjectGroupByName = function(name, list, excludePg){
        var filtered = list.filter(function(t){
            var result = t.name.toLowerCase() == name.toLowerCase();
            if( excludePg && excludePg.ID == t.ID)
                result = false;

            return result
        });
        return filtered.length > 0 ? filtered[0] : null;
    };


    $scope.openProjectGroup = function(projectGroup){
        if(projectGroup.projectGroups == null){
            projectGroup.projectGroups = [];
        }

        if(projectGroup.projects == null){
            projectGroup.projects = [];
        }

        $scope.currentProjectGroup = projectGroup;
        $scope.breadcrumbsStack.push(projectGroup);

        if(!projectGroup.dataLoaded) {
            $scope.retrieveProjects();
        }
    };

    $scope.openBreadCrumbProjectGroup = function(projectGroup, index){
        var stackLen = $scope.breadcrumbsStack.length;
        if(index < stackLen - 1) {
            $scope.breadcrumbsStack.splice(0, stackLen - index);
            $scope.openProjectGroup(projectGroup);
        }
    };

    $scope.openLastFolder = function(){
        $scope.breadcrumbsStack.pop();
        $scope.currentProjectGroup = $scope.breadcrumbsStack[$scope.breadcrumbsStack.length - 1];

        if(!$scope.currentProjectGroup.dataLoaded){
            $scope.retrieveProjects();
        }
    };

    $scope.showUpdateDefaultProject = function(){

        var buildPopup = function () {
            var div = $('<div/>').html($("#updateDefaultProjectTemplate").html());
            div.find('select').attr('ui-select2', "{ width: '100%'}");
            for (var i = 0; i < $scope.defaultProjectList.length; i++) {
                var option = $('<option value="'+$scope.defaultProjectList[i].ID+'">'+$scope.defaultProjectList[i].name+'</option>');
                div.find('select').append(option);
            }
            var entityTemplate = $compile(div.html())($scope);
            // Append a new container to the body and load dialog
            $scope.modalContainer = new ModalClass({title : "Default Project", "body" : entityTemplate});
            // hook into the save button
            $scope.modalContainer.primaryBtn.click(function(){

                var selectedProject = null;
                for(var i=0; i< $scope.defaultProjectList.length; i++){
                    if($scope.defaultProjectList[i].ID == $scope.popupDefaultProject.ID){
                        selectedProject = $scope.defaultProjectList[i];
                        break;
                    }
                }
                $scope.modalContainer.resetStatus();

                // Post to server
                $http({
                    method: "POST", url: $scope.$parent.backend + "/groups", "params": {},
                    "data": {action : "updateDefaultProject", "projectId" : parseInt($scope.popupDefaultProject.ID)}
                })
                    .success(function (data, status) {

                        if (data.success) {
                            $scope.defaultProject = selectedProject;
                            $scope.modalContainer.hide();
                        }else{
                            $scope.modalContainer.setErrorText("An Error Has occurred : " +data.error);
                        }
                    })
                    .error(function (data) {
                        console.error(data);
                        $scope.modalContainer.setErrorText("An Error Has occurred : " + JSON.stringify(data));
                    });
            });
        };

        var projects = [];
        $scope.defaultProjectList = [];
        $scope.popupDefaultProject = {
            ID: 0
        };
        // load latest projects data
        $http({
            method: "POST", url: $scope.$parent.backend + "/retrieveProjects", "params": {},
            "data": {action : "allPublished"}
        })
            .success(function (data, status) {
                if (data.success) {
                    $scope.defaultProjectList = data.projects;

                    if($scope.defaultProject)
                        $scope.popupDefaultProject.ID = parseInt($scope.defaultProject.ID);
                } else {
                    $scope.$parent.activAlert("An Error Has occurred : " + data.error, 4000);
                }
                $timeout(function () {
                    buildPopup();
                }, 0);
            })
            .error(function (data) {
                console.error(data);
                $scope.$parent.activAlert("An Error Has occurred : " + JSON.stringify(data), 4000);
                $timeout(function () {
                    buildPopup();
                }, 0);
            });
    };

    $scope.showAddUpdateProjectGroup = function(editProjectGroup, projects){
            var addEntityTemplate = $("#addUpdateProjectGroupTemplate").html();

            // Append a new container to the body and load dialog
            $scope.modalContainer = new ModalClass({title : "Project Group", "body" : addEntityTemplate});

            var editMode = editProjectGroup != null;

            // hook into the save button
            $scope.modalContainer.primaryBtn.click(function(){

                var pgNameInput = $scope.modalContainer.find(".projectGroupName");
                if(Validations.checkEmpty(pgNameInput, $scope.modalContainer)){
                    var pgName = $.trim(pgNameInput.val());
                    if(!editMode && $scope.getProjectGroupByName(pgName, $scope.currentProjectGroup.projectGroups, editProjectGroup)) {
                        $scope.modalContainer.setStatusText("A Project Group with the same name already exists", "red");
                        pgNameInput.focus();
                        return;
                    }

                    var newProjectGroup = {
                        ID : 0,
                        name : pgName,
                        "parentId": $scope.currentProjectGroup.ID
                    };



                    var postData = { action : "addUpdateProjectGroup", projectGroup : newProjectGroup };

                    if(editProjectGroup){
                        postData.projectGroup.ID = editProjectGroup.ID;
                    }

                    if(projects){
                        var projectIds = "";
                        var separator = "";
                        for(var p in projects) {
                            var proj = projects[p];
                            projectIds += separator + proj.ID;
                            separator = ",";
                        }
                        postData.projectGroupProjects = projectIds;
                    }

                    var callback = function(data){
                        if(data.success){
                            $scope.modalContainer.hide();
                            if(editProjectGroup){
                                // Update props
                                editProjectGroup.name = pgName;
                            }else {
                                var newProjectGroup = data.projectGroup;
                                newProjectGroup.dataLoaded = true;
                                newProjectGroup.projects = [];
                                newProjectGroup.projectGroups = [];

                                if (projects) {
                                    for(var p in projects) {
                                        var project = projects[p];

                                        $scope.removeProjectFromProjectGroup($scope.currentProjectGroup, project);
                                        newProjectGroup.projects.push(project);
                                    }
                                }

                                $scope.currentProjectGroup.projectGroups.push(newProjectGroup);
                            }
                        }else{
                            $scope.modalContainer.setStatusText("Error : " + data.error?data.error : "", "red");
                        }
                    };

                    $scope.projectGroupPost(postData, callback);
                }

            });

            // Prefill
            if(editProjectGroup){
                $scope.modalContainer.find(".projectGroupName").val(editProjectGroup.name);
            }
        };

    $scope.removeProjectGroup = function(projectGroup){

        $scope.$root.showActivConfirm("Are you sure you want to remove the project group : " + projectGroup.name
                + "<br/>*This will also remove all child Projects/Project Groups within this group",
            function(modal){
            projectGroup.removeChildren = true;

            // Post delete action
            var postData = {action: "removeProjectGroup", "removeChildren": projectGroup.removeChildren,  "projectGroupId": projectGroup.ID}
            var callback = function (data) {
                if (data.success) {
                    $scope.removeProjectGroupFromProjectGroup($scope.currentProjectGroup, projectGroup);
                    $scope.$root.confirmModal.hide();
                } else {
                    $scope.$root.confirmModal.setStatusText("An Error Has occurred : " + data.error ? data.error:"", "red");
                }
            };

            $scope.projectGroupPost(postData, callback);
        });
    };
        $scope.projectGroupItemDrop = function(event, channel, draggedData, index, droppedOnChannel, droppedOn) {

        if(!$scope.$root.hasAccessRight('Project Groups - Edit')){
            return;
        }

        // Disable drop on same item
        if (droppedOnChannel == channel) {
            // Ignore drop on same item
            if (draggedData.ID == droppedOn.ID) {
                return;
            }
        } else if ($scope.breadcrumbsStack.length == 1 && droppedOnChannel == "breadcrumb") {
            // We're at the root, dont allow any breadcrumb action
            return;
        }

        // All good, process the drop action
        // Identify action

        // Action 1 : Project on Project Group
        if (( droppedOnChannel == "breadcrumb"
            || droppedOnChannel == "projectGroup")
            && channel == "project"
        ) {
            $http({
                method: "POST", url: $scope.$parent.backend + "/projectGroups", "params": {},
                "data": {action: "updateProjectProjectGroup", "projectId": draggedData.ID, "projectGroupId": droppedOn.ID}
            })
                .success(function (data, status) {
                    if (data.success) {
                        droppedOn.projects = droppedOn.projects ? droppedOn.projects : [];
                        // Move the user to the target team
                        droppedOn.projects.push(draggedData);
                        $scope.removeProjectFromProjectGroup($scope.currentProjectGroup, draggedData);
                    } else {
                        $scope.$parent.activAlert("An Error Has occurred : " + data.error, 4000);
                    }
                })
                .error(function (data) {
                    console.error(data);
                    $scope.$parent.activAlert("An Error Has occurred : " + JSON.stringify(data), 4000);
                });
        }
        else if ((droppedOnChannel == "breadcrumb"
            || droppedOnChannel == "projectGroup")
            && channel == "projectGroup"
        ) {
            // Action 2 : Project Group on Project Group

            // Check if the team is duplicate
            if (droppedOn.dataLoaded && $scope.getProjectGroupByName(draggedData.name, droppedOn.projectGroups)) {
                $scope.$parent.activAlert("A Project Group with the same name already exists in the target", 4000);
                return;
            }

            $http({
                method: "POST", url: $scope.$parent.backend + "/projectGroups", "params": {},
                "data": {
                    action: "updateProjectGroupProjectGroup",
                    projectGroupName: draggedData.name,
                    "childProjectGroupId": draggedData.ID,
                    "projectGroupId": droppedOn.ID
                }
            })
                .success(function (data, status) {
                    if (data.success) {
                        droppedOn.projectGroups = droppedOn.projectGroups ? droppedOn.projectGroups : [];
                        // Move the user to the target team
                        if(droppedOn.dataLoaded) {
                            droppedOn.projectGroups.push(draggedData);
                        }

                        $scope.removeProjectGroupFromProjectGroup($scope.currentProjectGroup, draggedData);
                    } else {
                        $scope.$parent.activAlert(data.error, 4000);
                    }
                })
                .error(function (data) {
                    console.error(data);
                    $scope.$parent.activAlert("An Error Has occurred : " + JSON.stringify(data), 4000);
                });
        } else if (channel == "project" && droppedOnChannel == channel) {
            // Action 3 : User on User
            // => Add a new team
            var projects = [draggedData, droppedOn];
            $scope.showAddUpdateProjectGroup(null, projects);
        }
    };

    $scope.projectGroupPost = function(postData, callback){
        $http({
            method: "POST", url: $scope.$parent.backend + "/projectGroups", "params": {},
            "data": postData
        })
            .success(function (data, status){
                callback(data);
            })
            .error(function (data) {
                callback({success:false, error:data.message});
            });
    };

    $scope.setTabCssClass = function (id) {
        if ($scope.$root.hasAccessRight('Projects - Edit')) {
            $('#' + id).addClass('dLink').addClass('dLink_active');
        }
    };

    $scope.showPanelTab = function(tabId)
    {
        // get the tab and display, if already open dont do anything
        var currentOpenDPanel = $(".dPanelActive");
        var tabContainerId = tabId + "Container";

        var currentOpenDPanelId = currentOpenDPanel.attr("id");

        if(currentOpenDPanel.attr("id") != tabContainerId)
        {
            currentOpenDPanel.hide();
            currentOpenDPanel.removeClass("dPanelActive");

            var currentOpenDPanelHeading = $("#" + currentOpenDPanelId.replace("Container", "Heading"));
            currentOpenDPanelHeading.removeClass("dLink_active");

            var dPanel = $("#"+  tabContainerId);
            var dPanelHeading = $("#" + tabId + "Heading");
            dPanelHeading.addClass("dLink_active");

            dPanel.show();
            dPanel.addClass("dPanelActive");
        }
    };

    // Initiate load
    $scope.openProjectGroup($scope.rootProjectGroup);

}]);
