/**
 * Created by Amit Yadav on 16-Jan-15.
 */

function DisplayScheduleClass(schedules, scope, container)
{
    var sts = new Object();

    // Add needed vars
    sts.schedules = schedules;
    sts.scope = scope;

    // Sets the text on each timespan
    sts.setTimeSpanText = function(textElement, text) {

        var type = Object.prototype.toString.call(textElement);

        switch(type) {

            case "[object HTMLElement]":

                textElement.innerHTML = text;
                textElement.setAttribute("title", text);

                break;

            case "[object HTMLParagraphElement]":

                textElement.innerHTML = text;
                textElement.setAttribute("title", text);

                break;

            case "[object Array]":

                textElement.forEach(function(el){

                    el.innerHTML = text;
                    el.setAttribute("title", text);

                });

                break;

            case "[object NodeList]":

                [].forEach.call(textElement, function(el){

                    el.innerHTML = text;
                    el.setAttribute("title", text);

                });

                break;

        };

    };

    sts.render = function()
    {
        for (var i = 0; i < this.schedules.length; i++) {
            var schedule = this.schedules[i];

            for (var j = 0; j < schedule.timeSpans.length; j++) {
                var timeSpan = schedule.timeSpans[j];

                var startTimeDate = new Date(timeSpan.startTime);
                var endTimeDate = new Date(timeSpan.endTime);

                var startTime = {
                    "hours":   startTimeDate.getHours() //parseInt(timeSpan.startTime.substr(0, 2), 10),
                    ,"minutes": startTimeDate.getMinutes() // parseInt(timeSpan.startTime.substr(2, 2), 10)
                };

                var endTime = {
                    "hours": endTimeDate.getHours() //parseInt(timeSpan.endTime.substr(0, 2), 10),
                    ,"minutes": endTimeDate.getMinutes() //parseInt(timeSpan.endTime.substr(2, 2), 10)
                };

                var totalLength = ((endTime.hours - startTime.hours) + ((endTime.minutes/60) - (startTime.minutes/60))) * this.scope.oneHour;
                var leftPos = ((startTime.hours + (startTime.minutes/60)) * this.scope.oneHour) + (this.scope.oneHour/2);

                var uniqueTId = (new Date()).getTime();
                for (var k = 0; k < this.scope.dayRows.length;k++) {
                    var dayRow = this.scope.dayRows[k];
                    if(timeSpan.days.indexOf(k) !== -1) {

                        var uniqueId = (new Date()).getTime();

                        var project = this.scope.getItemByID(this.scope.projects, timeSpan.projectID);
                        if(!project)
                            continue;

                        var aboutText = schedule.name + " - " + project.name
                            + " : " + twoDigits(startTime.hours) + ":" +  twoDigits(startTime.minutes)
                            +" - "
                            +twoDigits(endTime.hours) + ":" + twoDigits(endTime.minutes);

                        var timeSpanElement = document.createElement("span");
                        timeSpanElement.setAttribute("id", "timeSpanElement_" + uniqueId);
                        timeSpanElement.setAttribute("timeSpanGroupId", uniqueTId);
                        timeSpanElement.classList.add("timeSpanGroup_"+uniqueTId);

                        var leftHandle = document.createElement("span");
                        var rightHandle = document.createElement("span");
                        var timeSpanText = document.createElement("p");
                        timeSpanText.style.paddingLeft = "10px";
                        leftHandle.classList.add("handle", "leftHandle", 'ui-resizable-handle', 'ui-resizable-w');
                        rightHandle.classList.add("handle", "rightHandle", 'ui-resizable-handle', 'ui-resizable-e');

                        leftHandle.setAttribute("id", "wgrip_"+ uniqueId);
                        rightHandle.setAttribute("id", "egrip_"+ uniqueId);

                        timeSpanElement.style.width = totalLength + "px";
                        timeSpanElement.style.left = leftPos + "px";
                        //timeSpanElement.style.top = "5px";
                        timeSpanElement.style["backgroundColor"] = this.scope.timeSpanColours[timeSpan.colour].background;
                        timeSpanElement.setAttribute("data-timespanid", "" + timeSpan.timeSpanID);
                        timeSpanElement.setAttribute("data-scheduleid", "" + schedule.scheduleID);
                        timeSpanElement.setAttribute("data-projectid", "" + timeSpan.projectID);
                        timeSpanElement.setAttribute("data-startday", "" + timeSpan.days[timeSpan.days.indexOf(i)]);
                        timeSpanElement.setAttribute("data-startwidth", "" + totalLength);
                        timeSpanElement.classList.add("timeSpan", 'draggable', 'ts_'+uniqueTId);

                        timeSpanText.style["color"] = this.scope.timeSpanColours[timeSpan.colour].text;

                        leftHandle.style.width = "10px";
                        rightHandle.style.width = "10px";

                        timeSpanElement.appendChild(leftHandle);
                        timeSpanElement.appendChild(rightHandle);
                        timeSpanElement.appendChild(timeSpanText);

                        this.setTimeSpanText(timeSpanText, aboutText);
                        dayRow.appendChild(timeSpanElement);

                        // Set resizable
                        var tElem = $("#timeSpanElement_" + uniqueId);
                        tElem.css("position", "absolute");

                        // Add data for tracking
                        tElem.data("oneHour", this.scope.oneHour);
                        tElem.data("schedule", schedule);
                        tElem.data("schedules", schedules);
                        tElem.data("uniqueId", uniqueId);
                        tElem.data("uniqueTId", uniqueTId);

                        tElem.data("scope", this.scope);

                        tElem.data("project", project);
                        tElem.data("scheduleObj", this);
                        tElem.data("schedule", schedule);
                        tElem.data("timeSpan", timeSpan);

                        tElem.css("box-sizing", "content-box)");
                        tElem.css("padding", "0px");

                        // set the right click action

                        tElem.bind('contextmenu', function(e) {
                            // Show Edit :
                            var scheduleObj = $(this).data("scheduleObj");
                            var schedule = $(this).data("schedule");

                            SchedulesManager.showEdit(scheduleObj, schedule);
                            return false;
                        });

                        var oneHour = this.scope.oneHour;
                        /*
                        var displaysContainer = $("#displaysContainer");

                        var parentOffsetLeft = displaysContainer.offset().left + 150 + 20;



                        var minX = parentOffsetLeft + oneHour / 2;
                        var maxWidth = ( oneHour * 24 ); // - (oneHour /2 );
                        var maxX = parentOffsetLeft + maxWidth - tElem.width() - oneHour / 2 + 8; // - minX ;
                        */

                        //$(this).draggable("option", "containment", [minX, 0,maxX, 100 ]);

                          var draggableOptions = {
                              //axis: "x,y",      // Enable when supporting vertical drag
                              //grid : [1, 50],
                              // containment : [minX, 0,maxX, 100 ],
                              axis: "x",
                              scroll : true,
                              drag: function(event, ui ) {
                                  return SchedulesManager.updateScheduleText(this, ui, "drag");
                              },
                              stop: function(event, ui ) {
                                  // Update data when stopped
                                  // Check if we're in a clash condition
                                  var tElem = $(this);
                                  var clashed = SchedulesManager.detectClash(tElem);
                                  if(clashed)
                                  {
                                      var originalPos = ui.originalPosition;

                                      // Revert to original position
                                      tElem.css("left", originalPos.left + "px");

                                      ui.position = originalPos;
                                      //SchedulesManager.detectClash(tElem);
                                      SchedulesManager.updateScheduleText(this, ui, "drag");
                                  }
                                  else
                                  {
                                      // All good, save to db
                                      SchedulesManager.saveSchedule(this);
                                  }

                              }
                          };

                          if(timeSpan.days.length > 1){
                              draggableOptions.alsoDrag = ".ts_"+uniqueTId;
                          }

                          tElem.draggable(draggableOptions);

                          var resizableOptions = {
                              handles: {
                                  'e': '#egrip_'+uniqueId,
                                  'w': '#wgrip_'+uniqueId
                              },
                              maxHeight: tElem.height(),
                              minHeight: tElem.height(),
                              maxWidth: (oneHour * 60),
                              resize: function(event, ui ) {
                                  // Dynamic Update while resizing to maintain left positions along width
                                 return SchedulesManager.updateScheduleText(this, ui, "resize");
                              },
                              stop: function(event, ui ) {
                                  // Update data when stopped

                                  var tElem = $(this);
                                  var clashed = SchedulesManager.detectClash(tElem);
                                  if(clashed)
                                  {
                                      var originalPos = ui.originalPosition;
                                      var originalSize = ui.originalSize;

                                      var uniqueTId = tElem.data("uniqueTId");

                                      var resizeElems = $(".ts_"+uniqueTId);

                                      // Revert to original position
                                      resizeElems.css("left", originalPos.left + "px");

                                      resizeElems.css("width", originalSize.width + "px");

                                      ui.position = originalPos;
                                      ui.size = originalSize;
                                      SchedulesManager.updateScheduleText(this, ui, "resize");
                                  }
                                  else
                                  {
                                      // All good, save to db
                                      SchedulesManager.saveSchedule(this);
                                  }

                              }
                          };

                          if(timeSpan.days.length > 1){
                              resizableOptions.alsoResize = ".ts_"+uniqueTId;
                          }

                          tElem.resizable(resizableOptions);
                      }
                  }
              }
          }
      };


    // Register to SchedulesManager
    return sts;
}


function twoDigits(num)
{
    var retStr = "" + num;

    if(num < 10 )
    {
        retStr = "0" + num;
    }

    return retStr;
};

var SchedulesManager = {
    reset : function()
    {
        // Clear data and helpers
        this.schedules = [];
    },

    addSchedule : function(schedule)
    {
        this.schedules.push();
    },

    showEdit : function(scheduleObj, schedule)
    {
        var sScope = scheduleObj.scope;

        // Show the edit dialog :
        sScope.$parent.showAddSchedule(schedule, sScope);
    },

    detectClashForSchedule : function(refSchedule, schedules)
    {
        // Check if the edited schedule is clashing with others or not

        var refTimeSpan = refSchedule.timeSpans[0];
        var tsRefStartTime =  this.normalisedTime(refTimeSpan.startTime);
        var tsRefEndTime =  this.normalisedTime(refTimeSpan.endTime);

        var clashed = false;

        for (var i = 0; i < schedules.length; i++) {

            var schedule = schedules[i];
            // Check all timespans except the reference one!
            if( schedule.scheduleID != refSchedule.scheduleID)
            {
                // Compare times
                var timeSpan = schedule.timeSpans[0];

                // First check if any of the days match or not
                var daysMatch = false;

                for (var j = 0; j < timeSpan.days.length; j++) {
                    var d = timeSpan.days[j];
                    if( refTimeSpan.days.indexOf(d)!= -1 )
                    {
                        daysMatch = true;
                        break;
                    }
                }

                // If days match, only then check times
                if(daysMatch) {
                    var tsStartTime = this.normalisedTime(timeSpan.startTime);
                    var tsEndTime = this.normalisedTime(timeSpan.endTime);

                    // Check StartTime to be in the middle
                    if( tsRefStartTime > tsStartTime && tsRefStartTime < tsEndTime)
                    {
                        clashed = true;
                        break;
                    }


                    // Check EndTime to be in the middle
                    if( tsRefEndTime > tsStartTime &&  tsRefEndTime < tsEndTime)
                    {
                        clashed = true;
                        break;
                    }

                    // Enclosing cases
                    if( tsRefStartTime <= tsStartTime && tsRefEndTime >= tsEndTime)
                    {
                        clashed = true;
                        break;
                    }

                    // Enclosing cases
                    if( tsRefStartTime >= tsStartTime && tsRefEndTime <= tsEndTime)
                    {
                        clashed = true;
                        break;
                    }

                }
            }

        }

        return clashed;
    },

    detectClash : function(refScheduleElem)
    {
        // Check if the edited schedule is clashing with others or not

        var schedules = refScheduleElem.data("schedules");
        var refSchedule = refScheduleElem.data("schedule");

        var clashed = this.detectClashForSchedule(refSchedule, schedules);

        var uniqueTId = refScheduleElem.data("uniqueTId");
        var refElems = $(".ts_" + uniqueTId);

        if(clashed)
        {
            // Highlight red
            refElems.css("background-color", "red");
        }
        else
        {
            var timeSpan = refSchedule.timeSpans[0];
            var scope = refScheduleElem.data("scope");
            var colour = scope.timeSpanColours[timeSpan.colour].background;
            // Normal colour
            refElems.css("background-color", colour);
        }

        return clashed;
    },

    normalisedTime : function(dtMs)
    {
        var dtTime = new Date(dtMs);

        dtTime.setDate(1);
        dtTime.setMonth(0);
        dtTime.setYear(1970);
        dtTime.setSeconds(0);
        dtTime.setMilliseconds(0);

        return dtTime.getTime();
    },


    // Manage different timezone issues!
    // Plus same date time comparisons
    timesOffsetSinceMorning : function(startTimeMs, endTimeMs)
    {
        var retTimes = {};

        var startDateObj = new Date(startTimeMs);
        var endDateObj = new Date(endTimeMs);

        var startTimeVal =  (new Date(startTimeMs)).getTime();
        var endTimeVal =  (new Date(endTimeMs)).getTime();

        var startMinutes = (startDateObj.getHours() *60 + startDateObj.getMinutes());
        var endMinutes = (endDateObj.getHours() *60 + endDateObj.getMinutes());

        var baseDate = new Date(startTimeMs);

        // case 1: start time is in the previous day
        if(startMinutes > endMinutes)
        {
            baseDate = new Date(endTimeMs);
        }

        baseDate.setHours(0);
        baseDate.setMinutes(0);
        baseDate.setSeconds(0);
        baseDate.setMilliseconds(0);

        var baseDateVal = baseDate.getTime();

        retTimes.startTimeOffset =  startTimeVal - baseDateVal;
        retTimes.endTimeOffset =  endTimeVal - baseDateVal;

        return retTimes;
    },

    updateScheduleText : function(timeSpan, ui, mode)
    {
        // Dynamic Update while dragging
        var tElem = $(timeSpan);

        var oneHour = tElem.data("oneHour");

        var uniqueTId = tElem.data("uniqueTId");
        var tElems = $(".ts_" + uniqueTId);

        // Verify limits anc cancel if out of limites
        var cancelDrag = false;

        var minX = oneHour / 2;
        var maxWidth = ( oneHour * 24 ); // - (oneHour /2 );
        var maxX = maxWidth - tElem.width() - minX + (oneHour );

        var uiLeft =  ui.position.left;
        var leftPos = uiLeft;

        if( leftPos < minX ) {
            cancelDrag = true;
            leftPos = minX;
        }
        else if( leftPos  > maxX ) {
            cancelDrag = true;
            leftPos = maxX;
        }

        // lets check right edge in case
        if( uiLeft + tElem.width() >= ( maxWidth + (oneHour/2)))
            cancelDrag = true;

        if(cancelDrag) {

           // Revert if greater than max
            if( uiLeft + tElem.width() >= ( maxWidth + (oneHour/2)) ) {
                cancelDrag = true;
                var maxPermittedWidth = maxWidth - leftPos +  (oneHour/2);
                tElems.css("width", maxPermittedWidth + "px");
            }
        }

        // Set optimum position
        tElems.css("left", leftPos + "px");

        var currentLeft =  leftPos - (oneHour/2);
        var currentRight = leftPos + tElem.width() - (oneHour/2);

        var time = ( currentLeft / oneHour );
        var etime = ( currentRight / oneHour );

        // Convert this time into hours and minutes
        var totalMinutes = time * 60;
        var hours = parseInt(totalMinutes/60);
        var minutes = parseInt(totalMinutes - (hours * 60));

        var newStartTime = new Date();
        newStartTime.setHours(hours);
        newStartTime.setMinutes(minutes);
        newStartTime.setSeconds(0);
        newStartTime.setMilliseconds(0);

        var etotalMinutes = etime * 60;
        var ehours = parseInt(etotalMinutes/60);
        var eminutes = parseInt(etotalMinutes - (ehours * 60));

        var newEndTime = new Date();
        newEndTime.setHours(ehours);
        newEndTime.setMinutes(eminutes);
        newEndTime.setSeconds(0);
        newEndTime.setMilliseconds(0);


        // Update scope
        var timeSpan = tElem.data("timeSpan");

        timeSpan.startTime = newStartTime.getTime();
        timeSpan.endTime = newEndTime.getTime();

        var startTimeStr = twoDigits(hours) + ":" + twoDigits(minutes);

        var endTimeStr = twoDigits(ehours) + ":" + twoDigits(eminutes);

        var timeSpanGroupId = tElem.attr("timeSpanGroupId");

        var timeSpanElements = $(".timeSpanGroup_"+timeSpanGroupId);

        var schedule = tElem.data("schedule");
        var project =  tElem.data("project");

        // find the time elements and update :
        var timeElemP = timeSpanElements.find("p");
        var pText = schedule.name + " - " + project.name + " : "+ startTimeStr + " - " + endTimeStr;
        timeElemP.html(pText);
        timeElemP.attr("title", pText);

        this.detectClash(tElem);
        // Update the scope to initiate an edit
        if(cancelDrag) {
            return false;
        }

        return true;
    },

    saveSchedule : function(timeSpan)
    {
        // Fetch the current parent display and save schedules.
        var tElem = $(timeSpan);
        var scope = tElem.data("scope");
        var parentScope = scope.$parent;

        parentScope.markCurrentDisplaySchedulesEdit();


        /*
        var uniqueTId = tElem.data("uniqueTId");
        var refElems = $(".ts_" + uniqueTId);


        // Show loading..
        refElems.find("p").before('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate" '
                                +' style="display:inline-block; float:left; margin-left:10px; margin-top:2px; margin-right:5px;"></span>');

        var successCallback = function(result)
        {
            refElems.find(".glyphicon-refresh").remove();
        };

        parentScope.saveCurrentOpenDisplaySchedules(successCallback);
        */
    }

};

