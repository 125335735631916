activ.directive("editorProperties", ["$timeout", function($timeout){

    return {

        restrict: "E",
        replace: true,
        templateUrl: "./views/editorProperties.html?v=" + version,
        scope: {},

        link: function(scope, element, attr){
            jscolor.init();
        },

        controller: function($scope){

            $scope.$on('ngRepeatFinished', function(ngRepeatFinishedEvent) {
                // init the speed fields based on reveal mode
                $scope.executeCheckedAction($scope.$parent.propertyObject.canvasObject.widgetProperties.revealMode);
            });

            $scope.$on('undoFinished', function(args) {
                // reset colours
                resetFieldColours();
            });

            function resetFieldColours() {
                // manually reset the stage field colour
                var stageColorField = document.getElementById('stagePropertiesColor');
                if(stageColorField) {
                    var colour = $scope.$parent.stageProperties.colour;
                    setFieldColour(stageColorField, colour);
                }

                var isObjectSelected = $scope.$parent.propertyObject != null;
                if(isObjectSelected) {
                    var properties = $scope.$parent.propertyObject.canvasObject.widgetProperties || [];
                    for(var i in properties) {
                        var property = properties[i];

                        // manually reset the field colour
                        if(property.valueType === 'colour') {
                            var field = document.getElementById(property.id)
                            var colour = property.val;
                            setFieldColour(field, colour);
                        }
                    }
                }
            }

            function setFieldColour(field, colour) {
                if(!field) {
                    return;
                }

                field.style.backgroundColor = colour;

                if(colour === '#000000') {
                    field.style.color = '#FFFFFF';
                } else {
                    field.style.color = '#000000';
                }
            }

            $scope.executeCheckedAction = function(widgetProperty) {
                if(widgetProperty != null && widgetProperty.id == 'revealMode') {
                    if(widgetProperty.val == true) {
                        document.getElementById("revealSpeedContainer").style.display="block";
                        document.getElementById("revealFontSizeContainer").style.display="block";
                        document.getElementById("scrollSpeedContainer").style.display="none";
                    } else {
                        document.getElementById("revealSpeedContainer").style.display="none";
                        document.getElementById("revealFontSizeContainer").style.display="none";
                        document.getElementById("scrollSpeedContainer").style.display="block";
                    }
                }
            };

            $scope.updateStageDimension = function() {
                var screenType = $scope.$parent.stageProperties.screenType;
                if(screenType != 'custom') {
                    $scope.$parent.stageProperties.dimensions.w = parseInt(screenType.split("x")[0]);
                    $scope.$parent.stageProperties.dimensions.h = parseInt(screenType.split("x")[1]);
                    $scope.$parent.stageProperties.updateDimensions();
                }
            };

            $scope.flipOrientation = function() {

                // broadcast the undo
                $scope.$emit('modelUpdated', {
                    source: '$parent.stageProperties',
                    data: JSON.stringify(stripMonitorObject($scope.$parent.stageProperties))
                });

                var width = $scope.$parent.stageProperties.dimensions.w;
                var height = $scope.$parent.stageProperties.dimensions.h;

                $scope.$parent.stageProperties.dimensions.w = height;
                $scope.$parent.stageProperties.dimensions.h = width;
                $scope.$parent.stageProperties.updateDimensions();
            };

            $scope.getAlphaText = function(alpha) {
                return parseInt(alpha * 100);
            };

            $scope.togglePropertySectionVisibility = function(e) {
                e.target.classList.toggle("open");
                var toggleElement = e.target.getAttribute("data-for");
                document.getElementById(toggleElement).classList.toggle("hidden");
            };

            $scope.updateProperty = function() {

                $scope.$parent.propertyObject.canvasObject.setSizePosition();

            };

            $scope.getRSSDataFeeds = function()
            {
                return $scope.$parent.rssDataFeeds;
            };


            $scope.getDivSelectors = function()
            {
                return $scope.$parent.getDivSelectors();
            };


            $scope.setWidgetTimout = null;

            $scope.waitAndSetWidget = function()
            {
                if($scope.setWidgetTimout)
                {
                    $timeout.cancel($scope.setWidgetTimout);
                    $scope.setWidgetTimout = null;
                }

                $scope.setWidgetTimout = $timeout(function(){
                    $scope.setWidgetTimout = null;
                    $scope.$parent.propertyObject.setWidget();
                }, 500);
            };


            $scope.showRSSInputDialog = function(){
                var entityTemplate = $("#rssFormatTemplate").html();
                // Append a new container to the body and load dialog
                $scope.modalContainer = new ModalClass({title : "RSS Feed Details", "body" : entityTemplate});
                window.currentModal = $scope.modalContainer;

                // hook into the save button
                $scope.modalContainer.primaryBtn.click(function(){
                    // Update format for current widget property
                    // Update url

                    var feedURLInput = window.currentModal.find(".feedURL");
                    var feedURL = feedURLInput.val().trim();

                    if(!feedURL || feedURL.length == 0){
                        window.currentModal.setErrorText("Please enter a valid url");
                        feedURLInput.focus();
                    }

                    $scope.$parent.propertyObject.canvasObject.widgetProperties.feedURL.val = feedURL;

                    // get the current feed json and save to format
                    $scope.$parent.propertyObject.canvasObject.widgetProperties.feedFormat.val = getPillsJSON();
                    // Call widget update to load new proeprties
                    $scope.setWidget($scope.$parent.propertyObject.canvasObject.widgetProperties.feedFormat);

                    $scope.modalContainer.hide();
                });

                // Prefill format from current rss widget
                var feedURLInput = $scope.modalContainer.find(".feedURL");
                feedURLInput.val($scope.$parent.propertyObject.canvasObject.widgetProperties.feedURL.val);

                // Prefill feedformat
                var feedFormat = $scope.$parent.propertyObject.canvasObject.widgetProperties.feedFormat.val;
                if(feedFormat.length > 0){
                    var feedFormatJSON = JSON.parse(feedFormat);

                    for(var i=0; i<feedFormatJSON.length; i++){
                        var ff = feedFormatJSON[i];
                        addRSSPillToModal(ff);
                    }
                }
                else{
                    var formatBlock = window.currentModal.find(".rssFormatContainer");
                    formatBlock.hide();
                }
            };

            $scope.getWidgetRangeText = function(widgetProperty) {
                var widgetValue = widgetProperty.val;
                var widgetResult = widgetProperty.result;

                if(widgetResult === 'percentage') {
                    return "(" + parseInt(widgetValue * 100) + "%)";
                } else {
                    return "(" + widgetValue + ")";
                }
            };

            $scope.eventField = null;

            $scope.focusCallback = function($event) {
                if($event === null) {
                    return;
                }

                $scope.eventField = $event;
            };

            $scope.setWidget = function(widgetProperty) {
                var isValid = validateWidgetProperty(widgetProperty, $scope.eventField);

                if(!isValid) {

                    var val = widgetProperty.val;
                    if(!isNaN(val)) {
                        if(widgetProperty.min && val < widgetProperty.min) {
                            widgetProperty.val = widgetProperty.min || 0;
                        } else if(widgetProperty.max && val > widgetProperty.max) {
                            widgetProperty.val = widgetProperty.max || 0;
                        }
                    }

                    $scope.waitAndSetWidget();
                } else {
                    $scope.waitAndSetWidget();
                }
            };

            var validateWidgetProperty = function(widgetProperty, element) {
                var valid = true;

                if(widgetProperty && widgetProperty.valueType == "Number")
                {
                    var val = widgetProperty.val;
                    if(isNaN(val))
                    {
                        valid = false;

                        if(widgetProperty.min && widgetProperty.max) {
                            $scope.$parent.toggleHelpCallout(element, "Value must be between " + widgetProperty.min + " and " + widgetProperty.max);
                        } else if(widgetProperty.min) {
                            $scope.$parent.toggleHelpCallout(element, "Value must be greater or equal to " + widgetProperty.min);
                        }
                    }
                    else
                    {
                        if(widgetProperty.min && val < widgetProperty.min) {
                            valid = false;
                            $scope.$parent.toggleHelpCallout(element, "You cannot enter a value less than " + widgetProperty.min);
                        }
                        else if(widgetProperty.max && val > widgetProperty.max) {
                            valid = false;
                            $scope.$parent.toggleHelpCallout(element, "You cannot enter a value greater than " + widgetProperty.max);
                        }
                    }
                }

                return valid;
            };

            $scope.isColour = function(widgetProperties) {
                if(widgetProperties.valueType && widgetProperties.valueType == "colour") {
                    jscolor.init();
                    return true;
                } else {
                    return false;
                }
            };

            $scope.isWidgetVideo = function(widgetProperty) {
                return widgetProperty != null && widgetProperty.asset.type == "video";
            };

            $scope.isWidgetImage = function(widgetProperty) {
                return widgetProperty != null && widgetProperty.asset.type == "image";
            };

            $scope.isContainer = function(widgetProperty) {
                return widgetProperty != null && widgetProperty.children != null && widgetProperty.children.length > 0;
            };

            $scope.isList = function(widgetProperties) {

                if(widgetProperties.valueList) {
                    return true;
                } else {
                    return false;
                }

            };

            $scope.listOverride = function(widgetProperties) {

                if(widgetProperties.valueList && widgetProperties.valueList[0] === "*") {
                    return true;
                } else {
                    return false;
                }

            };

            $scope.getTitle = function(widgetProperty){
                return widgetProperty.helpText?widgetProperty.helpText : widgetProperty.propertyName;
            };

            $scope.getImages = function(){
                return $scope.$parent.projectLibrary.images;
            };

            $scope.getFontNameFromValue = function(value){
                var fontName = "";
                var allFonts = $scope.getWebFonts();
                for(f in allFonts){
                    var font = allFonts[f];
                    if(font.URL == value){
                        fontName = font.name;
                        break;
                    }
                }

                return fontName;
            }

            $scope.$root.getFontNameFromValue = $scope.getFontNameFromValue;

            $scope.wfOpened = true;
      
            $scope.wfComparator = function (wf, viewValue) {
              return viewValue === secretEmptyKey || (''+ wf).toLowerCase().indexOf((''+viewValue).toLowerCase()) > -1;
            };
            
            $scope.onWFFocus = function (e) {
              $timeout(function () {
                $(e.target).trigger('input');
                $(e.target).trigger('change'); // for IE
              });
            };
      
            $scope.getWebFonts = function(){
                return $scope.$parent.webFonts;
            };

            $scope.getSplashScreenImages = function(){
                return $scope.$parent.projectLibrary.images;
            };

            $scope.isLibraryImage = function(widgetProperties) {
                return (widgetProperties.valueType && widgetProperties.valueType == "libraryImage");
            };

            $scope.isLibraryWebFont = function(widgetProperties) {
                return (widgetProperties.valueType && widgetProperties.valueType == "libraryWebFont")
            };

            $scope.autoFillElements = function(){
                // Auto space the children
                var parent = $scope.$parent.propertyObject;
                if(parent.autoFill) {
                    parent.autoFillElements();
                }
            };

            $scope.repeatItems = function(){
                var parent = $scope.$parent.propertyObject;
                if(!parent.repeatItems) {
                    parent.repeatCount = 1;
                    parent.containerFixStartEnd();
                }
            };

            $scope.checkEnterAndChangeSetTimes = function(ev) {
                if (ev.keyCode == 13) {  // Enter key
                    $scope.startEndTimeChange();
                }
            };

            $scope.startEndTimeChange = function(){
                $scope.$parent.propertyObject.setTimes();
                $scope.$parent.propertyObject.containerFixStartEnd();
            };

            $scope.getSelected = function(widgetValue, optionValue){
                return (widgetValue == optionValue) ? "selected" : "";
            };

        }

    }

}]);

function rssFormatInputKeyup(event){
    var key = event.which || event.keyCode;

    if(key == 13)
    {
        addRssFormatPill(event.target);
        // Return key
    }
}


function comboboxDropDownToggle(elem){
    var jElem = $(elem);
    var parent = jElem.parent();
    var dropDown = parent.next();

    if( !dropDown.is(":visible")){
        dropDown.show();
    }else{
        dropDown.hide();
    }

    window.currentModal.adjustContentHeight();
}

var currentPill = {};
var currentEditPillDom = null;
var currentPillMode = "";

function resetRSSPillBox(inputElem){
    currentPill = {"Column Name" : "", "Alias":"", "Field Format":""};
    currentPillMode = "Column Name";

    if(inputElem){
        inputElem.val("");
        inputElem.attr("placeholder", "Column Name");
        inputElem.focus();
    }
}

resetRSSPillBox();

function comboboxDropDownClick(elem){
    var jElem = $(elem);
    var parent = jElem.closest(".comboBox");

    var dropDownInput = parent.find(".comboBoxInput");

    var dropDownInputVal = dropDownInput.val();
    currentPill[currentPillMode] = dropDownInputVal;

    var selectedOption = jElem.find(".comboBoxLISpan").text();
    currentPillMode = selectedOption;

    dropDownInput.attr("placeholder", selectedOption);

    dropDownInput.val(currentPill[selectedOption]);

    comboboxDropDownToggle(dropDownInput);
}

function addRssFormatPill(elem){
    // Load Pill Data
    var jElem = $(elem);
    var parent = jElem.closest(".comboBox");
    var dropDownInput = parent.find(".comboBoxInput");

    // Update data
    var dropDownInputVal = dropDownInput.val();
    currentPill[currentPillMode] = dropDownInputVal;
    var validate = validatePill(currentPill);
    if(validate.valid) {
        addRSSPillToModal(currentPill);
        resetRSSPillBox(dropDownInput);
    }else{
        window.currentModal.setErrorText(validate.error);
    }
}

function feedURLUpdated(elem)
{
    // check if the format block should be shown or not
    var val = $(elem).val().trim().toLowerCase();
    var formatBlock = window.currentModal.find(".rssFormatContainer");
    if(val.indexOf("file:") == 0){
        formatBlock.show();
    }else{
        formatBlock.hide();
    }
    window.currentModal.adjustContentHeight();
}


function updateRSSPill(domElem, pillData){
    // Change HTML Text / Title / data
    domElem.find(".rssFormatPillText").html(pillData["Column Name"]);

    var titleText = "";
    titleText += "Alias : " + ( (pillData["Alias"] && pillData["Alias"].trim().length > 0)
            ?pillData["Alias"] : "No Alias Specified" );
    titleText += "\nFormat : " + (  (pillData["Field Format"] && pillData["Field Format"].trim().length > 0)
            ?pillData["Field Format"] : "No Format Specified" );

    domElem.attr("title", titleText);

    domElem.data("pillData", pillData);

}

function addRSSPillToModal(pill){

    var rssFormatPills = window.currentModal.find(".rssFormatPills");
    var newRssPill = $("<div onmouseenter='rssPillMouseEnter(this)' "
        +"onmouseleave='rssPillMouseLeave(this)' "
        +"class='rssFormatPillContainer'>"
        +"<span class='rssFormatPillText'>"+pill["Column Name"]+"</span>"

        +"<span class='rssPillDelete glyphicon glyphicon-minus-sign' onclick='deleteRSSPill(this)'></span>"
        +"</div>");

    rssFormatPills.append(newRssPill);

    // Copy data into the dom for reference
    var pillData = jQuery.extend({}, pill);

    newRssPill.data("pillData", pillData);

    var titleText = "";
    titleText += "Alias : " + ( (pillData["Alias"] && pillData["Alias"].trim().length > 0)
                                    ?pillData["Alias"] : "No Alias Specified" );
    titleText += "\nFormat : " + (  (pillData["Field Format"] && pillData["Field Format"].trim().length > 0)
                                    ?pillData["Field Format"] : "No Format Specified" );

    newRssPill.attr("title", titleText);

    newRssPill.click(function(){

        $(".rssFormatPillContainerEdit").removeClass("rssFormatPillContainerEdit");
        $(this).addClass("rssFormatPillContainerEdit");

        // Show Edit
        var editPillButtons = window.currentModal.find(".editPillButtons");
        var addPillButton = window.currentModal.find(".addPillButton");

        editPillButtons.show();
        addPillButton.hide();

        var thisRssFormatPillContainer = $(this).closest(".rssFormatPillContainer");
        var thisPillData = thisRssFormatPillContainer.data("pillData");

        // Prefill data
        var inputElem = window.currentModal.find(".comboBoxInput");
        resetRSSPillBox(inputElem);

        currentPill = jQuery.extend({}, thisPillData);

        // Update the current pill
        currentEditPillDom = $(this);
        inputElem.val(thisPillData["Column Name"]);

    });

    window.currentModal.adjustContentHeight();

    if(rssFormatPills.hasClass('ui-sortable')){
        // Refresh sortable
        rssFormatPills.sortable( "refresh" );
    }else{
        // Update sortable
        rssFormatPills.sortable({
            items: "> .rssFormatPillContainer",
            helper: "clone"
        });
        rssFormatPills.disableSelection();
    }
    rssFormatPills.disableSelection();
    window.currentModal.adjustContentHeight();
}

function saveEditPill(elem){
    var editPillButtons = window.currentModal.find(".editPillButtons");
    var addPillButton = window.currentModal.find(".addPillButton");

    editPillButtons.hide();
    addPillButton.show();

    var dropDownInput = window.currentModal.find(".comboBoxInput");

    // Update data
    var dropDownInputVal = dropDownInput.val();
    currentPill[currentPillMode] = dropDownInputVal;

    updateRSSPill(currentEditPillDom, currentPill);

    $(".rssFormatPillContainerEdit").removeClass("rssFormatPillContainerEdit");
    var inputElem = window.currentModal.find(".comboBoxInput");
    resetRSSPillBox(inputElem);
}

function validatePill(pill, ignorePill){
    window.currentModal.resetStatus();
    var result = {"valid" : true, "error" : null};
    // Check blank column name
    var columnName = pill["Column Name"].trim().toLowerCase();
    if( columnName.length == 0 ){
        result.valid = false;
        result.error = "Blank Column Name is not allowed";
    }
    else{
        // Check duplicate
        var currentPillsArray = getPillsArray();
        for(var i=0; i<currentPillsArray.length; i++ ){
            var cp = currentPillsArray[i];
            if(ignorePill && cp == ignorePill ){
                continue;
            }
            else{
                if(cp["Column Name"].trim().toLowerCase() == columnName){
                    result.valid = false;
                    result.error = "Column Name already exists";
                    break;
                }
            }
        }
    }

    return result;
}

function cancelEditPill(elem){
    var editPillButtons = window.currentModal.find(".editPillButtons");
    var addPillButton = window.currentModal.find(".addPillButton");

    editPillButtons.hide();
    addPillButton.show();

    $(".rssFormatPillContainerEdit").removeClass("rssFormatPillContainerEdit");

    var inputElem = window.currentModal.find(".comboBoxInput");
    resetRSSPillBox(inputElem);
}

function removeSpacesFromString(str){
    return str.replace(/\s+/g, '');
}

function deleteRSSPill(elem){
    var jElem = $(elem);
    var parentPill = jElem.closest(".rssFormatPillContainer");
    parentPill.remove();
    window.currentModal.adjustContentHeight();
}

function rssPillMouseEnter(elem){
    var jElem = $(elem);
    var rssPillDelete = jElem.find(".rssPillDelete");
    rssPillDelete.show();
}

function getPillsJSON(){
    var pillsJSON = "[]";

    var currentPillsArray = getPillsArray();
    pillsJSON = JSON.stringify(currentPillsArray);
    return pillsJSON;
}

function getPillsArray(){
    var currentPillsArray = [];
    var currentPills = window.currentModal.find(".rssFormatPillContainer");
    for(var i=0; i< currentPills.length; i++ ){
        var pill = $(currentPills[i]);
        var pillData = pill.data("pillData");
        currentPillsArray.push(pillData);
    }

    return currentPillsArray;
}

function rssPillMouseLeave(elem){
    var jElem = $(elem);
    var rssPillDelete = jElem.find(".rssPillDelete");
    rssPillDelete.hide();
}

function getFormattedMSTime(timeVal){

    timeVal = Math.floor( timeVal/1000 );

    // Timeval is in seconds
    var hours = Math.floor(timeVal/(60*60));
    var minutes = Math.floor( ( timeVal % (60*60))/60 );
    var seconds = timeVal % 60;

    var retVal = twoDigits(seconds);

    retVal = twoDigits(minutes) + ":" + retVal;

    if(hours > 0){
        retVal = twoDigits(hours) + ":" + retVal;
    }

    return retVal;
}

function getMSFromFormattedTime(formattedVal){

    // Strip all non valid chars
    formattedVal = stripMSVal(formattedVal);

    var matches = formattedVal.match(/:/g);

    var counts = matches?  matches.length : 0;

    var split = formattedVal.split(":");
    var msVal = 0;
    if(counts > 0) {
        if (counts > 1) {
            // We got hours
            msVal = intVal(split[counts-2]) * 60 * 60;
            // Minutes
            msVal += intVal(split[counts-1]) * 60;
            // Seconds
            msVal += intVal(split[counts]);
        }
        else {
            // Minutes
            msVal += intVal(split[counts-1]) * 60;
            // Seconds
            msVal += intVal(split[counts]);
        }
    }
    else{
        msVal = intVal(formattedVal);
    }

    // Milliseconds
    msVal = msVal * 1000;

    return msVal;
}

function stripMSVal(str){

    var retStr = str.toString().replace(/[^0-9,:]/g, '');
    return retStr;
}

function intVal(numStr){

    if(!numStr || isNaN(numStr))
        return 0;

    return parseInt(numStr);
}

var secretEmptyKey = '[$empty$]';
activ.directive('focusMe', function($timeout, $parse) {
    return {
        //scope: true,   // optionally create a child scope
        link: function(scope, element, attrs) {
            var model = $parse(attrs.focusMe);
            scope.$watch(model, function(value) {
                if(value === true) { 
                    $timeout(function() {
                        element[0].focus();
                    });
                }
            });
        }
    };
  })
  .directive('emptyTypeahead', function () {
    return {
      require: 'ngModel',
      link: function (scope, element, attrs, modelCtrl) {
        // this parser run before typeahead's parser
        modelCtrl.$parsers.unshift(function (inputValue) {
          var value = (inputValue ? inputValue : secretEmptyKey); // replace empty string with secretEmptyKey to bypass typeahead-min-length check
          modelCtrl.$viewValue = value; // this $viewValue must match the inputValue pass to typehead directive
          return value;
        });
        
        // this parser run after typeahead's parser
        modelCtrl.$parsers.push(function (inputValue) {
          return inputValue === secretEmptyKey ? '' : inputValue; // set the secretEmptyKey back to empty string
        });
      }
    }
  });

activ.directive('mstimeinput', ['$filter', function ($filter) {
    return {
        require: '^ngModel',
        restrict:'A',
        link:function (scope, elm, attrs, ctrl) {

            if (!ctrl) return;

            ctrl.$formatters.unshift(function (a) {
                var modelVal = ctrl.$modelValue;
                if(!modelVal)
                    return 0;

                return getFormattedMSTime(modelVal);
            });

            elm.bind('change', function(){

                // validate and update the model value
                var val = elm.val();
                var formattedVal = getFormattedMSTime(getMSFromFormattedTime(val));
                elm.val(formattedVal);
            });

            ctrl.$parsers.unshift(function (viewValue) {
                if(!viewValue)
                    return 0;

                var plainNumber = getMSFromFormattedTime(viewValue);
                return plainNumber;
            });


        }
    };
}]);
