activ.directive("monitorField", function() {
    return {
        scope:{
            monitorField: "="
        },
        require: "ngModel",
        link: function(scope, element, attrs, ctrl) {
            var oldValue;
            element.bind('focus', function(event) {
                oldValue = JSON.stringify(stripMonitorObject(scope.monitorField));
           });

            element.bind('blur', function(event) {
                scope.$apply(function() {
                    var newValue = JSON.stringify(stripMonitorObject(scope.monitorField));
                    var hasChanged = oldValue !== newValue;
                    if(hasChanged) {
                        scope.$emit('modelUpdated', {
                            source: attrs.monitorField,
                            data: oldValue
                        });
                    }
                });
            });
        }
    };
});
activ.directive("monitorModel", function() {
    return {
        scope:{
            monitorModel: "="
        },
        require: "ngModel",
        link: function(scope, element, attrs, ctrl) {
            ctrl.$parsers.push(function(value) {
                scope.$emit('modelUpdated', {
                    source: attrs.monitorModel,
                    data: JSON.stringify(stripMonitorObject(scope.monitorModel))
                });

                return value;
            });
        }
    };
});

function stripMonitorObject(object) {
    if(!object) {
        return;
    }

    var ignoreStageKeys = ['projectName','projectLength'];
    var ignoreCanvasKeys = ['groupElement','holdingElement','resizeElement','content','widgetElement','widgetFunctions'];
    var ignorePropertyKeys = ['parent','children','canvasObject','lhsTimelineElement','rhsTimeSpanGroup','rhsTimelineElement'];

    var ignoreKeys = [];
    ignoreKeys = ignoreKeys.concat(ignoreStageKeys);
    ignoreKeys = ignoreKeys.concat(ignoreCanvasKeys);
    ignoreKeys = ignoreKeys.concat(ignorePropertyKeys);
    return withoutKeys(object, ignoreKeys);
}

function withoutKeys(obj, keys) {
    return Object.keys(obj).filter(function (key) {
        return keys.indexOf(key) === -1;
    }).reduce(function (result, key) {
        result[key] = obj[key];
        return result;
    }, {});
}