var activ = angular.module("activ", ["ngRoute", "ngResource", "ngSanitize", "ui.bootstrap", "lvl.directives.dragdrop", "angularFileUpload", "ang-drag-drop", "ngDialog", "nvd3", 'ui.select2']);

activ.config(function($routeProvider) {

    $routeProvider.when("/login", {

        templateUrl: "./views/login.html",
        controller: "login",
        caseInsensitiveMatch: true

    }).when("/forgotpassword/:token", {

        templateUrl: "./views/forgotpassword.html",
        controller: "forgotpassword",
        caseInsensitiveMatch: true

    }).when("/home", {

        templateUrl: "./views/home.html",
        controller: "home",
        caseInsensitiveMatch: true

    }).when("/home/profile", {

        templateUrl: "./views/profile.html",
        controller: "profile",
        caseInsensitiveMatch: true

    }).when("/home/teams", {

        templateUrl: "./views/teams.html",
        controller: "teams",
        caseInsensitiveMatch: true

    }).when("/home/projects", {

        templateUrl: "./views/projects.html",
        controller: "projects",
        caseInsensitiveMatch: true

    }).when("/home/displays", {

        templateUrl: "./views/displays.html",
        controller: "displays",
        caseInsensitiveMatch: true

    }).when("/home/library", {

        templateUrl: "./views/library.html",
        controller: "library",
        caseInsensitiveMatch: true

    }).when("/editor/:projectID", {

        templateUrl: "./views/editor.html",
        controller: "editor",
        caseInsensitiveMatch: true

    }).when("/editor/template/:templateID", {

        templateUrl: "./views/editor.html",
        controller: "editor",
        caseInsensitiveMatch: true

    }).when("/home/register", {

        templateUrl: "./views/register.html",
        controller: "register",
        caseInsensitiveMatch: true

    }).when("/home/cms", {

        templateUrl: "./views/cms.html",
        controller: "cms",
        caseInsensitiveMatch: true

    }).when("/home/cms/:pageID", {

        templateUrl: "./views/cms.html",
        controller: "cms",
        caseInsensitiveMatch: true

    }).when("/home/licence", {

        templateUrl: "./views/licence.html",
        controller: "licence",
        caseInsensitiveMatch: true

    }).when("/contact", {

        templateUrl: "./views/contact.html",
        controller: "contact",
        caseInsensitiveMatch: true
    }).otherwise({
        redirectTo: '/login'
    });

});

function sortAssets(a,b) {
    var typeA = a.type;
    var typeB = b.type;

    if (typeA == "folder" && typeB != "folder") {
        return -1;
    }
    else if (typeA != "folder" && typeB == "folder") {
        return 1;
    }

    return sortAlphabetically(a, b);
}

function sortAlphabetically(a, b) {
    if(a.name < b.name) return -1;
    if(a.name > b.name) return 1;
    return 0;
}

function getFileSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
}
