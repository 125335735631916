activ.controller("forgotpassword", ["$scope", "$http", "$location", "$routeParams", function($scope, $http, $location, $routeParams){


    $scope.errorText = null;
    $scope.statusText = null;

    $scope.showLogin = function(){
        $location.path('/login');
    };

    $scope.token = $routeParams.token;
    $scope.resetPassword = function(){

        $scope.errorText = null;
        $scope.statusText = null;

        var resetPassForm = $("#resetPassForm");

        var newPasswordInput = resetPassForm.find("#newPassword");
        var confirmPasswordInput = resetPassForm.find("#confirmPassword");

        var newPasswordVal = newPasswordInput.val();
        var confirmPasswordVal = confirmPasswordInput.val();

        // Validate and post
        if(newPasswordVal.length == 0){
            $scope.errorText = "Please enter a password";
            newPasswordInput.focus();
            return;
        }

        if(confirmPasswordVal.length == 0){
            $scope.errorText = "Please confirm password";
            confirmPasswordInput.focus();
            return;
        }

        // Validate password
        // Check password match
        if(newPasswordVal != confirmPasswordVal){
            $scope.errorText = "Passwords do not match";
            newPasswordInput.focus();
            return;
        }

        // Validate password Strength
        var validate = Validations.checkPasswordStrength(newPasswordVal);

        if(validate.passwordErr != null)
        {
            newPasswordInput.focus();
            $scope.errorText = validate.passwordErr;
            return;
        }

        var passwordData = {
            password : newPasswordInput.val(),
            token : $scope.token
        };

        var postData = {
            action: "resetUserPassword",
            passwordData : passwordData
        };

        $scope.statusText = "Submitting. Please Wait..";
        $http({
            method: "POST", url: $scope.$parent.backend + "/forgotPassword", "params": {},
            "data": postData
        })
            .success(function (data, status){

                $scope.statusText = null;
                if(data.success){
                    $("#resetPassInputs").hide();
                    $("#successMessage").show();
                }else{
                    $scope.errorText = "Error : " + data.error;
                }
            })
            .error(function (data) {
                $scope.statusText = null;
                $scope.errorText = "Error : " + data.error;
            });

    };
}]);