activ.controller("editor", ["$scope", "$http", "uuid", "$routeParams", "$location",
    function($scope, $http, uuid, $routeParams, $location){

    var PROPERTY_PANEL_RESIZE_KEY = 'propertyPanelResize';

    $scope.undoManager = new EditorUndoManager($scope);

    $scope.timelineEntryCount = 0;

    $scope.loadingProjectData = true;

    $scope.groupFolder = $scope.$root.getGroupFolder();

    $scope.version = version;

    $scope.libraryItems = {
        "videos": [],
        "images": [],
        "widgets": [],
        "templates" : []
    };

    $scope.countTemplates = 0;

    // Override logout
    $scope.$parent.logout = function()
    {
        // Protection for For editor choice alert,
        // User can click cancel!
        if(!$scope.changesMade)
        {
            sessionStorage.removeItem("userData");
        }

        var referrer = sessionStorage.getItem('referrer');
        sessionStorage.clear();
        config.authToken = null;
        if (referrer && referrer !== "false") {
          window.location = referrer + '?action=logout';
        } else {
          $location.path("#/login");
        }
    };

    $scope.$on('$locationChangeStart', function(event, newurl, oldurl) {

        if($scope.changesMade && !$scope.changesMadeChoice) {

            event.preventDefault();

            var newHash = newurl.split("#/")[1];

            // logout check is needed here
            $scope.$parent.activChoice("Do you want to save your changes before you exit?", [
                {
                    "label": "Save",
                    "fn": function() {
                        $scope.exportProjectJSON("", function(){
                            $scope.changesMadeChoice = true;

                            if(newHash.toLowerCase() == "login" )
                            {
                                $scope.changesMade = false;
                                $scope.$parent.logout();
                            }
                            else {
                                window.location.hash = "#/" + newHash;
                            }
                        });
                    }
                },
                {
                    "label": "Don't Save",
                    "fn": function() {
                        $scope.changesMadeChoice = true;
                        if(newHash.toLowerCase() == "login" )
                        {
                            $scope.changesMade = false;
                            $scope.$parent.logout();
                            $scope.$apply();
                        }
                        else {
                            window.location.hash = "#/" + newHash;
                        }
                    }
                },
                {
                    "label": "Cancel",
                    "fn": function() { }
                }
            ]);

        }

    });

    /*

    EDITOR VARIABLES

    */

    // Check if we are editing a template
    $scope._projectID = $routeParams.projectID;
    $scope.projectId = $routeParams.projectID;

    $scope.isTemplate = location.hash.toLowerCase().indexOf("template") != -1;
    $scope.templateID = null;

    if( $scope.isTemplate){
        $scope.templateID = $routeParams.templateID;
    }

    var sideMenuType = "properties";
    var sidePaneOpen = true;
    var resizePaneMouse = {
        x: 0,
        y: 0
    };
    var resizeElementDimensions = {
        w: 0,
        h: 0
    };
    var resizeElement;
    var resizeElements;
    var stageEl = null;
    var stageContainer = null;
    var editorContainer = null;
    var dragCoords = { x: 0, y: 0 };
    var stageCoords = { x: 0, y: 0 };
    var key = null;

    $scope.projectLibrary = {};
    $scope.zoom = 0.5;
    $scope.timeLineZoom = 1.0;
    timeline.$scope = $scope;
    $scope.oneSecond = parseInt(50 * $scope.timeLineZoom);
    $scope.layers = [];
    $scope.groupCount = 0;
    $scope.totalSelected = 0;
    $scope.multipleSelected = true;
    $scope.projectLibraryView = true;
    $scope.toggleLibraryPaneText = "Open full library";
    $scope.propertyObject = null;

    $scope.changesMade = false;
    $scope.changesMadeChoice = false;
    $scope.resizing = false;


    $scope.editorDomReady = function(){
        stageEl = document.getElementById("stage");
        stageContainer = document.getElementById("stageHolder");
        editorContainer = document.getElementById("editorContainer");

        $scope.$watch("zoom", function(){

            stageEl.style["-webkit-transform"] = "scale(" + $scope.zoom + ")";
            stageEl.style["transform"] = "scale(" + $scope.zoom + ")";

        });

    };

    $scope.scrollBarSizes = (function() {

        // Retrieves the width and height of a scrollbar in the browser
        var div = document.createElement("div");
        div.style.overflow = "scroll";
        div.style.visibility = "hidden";
        div.style.position = 'absolute';
        div.style.width = '100px';
        div.style.height = '100px';
        document.body.appendChild(div);

        var w = div.offsetWidth - div.clientWidth;
        var h = div.offsetHeight - div.clientHeight;

        document.body.removeChild(div);

        return {
            width: w,
            height: h
        };

    })();

    $scope.round = function(value, decimals) {
        return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
    };

    $scope.getLayerById = function(layerID) {

        var l = false;

        $scope.layers.forEach(function(_layer){

            if(_layer.id === layerID) {
                l = _layer;
            }

        });

        return l;

    };

    $scope.getLayersInGroup = function(groupID) {

        var l = [];

        $scope.layers.forEach(function(_layer){

            if(_layer.parentGroupID === groupID) {

                l.push(_layer);

            }

        });

        return l;

    };

    $scope.setProjectLength = function() {

        var endTime = null;

        $scope.layers.forEach(function(_layer){

            if(_layer.type === "layer") {

                if (endTime === null) {
                    endTime = _layer.endTime;
                }

                if (endTime <= _layer.endTime) {
                    endTime = _layer.endTime;
                }

            }

        });

        // Done in timeline
       // $scope.stageProperties.projectLength = Math.ceil(endTime);

        if (!$scope.$$phase)
            $scope.$apply()

    };

    /*

    EDITOR VIEW FUNCTIONS

    */

    $scope.zoomIn = function(e) {

        e.preventDefault();
        e.stopPropagation();

        if($scope.zoom < 1.7) {
            $scope.zoom = $scope.round($scope.zoom + 0.1, 1);
        }

    };

    $scope.zoomOut = function(e) {

        e.preventDefault();
        e.stopPropagation();

        if($scope.zoom > 0.3) {
            $scope.zoom = $scope.round($scope.zoom - 0.1, 1);
        }

    };

    var stageDragStart = function(e) {

        e.stopPropagation();
        e.preventDefault();

        if(key && key === 32) {

            dragCoords.x = parseInt(e.pageX, 10);
            dragCoords.y = parseInt(e.pageY, 10);
            stageCoords.x = parseInt(window.getComputedStyle(stageEl).getPropertyValue("left"), 10);
            stageCoords.y = parseInt(window.getComputedStyle(stageEl).getPropertyValue("top"), 10);
            window.addEventListener("mousemove", stageDrag);

        } else if(e.target.getAttribute("id") === "stage")
        {
            // DeSelect all
            timeline.items.forEach(function(_layer){
                _layer.deSelect();
            });

            $scope.propertyObject = null;
            // TRIGGER Here
            $scope.$apply();
        }
    };

    var stageDrag = function(e) {

        e.stopPropagation();
        e.preventDefault();

        var xMove = parseInt(e.pageX, 10) - dragCoords.x;
        var yMove = parseInt(e.pageY, 10) - dragCoords.y;

        stageEl.style.left = (stageCoords.x + xMove) + "px";
        stageEl.style.top = (stageCoords.y + yMove) + "px";

    };

    var stageDragEnd = function(e) {

        if(stageEl && $(stageEl).hasClass("moving"))
        {
            e.stopPropagation();
            e.preventDefault();

            stageEl.classList.remove("moving");
            window.removeEventListener("mousemove", stageDrag);
        }

    };

    var stageHolderClick = function(e) {

        e.stopPropagation();
        e.preventDefault();

        // Deselect all
        if(e.target.getAttribute("id") === "stageHolder") {

            timeline.items.forEach(function(_layer){
                _layer.deSelect();
            });

            $scope.propertyObject = null;

            // TRIGGER Here

            $scope.$apply();
        }

    };

    var mouseZoom = function(e) {

        if(e.wheelDelta > 0) {

            $scope.zoomIn(e);

        } else if(e.wheelDelta < 0) {

            $scope.zoomOut(e);

        }

        $scope.$apply();

    };

    var keyDown = function(e) {

        key = e.keyCode;

        // SPACE
        if(key === 32) {
            stageEl.classList.add("moving");
        } else {
            stageEl.classList.remove("moving");
        }

        var shiftKey = e.shiftKey;
        var macWindowKey = 91;

        // Process Arrow Keys
        var arrowKeyPressed = key >=37 && key<=40;

        if(arrowKeyPressed && document.activeElement.nodeName != "INPUT")
        {
            var positionDelta = {"x": 0, "y" : 0};
            var deltaFactor = shiftKey?10:1;

            // MOVE THE SELECTED ASSET USING ARROW KEYS
            switch (key) {
                // LEFT ARROW
                case 37 :
                {
                    positionDelta.x = -1 * deltaFactor;
                }
                    break;

                // UP ARROW
                case 38 :
                {
                    positionDelta.y = -1 * deltaFactor;
                }
                    break;

                // Right ARROW
                case 39 :
                {
                    positionDelta.x = 1 * deltaFactor;
                }
                    break;

                // DOWN ARROW
                case 40 :
                {
                    positionDelta.y = 1 * deltaFactor;
                }
                    break;

            }

            // Update selected stage element's position
            timeline.items.forEach(function(_layer)
            {
                if(_layer.selected)
                {
                    // update layer position property
                    _layer.canvasObject.x = _layer.x = parseInt(_layer.x) + positionDelta.x;
                    _layer.canvasObject.y = _layer.y = parseInt(_layer.y) +  positionDelta.y;

                    // Update DOM Element
                    _layer.canvasObject.setSizePosition();

                    // TRIGGER Here
                }
            });


            // Update scope to update properties window
            $scope.$apply();
        }

        /*
        // G
        if(key === 71) {
            createLayerGroup();
        }
        */

        // DELETE OR BACKSPACE
        if(e.keyCode === 46 || e.keyCode === 8) {

            if(e.keyCode === 8 && document.activeElement.nodeName == "BODY") {
                e.preventDefault();
            } else if( (e.keyCode === 8 || e.keyCode === 46) && document.activeElement.nodeName == "INPUT") {
                return;
            }

            timeline.$scope.$emit('modelUpdated', {
                source: 'timelineModified',
                data: JSON.stringify(timeline.getLayers(null))
            });


            timeline.deleteSelected();
        }
        
        if( e.metaKey || e.ctrlKey || key == macWindowKey) {
            if ( key == 83) {
                e.preventDefault();
                // Ctrl + s or Cmd + s to save project
                $scope.exportProjectJSON();
            } else if(key == 89) {
                e.preventDefault();
                $scope.undoManager.redo();
            } else if(key == 90) {
                e.preventDefault();
                $scope.undoManager.undo();
            }

            /*)
            else if ( key == 67 ) {  // C pressed
                // Copy Selected Item
                e.preventDefault();
                // set selected item if any
                $scope.copiedItem = timeline.getSelectedItem();
            }
            else if( key == 86 ) {  // V pressed
                // Paste Selected Item
                if(!$scope.copiedItem) {
                    return;
                }

                e.preventDefault();

                // We got a valid selected item
                // Where to paste?
                var currentSelectedItem = timeline.getSelectedItem();
                var addToItem = null;

                var newItem = new TimeLineItem(timeline.uuid.new());
                newItem.copy($scope.copiedItem);

                if(currentSelectedItem){
                    // Is this a container?
                    if(currentSelectedItem.hasChildren()){
                        addToItem = currentSelectedItem;
                    }
                    else{
                        // Are we a part of a container?
                        addToItem = currentSelectedItem.parent ? currentSelectedItem.parent : currentSelectedItem;
                    }

                    // Add item to selected element
                    addToItem.addChildTimelineItem(newItem, false);
                }
                else{
                    // we do not have any item selected, drop on canvas
                    newItem.x = parseInt($scope.stageProperties.dimensions.w)/2 - newItem.w/2;
                    newItem.y = parseInt($scope.stageProperties.dimensions.h)/2 - newItem.h/2;
                    $scope.dropped(null, null, null, newItem);
                }
            }
            */
        }

    };

    var keyUp = function(e) {

        key = null;
        stageEl.classList.remove("moving");

        var escKey = 27;
        if (e.keyCode == escKey) {
           // when esc pressed then end the resize aswell
           resizePaneEnd(e);
        }
    };

    var createTemplateBlock = function(layerObject) {

        var templateBlock = document.createElement("div");
        templateBlock.style.left = layerObject.x + "px";
        templateBlock.style.top = layerObject.y + "px";
        templateBlock.style.width = layerObject.w + "px";
        templateBlock.style.height = layerObject.h + "px";
        templateBlock.style.zIndex = 10000 + layerObject.z;
        templateBlock.style.backgroundColor = layerObject.asset.colour;
        templateBlock.style.position = "absolute";
        templateBlock.classList.add("templateBlock");
        templateBlock.setAttribute("x-lvl-drop-target", "true");
        templateBlock.setAttribute("templateBlockID", layerObject.id);

        document.getElementById("stage").appendChild(templateBlock);

    };

    $scope.dropped = function(dragEl, dropEl, dropEvent, layerLoadObject) {
        if(!layerLoadObject) {

            dropEvent = dropEvent.originalEvent || dropEvent;

            var item = $scope.projectLibrary[dragEl.getAttribute("data-category")][dragEl.getAttribute("data-categoryIndex")];

            var itemType = item.type;
            itemType = itemType.split("/")[0];
            item.type = itemType;

            var xPosition = parseInt(dropEvent.offsetX, 10);
            var yPosition = parseInt(dropEvent.offsetY, 10);

            if(item.type == "template"){
                // This is a template, load all child layers
                var jsonData = item.templateJSON;
                var layers = jsonData.layers;
                for(var i=0; i< layers.length; i++){
                    var l = layers[i];
                    if(l.layerType == "layer") {
                        l.id = uuid.new();
                        l.locked = true;
                        $scope.dropped(null, null, null, l);
                    }
                }
                return;
            } else if(item.type == "video") {
                xPosition = 0;
                yPosition = 0;
            } else if(item.type == "widget") {
                item.widgetId = item.ID;
                item.widgetVersion = item.version;
            }

            var elementObj = {
                itemID: uuid.new(),
                name: item.name,
                type: item.type,
                resource: item.fileName,
                width: parseInt(item.width, 10),
                height: parseInt(item.height, 10),
                x: xPosition,
                y: yPosition,
                z: Math.max( timeline.getMaxZ() + 1 , 10000 ),
                alpha: 1,
                layerID: null,
                locked: false,
                widgetVersion: item.version
            };

            var layerObj = {
                name: elementObj.name,
                id: uuid.new(),
                type: "layer",
                parentGroupID: null,
                assetID: item.assetID,
                stageElement: new stageElement(elementObj),
                duration: item.duration ? item.duration : null
            };

            layerObj.stageElement.layerID = layerObj.id;

            // broadcast the undo
            timeline.$scope.$emit('modelUpdated', {
                source: 'timelineModified',
                data: JSON.stringify(timeline.getLayers(null))
            });

            if (dropEvent.target.classList.contains("groupElement")) {

                dropEvent.target.classList.remove("lvl-over");
                dropEvent.target.classList.remove("lvl-over-red");
                var id = dropEvent.target.parentNode.getAttribute("data-layerid");
                var tli = timeline.getItemById(id);

                // Check types
                if(tli.asset.type == item.type && (item.type == "image" || item.type == "video"))
                {
                    item.duration = layerObj.duration;

                    if(dropEvent.target.classList.contains("templateBlock")) {
                        // Remove the first Child to replace the asset
                        tli.updateTemplateChild(layerObj, elementObj.name, item);
                    } else {
                        if (tli.children.length == 0) {
                            // convert current timeline item into a child item
                            var convertedChild = new TimeLineItem(uuid.new(), tli.name, 0, 0, 0, 0, 0, 0, tli.startTime, tli.duration, tli.asset, tli.canvasObject);
                            convertedChild.parent = tli;
                            tli.children.push(convertedChild);
                        }

                        tli.addChild(layerObj.id, elementObj.name, item);
                    }

                    timeline.renderTimeline();
                }
            } else {
                //function(id, name, x, y, z, w, h, alpha, startTime, duration, asset, canvasObject) {
                timeline.add(layerObj.id, elementObj.name, elementObj.x, elementObj.y, elementObj.z,
                    elementObj.width, elementObj.height, elementObj.alpha,
                    0, layerObj.duration, item, layerObj.stageElement);

                layerObj.stageElement.addToStage();
            }

            $scope.changesMade = true;
            $scope.timelineEntryCount++;

            //  update bindings
            $scope.$apply();
        } else {

            if(layerLoadObject.asset.type == "templateBlock") {

                createTemplateBlock(layerLoadObject);

            }
            else
            {
                // Add from file
                var layerType = layerLoadObject.layerType;
                if(layerType == "group")
                {
                   // Do Nothing

                }
                else
                {
                    var item = layerLoadObject.asset;

                    var elementObj = {
                        itemID: layerLoadObject.id,
                        name: item.name,
                        type: item.type,
                        resource: item.fileName,
                        autoFill : layerLoadObject.autoFill,
                        repeatItems : layerLoadObject.repeatItems,
                        repeatCount : layerLoadObject.repeatCount ? layerLoadObject.repeatCount : 1,
                        snapRepeatIndex : layerLoadObject.snapRepeatIndex,
                        width: parseInt(layerLoadObject.w, 10),
                        height: parseInt(layerLoadObject.h, 10),
                        x: parseInt(layerLoadObject.x, 10),
                        y: parseInt(layerLoadObject.y, 10),
                        z: parseInt(layerLoadObject.z, 10),
                        alpha: layerLoadObject.alpha ? parseFloat(layerLoadObject.alpha) : 1,
                        widgetId: item.widgetId,
                        widgetVersion: item.widgetVersion,
                        widgetProperties: item.widgetProperties || null,
                        widgetType: item.widgetType,
                        startTime : parseInt(layerLoadObject.startTime, 10),
                        endTime : parseInt(layerLoadObject.endTime,10),
                        duration: parseInt(layerLoadObject.endTime) - parseInt(layerLoadObject.startTime),
                        layerID: layerLoadObject.id,
                        locked: layerLoadObject.locked,
                        visible :  layerLoadObject.visible,
                        alwaysOn: layerLoadObject.alwaysOn?layerLoadObject.alwaysOn:null,
                        isCollapsed : layerLoadObject.isCollapsed ? layerLoadObject.isCollapsed : false,
                        templateBlock:layerLoadObject.templateBlock ? true : false
                    };

                    var layerObj = {
                        name: elementObj.name,
                        id: layerLoadObject.id,
                        type: "layer",
                        alwaysOn : layerLoadObject.alwaysOn,
                        autoFill : layerLoadObject.autoFill,
                        repeatItems : layerLoadObject.repeatItems,
                        repeatCount : layerLoadObject.repeatCount,
                        snapRepeatIndex : layerLoadObject.snapRepeatIndex,
                        parentGroupID: null,
                        assetID: item.id,
                        stageElement: new stageElement(elementObj),
                        startTime : parseInt(layerLoadObject.startTime),
                        endTime : parseInt(layerLoadObject.endTime),
                        duration: parseInt(layerLoadObject.endTime) - parseInt(layerLoadObject.startTime)
                    };


                    var parentGroupId =  layerLoadObject.parentGroupID;
                    if(parentGroupId != null)
                    {
                        var tli = timeline.getItemById(parentGroupId);

                        if(!tli) {
                            // create parent
                            tli = timeline.add(parentGroupId, elementObj.name, elementObj.x, elementObj.y, elementObj.z,
                                elementObj.width, elementObj.height, elementObj.alpha,
                                layerLoadObject.startTime, (layerLoadObject.endTime - layerLoadObject.startTime), item, layerObj.stageElement);

                            //  interface properties
                            tli.locked = elementObj.locked;
                            tli.visible = elementObj.visible;
                            tli.isCollapsed = elementObj.isCollapsed;
                            tli.alwaysOn = elementObj.alwaysOn;
                            tli.autoFill = elementObj.autoFill;
                            tli.repeatItems = elementObj.repeatItems;
                            tli.repeatCount = elementObj.repeatCount;
                            tli.snapRepeatIndex = elementObj.snapRepeatIndex;

                            layerObj.stageElement.layerID = parentGroupId;
                            layerObj.stageElement.addToStage();
                        }

                        // add child
                        item.duration = parseInt(layerObj.duration, 10);
                        item.startTime = parseInt(layerObj.startTime, 10);
                        tli.addChild(layerObj.id, elementObj.name, item);
                    }
                    else
                    {
                        var tli = timeline.add(layerObj.id, elementObj.name, elementObj.x, elementObj.y, elementObj.z,
                            elementObj.width, elementObj.height, elementObj.alpha,
                            layerLoadObject.startTime, (layerLoadObject.endTime - layerLoadObject.startTime), item, layerObj.stageElement);

                        //  interface properties
                        tli.locked = elementObj.locked;
                        tli.visible = elementObj.visible;
                        tli.isCollapsed = elementObj.isCollapsed;
                        tli.alwaysOn = elementObj.alwaysOn;
                        tli.autoFill = elementObj.autoFill;
                        tli.repeatItems = elementObj.repeatItems;
                        tli.repeatCount = elementObj.repeatCount;
                        tli.snapRepeatIndex = elementObj.snapRepeatIndex,

                        layerObj.stageElement.layerID = layerObj.id;
                        layerObj.stageElement.addToStage();
                    }
                    $scope.timelineEntryCount++;
                }
            }

        }

    };

    $scope.stageProperties = {
        screenType: "1920x1080",
        dimensions: { w: 1920, h: 1080 },
        colour: "#FFFFFF",
        projectLength: 0,
        splashScreen: "",
        noClipping: false,

        "updateDimensions": function() {

            stageEl.style.width = this.dimensions.w + "px";
            stageEl.style.height = this.dimensions.h + "px";

            stageEl.style.left = "calc(50% - (" + this.dimensions.w + "px / 2))";
            stageEl.style.top = "calc(50% - (" + this.dimensions.h + "px / 2))";

        },

        "updateColour": function() {

            stageEl.style.backgroundColor = this.colour;

        },

        /*
        "updateFlipOrientation" : function()
        {
            timeline.flipOrientation = this.flipOrientation;
        },
        */

        "updateNoClipping" : function()
        {
            timeline.noClipping = this.noClipping;

            if( this.noClipping )
            {
                // Check for all elements to be withing ht
                for(var i=0; i< timeline.items.length; i++)
                {
                    var item = timeline.items[i];
                    item.setSizePosition();
                }
            }

        },

        "updateProjectLength": function() {



        }

    };

    $scope.sortZ = function() {

        $scope.layers.forEach(function(_layer){

            if(_layer.type === "layer" && _layer.stageElement.holdingElement) {
                _layer.stageElement.z = _layer.stageElement.holdingElement.style.zIndex = 10000 + _layer.z;
            }

        });

    };
    /*

    EDITOR UI VIEW FUNCTIONS

    */

    $scope.togglePane = function(pane, e, dontClose) {

        if(e) {
            e.preventDefault();
            e.stopPropagation();
        }

        var toggleSidePane = function() {

            if(sidePaneOpen && sideMenuType === pane && !dontClose) {

                document.getElementById("sideMenu").classList.add("hidden");
                sidePaneOpen = false;

                [].forEach.call(document.querySelectorAll(".menuTabs ul li"), function(el){

                    if(el.getAttribute("data-for") !== "timeline") {
                        el.classList.remove("selected");
                    }

                });

            } else if(!sidePaneOpen && sideMenuType === pane && !dontClose) {

                document.getElementById("sideMenu").classList.remove("hidden");

                [].forEach.call(document.querySelectorAll(".menuTabs ul li"), function(el){

                    if(el.getAttribute("data-for") === pane) {
                        el.classList.add("selected");
                    } else if(el.getAttribute("data-for") !== "timeline"){
                        el.classList.remove("selected");
                    }

                });

                sidePaneOpen = true;
                sideMenuType = pane;

            } else {

                [].forEach.call(document.querySelectorAll(".sideMenuPane"), function(el){
                    if(el.getAttribute("id") === pane) {
                        el.style.display = "block";
                    } else {
                        el.style.display = "none";
                    }
                });

                [].forEach.call(document.querySelectorAll(".menuTabs ul li"), function(el){

                    if(el.getAttribute("data-for") === pane) {
                        el.classList.add("selected");
                    } else if(el.getAttribute("data-for") !== "timeline"){
                        el.classList.remove("selected");
                    }

                });

                document.getElementById("sideMenu").classList.remove("hidden");
                sidePaneOpen = true;
                sideMenuType = pane;
            }

        };

        if(!$scope.projectLibraryView && pane!= "libraryLarge"){
            // Close full library first
            $scope.togglePane("libraryLarge", e, dontClose);
        }
        switch(pane) {

            case "librarySmall":

                document.getElementById(pane).parentNode.classList.remove("fullWidth");
                toggleSidePane();

                break;

            case "libraryLarge":

                document.getElementById(pane).parentNode.classList.toggle("fullWidth");

                if($scope.toggleLibraryPaneText === "Open full library") {

                    $scope.toggleLibraryPaneText = "Close full library";
                    $scope.projectLibraryView = false;

                } else {

                    $scope.toggleLibraryPaneText = "Open full library";
                    $scope.projectLibraryView = true;

                }

                break;

            case "properties":

                document.getElementById(pane).parentNode.classList.remove("fullWidth");
                $scope.toggleLibraryPaneText = "Open full library";
                $scope.projectLibraryView = true;

                toggleSidePane();

                break;

            case "projectPanel":

                document.getElementById(pane).parentNode.classList.remove("fullWidth");
                $scope.toggleLibraryPaneText = "Open full library";
                $scope.projectLibraryView = true;

                toggleSidePane();

                break;


        }

    };

    var resizePaneStart = function(e) {

        e.preventDefault();
        e.stopPropagation();

        resizeElement = e.target;
        resizeElements = [];

        resizePaneMouse.x = parseInt(e.pageX, 10);
        resizePaneMouse.y = parseInt(e.pageY, 10);

        var resizeId = resizeElement.getAttribute('resize-with-id');
        if(resizeId) {
            resizeElements = getAllResizeElementsWithId(resizeId);
        }

        resizeElementDimensions.w = parseInt(window.getComputedStyle(resizeElement.parentNode).getPropertyValue("width"), 10);
        resizeElementDimensions.h = parseInt(window.getComputedStyle(resizeElement.parentNode).getPropertyValue("height"), 10);

        window.addEventListener("mousemove", resizePaneMove);
        window.addEventListener("mouseup", resizePaneEnd);
    };

    function getAllResizeElementsWithId(resizeId) {
        var matchingElements = [];
        var allElements = document.getElementsByTagName('*');
        for (var i = 0, n = allElements.length; i < n; i++) {
            if (allElements[i].getAttribute('resize-id') == resizeId) {
                matchingElements.push(allElements[i]);
            }
        }
        return matchingElements;
    }

    var resizePaneMove = function(e) {

        e.preventDefault();
        e.stopPropagation();

        var resizeMinimum = resizeElement.getAttribute('resize-minimum') || 20;

        var resizeAmount = 0;
        if(resizeElement.classList.contains("horizontalResize")) {
            resizeAmount = resizeElementDimensions.w + (parseInt(e.pageX, 10) - resizePaneMouse.x);
        } else {
            resizeAmount = resizeElementDimensions.h - (parseInt(e.pageY, 10) - resizePaneMouse.y);
        }

        if(resizeElements.length > 0 ) {
            resizeElements.forEach(function(element) {
                updateElementSize(element, resizeAmount, resizeMinimum);
            });
        } else {
            updateElementSize(resizeElement.parentNode, resizeAmount, resizeMinimum);
        }
    };

    function updateElementSize(element, toSize, minimumSize) {
        if(toSize <= minimumSize) {
            localStorage.setItem(PROPERTY_PANEL_RESIZE_KEY, minimumSize);
            element.style["flexBasis"] = minimumSize + "px";
        } else {
            localStorage.setItem(PROPERTY_PANEL_RESIZE_KEY, toSize);
            element.style["flexBasis"] = toSize + "px";
        }
    }

    var resizePaneEnd = function(e) {
        e.preventDefault();
        e.stopPropagation();

        window.removeEventListener("mousemove", resizePaneMove);
        window.removeEventListener("mouseup", resizePaneEnd);
    };

    $scope.getInteger = function(val)
    {
        if(isNaN(val))
        {
            var cleanVal = val.replace(/[^0-9 ]/g, '');

            if (isNaN(cleanVal))
                cleanVal = 0;
        }

        return parseInt(cleanVal);
    };

    $scope.$watch("timeLineZoom", function(){

        timeline.updateTimelineZoom();

    });

    $scope.getProjectSize = function() {
        var bytes = timeline.getProjectSize();
        if (!bytes) {
            return '';
        }

        return '(' + getFileSize(bytes) + ')';
    };

    /*

    EDITOR FILE FUNCTIONS

    */

    var createProjectThumbnail = function() {
        $http({ "method": "POST", "url": $scope.$parent.backend +  "/" + config.endpoints.createProjectThumbnail, "params": {},
            "data": { "groupFolder": $scope.groupFolder, "projectId": $scope.projectId,"fileName": $scope.projectFile,
                "width": $scope.stageProperties.dimensions.w, "height": $scope.stageProperties.dimensions.h } }).success(function(data, status){
        }).error(function(data, status){

        });
    };

    $scope.checkProjectDataLoading = function(){
        if($scope.loadingProjectData){
            $scope.$parent.activAlert("Please wait. Project data is being updated.", 2000);
            return true;
        }

        return false;
    }

    $scope.exportProjectJSON = function(saveType, cb) {
        // Dont allow user to save until the project loads completely.
        if($scope.checkProjectDataLoading()){
            return;
        }

        // Validate project length
        var endTime = timeline.projectLength;
        if(endTime < 10000){
            $scope.$parent.activAlert("The project length is too short.<br/>"
                +"Please make sure the project length is at least 10 seconds.", 4000);
            return;
        }

        var overlappingLiveVideo = false;

        // Check for any overlay on the youtube live widget
        for(var i=0; i< timeline.items.length; i++)
        {
            var tItem = timeline.items[i];

            if(tItem.name.toLowerCase() == "ytl") {
                // Traverse all other elements and check intersection..
                for (var j = 0; j < timeline.items.length; j++) {
                    var tItem2 = timeline.items[j];

                    if (tItem2.id != tItem.id) {
                        if (tItem.intersectsWith(tItem2)) {
                            overlappingLiveVideo = true;
                            break;
                        }
                    }
                }
            }

            if(overlappingLiveVideo){
                break;
            }
        }
        if(overlappingLiveVideo){
            $scope.$parent.activAlert("Please make sure no element overlaps the Youtube Live video.", 4000);
            return;
        }

        if($scope.isTemplate){
            saveType = "template";
        }

        var getAssetByID = function(id) {

            var item = null;

            for(var key in $scope.libraryItems) {

                $scope.libraryItems[key].forEach(function(_item){

                    if(_item.assetID === id) {
                        item = _item;
                    }

                });

            }

            return item;

        };

        var project = {};
        var output = {};

        project.width = $scope.stageProperties.dimensions.w;
        project.height = $scope.stageProperties.dimensions.h;
        project.background = $scope.stageProperties.colour;
        project.id = $scope._projectID;
        project.name = $scope.stageProperties.projectName;
        project.noClipping = $scope.stageProperties.noClipping;
        project.discoverable = $scope.stageProperties.discoverable;
        project.assetsPrepareTimeout = $scope.stageProperties.assetsPrepareTimeout;

        project.splashScreen = $scope.stageProperties.splashScreen;
        project.screenType = $scope.stageProperties.screenType;

        // project.flipOrientation = $scope.stageProperties.flipOrientation;

        project.checkForUpdates = 600000; // TODO: Add to properties
        project.duration = parseInt($scope.stageProperties.projectLength, 10);
        project.isTemplate = saveType == "template" ? true : false;
        project.zoomLevel = parseInt($scope.zoom , 10);
        project.timeLineZoom = $scope.timeLineZoom;
        project.layers = timeline.getLayers(saveType);

        output.schedule = {};
        output.projectData = project;
        output.projectSize = timeline.getProjectSize();

        output.saveType =  saveType;

        if(saveType == "preview") {
            return output;
        } else if(saveType == "template") {
            var saveAsTemplateCallback = function(templateName){

                if(templateName.length == 0)
                    return;

                var template = output.projectData;
                template.duration = 0;

                for (var i = 0; i < template.layers.length; i++)
                {
                    var layer = template.layers[i];
                    // Mark as templates
                    layer.asset.templateBlock = true;
                    layer.templateBlock = true;
                    layer.locked = true;
                    layer.startTime = 0;
                    layer.endTime = 0;

                    // Remove any groups and children
                    if(layer.layerType == "group")
                    {
                        // Remove the parent layer
                        template.layers.splice(i,1);
                        i--;

                        var refParentId = layer.id;
                        // Find and remove all children,
                        // We don't need them
                        var firstAsset = true;
                        for (var j = 0; j < template.layers.length; j++)
                        {
                            var cLayer = template.layers[j];

                            if(cLayer.parentGroupID && cLayer.parentGroupID == refParentId)
                            {
                                // Remove all but the last layer asset
                                if(!firstAsset)
                                {
                                    template.layers.splice(j, 1);
                                    j--;
                                }
                                firstAsset = false;
                            }
                        }
                    }
                    else
                    {
                        layer.templateBlock = layer.asset.type;
                    }

                }

                // Rename the layers with layer types
                var imagesCount = 0;
                var videosCount = 0;

                // sort by z index
                template.layers.sort(function (a, b) {
                    var val = a.z - b.z;

                    if(val < 0)
                        return -1;
                    if(val > 0)
                        return 1;

                    return 0;

                });

                for (var i = 0; i < template.layers.length; i++)
                {
                    var layer = template.layers[i];
                    delete layer.parentGroupID;
                    delete layer.repeatItems;
                    delete layer.repeatCount;
                    delete layer.alwaysOn;
                    delete layer.autoFill;
                    delete layer.alpha;

                    if(layer.asset.type == "image")
                    {
                        layer.asset.name = "Image " + (++imagesCount);
                    }

                    if(layer.asset.type == "video")
                    {
                        layer.asset.name = "Video " + (++videosCount);
                    }
                }

                var sendData = JSON.stringify(output);
                var backend = $scope.backend  || $scope.$parent.backend;

                var postData = { "data": sendData, "templateName": templateName };

                if($scope.isTemplate){
                    postData.id = $scope.templateID;
                }

                $http({ "method": "POST", "url": $scope.$parent.backend + "/saveAsTemplate", "params": {},
                    "data": postData }).
                    success(function (data, status) {

                        if (data.success) {
                            $scope.changesMade = false;
                            // Add template to the library
                            var newTemplate = data.template;

                            $scope.$parent.activAlert("Template \"" + templateName + "\" saved.", 2000);

                            $scope.countTemplates += 1;

                            if(cb) {
                                cb();
                            }

                            var postData = { "groupFolder": $scope.groupFolder,
                                "templateId" : data.template.ID,
                                "width": $scope.stageProperties.dimensions.w,
                                "height": $scope.stageProperties.dimensions.h }

                            // Generate a new thumbnail
                            $http({ "method": "POST", "url": backend + "/" + config.endpoints.createProjectThumbnail, "params": {},
                                "data": postData })
                                .success(function(createThumbData, status){
                                    // Add item to library
                                    if(!$scope.isTemplate) $scope.libraryItems.templates.push(newTemplate);
                                }).error(function(createThumbData, status){
                                    // Add the item anyways!
                                    if(!$scope.isTemplate) $scope.libraryItems.templates.push(newTemplate);
                                });

                        }else if(data.exists)
                        {
                            $scope.$parent.activAlert("Template with the same name already exists.", 2000);
                        }else {

                            $scope.$parent.activAlert("Error. Template not saved.", 2000);

                        }

                    }).
                    error(function (data, status) { });

            };

            if($scope.isTemplate) {
                saveAsTemplateCallback($scope.stageProperties.projectName);
            } else {
                if (!$scope.$root.checkPermission('templates', 'numberOfTemplates', $scope.countTemplates)) {
                    return $scope.$parent.activAlert("You have reached your licence limit.", 2000);
                } else
                    $scope.$parent.activPrompt("Please enter a template name", saveAsTemplateCallback);
            }

        } else if(saveType == "publish") {
            expandRepeatingItems(output);
            var sendData = JSON.stringify(output);
            sendData.publish = true;
            $http({ "method": "POST", "url": $scope.$parent.backend + "/saveProject", "params": {}, "data": sendData }).
                success(function (data, status) {

                    if (data.success) {

                        createProjectThumbnail();

                        $scope.$parent.activAlert("Project published.", 2000);
                        $scope.changesMade = false;

                        if(cb) {
                            cb();
                        }
                    } else {

                        $scope.$parent.activAlert("Error. Project not published.", 2000);

                    }

                }).
                error(function (data, status) { });

        } else if(saveType == "saveAs"){
            output.projectData.name = $scope.saveAsProjectTitle;
            expandRepeatingItems(output);

            var sendData = JSON.stringify(output);
            var backend = $scope.backend  || $scope.$parent.backend;

            // We need to create the project first with a new name and using the currnet json:
            $http({ "method": "POST", "url": backend + "/saveProject", "params": {}, "data": sendData }).
                success(function (data, status)
                {
                    if (data.success)
                    {
                        $scope.$parent.activAlert("Project saved.", 2000);
                        // Load the new project
                        var newProjectId = data.project.ID;

                        $scope.changesMade = false;

                        var postData = { "groupFolder": $scope.groupFolder,
                            "projectId": newProjectId,
                            "fileName": data.project.projectFile,
                            "width": $scope.stageProperties.dimensions.w,
                            "height": $scope.stageProperties.dimensions.h };

                        // Generate a new thumbnail
                        $http({ "method": "POST", "url": backend + "/" + config.endpoints.createProjectThumbnail, "params": {},
                            "data": postData})
                            .success(function(createThumbData, status){
                                $location.path("/editor/"+ newProjectId);
                            }).error(function(createThumbData, status){
                                // move anyways,,
                                $scope.changesMade = false;
                                $location.path("/editor/"+ newProjectId);

                            });



                    } else if(data.projectExists)
                    {
                        $scope.$parent.activAlert("Error. Project not saved.<br/>A Project named '"+output.projectData.name+"' already exists.", 2000);
                    }
                    else
                    {
                        $scope.$parent.activAlert("Error. Project not saved.", 2000);
                    }

                }).
                error(function (data, status) { });
        }
        else{
            expandRepeatingItems(output);
            var sendData = JSON.stringify(output);

            var backendURL =  $scope.backend ||  $scope.$parent.backend;
            $http({ "method": "POST", "url": backendURL + "/saveProject", "params": {}, "data": sendData }).
                success(function (data, status) {

                    if (data.success) {

                        $scope.$parent.activAlert("Project saved.", 2000);
                        $scope.changesMade = false;

                        createProjectThumbnail();

                        if(cb) {
                            cb();
                        }

                    } else {

                        $scope.$parent.activAlert("Error. Project not saved.", 2000);

                    }

                }).
                error(function (data, status) { });

        }



    };

    $scope.getDivSelectors = function() {
      if ($scope.checkDivSelectorLoading) {
        return;
      }

      if (!$scope.propertyObject.canvasObject.widgetProperties) {
        return;
      }

      var widgetProperties = $scope.propertyObject.canvasObject.widgetProperties;

      if (!widgetProperties.url) {
        return;
      }

      if ($scope.divSelector && $scope.divSelector[widgetProperties.url.val]) {
        return $scope.divSelector[widgetProperties.url.val];
      }

      $scope.checkDivSelectorLoading = true;
      var sendData = JSON.stringify({url: widgetProperties.url.val, element: "div"});
      $http({ "method": "POST", "url": $scope.$parent.backend + "/getHTMLElementList", "params": {}, "data": sendData }).
          success(function (data, status) {
            $scope.checkDivSelectorLoading = false;
            if (!$scope.divSelector) $scope.divSelector = [];

            $scope.divSelector[widgetProperties.url.val] = (data.elements) ? data.elements : [];
            return $scope.divSelector[widgetProperties.url.val];
          })
          .error(function(data,status) {
            $scope.checkDivSelectorLoading = false;
            $scope.divSelector[widgetProperties.url.val] = [];
            return $scope.divSelector[widgetProperties.url.val];
          })
    }

    var calloutShowing = false;

    $scope.toggleHelpCallout = function(element, calloutText) {
        if(!element) {
            return;
        }

        var elementOffset = jQuery(element.target).offset();
        var elementWidth = jQuery(element.target).width();
        var elementHeight = jQuery(element.target).height();

        var callout = jQuery('#helpCallout');
        callout.css('top', elementOffset.top - (elementHeight/2) - 4);
        callout.css('left', elementOffset.left + elementWidth + 10);
        callout.text(calloutText);

        if(!calloutShowing) {
           calloutShowing = true;
           callout.toggle(true);

           setTimeout(function(){
               calloutShowing = false;
               callout.fadeToggle("fast", "linear");
           }, 1500);
        }
    };

    $scope.previewProject = function() {

        // Dont allow preview unless full project loads
        if($scope.checkProjectDataLoading()){
            return;
        }

        var previewPane = document.getElementById("previewPlayer");
        previewPane.innerHTML = "";

        var closeButton = document.createElement("span");
        closeButton.classList.add("glyphicon", "glyphicon-remove", "previewCloseButton");
        closeButton.addEventListener("click", $scope.closePreview);

        var previewIframe = document.createElement("iframe");
        previewIframe.seamless = "true";
        previewIframe.frameBorder = "0";
        previewIframe.width = "100%";
        previewIframe.height = "100%";

        var output = $scope.exportProjectJSON("preview");
        output.backend = config.backend;

        expandRepeatingItems(output)

        sessionStorage.setItem("previewJSON", JSON.stringify(output));

        previewIframe.src = config.baseUrlPlayer
                + "/Player/index.html?loadFromCache=true&gf="
                + encodeURIComponent($scope.groupFolder)
            + "&authToken="+$scope.$root.authToken
            +"&home=" + $scope.$root.appRoot
            + "&cb=" + new Date().getTime();

        previewPane.appendChild(closeButton);
        previewPane.appendChild(previewIframe);

        previewPane.style.display = "block";

    };

    $scope.closePreview = function() {

        var previewPane = document.getElementById("previewPlayer");
        previewPane.innerHTML = "";
        previewPane.style.display = "none";
        sessionStorage.removeItem("previewJSON");

    };

    var collapseRepeatingItems = function(projectData) {
        var newLayers = [];
        var assetIndex = 0;

        projectData.layers.forEach(function(_layer){
            if(_layer.asset.templateBlock) {
               newLayers.push(_layer);
            } else {
                if(_layer.repeatItems && _layer.repeatCount > 1) {
                    if(_layer.repeatIndex == 0) {
                        var repeatingItem = JSON.parse(JSON.stringify(_layer));
                        repeatingItem.startTime = _layer.startTime * _layer.repeatCount;
                        repeatingItem.endTime = _layer.endTime * _layer.repeatCount;
                        newLayers.push(repeatingItem);
                    }
                } else {
                    newLayers.push(_layer);
                }
            }
        });

        projectData.layers = newLayers;

    };

    var expandRepeatingItems = function(project) {
        var groups = {};
        project.projectData.layers.forEach(function(_layer){
            if(_layer.layerType == 'group') {
                groups[_layer.id] = _layer;
            }
        });

        var expandedLayers = [];
        var assetIndex = 0;
        project.projectData.layers.forEach(function(_layer){

            if(_layer.repeatItems && _layer.repeatCount > 1) {
                 for(var count=0; count<_layer.repeatCount; count++) {
                    var parent = groups[_layer.parentGroupID];
                    var duration = parent.endTime - parent.startTime;
                    var offset = (duration / _layer.repeatCount) * count;

                    var repeatingItem = JSON.parse(JSON.stringify(_layer));
                    repeatingItem.repeatIndex = count;
                    repeatingItem.startTime = offset + (_layer.startTime / _layer.repeatCount);
                    repeatingItem.endTime = offset + (_layer.endTime / _layer.repeatCount);

                    repeatingItem.asset.id = assetIndex++;
                    expandedLayers.push(repeatingItem);
                 }
            } else {
                _layer.asset.id = assetIndex++;
                expandedLayers.push(_layer);
            }
        });

        project.projectData.layers = expandedLayers;
    };

    /*

    LOAD PROJECT

     */

    var loadProject = function(cb){
        timeline.reset();

        var postData = { "id": $scope._projectID };
        if($scope.isTemplate){
            postData.id = $scope.templateID;
            postData.isTemplate = true;
        }

        $http({ "method": "POST", "url": $scope.$parent.backend + "/openProject", "params": {},
            "data": postData }).
            success(function(data, status){

                if(data.success) {
                    $scope.loadingProjectData = false;

                    if(!$scope.isTemplate) {
                        $http({
                            "method": "POST",
                            "url": $scope.$parent.backend + "/addRecentProject",
                            "params": {},
                            "data": {"projectID": $scope._projectID}
                        }).success(function (data, status) {
                        }).error(function (data, status) {
                        });
                    }

                    $scope.projectFile = data.project.projectFile;

                    $scope.loadedProject = data.project;
                    $scope.dbLibrary = data.dbLibrary;

                    data.libraryContent.images.forEach(function(img){
                        img.format = img.type;
                        img.type = img.type.substr(0, img.type.indexOf("/"));
                        img.assetID = img.ID;
                    });

                    data.libraryContent.templates.forEach(function(tmp){
                        tmp.type = "template";
                        tmp.assetID = tmp.ID;
                    });

                    data.libraryContent.videos.forEach(function(vid){
                        vid.format = vid.type;
                        vid.type = vid.type.substr(0, vid.type.indexOf("/"));
                        vid.assetID = vid.ID;
                    });

                    $scope.countTemplates = data.countTemplates;

                    timeline.init();

                    $scope.projectLibrary = data.libraryContent;

                    // Update the data source
                    data.projectData = $scope.isTemplate ?
                            JSON.parse(data.project.templateJSON) :
                            JSON.parse(data.project.projectData);

                    // Update all titles :
                    $scope.rssDataFeeds = []; // ["@feed1", "@feed2"];
                    $scope.webFonts = data.webFonts || [];
                    
                    // Sort Web fonts alphabetically
                    $scope.webFonts = $scope.webFonts.sort(function compare(a,b) {
                        if (a.name < b.name)
                            return -1;
                        if (a.name > b.name)
                            return 1;
                        return 0;
                    });

                    // Apply web fonts
                    for (var i = 0; i < $scope.webFonts.length; i++) {
                        var wf =  $scope.webFonts[i];
                        wf.fontName = applyWebFont(wf.URL);
                    }

                    for (var i = 0; i < data.rssDataFeeds.length; i++) {
                        var rf = data.rssDataFeeds[i];
                        rf.title = "@" + rf.title;
                    }
                    $scope.rssDataFeeds = data.rssDataFeeds;


                    $scope.stageProperties.projectName = data.project.name;

                    var projectData = data.projectData;
                    collapseRepeatingItems(projectData);
                    $scope.initProjectData(projectData);
                } else {

                    $scope.$parent.activAlert("An Error Has occurred : " + JSON.stringify(data), 2000);

                }

                $scope.changesMade = false;

                // Re render ticks
                timeline.rerenderTicks();
                timeline.renderTimeline();

                $scope.attachResizeEvents();

                attachTimelineScrollListener();
                if(cb) {
                    cb();
                }
            }).
            error(function(data, status){ });

    };

    $scope.initProjectData = function(projectData) {
        $scope.stageProperties.screenType = projectData.screenType || $scope.stageProperties.screenType;
        $scope.stageProperties.dimensions = { w: projectData.width, h: projectData.height };
        $scope.stageProperties.colour = projectData.background;
        $scope.stageProperties.projectLength = projectData.duration;
        $scope.stageProperties.noClipping = projectData.noClipping || $scope.stageProperties.noClipping;
        $scope.stageProperties.discoverable = projectData.discoverable || $scope.stageProperties.discoverable;

        $scope.stageProperties.assetsPrepareTimeout =  projectData.assetsPrepareTimeout == undefined ? 2 : projectData.assetsPrepareTimeout; // defaults to 2 minutes..

        $scope.stageProperties.splashScreen = projectData.splashScreen || $scope.stageProperties.splashScreen;
        // $scope.stageProperties.flipOrientation = projectData.flipOrientation;
        $scope.stageProperties.updateColour();
        $scope.stageProperties.updateDimensions();
        $scope.timeLineZoom = projectData.timeLineZoom ? projectData.timeLineZoom : 1.0;
        $scope.zoom = projectData.zoom ? projectData.zoom : 0.5;

        timeline.updateTimelineZoom();

        var layers = projectData.layers;

        var parentLayers = [];

        // Prepare layers for proper parent elements
        for(var i=0; i < layers.length; i++) {
            var l = layers[i];
            if(l.layerType == "group"){
                parentLayers.push(l);
            }
        }

        // Use the old method to render layers,
        // The layers will have the parent layer info we need
        for(var i=0; i < layers.length; i++) {
            var l = layers[i];
            l.duration = l.endTime - l.startTime;

            if (l.asset
                && l.asset.type == "widget"
                && l.asset.widgetType == "HTML"
                && l.asset.enhanced) {
              l.asset.widgetType = "EnhancedHTML";
              l.asset.name = "EnhancedHTML";
              l.asset.url = "enhancedhtml.html";
              l.asset.widgetProperties.url = l.asset.widgetProperties.rawUrl;
            }

            $scope.dropped(null, null, null, l);
        }

        // Layers rendered update parent layers start/end times
        for(var i=0; i < parentLayers.length; i++) {
            var pl = parentLayers[i];

            //Get timeline element for the parent and update
            var tli = timeline.getItemById(pl.id);
            tli.startTime = pl.startTime;
            tli.endTime = pl.endTime;
            tli.duration = pl.endTime - pl.startTime;
            tli.alwaysOn = pl.alwaysOn;

            //tli.updateRHSTimelineElement();
        }

        // Update project length and ticks
        timeline.setProjectLength();
    }


    /*

    LOAD LIBRARY

     */

    $scope.getFolderFromLibrary = function(assetItems, folderId)
    {
        var folder = null;
        for(var ai=0; ai < assetItems.length; ai++)
        {
            var item = assetItems[ai];

            if(item.children && item.ID == folderId)
            {
                folder = item;
                break;
            }
        }

        return folder;
    }

    $scope.loadFolders = function(dataArray, type) {
        // check for folders
        var folders = [];
        for (var i = 0; i < dataArray.length; i++) {
            var img = dataArray[i];
            if (img.assetGroupId) {
                var folder = $scope.getFolderFromLibrary(folders, img.assetGroupId);
                if (!folder) {
                    folder = new EditorAssetFolder($scope, type);
                    folder.ID = img.assetGroupId;
                    folder.folderName = img.assetGroupTitle;
                    folders.push(folder);
                }
                folder.children.push(img);

                dataArray.splice(i, 1);
                i--;
            }
        }

        // Add folders back right one the top
        for(var i=0; i< folders.length; i++ )
        {
            dataArray.splice(0,0,folders[i]);
        }
    };

    var retrieveLibrary = function(cb){

        $http({ "method": "POST", "url": $scope.$parent.backend + "/retrieveLibrary", "params": {}, "data": {} }).
            success(function(data, status){
                data.images.forEach(function(img){
                    if(img.type != "folder") {
                        img.format = img.type;
                        img.type = img.type.substr(0, img.type.indexOf("/"));
                        img.assetID = img.ID;
                    }
                });

                data.videos.forEach(function(vid){
                    if(vid.type != "folder") {
                        vid.format = vid.type;
                        vid.type = vid.type.substr(0, vid.type.indexOf("/"));
                        vid.assetID = vid.ID;
                    }
                });

                // $scope.loadFolders(data.images, "images");
                // $scope.loadFolders(data.videos, "videos");

                $scope.libraryItems.images = data.images;
                $scope.libraryItems.videos = data.videos;
                $scope.libraryItems.widgets = data.widgets;
                $scope.libraryItems.templates = data.templates;

                if(cb) {
                    cb();
                }

                loadProject();

            }).
            error(function(data, status){ });

    };


    $scope.activityTime = new Date().getTime();

    $scope.getActivityEntry = function(){
        var types = [{type: "library", time: $scope.activityTime}];

        $http({ method: "POST", url: $scope.$parent.backend + "/getActivityEntry", "params": {}, "data": {groupId: $scope.$parent.userInfo.groupID, types: types} })
            .success(function(data, status){
                $scope.activityTime = data.results[0].dbTime;
                if (data && data.results[0].isNewer) {
                    // retrieveLibrary();
                }

            })
            .error(function(data){
                console.error(data);
                //alert("error")
            });
    };

    // poller
/*    setInterval(function(){
        if ($location.path().indexOf("/editor") !== -1)
            $scope.getActivityEntry();
    }, 5000);*/


    /*

     SET UP EVENT LISTENERS

     */

    //angular.element(document).ready(function () {
     $scope.$on('$viewContentLoaded', function(){

         setTimeout(function(){

            $scope.editorDomReady();
            // Run code after dom ready

            var panelResize = localStorage.getItem(PROPERTY_PANEL_RESIZE_KEY);
            if(panelResize) {
                var resizeElement = document.getElementById("sideMenu");
                var resizeId = resizeElement.getAttribute('resize-id');

                var elements = getAllResizeElementsWithId(resizeId);
                elements.forEach(function(element) {
                    updateElementSize(element, panelResize, panelResize);
                });
            }

            stageEl.addEventListener("mousedown", stageDragStart);
            stageEl.addEventListener("mouseup", stageDragEnd);

            stageContainer.addEventListener("mousewheel", mouseZoom);
            stageContainer.addEventListener("click", stageHolderClick);
            stageContainer.addEventListener("DOMMouseScroll", mouseZoom);

            window.addEventListener("keydown", keyDown);
            window.addEventListener("keyup", keyUp);

            // retrieveLibrary();
             loadProject();

         }, 500);

    });

    $scope.attachResizeEvents = function(){
        [].forEach.call(document.querySelectorAll(".resizeHandle"), function(_resizeHandle){
            _resizeHandle.addEventListener("mousedown", resizePaneStart);
        });
    };

    $scope.$on('modelUpdated', function (event, data) {
        // if it's a selected object then get the id
        if(!data.objectId && $scope.propertyObject) {
            data.objectId = $scope.propertyObject.id;
        }

        // add to undo
        $scope.undoManager.push(data);
    });

}]);

function EditorAssetFolder(scope, type)
{
    var af = new Object();

    // Default vals
    af.ID = "";
    af.children = [];
    af.scope = scope;
    af.assetType = type.toLocaleLowerCase();
    af.folderName = "";

    return af;
}

function EditorUndoManager(scope) {
    var undoStack = [];
    var redoStack = [];
    var scope = scope;

    this.push = function(data) {
        undoStack.push(data);

        // clear the redo stack here
        redoStack = [];
    };

    this.undo = function() {
        var item = undoStack.pop();
        if (item) {
            var previousData = applyStackItem(item);
            item.data = previousData;
            redoStack.push(item);

            scope.$broadcast('undoFinished', {});
        }
    };

    this.redo = function() {
        
        var item = redoStack.pop();
        
        if (item) {
            var previousData = applyStackItem(item);
            item.data = previousData;
            undoStack.push(item);

            scope.$broadcast('undoFinished', {});
        }
    };

    function applyStackItem(item) {
        var currentData = {};
        var newData = JSON.parse(item.data);

        if(item.source == 'widgetProperty') { // widget
            var object = timeline.getItemById(item.objectId);
            var to = object.canvasObject.widgetProperties[newData.id];

            // copy params
            copyObject(stripMonitorObject(to), currentData)
            copyObject(newData, to);

            // update ui
            object.setWidget();
            object.containerFixStartEnd();
            object.setSizePosition();
        } else if(item.source == '$parent.stageProperties') { // stage properties
            var to = scope.stageProperties;

            // copy params
            copyObject(stripMonitorObject(to), currentData)
            copyObject(newData, to);

            // update ui
            to.updateDimensions();
            to.updateNoClipping();
            to.updateColour();
        } else if(item.source == 'timelineModified') { // timeline modifications
            currentData = timeline.getLayers(null);

            timeline.reset();

            var projectData = JSON.parse(scope.loadedProject.projectData);
            projectData.layers = newData;
            scope.initProjectData(projectData);

            // update ui
            timeline.renderTimeline();
        } else { // otherwise timeline item changes
            var to = timeline.getItemById(item.objectId);
            
            // copy params
            copyObject(stripMonitorObject(to), currentData)
            copyObject(newData, to)

            // update ui
            to.setWidget();
            to.setTimes();
            to.containerFixStartEnd();
            to.setSizePosition();
            to.updateLayerVisibility();
        }

        return JSON.stringify(currentData);
    }

    function copyObject(from, to) {
        for(var key in from) {
            to[key] = from[key];
        }
    }
}

function applyWebFont(fontURL, domElement){
    var head = document.getElementsByTagName('head')[0];
    var link = document.createElement('link');
    
    var fontName = getFontNameFromURL(fontURL);

    link.id = fontName;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = fontURL;
    link.media = 'all';
    head.appendChild(link);

    if(domElement){
        domElement.style.fontFamily = fontName;
    }

    return fontName;
}

function getFontNameFromURL(url){
    var fontName = null;
    var fontNameVar = getURLVar(url, "family");
    if(fontNameVar){
        // decode url encoding and replace all plus symbols with a space..
        fontName = decodeURIComponent(fontNameVar).replace(/\+/g,' ');
    }
    return fontName;
}

function getURLVar(url, key){
    var urlVars = getUrlVars(url);
    var urlVar = urlVars[key];
    return urlVar;
}

function getUrlVars(url) {
    var vars = {};
    var parts = url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}


