activ.controller("teams", ["$scope", "$http", function($scope, $http){

    $scope.pageTitle = "Teams";
    $scope.newTeam = null;
    $scope.breadcrumbsStack = [];
    $scope.rootTeam = {ID:-1, // This id will be filled by the default teamId
                        name : "Teams",
                        teams : [], users: [],
                        accessRights : getValueFromAccessRights(AllAccessRights)};

    $scope.teamsLoaded = false;
    var userData = $scope.$root.getUserData();
    var groupData = $scope.$root.getGroupData();

    $scope.isRootTeamUser = false; // to do

    $scope.currentTeam = $scope.rootTeam;

    $scope.virtualAdmin = {ID:0, name : "Admin", dataLoaded:true,users: [], teams:[] };

    $scope.checkVirtualAdmins = function(){
        if($scope.isRootTeamUser) {
            if ($scope.currentTeam.ID == groupData.defaultTeamId) {
                // This is the root team, any users here are supposed to be in the virtual admin folder
                while ($scope.currentTeam.users.length > 0) {
                    $scope.virtualAdmin.users.push($scope.currentTeam.users.pop());
                }
            }
        }
    }

    $scope.getTeamById = function(teamId, list){
        var filtered = list.filter(function(t){return t.ID == teamId});
        return filtered.length > 0 ? filtered[0] : null;
    };

    $scope.getTeamByName = function(name, list, excludeTeam){
        var filtered = list.filter(function(t){
            var result = t.name.toLowerCase() == name.toLowerCase();
            if( excludeTeam && excludeTeam.ID == t.ID)
                result = false;
            return result
        });
        return filtered.length > 0 ? filtered[0] : null;
    };

    $scope.removeUserFromTeam = function(team, user){
        for(var i=0; i< team.users.length; i++){
            if(team.users[i].ID == user.ID){
                team.users.splice(i,1);
                break;
            }
        }
    };

    $scope.removeTeamFromTeam = function(team, childTeam){
        for(var i=0; i< team.teams.length; i++){
            if(team.teams[i].ID == childTeam.ID){
                team.teams.splice(i,1);
                break;
            }
        }
    };

    $scope.loadCurrentTeamChildren = function(){
        // Use cached data if available
        if(!$scope.currentTeam.dataLoaded) {

            $scope.teamsLoaded = false;

            $http({
                method: "POST", url: $scope.$parent.backend + "/teams", "params": {},
                "data": {action: "get", "parentId": $scope.currentTeam.ID}
            })
                .success(function (data, status) {
                    if (data.success) {
                        // This will load the default teamId
                        $scope.currentTeam.ID = data.teamId;
                        $scope.teamsLoaded = true;
                        $scope.currentTeam.dataLoaded = true;
                        $scope.currentTeam.teams = data.teams;
                        $scope.currentTeam.users = data.users;
                        $scope.totalTeams = data.teamCount;
                        $scope.totalUsers = data.userCount;

                        //$scope.checkVirtualAdmins();

                    } else {
                        $scope.$parent.activAlert("An Error Has occurred : " + data.error, 4000);
                    }
                })
                .error(function (data) {
                    console.error(data);
                    $scope.$parent.activAlert("An Error Has occurred : " + JSON.stringify(data), 4000);
                });
        }
        else{
            $scope.checkVirtualAdmins();
        }
    };

    $scope.openTeam = function(team){
        if(team.teams == null){
            team.teams = [];
        }
        if(team.users == null){
            team.users = [];
        }

        $scope.currentTeam = team;
        $scope.loadCurrentTeamChildren();
        $scope.breadcrumbsStack.push(team);
    };

    $scope.openRoot = function(){
        $scope.openTeam($scope.rootTeam);
    }

    $scope.openBreadCrumbTeam = function(team, index){
        var stackLen = $scope.breadcrumbsStack.length;
        if(index < stackLen - 1) {
            $scope.breadcrumbsStack.splice(0, stackLen - index);
            $scope.openTeam(team);
        }
    };

    $scope.openLastFolder = function(){
        $scope.breadcrumbsStack.pop();
        if($scope.breadcrumbsStack.length == 0){
            $scope.openRoot();
        }
        else {
            $scope.currentTeam = $scope.breadcrumbsStack[$scope.breadcrumbsStack.length - 1];
            //$scope.checkVirtualAdmins();
        }
    };

    $scope.removeUser = function(user){

        if(confirm("This will delete the user ': " + user.firstname + " " + user.surname +"' from the system! Are you sure?"
                + "\n*If you wish to remove the user from the Team, you can drag/drop the user onto the breadcrumbs.")){
            $http({
                method: "POST", url: $scope.$parent.backend + "/teams", "params": {},
                "data": {action: "removeUser",  "userId": user.ID}
            })
                .success(function (data, status) {
                    if (data.success) {
                        $scope.removeUserFromTeam($scope.currentTeam, user);
                    } else {
                        $scope.$parent.activAlert("An Error Has occurred : " + data.error, 4000);
                    }
                })
                .error(function (data) {
                    console.error(data);
                    $scope.$parent.activAlert("An Error Has occurred : " + JSON.stringify(data), 4000);
                });
        }
    };

    $scope.removeTeam = function(team){

        if(confirm("Are you sure you want to remove team : " + team.name)){
            $http({
                method: "POST", url: $scope.$parent.backend + "/teams", "params": {},
                "data": {action: "removeTeam",  "teamId": team.ID}
            })
                .success(function (data, status) {
                    if (data.success) {
                        $scope.removeTeamFromTeam($scope.currentTeam, team);
                    } else {
                        $scope.$parent.activAlert("An Error Has occurred : " + data.error, 4000);
                    }
                })
                .error(function (data) {
                    console.error(data);
                    $scope.$parent.activAlert("An Error Has occurred : " + JSON.stringify(data), 4000);
                });
        }
    };

    // Drag drop related functions
    $scope.teamItemDrop = function(event, channel, draggedData, index, droppedOnChannel, droppedOn) {

        if($scope.currentTeam.ID == 0){
            // not allowed for virtual admin
            return;
        }
        // Disable drop on same item
        if (droppedOnChannel == channel) {
            // Ignore drop on same item
            if (draggedData.ID == droppedOn.ID) {
                return;
            }
        } else if ($scope.breadcrumbsStack.length == 1 && droppedOnChannel == "breadcrumb") {
            // We're at the root, dont allow any breadcrumb action
            return;
        }

        // All good, process the drop action
        // Identify action

        // Action 1 : User on Team
        if (( droppedOnChannel == "breadcrumb"
            || droppedOnChannel == "team")
            && channel == "user"
        ) {
            // Do we have the access rights for drag drop ?
            if (!$scope.$root.hasAccessRight('Teams - Edit')) {
                return;
            }

            $http({
                method: "POST", url: $scope.$parent.backend + "/teams", "params": {},
                "data": {action: "updateUserTeam", "userId": draggedData.ID, "teamId": droppedOn.ID,
                    "removeFromTeam" : $scope.currentTeam.ID}
            })
                .success(function (data, status) {
                    if (data.success) {
                        droppedOn.users = droppedOn.users ? droppedOn.users : [];

                        // Remove user from current team
                        $scope.removeUserFromTeam($scope.currentTeam, draggedData);
                        // Move the user to the target team if applicable
                        if(data.recCount > 0) {
                            droppedOn.users.push(draggedData);
                        }

                    } else {
                        $scope.$parent.activAlert("An Error Has occurred : " + data.error, 4000);
                    }
                })
                .error(function (data) {
                    console.error(data);
                    $scope.$parent.activAlert("An Error Has occurred : " + JSON.stringify(data), 4000);
                });
        }
        else if ((droppedOnChannel == "breadcrumb"
            || droppedOnChannel == "team")
            && channel == "team"
        ) {
            // Action 2 : Team on Team

            // Do we have the access rights for drag drop ?
            if (!$scope.$root.hasAccessRight('Teams - Edit')) {
                return;
            }

            // Check if the team is duplicate
            if (droppedOn.dataLoaded && $scope.getTeamByName(draggedData.name, droppedOn.teams)) {
                $scope.$parent.activAlert("A team with the same name already exists in the target", 4000);
                return;
            }

            $http({
                method: "POST", url: $scope.$parent.backend + "/teams", "params": {},
                "data": {
                    action: "updateTeamTeam",
                    teamName: draggedData.name,
                    "childTeamId": draggedData.ID,
                    "teamId": droppedOn.ID
                }
            })
                .success(function (data, status) {
                    if (data.success) {
                        droppedOn.teams = droppedOn.teams ? droppedOn.teams : [];
                        // Move the user to the target team
                        droppedOn.teams.push(draggedData);
                        $scope.removeTeamFromTeam($scope.currentTeam, draggedData);
                    } else {
                        $scope.$parent.activAlert(data.error, 4000);
                    }
                })
                .error(function (data) {
                    console.error(data);
                    $scope.$parent.activAlert("An Error Has occurred : " + JSON.stringify(data), 4000);
                });
        } else if (channel == "user" && droppedOnChannel == channel) {
            // Action 3 : User on User
            // => Add a new team

            // Do we have the access rights for drag drop ?
            if (!$scope.$root.hasAccessRight('Teams - Create')) {
                return;
            }

            var teamUsers = [draggedData, droppedOn];
            $scope.showAddUpdateTeam(null, teamUsers);
        }
    };

    $scope.teamPost = function(postData, callback){
        $http({
            method: "POST", url: $scope.$parent.backend + "/teams", "params": {},
            "data": postData
        })
        .success(function (data, status){
            callback(data);
        })
        .error(function (data) {
            callback({success:false, error:data.message});
        });
    };

    $scope.updateUserAcrossTeams = function(user, team){

        var currentTeam = team ? team : $scope.rootTeam;

        if(currentTeam.dataLoaded) {
            for (var i = 0; i < currentTeam.users.length; i++) {
                if(currentTeam.users[i].ID == user.ID){
                    currentTeam.users[i] = user;
                    break;
                }
            }

            // Recurse for any child teams
            for(var i=0; i<currentTeam.teams.length; i++ ){
                $scope.updateUserAcrossTeams(user, currentTeam.teams[i]);
            }
        }
    };

    $scope.showAddUpdateUser = function(editUser){
        if (!$scope.$root.checkPermission('user', 'numberOfUsers', $scope.totalUsers) &&
          !editUser) {
          return $scope.$parent.activAlert("You have reached your licence limit.", 1000);
        }

        var addEntityTemplate = $("#addUpdateUserTemplate").html();

        // Append a new container to the body and load dialog
        $scope.modalContainer = new ModalClass({title : "User", "body" : addEntityTemplate,
                                                popupBodyCssClass:"auto-overflow-y"});

        var editMode = editUser != null;

        // hook into the save button
        $scope.modalContainer.primaryBtn.click(function(){
            // Get data back from the modal
            $scope.modalContainer.resetStatus();

            var usernameInput = $scope.modalContainer.find(".username");
            var firstnameInput = $scope.modalContainer.find(".firstname");
            var surnameInput = $scope.modalContainer.find(".surname");
            var emailInput = $scope.modalContainer.find(".email");

            if(Validations.checkEmpty(usernameInput, $scope.modalContainer)
                && Validations.checkEmpty(firstnameInput, $scope.modalContainer)
                && Validations.checkEmpty(surnameInput, $scope.modalContainer)
                && Validations.checkEmpty(emailInput, $scope.modalContainer)
            ){
                var user = {
                    username : $.trim(usernameInput.val()),
                    firstname : $.trim(firstnameInput.val()),
                    surname : $.trim(surnameInput.val()),
                    email: $.trim(emailInput.val()),
                    teamId : $scope.currentTeam.ID > 0 ? $scope.currentTeam.ID : 0,
                    accessRights : getSelectedAccessRights($scope.modalContainer)
                };

                user.ID = editUser?editUser.ID:0;

                var postData = {
                    action: "addUpdateUser",
                    user : user
                };

                var callback = function(data){
                    if(data.success){
                        $scope.modalContainer.hide();
                        if(editUser){
                            // Replace user
                            var userIndex = $scope.currentTeam.users.indexOf(editUser);
                            user.avatar = editUser.avatar;
                            $scope.updateUserAcrossTeams(user);
                        }else {
                            var newUser = data.user;
                            $scope.currentTeam.users.push(newUser);
                        }
                    }else{
                        $scope.modalContainer.setStatusText("Error : " + data.error, "red");
                    }
                };

                $scope.teamPost(postData, callback);
            }
        });

        var accessRightsContainer = $scope.modalContainer.find(".accessRights");

        renderAccessRights(accessRightsContainer, editUser?editUser.accessRights : 0, $scope.modalContainer, $scope);

        // Prefill data
        if(editUser){
            $scope.modalContainer.find(".firstname").val(editUser.firstname);
            $scope.modalContainer.find(".surname").val(editUser.surname);
            $scope.modalContainer.find(".username").val(editUser.username);
            $scope.modalContainer.find(".email").val(editUser.email);
        }

        $scope.modalContainer.adjustContentHeight();
    };

    $scope.showAddUpdateTeam = function(editTeam, teamusers){
        if (!$scope.$root.checkPermission('teams', 'numberOfTeams', $scope.totalTeams) &&
          !editTeam) {
          return $scope.$parent.activAlert("You have reached your licence limit.", 1000);
        }

        var addEntityTemplate = $("#addUpdateTeamTemplate").html();

        // Append a new container to the body and load dialog
        $scope.modalContainer = new ModalClass({title : "Team", "body" : addEntityTemplate});

        var editMode = editTeam != null;

        // hook into the save button
        $scope.modalContainer.primaryBtn.click(function(){
            // Get data back from the modal
            $scope.modalContainer.resetStatus();

            var teamNameInput = $scope.modalContainer.find(".teamName");
            if(Validations.checkEmpty(teamNameInput, $scope.modalContainer)){
                // Team name is valid, pass on to create team
                var teamName = $scope.modalContainer.find(".teamName").val();

                if($scope.getTeamByName(teamName, $scope.currentTeam.teams, editTeam)) {
                    $scope.modalContainer.setStatusText("A team with the same name already exists", "red");
                    teamNameInput.focus();
                    return;
                }

                var team = {
                    ID : 0,
                    name : $.trim(teamName),
                    "accessRights" : getSelectedAccessRights($scope.modalContainer),
                    "parentId": $scope.currentTeam.ID
                };


                if(editTeam){
                    team.ID = editTeam.ID;
                }

                var postData = {
                    action: "addUpdateTeam",
                    "team": team
                };

             if(teamusers){
                    var teamUserIds = "";
                    var separator = "";
                    for(var u in teamusers) {
                        var tUser = teamusers[u];
                        teamUserIds += separator + tUser.ID;
                        separator = ",";
                    }
                    postData.teamUsers = teamUserIds;
                }

                var callback = function(data){

                    if(data.success){
                        $scope.modalContainer.hide();
                        if(editTeam){
                            // Replace Team
                            var teamIndex = $scope.currentTeam.teams.indexOf(editTeam);
                            $scope.currentTeam.teams.splice(teamIndex, 1, team);
                        }else {
                            var newTeam = data.team;
                            newTeam.dataLoaded = true;
                            newTeam.users = [];
                            newTeam.teams = [];

                            // Move the entities to the target team
                            if (teamusers) {
                                for(var u in teamusers) {
                                    var tUser = teamusers[u];

                                    $scope.removeUserFromTeam($scope.currentTeam, tUser);
                                    newTeam.users.push(tUser);
                                }
                            }

                            $scope.currentTeam.teams.push(newTeam);
                        }
                    }else{
                        $scope.modalContainer.setStatusText("Error : " + data.error?data.error : "", "red");
                    }
                };

                $scope.teamPost(postData, callback);
            }
        });

        // update the access rights
        var accessRightsContainer = $scope.modalContainer.find(".accessRights");
        var teamAccessRights = $scope.currentTeam.ID == 0 ? getAllAccessRights():$scope.currentTeam.accessRights;
        renderAccessRights(accessRightsContainer , editTeam?editTeam.accessRights : 0, $scope.modalContainer, $scope);

        if(editTeam){
            $scope.modalContainer.find(".teamName").val(editTeam.name);
        }

        $scope.modalContainer.adjustContentHeight();
    };


    $scope.currentTeamHasUser = function(userId){
        var result = false;
        for(var i=0; i< $scope.currentTeam.users.length; i++){
            if($scope.currentTeam.users[i].ID == userId){
                result = true;
                break;
            }
        }
        return result;
    };

    $scope.clearTypeAhead = function(){
        $scope.searchMembers = "";
        $("#teams_searchUsersInput").val("");
    };

    $scope.memberSelected = function(item, model, label, event, displayGroup){
        // Add the user to the current team
        $http({
            method: "POST", url: $scope.$parent.backend + "/teams", "params": {},
            "data": {action: "updateUserTeam", "userId": item.ID, "teamId": $scope.currentTeam.ID}
        })
        .success(function (data, status) {
            if (data.success) {
                $scope.currentTeam.users = $scope.currentTeam.users ? $scope.currentTeam.users : [];
                // Move the user to the target team
                $scope.currentTeam.users.push(item);
                $scope.removeUserFromTeam($scope.currentTeam, $scope.currentTeam);
                $scope.clearTypeAhead();
            } else {
                $scope.$parent.activAlert("An Error Has occurred : " + data.error, 4000);
            }
        })
        .error(function (data) {
            console.error(data);
            $scope.$parent.activAlert("An Error Has occurred : " + JSON.stringify(data), 4000);
        });
    };

    $scope.getMatchingMembers = function(val){
        var postData = {action : "getMatchingEntities", queryString : val};

        return  $http({ "method": "POST", "url": config.backend + "/teams", "params": {},
            "data": postData }).then(function(response, status){
            var data = response.data;
            var list = [];
            if(data.success) {
                // process
                // Load users who are not in the current team
                for (var i = 0; i < data.users.length; i++) {
                    var user = data.users[i];

                    if(!$scope.currentTeamHasUser(user.ID)) {
                        user.displayString = user.firstname + " " + user.surname + " (" + user.username + ")";
                        list.push(user);
                    }
                }

            }
            return list;
        });

    };

    // Initiate data load
    $scope.openRoot();
}]);
